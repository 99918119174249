<template>
  <b-modal id="printerTypeModal" :title="onlyPosition?$t('settings.printer.position'):$t('settings.printer.printer-type')" @ok="emitPrinterType">
    <template #default>
      <b-form-group v-if="onlyPosition"
                    :label="$t('settings.printer.select-position')"
                    label-for="labelPosition" v-slot="{ ariaDescribedby }">
        <div class="row mt-2 mb-2">
          <div class="col-3">
            <b-img src="/labelnumbers_a4.png" style="width: 100%" />
          </div>
          <div class="col-9">
            <b-form-radio-group name="labelPosition" :disabled="printerType != 'LASER_POSITION'"
                                v-model="labelPosition"
                                :aria-describedby="ariaDescribedby">
              <b-form-radio value="1">1</b-form-radio>
              <b-form-radio value="2">2</b-form-radio>
              <b-form-radio value="3">3</b-form-radio>
              <b-form-radio value="4">4</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </b-form-group>
      <b-form-group v-else
          :label="$t('settings.printer.select-printer-type')"
          label-for="printerType" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
            v-model="printerType"
            :aria-describedby="ariaDescribedby"
            name="printerType">
          <b-form-radio value="LASER">
            <span>{{ $t('settings.printer.labels.laser') }}</span>
            <b-form-text v-html="$t('settings.printer.labels.laser.description')" />
          </b-form-radio>
          <b-form-radio value="LASER_POSITION">
            <span>{{ $t('settings.printer.labels.laser_position') }}</span>
            <b-form-text v-html="$t('settings.printer.labels.laser.description')" />
            <b-form-group
                          :label="$t('settings.printer.select-position')"
                          label-for="labelPosition" v-slot="{ ariaDescribedby }">
              <div class="row">
                <div class="col-3">
                  <b-img src="/labelnumbers_a4.png" style="width: 100%" />
                </div>
                <div class="col-9">
                  <b-form-radio-group name="labelPosition" :disabled="printerType != 'LASER_POSITION'"
                                      v-model="labelPosition"
                                      :aria-describedby="ariaDescribedby">
                    <b-form-radio value="1">1</b-form-radio>
                    <b-form-radio value="2">2</b-form-radio>
                    <b-form-radio value="3">3</b-form-radio>
                    <b-form-radio value="4">4</b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>
            </b-form-group>
          </b-form-radio>
          <b-form-radio value="ZEBRA">
            <span>{{ $t('settings.printer.labels.zebra') }}</span>
            <b-form-text v-html="$t('settings.printer.labels.zebra.description')" />
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </template>
    <template #modal-footer="{ok, cancel, hide}">
      <b-checkbox v-if="!onlyPosition" v-model="save" class="mr-auto">{{ $t('settings.save') }}</b-checkbox>
      <b-button variant="secondary" @click="cancel">{{ $t('controls.abort') }}</b-button>
      <b-button variant="primary" @click="ok">{{ $t('controls.print') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "PrinterTypeModal",
  data() {
    return {
      onlyPosition: false,
      printerType: 'LASER',
      labelPosition: 1,
      save: false,
    }
  },
  methods: {
    show(onlyPosition) {
      if (onlyPosition) {
        this.onlyPosition = true
        this.printerType = 'LASER_POSITION'
        this.save = false
      }
      this.$bvModal.show('printerTypeModal')
    },
    emitPrinterType() {
      this.$emit('confirmed', { type: this.printerType, position: this.labelPosition, save: this.save} )
      this.save = false
    }
  }
}
</script>

<style scoped>

</style>