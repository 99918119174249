<template>
  <b-modal :id="id + '-importAddressDataModal'" title="Import Addresses" @ok="confirmImport">
    <template #default>
      <div class="mt-4">
        <b-form-file v-model="file" placeholder="Choose file" :accept="accept" />
      </div>
    </template>
    <template #modal-footer="{ok, cancel, hide}">
      <b-button variant="secondary" @click="cancel">{{ $t('controls.abort') }}</b-button>
      <b-button variant="primary" :disabled="!file" @click="ok">{{ $t('controls.ok') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ImportAddressDataModal",
  props: {
    id: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      file: null,
      accept: null
    }
  },
  methods: {
    reset() {
      this.file = null
      this.accept = null
    },
    show(acceptedTypes) {
      this.reset()
      this.accept = acceptedTypes
      this.$bvModal.show(this.id + '-importAddressDataModal')
    },
    confirmImport() {
      this.$emit('confirmed', this.file)
    }
  }
}
</script>

<style scoped>

</style>