var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-search-filter-page', {
    attrs: {
      "service": _vm.service
    },
    on: {
      "enableSpinner": function enableSpinner($event) {
        return _vm.enableSpinner();
      },
      "disableSpinner": function disableSpinner($event) {
        return _vm.disableSpinner();
      }
    },
    scopedSlots: _vm._u([{
      key: "header-controls",
      fn: function fn(_ref) {
        var _links$create;

        var created = _ref.created,
            items = _ref.items,
            links = _ref.links;
        return [_c('b-button-toolbar', {
          staticClass: "float-right",
          attrs: {
            "key-nav": ""
          }
        }, [links !== null && links !== void 0 && (_links$create = links.create) !== null && _links$create !== void 0 && _links$create.href ? _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary",
            "disabled": items.filter(function (item) {
              return !item._links;
            }).length > 0
          },
          on: {
            "click": function click($event) {
              created(_vm.service.create());
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "plus"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('controls.create')) + " ")], 1)], 1) : _vm._e()], 1)];
      }
    }, {
      key: "spinner",
      fn: function fn() {
        return [_vm.loading ? _c('b-spinner', {
          staticClass: "spinner",
          attrs: {
            "label": "Loading..."
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "table-header",
      fn: function fn(_ref2) {
        var items = _ref2.items,
            sortBy = _ref2.sortBy,
            sortIcon = _ref2.sortIcon;
        return [_c('div', {
          staticClass: "col-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('isDefaultSenderAddress');
            }
          }
        }, [_c(sortIcon('isDefaultSenderAddress'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('address.defaultAddress')))])], 1), _c('div', {
          staticClass: "col-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('customerId');
            }
          }
        }, [_c(sortIcon('customerId'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('address.customerId')))])], 1), _c('div', {
          staticClass: "col-3 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('name');
            }
          }
        }, [_c(sortIcon('name'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('address.name')))])], 1), _c('div', {
          staticClass: "col-5"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-5 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('street');
            }
          }
        }, [_c(sortIcon('street'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('address.street')))])], 1), _c('div', {
          staticClass: "col-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('country');
            }
          }
        }, [_c(sortIcon('country'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('address.country')))])], 1), _c('div', {
          staticClass: "col-2 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('zipCode');
            }
          }
        }, [_c(sortIcon('zipCode'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('address.zipCode')))])], 1), _c('div', {
          staticClass: "col-4 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('city');
            }
          }
        }, [_c(sortIcon('city'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('address.city')))])], 1)])])];
      }
    }, {
      key: "entity-header",
      fn: function fn(_ref3) {
        var id = _ref3.id,
            items = _ref3.items,
            entity = _ref3.entity,
            index = _ref3.index,
            deleted = _ref3.deleted,
            saved = _ref3.saved;
        return [_c('div', {
          staticClass: "col-1 clip-text text-center"
        }, [!!entity.isDefaultSenderAddress ? _c('span', [_c('e-tooltip', {
          attrs: {
            "title": _vm.$t('addressType.SENDER')
          }
        }, [_c('b-icon-check-circle')], 1)], 1) : _vm._e(), !!entity.isDefaultSenderAddress && !!entity.isDefaultConsigneeAddress ? _c('span', [_vm._v(" / ")]) : _vm._e(), !!entity.isDefaultConsigneeAddress ? _c('span', [_c('e-tooltip', {
          attrs: {
            "title": _vm.$t('addressType.CONSIGNEE')
          }
        }, [_c('b-icon-check-circle')], 1)], 1) : _c('span')]), _c('div', {
          staticClass: "col-1 clip-text"
        }, [_c('span', [_vm._v(_vm._s(entity.customerId))])]), _c('div', {
          staticClass: "col-3 clip-text"
        }, [_c('span', [_vm._v(_vm._s(entity.name))])]), _c('div', {
          staticClass: "col-5"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-5 clip-text"
        }, [_c('span', [_vm._v(_vm._s(entity.street))])]), _c('div', {
          staticClass: "col-1 clip-text"
        }, [_c('span', [_vm._v(_vm._s(entity.country))])]), _c('div', {
          staticClass: "col-2 clip-text"
        }, [_c('span', [_vm._v(_vm._s(entity.zipCode))])]), _c('div', {
          staticClass: "col-4 clip-text"
        }, [_c('span', [_vm._v(_vm._s(entity.city))])])])])];
      }
    }, {
      key: "entity-content",
      fn: function fn(_ref4) {
        var id = _ref4.id,
            entity = _ref4.entity,
            index = _ref4.index,
            deleted = _ref4.deleted,
            saved = _ref4.saved;
        return [_c('detail-view', {
          attrs: {
            "id": id,
            "value": entity
          },
          on: {
            "delete": function _delete($event) {
              return deleted(index);
            },
            "save": function save($event) {
              return saved(index, $event);
            },
            "enableSpinner": function enableSpinner($event) {
              return _vm.enableSpinner();
            },
            "disableSpinner": function disableSpinner($event) {
              return _vm.disableSpinner();
            }
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }