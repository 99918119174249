<template>
  <div class="card">
    <div class="card-header p-2">
      <div class="form-row align-items-center">
        <label class="col-6 mb-0">{{ $t('orders.cargo-list') }}</label>
        <div class="col-6 text-right">

          <b-dropdown v-if="editable" variant="link" toggle-class="p-0" right
                      class="template-dropdown-menu" @shown="onDropdownShown" @hidden="onDropdownHidden">

            <template #button-content>{{ $t('orders.add-cargo-item') }}</template>

            <b-dropdown-item @click="addCargoItem()">{{ $t('orders.add-empty-cargo-item') }}</b-dropdown-item>

            <b-dropdown-divider/>

            <b-dropdown-form @submit.stop.prevent
                             @focus="$refs.filterCargoItemTemplates.focus()">

              <b-form-input id="filter-cargoItemTemplates"
                            ref="filterCargoItemTemplates"
                            size="sm"
                            autocomplete="off"
                            :placeholder="$t('orders.search-templates')"
                            v-model="filterCargoItemTemplates"
                            v-debounce:500ms="findCargoItemTemplates"></b-form-input>
            </b-dropdown-form>

            <b-dropdown-divider v-if="cargoItemTemplates.length > 0"/>

            <b-dropdown-group class="template-dropdown-group">
            <b-dropdown-item v-if="cargoItemTemplates.length > 0"
                             v-for="(cargoItemTemplate, idx) in cargoItemTemplates"
                             :key="'template-' + idx"
                             @click="addCargoItemTemplate(cargoItemTemplate)">
              <div>
                <strong>{{ cargoItemTemplate.name }}</strong>
              </div>

            </b-dropdown-item>
            </b-dropdown-group>

          </b-dropdown>

        </div>
      </div>
    </div>

    <ul class="list-group list-group-flush">
      <validation-observer v-for="(item, idx) in value.items" :key="idx" :vid="'cargoListObserver-' + id + '[' + idx + ']'" ref="cargoListObserver">
      <li class="list-group-item">
        <div class="d-flex w-100 align-items-center">
          <h6 class="text-truncate">{{ $t('cargo-item.position', {position: idx + 1}) }}</h6>
        </div>

        <div>
          <div class="form-row">
            <div class="col-12 col-lg-6 col-xl-6">
              <div class="form-row">
                <div class="col-2 col-lg-3">
                  <e-form-text-input :id="id + '-cargoList.items[' + idx + '].colliQty'"
                                     type="number"
                                     min="1"
                                     step="1"
                                     name="colliQty"
                                     :rules="{
                                           required : true,
                                           numeric: true,
                                           positive: true,
                                           min_value: 1,
                                     }"
                                     size="sm"
                                     :placeholder="$t('cargo-item.colli.short')"
                                     :disabled="!editable"
                                     v-model="item.colliQty"
                                     @input="calculateVolume"/>
                </div>
                <div class="col-3 col-lg-3">
                  <packaging-code-select :id="id + '-cargoList.items[' + idx + '].packingCode'"
                                         name="packingCode"
                                         size="sm"
                                         :required="true"
                                         :disabled="!editable"
                                         v-model="item.packingCode"
                                         :filter="packagingCodeFilter"
                                         :placeholder="$t('cargo-item.packingCode.short')" />
                </div>
                <div class="col-7 col-lg-6">
                  <e-form-text-input :id="id + '-cargoList.items[' + idx + '].goods'"
                                     name="goods"
                                     maxlength="30"
                                     rules="required"
                                     size="sm"
                                     :placeholder="$t('cargo-item.goods')"
                                     :disabled="!editable"
                                     v-model="item.goods" />
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6">
              <div class="form-row">
                <div class="col-6">
                  <e-form-text-input :id="id + '-cargoList.items[' + idx + '].marking'"
                                     name="marking"
                                     maxlength="17"
                                     size="sm"
                                     :rules="requiredFields.includes('marking')?'required':''"
                                     :placeholder="$t('cargo-item.marking')"
                                     :disabled="!editable"
                                     v-model="item.marking" />
                </div>
                <div class="col-5">
                  <e-form-text-input :id="id + '-cargoList.items[' + idx + '].orderNumber'"
                                     name="orderNumber"
                                     maxlength="35"
                                     size="sm"
                                     :rules="requiredFields.includes('orderNumber')?'required':''"
                                     :placeholder="$t('cargo-item.orderNumber.short')"
                                     :disabled="!editable"
                                     v-model="item.orderNumber" />
                </div>
                <div class="col-1 text-right">
                  <b-icon v-if="editable && value.items.length > 1"
                          icon="trash"
                          style="margin-top: 7px; cursor: pointer;"
                          font-scale="1.2"
                          variant="danger"
                          @click="removeCargoItem(idx)">
                  </b-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12 col-lg-8 col-xl-8">
              <div class="form-row">
                <div class="col-3">
                  <e-form-text-input :id="id + '-cargoList.items[' + idx + '].length'"
                                     type="number"
                                     name="length"
                                     maxlength="3"
                                     size="sm"
                                     :rules="{
                                           required : !optionalFields?.includes('dimensions'),
                                           numeric: true,
                                           positive: true,
                                           min_value: 1,
                                     }"
                                     :placeholder="$t('cargo-item.length')"
                                     :disabled="!editable"
                                     v-model="item.length"
                                     :append="$t('unit.centimeter')"
                                     @blur="checkLength($event)"
                                     @input="calculateVolume" />
                </div>
                <div class="col-3">
                  <e-form-text-input :id="id + '-cargoList.items[' + idx + '].width'"
                                     type="number"
                                     maxlength="3"
                                     name="width"
                                     size="sm"
                                     :rules="{
                                           required : !optionalFields?.includes('dimensions'),
                                           numeric: true,
                                           positive: true,
                                           min_value: 1,
                                     }"
                                     :placeholder="$t('cargo-item.width')"
                                     :disabled="!editable"
                                     v-model="item.width"
                                     :append="$t('unit.centimeter')"
                                     @input="calculateVolume"/>
                </div>
                <div class="col-3">
                  <e-form-text-input :id="id + '-cargoList.items[' + idx + '].height'"
                                     type="number"
                                     maxlength="3"
                                     name="height"
                                     size="sm"
                                     :rules="{
                                           required : !optionalFields?.includes('dimensions'),
                                           numeric: true,
                                           positive: true,
                                           min_value: 1,
                                     }"
                                     :placeholder="$t('cargo-item.height')"
                                     :disabled="!editable"
                                     v-model="item.height"
                                     :append="$t('unit.centimeter')"
                                     @blur="checkHeight($event)"
                                     @input="calculateVolume"/>
                </div>
                <div class="col-3">
                  <e-form-text-input :id="id + '-cargoList.items[' + idx + '].weight'"
                                     type="number"
                                     maxlength="5"
                                     name="weight"
                                     :rules="{
                                           required : true,
                                           numeric: true,
                                           positive: true,
                                           min_value: 1,
                                     }"
                                     size="sm"
                                     :placeholder="$t('cargo-item.weight')"
                                     :disabled="!editable"
                                     v-model="item.weight"
                                     :append="$t('unit.kilogram')" />
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-2 col-xl-2">
              <div class="form-check form-check-inline">
                <b-checkbox name="dangerousGoods"
                            style="margin-top: 5px"
                            size="sm"
                            :disabled="!editable || (item.hazmats && item.hazmats.length > 0)"
                            :checked="(item.hazmats && item.hazmats.length > 0)"
                            @change="enableHazmat($event, item)">
                  {{ $t('cargo-item.dangerousGoods') }}
                </b-checkbox>
              </div>
            </div>
          </div>

          <ul class="list-group list-group-flush" v-if="item.hazmats && item.hazmats.length > 0">
            <div class="d-flex w-100 align-items-center">
              <h6 class="text-truncate">{{ $t('cargo-item.adr.for-position', {position: (idx + 1)}) }}</h6>
              <div class="col d-flex justify-content-end">
                <button v-if="editable" class="btn btn-sm btn-link" @click="addHazmat(item)">
                  <b-icon icon="plus"></b-icon>
                  {{ $t('cargo-item.adr.add') }}
                </button>
              </div>
            </div>
            <li class="list-group-item" v-for="(hazmat, hazmatIdx) in item.hazmats" :key="hazmatIdx">
              <div class="form-row">
                <div class="col-12 col-lg-6 col-xl-6">
                  <div class="form-row">
                    <div class="col-2 hazmat-unnr">
                      <un-number-select
                          :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].unNumber'"
                          rules="required" size="sm"
                          :editable="editable"
                          :value="hazmat.unNumber" @input="hazmatTemplateSelected($event, hazmat)" />
                    </div>
                    <div class="col-3">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].quantity'"
                                         type="number" min="1" step="1"
                                         name="quantity"
                                         :rules="{
                                           required : true,
                                           numeric: true,
                                           positive: true,
                                           min_value: 1,
                                         }"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.quantity.short')"
                                         :disabled="!editable"
                                         v-model="hazmat.quantity"/>
                    </div>
                    <div class="col-2">
                      <hazmat-packaging-code-select
                          :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].packagingCode'"
                          name="packagingCode"
                          size="sm"
                          :placeholder="$t('cargo-item.packingCode')"
                          :disabled="!editable"
                          :required="true"
                          v-model="hazmat.packagingCode" />
                    </div>
                    <div class="col-5">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].shippingName'"
                                         name="shippingName"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.shipping-name')"
                                         :disabled="true"
                                         :value="hazmat.shippingName"/>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div class="form-row">
                    <div class="col-2">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].packagingGroup'"
                                         name="packagingGroup"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.packaging-group.short')"
                                         disabled
                                         rules="required"
                                         :value="hazmat.packagingGroup || '-'"/>
                    </div>
                    <div class="col-2">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].hazardousSubstanceLabel'"
                                         name="hazardousSubstanceLabel"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.hazardous-substance-label.short')"
                                         disabled
                                         :value="hazmat.hazardousSubstanceLabel || '-'"/>
                    </div>
                    <div class="col-2">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].classificationCode'"
                                         name="classificationCode"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.classification-code.short')"
                                         disabled
                                         :value="hazmat.classificationCode || '-'"/>
                    </div>
                    <div class="col-2">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].tunnelRestrictionCode'"
                                         name="tunnelRestrictionCode"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.tunnel-restriction-code.short')"
                                         disabled
                                         :value="hazmat.tunnelRestrictionCode || '-'"/>
                    </div>
                    <div class="col-2">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].transportCategory'"
                                         name="transportCategory"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.transport-category.short')"
                                         disabled
                                         :value="hazmat.transportCategory || '-'"/>
                    </div>
                    <div class="col-2"></div>

                  </div>
                </div>
                <div class="col-12 col-lg-4 col-xl-4">
                  <div class="form-row">
                    <div class="col-12">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].technicalName'"
                                         name="technicalName"
                                         :rules="{required: hazmat.technicalNameRequired}"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.technical-name.short')"
                                         :disabled="!editable"
                                         v-model="hazmat.technicalName"/>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-8 col-xl-8">
                  <div class="form-row">
                    <div class="col-6">
                      <e-form-checkbox :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].limitedQuantities'"
                                       name="limitedQuantities"
                                       size="sm"
                                       :placeholder="$t('cargo-item.adr.limited-quantities')"
                                       :disabled="!editable"
                                       v-model="hazmat.limitedQuantities"/>
                      <e-form-checkbox :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].environmentalHazard'"
                                       name="environmentalHazard"
                                       size="sm"
                                       :placeholder="$t('cargo-item.adr.environmental-hazard')"
                                       :disabled="!editable"
                                       v-model="hazmat.environmentalHazard"/>
                    </div>
                    <div class="col-3">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].weight'"
                                         type="number"
                                         name="weight"
                                         :rules="{
                                           required : true,
                                           positive: true,
                                           max_value: hazmat.massUnit == 'kg'? item.weight : false
                                         }"
                                         size="sm"
                                         :placeholder="$t('cargo-item.weight')"
                                         :disabled="!editable"
                                         v-model="hazmat.weight"
                                         :append="hazmat.massUnit">
                        <template v-slot:append="{additionalClasses}" v-if="editable">
                          <b-dropdown
                              variant="secondary" class="p-0" :class="additionalClasses" toggle-class="input-group-text dropdown-input"
                              :text="hazmat.massUnit || 'kg'">
                            <b-dropdown-item @click="hazmat.massUnit='kg'">{{ $t('unit.kilogram') }}</b-dropdown-item>
                            <b-dropdown-item @click="hazmat.massUnit='ltr'">{{ $t('unit.litres') }}</b-dropdown-item>
                          </b-dropdown>
                        </template>
                      </e-form-text-input>
                    </div>
                    <div class="col-2">
                      <e-form-text-input :id="id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].neq'"
                                         type="number"
                                         name="neq"
                                         :rules="{required: hazmat.hazardClass.startsWith('1')}"
                                         size="sm"
                                         :placeholder="$t('cargo-item.adr.net-explosive-quantity.short')"
                                         :disabled="!editable || !hazmat.hazardClass.startsWith('1')"
                                         v-model="hazmat.neq"
                                         :append="$t('unit.kilogram')"/>
                    </div>
                    <div class="col-1 text-right">
                      <b-icon icon="trash"
                              style="margin-top: 7px; cursor: pointer;"
                              font-scale="1.2"
                              variant="danger"
                              v-if="editable"
                              @click="deleteHazmat(item, hazmatIdx)"></b-icon>
                    </div>

                  </div>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </li>
      </validation-observer>
    </ul>
  </div>
</template>

<script>
import Vue from "vue"
import {ValidationObserver, ValidationProvider} from "@emons/emons-vue"
import HazmatService from "@/services/hazmat.service"
import SettingsService from "@/services/settings.service";
import vueDebounce from 'vue-debounce'
import CargoListTemplateService from "@/services/cargoListTemplate.service";
import HazmatPackagingCodeSelect from "@/components/form-controls/HazmatPackagingCodeSelect";
import PackagingCodeSelect from "@/components/form-controls/PackagingCodeSelect";
import UnNumberSelect from "@/components/form-controls/UnNumberSelect";

Vue.use(vueDebounce)

export default {
  name: "OrderCargoList",
  components: {UnNumberSelect, HazmatPackagingCodeSelect, PackagingCodeSelect, ValidationObserver, ValidationProvider},
  props: {
    value: {
      type: Object,
    },
    id: {
      type: String,
      default: 'cargoList'
    },
    editable: {
      type: Boolean,
      default: false
    },
    requiredFields: {
      type: Array,
      default: () => []
    },
    optionalFields: {
      type: Array,
      default: () => []
    },
    additionalPaletteSwapCountries: {
      type: Array,
      default: () => []
    },
    consigneeCountry: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      hazmatList: null,
      filterCargoItemTemplates: null,
      cargoItemTemplates: []
    }
  },
  methods: {
    async onDropdownShown() {
      await this.$nextTick() // dropdown-menu might not be in DOM yet
      const menu = this.$el.querySelector('.dropdown-menu')
      menu.addEventListener('mouseenter', this.mouseListener, false)
      menu.addEventListener('mouseleave', this.mouseListener, false)
    },
    onDropdownHidden() {
      const menu = this.$el.querySelector('.dropdown-menu')
      menu.removeEventListener('mouseenter', this.mouseListener, false)
      menu.removeEventListener('mouseleave', this.mouseListener, false)
      this.lockParentScroll(false)
    },
    mouseListener(event) {
      this.lockParentScroll(event.type == 'mouseenter')
    },
    lockParentScroll(lock) {
      this.$eventBus.$emit('scroll:lock', lock)
    },
    showAlert: function (message, title = "Achtung!") {
      this.$bvModal.msgBoxOk(message, {
        title: title,
        titleClass: 'pl-2',
        centered: true,
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
      })
    },
    checkLength: function (length) {
      if (length > 745) {
        this.showAlert(this.$t('shipment.exceptional.length') + " - " + this.$t('shipment.call-branch-office'))
      }
    },
    checkHeight: function (height) {
      if (height > 220) {
        this.showAlert(this.$t('shipment.exceptional.height') + " - " + this.$t('shipment.call-branch-office'))
      }
    },
    hazmatTemplateSelected: function (template, hazmat) {
      if (!template || typeof (template) !== 'object') {
        return
      }
      this.$log('DEBUG', 'hazmat template selected', template)

      hazmat.unNumber = template.unNumber
      hazmat.shippingName = template.name
      if (hazmat.hazardClass && hazmat.hazardClass.startsWith('1') && !(template.clazz && template.clazz.startsWith('1'))) {
        this.$delete(hazmat, 'neq')
      }
      hazmat.hazardClass = template.clazz
      hazmat.packagingGroup = template.packagingGroup
      hazmat.hazardousSubstanceLabel = template.note
      hazmat.classificationCode = template.classificationCode
      hazmat.tunnelRestrictionCode = template.tunnelRestrictionCode
      hazmat.transportCategory = template.transportCategory
      hazmat.massUnit = template.massUnit == '2' ? 'ltr' : 'kg'
      hazmat.technicalNameRequired = (template.collectiveTerm == '1')
      hazmat.factor = template.factor

      this.$log('DEBUG', 'set hazmat from selected template', hazmat)
    },
    addHazmat: function (item) {
      item.hazmats.splice(item.hazmats.length, 0, {
        unNumber: null,
        quantity: 1,
        packagingCode: null,
        shippingName: '',
        hazardClass: '',
        packagingGroup: '',
        hazardousSubstanceLabel: '',
        classificationCode: '',
        tunnelRestrictionCode: '',
        transportCategory: '',
        massUnit: 'kg',
        technicalNameRequired: false,
        technicalName: '',
        factor: 0
      })
    },
    deleteHazmat: function (item, idx) {
      item.hazmats.splice(idx, 1)
    },
    enableHazmat: function (hazmatEnabled, item) {
      this.$log('debug', 'enableHazmat: ' + hazmatEnabled)
      if (hazmatEnabled) {
        if (item.hazmats == null) Vue.set(item, 'hazmats', [])
        if (item.hazmats.length == 0) {
          this.addHazmat(item)
        }
      } else {
        if (item.hazmats != null && item.hazmats.length > 0) {
          item.hazmats.splice(0, item.hazmats.length)
        }
      }
    },
    reset: async function () {
      if (this.editable) {
        this.findCargoItemTemplates();

        if (this.hazmatList == null) {
          await HazmatService.findAll().then(response => {
            this.hazmatList = response?.data?.items
          })
          this.$log('debug', 'hazmat list loaded', this.hazmatList)
        }
      }
    },
    findCargoItemTemplates: function () {
      CargoListTemplateService
          .find(
              this.filterCargoItemTemplates,
              null,
              null,
              'name',
              'asc',
              15)
          .then(response => {
            this.cargoItemTemplates = response.data ? response.data.items : []
          })
    },
    canOverwrite: function(item) {
      return (!item.colliQty || item.colliQty == 1)
          && !item.packingCode && !item.goods && !item.marking && !item.orderNumber
          && !item.length && !item.width && !item.height && !item.weight && !item.hazmats?.length
    },
    addCargoItemTemplate: function (template) {
      // Beim Hinzufügen einer Vorlage => Standard-Item löschen falls überschreibbar (= leer)
      if (this.value.items.length === 1 && this.canOverwrite(this.value.items[0])) {
        this.value.items = []
      }

      const position = this.value.items.length
      this.addCargoItem()
      this.enableHazmat(template.dangerousGoods, this.value.items[this.value.items.length - 1])
      this.$nextTick(() => {
        Vue.set(this.value.items, position, {...template})
      })
    },
    addCargoItem: function () {
      Vue.set(this.value.items, this.value.items.length, {
        marking: "",
        orderNumber: "",
        colliQty: 1,
        packingCode: "",
        weight: "",
        goods: "",
        length: "",
        width: "",
        height: "",
        hazmats: [],
      })
    },
    removeCargoItem: function (idx) {
      this.value.items.splice(idx, 1)
    },
    calculateVolume: function() {
      if (!this.editable) {
        return
      }

      let cbm = 0

      this.value.items.forEach((item, index) => {
        if(item.colliQty && item.length && item.width && item.height) {
          cbm += item.colliQty * (item.length * item.width * item.height / 1000000)
        }
      })

      this.$log('debug', 'calculated volume in cbm: ' + cbm)
      if (cbm > 0) {
        this.value.cbm = parseFloat(cbm).toFixed(3)
      }
    },
  },
  computed: {
    packagingCodeFilter: function () {
      const country = this.consigneeCountry;
      if (['DE', 'AT', 'BE', 'NL', 'LU', 'CH', 'CZ', 'SK'].includes(country)) {
        return null
      } else if (this.additionalPaletteSwapCountries.includes(country)) {
        return null
      } else {
        return (code) => 'FP' != code.identifier
      }
    }
  },
  watch: {
    "value.items": {
      immediate: true,
      handler: function() {
        this.calculateVolume()
      }
    },
    value: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        await this.reset()
      }
    },
    editable: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        await this.reset()
      }
    }
  }
}
</script>

<style>
.template-dropdown-group {
  max-height: 200px;
  overflow-y: scroll;
}
.hazmat-unnr .dropdown-content {
  min-width: 450px !important;
}

#filter-cargoItemTemplates {
  min-width: 225px;
}

</style>