<template>
  <hazmat-code-list />
</template>

<script>
import HazmatCodeList from "@/components/HazmatCodeList";

export default {
  name: "AdminHazmatCodes",
  components: {HazmatCodeList}
}
</script>

<style scoped>

</style>