<template>
  <e-scrollable-search-filter-page :service="service"
                                   v-on:enableSpinner="enableSpinner()"
                                   v-on:disableSpinner="disableSpinner()">

    <template v-slot:spinner>

      <b-spinner v-if="loading"
                 class="spinner"
                 label="Loading..."/>

    </template>

    <template v-slot:entity-content="{ id, entity, index, saved }">

      <detail-view :value="entity" :id="id"
                    @save="saved(index, $event)"
                    v-on:enableSpinner="enableSpinner()"
                    v-on:disableSpinner="disableSpinner()"/>

    </template>

  </e-scrollable-search-filter-page>
</template>

<script>
import service from "@/services/locationDetails.service";
import DetailView from "@/components/LocationView";
import {log} from "@emons/emons-vue"

export default {
  name: 'LocationList',
  components: {DetailView},
  data() {
    return {
      loading: false,
      service: service
    }
  },
  methods: {
    enableSpinner: function () {
      log('debug', 'enableSpinner() called...')
      this.loading = true
    },
    disableSpinner: function () {
      log('debug', 'disableSpinner() called...')
      this.loading = false
    }
  }
}
</script>

<style scoped>

.spinner {
  position: absolute;
  z-index: 100000;
  top: 25%;
  left: 50%;
}

</style>