import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const rel = 'cargoListTemplates'
const path = '/api/templates/cargoitems';
const schema = {
    name: null,
    marking: null,
    orderNumber: null,
    colliQty: null,
    packingCode: null,
    goods: null,
    dangerousGoods: null,
    weight: null,
    length: null,
    width: null,
    height: null
}
const header = {
    name: {
        name: 'template.name',
        col: 'col-2',
        property: 'name'
    },
    colliQty: {
        name: 'cargo-item.colli',
        col: 'col-1',
        property: 'colliQty'
    },
    goods: {
        name: 'cargo-item.goods',
        col: 'col-3',
        property: 'goods'
    },
    packingCode: {
        name: 'cargo-item.packingCode',
        col: 'col-1',
        property: 'packingCode'
    },
    marking: {
        name: 'cargo-item.marking',
        col: 'col-2',
        property: 'marking'
    },
    length: {
        name: 'cargo-item.length',
        col: 'col-1',
        property: 'length'
    },
    width: {
        name: 'cargo-item.width',
        col: 'col-1',
        property: 'width'
    },
    height: {
        name: 'cargo-item.height',
        col: 'col-1',
        property: 'height'
    }
}
const filter = null
const sync = null
const initialSort = 'name'
const projection = 'list'
const create = true;

const Service = {
    getHeader: function () {
        return header
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function() {
        return initialSort
    },
    getProjection: function() {
        return projection
    },
    getDefault: async function() {
        try {
            const response = await this.find(
                null,
                null,
                null,
                'isDefault',
                'asc',
                1)
            if (response.data && response.data.items && response.data.items.length > 0) {
                let first = response.data.items[0]

                if (first['isDefault'])
                    return first;
            }
        } catch (error) {
            if (404 == error.response?.status) {
                log('debug', 'default cargolist template not found: ', error)
            } else {
                log('error', 'Error querying default cargolist template: ', error)
            }
        }
    },
    find: async function (query,
                          filters, // nur wg. allgemeiner Signatur hier
                          projection,
                          sortProperty,
                          sortDirection,
                          size) {
        try {
            return ApiService.get(path, {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'] && parsedData['_embedded'][rel]?parsedData['_embedded'][rel]:[]
                            }

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)
                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    nextPage: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'] && parsedData['_embedded'][rel]?parsedData['_embedded'][rel]:[]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    save: async function(entity) {
        if (entity._links && entity._links.self)
            return ApiService.put(this.cleanLink(entity._links.self.href), entity).then(
                response => {
                    if(response.data)
                        ApiService.ensureSchema(schema, response.data)

                    return response
                })
        else
            return ApiService.post(path, entity).then(
                response => {
                    if(response.data)
                        ApiService.ensureSchema(schema, response.data)

                    return response
                })
    },
    delete: async function(entity) {
        if (entity._links && entity._links.self) {
            return ApiService.delete(this.cleanLink(entity._links.self.href))
        }
    },
    create: function () {
        log('debug', 'create() called...')
        return {
            isOpen: true,
            changed: true
        };
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    }
}

export default Service