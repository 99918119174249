var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value.type == 'MOD' ? _c('ul', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'audit-collapse-' + _vm.value.revision,
      expression: "'audit-collapse-' + value.revision"
    }]
  }, [!_vm.visible ? _c('b-icon-caret-right-fill') : _c('b-icon-caret-down-fill'), _c('span', {
    staticStyle: {
      "margin-left": "0.5em"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.$t('auditlog.entry.MOD', _vm.formatterParams))
    }
  }), _c('b-collapse', {
    attrs: {
      "id": 'audit-collapse-' + _vm.value.revision
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, _vm._l(_vm.value.modifications, function (value, key) {
    return _c('li', [_vm._v(_vm._s(_vm.$t(key)) + " auf "), _c('strong', [_vm._v(_vm._s(value))]), _vm._v(" geändert")]);
  }), 0)], 1) : _vm.value.type == 'ADD' ? _c('ul', [_c('b-icon-pencil-fill'), _c('span', {
    staticStyle: {
      "margin-left": "0.5em"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.$t('auditlog.entry.ADD', _vm.formatterParams))
    }
  })], 1) : _vm.value.type == 'DEL' ? _c('ul', [_c('span', {
    staticStyle: {
      "margin-left": "0.5em"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.$t('auditlog.entry.ADD', _vm.formatterParams))
    }
  })]) : _c('ul');
}
var staticRenderFns = []

export { render, staticRenderFns }