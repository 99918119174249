<template>
  <v-select ref="vselect"
            @open="onOpen" @close="onClose" @search="onDebouncedSearch" @input="onInput"
            :disabled="!editable"
            :options="results"
            :loading="isLoading"
            :filterable="false"
            :clearable="false"
            :select-on-tab="$refs.vselect?.$data?.open" class="addressbook-dropdown"
            :placeholder="$t('orders.search-addressbook')" label="name"
            :value="selectedEntry">

    <template v-slot:option="option">
      <strong>{{ option.name }}</strong> <span v-if="showType && types.includes('SENDER')" class="font-weight-lighter font-italic small">({{ getSenderDisplayType(option) }})</span>
      <br>
      <span v-if="option.customerId">{{ option.customerId }}</span>
      <span v-if="option.customerId"> | </span>
      <span>{{ option.street }}</span>
      <span> | </span>
      <span>{{ option.country }}-{{ option.zipCode }} {{ option.city }}</span>
    </template>


    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
        Loading...
      </li>
    </template>

  </v-select>
</template>

<script>
import AddressService from "@/services/address.service";
import {debounce} from 'vue-debounce';
import InvoiceRecipientAddressService from "@/services/invoiceRecipientAddress.service";
//import debounce from "lodash.debounce";

export default {
  name: "AddressbookDropdown",
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    types: {
      default: []
    },
    pageSize: {
      type: Number,
      default: 25
    },
    showType: {
      type: Boolean,
      default: false
    },
    apiPath: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      query: null,
      currentPage : 0,
      totalPages: -1,
      observer: null,
      typeAheadObserver: null,
      results: [],
      selectedEntry: null,
    }
  },
  created() {
    this.onDebouncedSearch = debounce(searchString => {
      if (searchString !== this.query) {
        this.query = searchString
        this.loadPage(0)
      }
    }, 500);
  },
  beforeUnmount() {
    this.onDebouncedSearch.cancel();
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  computed: {
    hasNextPage() {
      return this.totalPages < 0 || this.currentPage < (this.totalPages - 1)
    },
  },
  methods: {
    getSenderDisplayType(entry) {
      const types = entry.types

      if (types.includes('NEUTRAL')) {
        if (entry.emonsCustomerId?.length > 0 && entry.emonsCustomerId == entry.customerId) {
          return this.$t('address.useNeutralLoadingSite')
        } else {
          return this.$t('address.useNeutralConsignor')
        }
      } else if (types?.includes('LOADING_SITE')) {
        return this.$t('addressType.LOADING_SITE')
      } else if (types?.includes('SENDER')) {
        return this.$t('addressType.SENDER')
      } else {
        // catchall for old entries with no type set
        return this.$t('addressType.SENDER')
      }
    },
    async search(page = 0) {
      this.$log('debug', 'AddressbookDropdown.search() called...', this.query, page)
      //this.isLoading = true
      const filter = this.types.map((t) => { return { type: t, active: true }})
      let results = null
      try {
        let response
        if (this.types.includes('INVOICE_RECIPIENT') && this.types.length == 1) {
          if (this.apiPath) {
            response = await InvoiceRecipientAddressService.findWithPath(
                this.apiPath,
                this.query,
                null,
                'dropDown',
                'name',
                'asc',
                100)
          } else {
            response = await InvoiceRecipientAddressService.find(
                this.query,
                null,
                'dropDown',
                'name',
                'asc',
                this.pageSize,
                page)
          }
        } else {
          response = await AddressService.find(
              this.query,
              filter,
              'dropDown',
              'name',
              'asc',
              this.pageSize,
              page)
        }
        if (response?.data) {
          results = response.data
        }
      } finally {
        //this.isLoading = false
      }
      return results
    },
    async loadPage(page) {
      const results = await this.search(page)
      let items
      if (results?.items) {
        this.totalPages = results?.page?.totalPages
        this.currentPage = results?.page?.number
        items = results.items
      } else {
        this.totalPages = -1
        this.currentPage = 0
        items = []
      }
      if (this.currentPage > 0) {
        items.forEach(item => this.results.push(item))
      } else {
        this.results = items
      }
    },
    mouseListener(event) {
      this.lockParentScroll(event.type == 'mouseenter')
    },
    lockParentScroll(lock) {
      this.$eventBus.$emit('scroll:lock', lock)
    },
    async onOpen() {
      if (this.hasNextPage) {
        this.$log('DEBUG', 'OBSERVING')
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
        // hacky solution for infinite scroll when navigating the dropdown with keyboard up/down:
        // - watch the vselect internal typeAheadPointer for changes
        // - if typeAheadPointer is at last result and more pages are available, then
        //   - fetch next results (= call regular infiniteScroll function
        //   - set typeAheadPointer to the value at function call, cause it gets reset when results are fetched
        this.typeAheadObserver = this.$watch("$refs.vselect.$data.typeAheadPointer",
            async (newValue) => {
              if (newValue == (this.results?.length - 1) && this.hasNextPage) {
                await this.infiniteScroll([{isIntersecting: true, target: this.$refs.load}])
                this.$refs.vselect.$data.typeAheadPointer = newValue
              }
            }
        )
      }

      // UGLY UGLY UGLY hack:
      // - add event listeners for mouseenter / mouseleave on dropdown menu using plain old js
      // - as vue-custom-scrollbar doesn't emit for these yet events yet
      await this.$nextTick() // dropdown-menu might not be in DOM yet
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.addEventListener('mouseenter', this.mouseListener, false)
      menu.addEventListener('mouseleave', this.mouseListener, false)
    },
    onClose() {
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.removeEventListener('mouseenter', this.mouseListener, false)
      menu.removeEventListener('mouseleave', this.mouseListener, false)
      this.lockParentScroll(false)

      this.observer.disconnect()
      if (!!this.typeAheadObserver) {
        this.typeAheadObserver()
        this.typeAheadObserver = null
      }
    },
    clear() {
      this.selectedEntry = null
    },
    async onSearch(searchString) {
      if (searchString !== this.query) {
        this.query = searchString
        await this.loadPage(0)
      }
    },
    async onInput($event) {
      this.selectedEntry = $event
      await this.$nextTick()
      this.$emit('input', $event)
    },
    async infiniteScroll([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await this.loadPage(this.totalPages < 1?0:this.currentPage + 1)

        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    }
  }
}
</script>

<style>

.addressbook-dropdown .vs__dropdown-menu {
  overflow-x: hidden;
}

</style>