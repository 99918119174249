import { axios } from '@emons/emons-vue'
import EmonsVue from '@emons/emons-vue'

const ApiService = {
    _addHeaders(config = {}) {
      const locale =   EmonsVue.vm?.$i18n?.locale
      if (locale) {
          return {
              ...config,
              headers: {
                  ...config?.headers,
                  'X-Override-Accept-Language': locale
              }
          }
      }
      return config
    },

    init(baseURL) {
        axios.defaults.baseURL = baseURL
    },

    ensureSchema(schema, obj) {
        Object.keys(schema).forEach((key) => {
            if(!Object.prototype.hasOwnProperty.call(obj, key)) {
                obj[key] = schema[key]
            }
        })
        return obj
    },

    get(resource, config = {}) {
        return axios.get(resource, this._addHeaders(config))
    },

    post(resource, data, config = {}) {
        return axios.post(resource, data, this._addHeaders(config))
    },

    put(resource, data, config = {}) {
        return axios.put(resource, data, this._addHeaders(config))
    },

    delete(resource, config = {}) {
        return axios.delete(resource, this._addHeaders(config))
    },
}

export default ApiService