var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id + '-importAddressDataModal',
      "title": "Import Addresses"
    },
    on: {
      "ok": _vm.confirmImport
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mt-4"
        }, [_c('b-form-file', {
          attrs: {
            "placeholder": "Choose file",
            "accept": _vm.accept
          },
          model: {
            value: _vm.file,
            callback: function callback($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn(_ref) {
        var ok = _ref.ok,
            cancel = _ref.cancel,
            hide = _ref.hide;
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.abort')))]), _c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.file
          },
          on: {
            "click": ok
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.ok')))])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }