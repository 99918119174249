var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmit(_vm.onSubmit);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-3 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmatCode-code'
    }
  }, [_vm._v(_vm._s(_vm.$t('hazmat-code.identifier')))]), _c('div', {
    staticClass: "col-9"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmatCode-code',
      "value": _vm.entity.code
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-3 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmatCode-name'
    }
  }, [_vm._v(_vm._s(_vm.$t('hazmat-code.label')))]), _c('div', {
    staticClass: "col-9"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmatCode-name',
      "value": _vm.entity.name
    }
  })], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }