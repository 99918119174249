var render = function () {
  var _vm$value,
      _vm$consignee,
      _vm$consignee2,
      _vm$consignee3,
      _vm$consignee4,
      _vm$sender,
      _vm$value2,
      _vm$value3,
      _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header p-2"
  }, [_c('div', {
    staticClass: "form-row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 mb-0"
  }, [_vm._v(_vm._s(_vm.title ? _vm.title : _vm.$t('orders.deliverynotes')))]), _c('div', {
    staticClass: "col-6 text-right"
  }, [_vm.editable ? _c('b-dropdown', {
    staticClass: "notification-code-drop-down",
    attrs: {
      "disabled": ((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.length) >= _vm.maxNotes,
      "variant": "link",
      "toggle-class": "p-0",
      "right": ""
    },
    on: {
      "hidden": function hidden($event) {
        _vm.searchTerm = '';
      },
      "shown": function shown($event) {
        return _vm.$refs.filterNotes.focus();
      }
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('controls.add')) + " ")];
      },
      proxy: true
    }], null, false, 3454851336)
  }, [_c('b-dropdown-form', {
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
      },
      "focus": function focus($event) {
        return _vm.$refs.filterNotes.focus();
      }
    }
  }, [_c('b-form-input', {
    ref: "filterNotes",
    attrs: {
      "id": "filter-notes",
      "size": "sm",
      "autocomplete": "off",
      "placeholder": "Filter"
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-dropdown-divider'), _vm._l(_vm.filteredDeliveryNotes, function (note, idx) {
    return _c('b-dropdown-item', {
      key: 'selectableNotes' + idx,
      on: {
        "click": function click($event) {
          return _vm.addDeliveryNote(note);
        }
      }
    }, [_vm._v(_vm._s(note.identifier + ' - ' + note.name) + " ")]);
  })], 2) : _vm._e()], 1)])]), _vm.enableRules ? _c('validation-provider', {
    attrs: {
      "vid": "consignee.phone",
      "name": _vm.$t('form-fields.consignee.phone'),
      "slim": ""
    }
  }, [_c('input', {
    ref: "consignee.phone",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": (_vm$consignee = _vm.consignee) === null || _vm$consignee === void 0 ? void 0 : _vm$consignee.phone
    }
  })]) : _vm._e(), _vm.enableRules ? _c('validation-provider', {
    attrs: {
      "vid": "consignee.mobile",
      "name": _vm.$t('form-fields.consignee.mobile'),
      "slim": ""
    }
  }, [_c('input', {
    ref: "consignee.mobile",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": (_vm$consignee2 = _vm.consignee) === null || _vm$consignee2 === void 0 ? void 0 : _vm$consignee2.mobile
    }
  })]) : _vm._e(), _vm.enableRules ? _c('validation-provider', {
    attrs: {
      "vid": "consignee.email",
      "name": _vm.$t('form-fields.consignee.email'),
      "slim": ""
    }
  }, [_c('input', {
    ref: "consignee.email",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": (_vm$consignee3 = _vm.consignee) === null || _vm$consignee3 === void 0 ? void 0 : _vm$consignee3.email
    }
  })]) : _vm._e(), _vm.enableRules ? _c('validation-provider', {
    attrs: {
      "vid": "consignee.web",
      "name": _vm.$t('form-fields.consignee.web'),
      "slim": ""
    }
  }, [_c('input', {
    ref: "consignee.web",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": (_vm$consignee4 = _vm.consignee) === null || _vm$consignee4 === void 0 ? void 0 : _vm$consignee4.web
    }
  })]) : _vm._e(), _vm.enableRules ? _c('validation-provider', {
    attrs: {
      "vid": "sender.phone",
      "name": _vm.$t('form-fields.sender.phone'),
      "slim": ""
    }
  }, [_c('input', {
    ref: "sender.phone",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": (_vm$sender = _vm.sender) === null || _vm$sender === void 0 ? void 0 : _vm$sender.phone
    }
  })]) : _vm._e(), ((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.length) > 0 ? _c('ul', {
    staticClass: "list-group list-group-flush"
  }, [_vm.editable && ((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.length) >= _vm.maxNotes ? _c('li', {
    staticClass: "list-group-item bg-warning text-dafk"
  }, [_c('b-icon-exclamation-triangle'), _vm._v(" " + _vm._s(_vm.$t('shipment.deliverynotes.maximum-reached')) + " ")], 1) : _vm._e(), _vm._l(_vm.value.filter(function (n) {
    if (_this.filter) { return _this.filter(n); }else { return true; }
  }), function (note, idx) {
    return _c('li', {
      key: 'deliveryNotes' + idx,
      staticClass: "list-group-item"
    }, [_c('delivery-note', {
      attrs: {
        "id": _vm.id + '-deliveryNotes[' + idx + ']',
        "editable": _vm.editable,
        "note": note,
        "consignee": _vm.consignee,
        "sender": _vm.sender,
        "enable-rules": _vm.enableRules
      },
      on: {
        "delete": function _delete($event) {
          return _vm.removeDeliveryNote(note);
        },
        "attachment-value": function attachmentValue($event) {
          note.attachmentValue = $event;
        }
      }
    })], 1);
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }