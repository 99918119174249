<template>
  <location-list />
</template>

<script>
import LocationList from "@/components/LocationList";

export default {
  name: "AdminLocations",
  components: {LocationList}
}
</script>

<style scoped>

</style>