<template>
  <e-scrollable-search-filter-page :service="service" ref="page"
                                   v-on:enableSpinner="loading(true)"
                                   v-on:disableSpinner="loading(false)">
    <template v-slot:header-controls="{ created }">
      <b-button-toolbar key-nav class="float-right">
        <!--
        <b-button-group class="mx-1">
          <b-button id="sync-button" type="button" size="sm" variant="secondary" class="pl-2 pr-2">
            <b-icon icon="cloud-download" />
            {{ $t('import') }}
          </b-button>
          <b-popover id="sync-popover"
                     target="sync-button"
                     triggers="click"
                     placement="bottom">

            <b-row class="justify-content-md-center">

              <b-col cols="12" class="popover-row"
                     v-for="(syncItem, key) in syncItems"
                     :key="key">

                <b-input-group class="input-group-filter-element"
                               size="sm">

                  <b-input-group-prepend>
                    <b-button variant="danger">{{ syncItem.name }}</b-button>
                  </b-input-group-prepend>

                  <div class="filter-select">

                    <v-select v-on:search:focus="getSyncElements(syncItem)"
                              @search="(query, loading) => findSyncElements(syncItem, query, loading)"
                              :options="syncItem['selectable']"
                              :loading="isSyncLoading"
                              :filterable="false"
                              v-model="syncItem['selected']"
                              label="dropDownNaming">
                    </v-select>

                  </div>

                  <b-input-group-append>
                    <b-button @click="syncItem['selected'] = []">
                      <b-icon icon="x"></b-icon>
                    </b-button>
                  </b-input-group-append>

                </b-input-group>

              </b-col>

              <b-col cols="12" class="popover-row">
                <b-button variant="danger"
                          size="sm"
                          @click="syncData()">{{ $t('import.apply' ) }}
                </b-button>
              </b-col>

            </b-row>

          </b-popover>
        </b-button-group>
        -->

        <b-button-group class="mx-1">
          <b-button type="button" size="sm" variant="secondary" class="pl-2 pr-2" @click="showCreateCustomerModal">
            <b-icon icon="plus" />
            {{ $t('controls.create') }}
          </b-button>
        </b-button-group>
      </b-button-toolbar>
      <customer-create-modal ref="create-customer-modal" @save="created" />
    </template>

    <template v-slot:spinner>

      <b-spinner v-if="isLoading"
                 class="spinner"
                 label="Loading..."/>

    </template>

    <template v-slot:entity-content="{ id, entity, index, deleted, saved }">
      <detail-view :id="id"
                   :value="entity"
                   @delete="deleted(index)"
                   @save="saved(index, $event)"
                   v-on:enableSpinner="loading(true)"
                   v-on:disableSpinner="loading(false)"/>
    </template>

  </e-scrollable-search-filter-page>
</template>

<script>
import service from "@/services/customer.service";
import DetailView from "@/components/CustomerView";
import CustomerCreateModal from "@/components/modals/CustomerCreateModal";

export default {
  name: 'CustomerList',
  components: {CustomerCreateModal, DetailView},
  data() {
    return {
      isLoading: false,
      //isSyncLoading: false,
      service: service,
      //syncItems: service.getSyncItems()
    }
  },
  methods: {
    /*
    findSyncElements: async function (syncItem, queryDropDown, loadingIndicator) {
      loadingIndicator(true)
      this.$log('debug', 'findSyncElements() called...')
      try {
        return await this.service.findInFilter(syncItem, null, queryDropDown)
      } finally {
        loadingIndicator(false)
      }
    },
    getSyncElements: async function (syncItem) {
      this.$log('debug', 'getSyncElements() called...')
      this.isSyncLoading = true
      try {
        return await this.service.findInFilter(syncItem, null, null)
      } finally {
        this.isSyncLoading = false
      }
    },
    syncData: async function () {
      this.$log('debug', 'syncData() called...')

      this.loading(true)
      try {
        await this.service.syncData()
        this.loading(false)
        this.$refs.page.find()
        this.$root.$emit('bv::hide::popover', 'sync-popover')
        await this.$bvModal.msgBoxOk("Der Kunde wurde importiert.")
      } catch (error) {
        this.$log("ERROR", "Error syncing customer data: ", error.response?"Request failed with status code " + error.response.status + " / " + error.response.data:error)
        this.$root.$emit('bv::hide::popover', 'sync-popover')
        this.loading(false)
        this.$eventBus.$emit('backend-error', error)
      }
    },
     */
    loading: function (isLoading) {
      this.$log('debug', 'loading(' + isLoading +') called...')
      this.isLoading = isLoading
    },
    showCreateCustomerModal: function () {
      this.$refs['create-customer-modal'].show()
    },
  }
}
</script>

<style>
.spinner {
  position: absolute;
  z-index: 100000;
  top: 25%;
  left: 50%;
}

#sync-popover {
  width: 800px;
  max-width: none;
}

</style>

<style scoped>


</style>