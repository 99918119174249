<template>
  <validation-observer vid="create_invoiceRecipient_observer" ref="observer"
                       v-slot="{ invalid }">
    <b-modal :id="id" ref="modal" @hidden="reset" @ok="submit">
      <template #modal-title>
        {{ $t('invoiceRecipient.migrate') }}
      </template>

      <template #default>
        <div class="form-row pb-2">
          <label :for="id + '-customerId'" class="col-4 col-form-label required text-right">{{ $t('customer.id') }}</label>
          <div class="col-8">
            <e-form-text-input :id="id + '-customerId'" name="customerId"
                               rules="required|length:6" v-model="customerId"/>
          </div>
        </div>
        <div v-if="customerId.length != 6"></div>
        <div v-else-if="searching" class="form-row pb-2"><div class="col-8 offset-4"><b-spinner /></div></div>
        <div v-else-if="!!(emrRecipient?._links?.self?.href)" class="form-row pb-2">
          <div class="col-8 offset-4 text-danger">{{ $t('invoiceRecipient.already-exists') }}</div>
        </div>
        <div v-else-if="!(emrRecipient)" class="form-row pb-2">
          <div class="col-8 offset-4 text-danger">{{ $t('invoiceRecipient.not-found') }}</div>
        </div>
        <div v-else>
          <div class="form-row pb-2">
            <label :for="id + '-name'" class="col-4 col-form-label required text-right">{{ $t('customer.name') }}</label>
            <div class="col-8">
              <e-form-text-input :id="id + '-name'" name="name" :disabled="true" rules="required"
                                 :value="emrRecipient.name"/>
            </div>
          </div>
          <div class="form-row pb-2">
            <label :for="id + '-additionalName'" class="col-4 col-form-label text-right">{{ $t('address.additionalName') }}</label>
            <div class="col-8">
              <e-form-text-input :id="id + '-additionalName'" name="name" :disabled="true"
                                 :value="emrRecipient.additionalName" />
            </div>
          </div>
          <div class="form-row pb-2">
            <label :for="id + '-street'" class="col-4 col-form-label text-right">{{ $t('address.street') }}</label>
            <div class="col-8">
              <e-form-text-input :id="id + '-street'" name="street" :disabled="true" rules="required"
                                 :value="emrRecipient.street" />
            </div>
          </div>
          <div class="form-row pb-2">
            <label :for="id + '-country'" class="col-4 col-form-label text-right">{{ $t('address.country') }} / {{ $t('address.zipCode') }} / {{ $t('address.city') }}</label>
            <div class="col-2">
              <e-form-text-input :id="id + '-country'" name="country" :disabled="true" rules="required"
                                 :value="emrRecipient.country" />
            </div>
            <div class="col-2">
              <e-form-text-input :id="id + '-zipCode'" name="zipCode" :disabled="true" rules="required"
                                 :value="emrRecipient.zipCode" />
            </div>
            <div class="col-4">
              <e-form-text-input :id="id + '-city'" name="city" :disabled="true" rules="required"
                                 :value="emrRecipient.city" />
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer="{ok, cancel, hide}">
        <b-button variant="secondary" @click="cancel">{{ $t('controls.cancel') }}</b-button>
        <b-button variant="primary" @click="ok" :disabled="invalid || searching || !(emrRecipient) || !!(emrRecipient?._links?.self?.href)">{{ $t('controls.ok') }}</b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver} from '@emons/emons-vue'
import InvoiceRecipientAddressService from "@/services/invoiceRecipientAddress.service";

export default {
  name: "InvoiceRecipientCreateModal",
  components: {ValidationObserver},
  data() {
    return {
      searching: false,
      id: 'invoice-recipient-create-modal',
      customerId: '',
    }
  },
  asyncComputed: {
    emrRecipient: async function () {
      const customerId = this.customerId;
      this.searching = true
      if (customerId.length == 6) {
        const customer = await InvoiceRecipientAddressService.getByCustomerId(customerId, true)
        this.$log('DEBUG', 'recipient', customer)
        this.searching = false
        return customer
      } else {
        this.searching = false
        return null
      }
    }
  },
  methods: {
    show: function () {
      this.$refs.modal.show()
    },
    reset: function () {
      this.searching = false
      this.customerId = ""
      this.location = ""
    },
    submit: async function (event) {
      event.preventDefault()

      try {
        await this.save()
        this.$refs.modal.hide()
        this.$refs.observer.reset()
      } catch(error) {
        this.$eventBus.$emit('backend-error', error)
      }
    },
    save: async function () {
      if (!(this?.emrRecipient?.customerId)) {
        return
      }
      let response = await InvoiceRecipientAddressService
          .save(
              this.emrRecipient
          ).catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              this.$refs.observer.setErrors(error.response.data.errors)
            }
          })

      this.$log('DEBUG', 'saved', response)
      this.$refs.observer.reset()
      this.$emit('save', response.data)
    },
  },
}
</script>

<style scoped>

</style>