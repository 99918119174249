var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "announcement_observer",
    attrs: {
      "vid": "announcementObserver",
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty;
        return [_c('b-modal', {
          attrs: {
            "id": _vm.id + '-announceShipmentModal',
            "title": _vm.$t('shipment.shipping-notice') + ' - ' + _vm.$t('shipment.shipping-notice.long')
          },
          on: {
            "ok": _vm.announceShipment
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_vm.failedShipments && _vm.failedShipments.length > 0 ? _c('div', {
                staticClass: "alert alert-danger"
              }, [_c('h6', {
                staticClass: "alert-heading"
              }, [_vm._v(" " + _vm._s(_vm.$t('shipment.shipping-notice.not-possible')) + " ")]), _c('ul', _vm._l(_vm.failedShipments, function (order, idx) {
                return _c('li', [_vm._v(_vm._s(order.shipmentNumber) + " - " + _vm._s(_vm.$t('shipment.shipping-notice.not-possible.' + order.failedReason)))]);
              }), 0)]) : _vm._e(), _vm.passedShipments && _vm.passedShipments.length > 0 ? _c('div', {
                staticClass: "mt-4"
              }, [_c('b-form-group', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "label": _vm.$t('shipment.shipping-date'),
                  "label-cols": "4"
                }
              }, [_c('b-form-group', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "label": _vm.showDateUntil ? _vm.$t('date.from') : '',
                  "label-for": "dateFrom",
                  "label-cols": "2",
                  "label-class": "required"
                }
              }, [_c('e-form-datepicker', {
                attrs: {
                  "id": _vm.id + '-dateFrom',
                  "name": "dateFrom",
                  "rules": {
                    required: true,
                    dateNotInPast: true
                  },
                  "placeholder": _vm.$t('orders.choose-shipping-date'),
                  "date-disabled-fn": _vm.isHoliday,
                  "min": new Date(),
                  "max": new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())
                },
                on: {
                  "input": function input($event) {
                    return _vm.updateShippingTime($event);
                  }
                },
                model: {
                  value: _vm.dateFrom,
                  callback: function callback($$v) {
                    _vm.dateFrom = $$v;
                  },
                  expression: "dateFrom"
                }
              })], 1), _vm.showDateUntil ? _c('b-form-group', {
                staticClass: "mb-0 mt-0",
                attrs: {
                  "label": _vm.$t('date.to'),
                  "label-cols": "2"
                }
              }, [_c('e-form-datepicker', {
                attrs: {
                  "id": _vm.id + '-dateUntil',
                  "name": "dateUntil",
                  "placeholder": _vm.$t('date.not-selected'),
                  "date-disabled-fn": _vm.isHoliday,
                  "min": new Date(_vm.dateFrom),
                  "max": new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())
                },
                model: {
                  value: _vm.dateUntil,
                  callback: function callback($$v) {
                    _vm.dateUntil = $$v;
                  },
                  expression: "dateUntil"
                }
              })], 1) : _vm._e()], 1), _vm.dateFromMultiple ? _c('div', {
                staticClass: "alert alert-info"
              }, [_vm._v(" " + _vm._s(_vm.$t('shipment.shipping-notice.multiple-shipping-dates')) + " " + _vm._s(_vm.$t('shipment.shipping-notice.schedule-common-shipping-date')) + " ")]) : _vm._e(), _vm.dateFromModified ? _c('div', {
                staticClass: "alert alert-info"
              }, [_vm._v(" " + _vm._s(_vm.$t('shipment.shipping-notice.shipping-date-in-past')) + " ")]) : _vm._e(), !_vm.hasMultipleDeliveryDateWindows && _vm.deliveryDateWindow != null ? _c('b-form-group', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "label": _vm.$t('shipment.delivery-date'),
                  "label-cols": "4"
                }
              }, [_c('b-form-group', {
                staticClass: "mb-0 mt-0",
                attrs: {
                  "label": "",
                  "label-cols": "2"
                }
              }, [_c('e-form-datepicker', {
                ref: "deliveryDate",
                attrs: {
                  "id": _vm.id + '-deliveryDate',
                  "name": "deliveryDate",
                  "rules": {
                    required: true,
                    withinDeliveryWindow: {
                      deliveryWindow: _vm.deliveryWindow
                    }
                  },
                  "placeholder": _vm.$t('date.not-selected'),
                  "disabled": _vm.deliveryDateWindow.min == _vm.deliveryDateWindow.max,
                  "date-disabled-fn": _vm.isHoliday,
                  "min": _vm.deliveryWindow.min,
                  "max": _vm.deliveryWindow.max
                },
                model: {
                  value: _vm.deliveryDate,
                  callback: function callback($$v) {
                    _vm.deliveryDate = $$v;
                  },
                  expression: "deliveryDate"
                }
              })], 1)], 1) : _vm._e(), !_vm.hasMultipleDeliveryDateWindows && _vm.deliveryDateWindow != null && _vm.passedShipments.filter(function (order) {
                return order.product.deliveryDateWindow == null || order.product.deliveryDateIsOptional;
              }).length > 0 ? _c('div', {
                staticClass: "alert alert-info"
              }, [_vm._v(" " + _vm._s(_vm.$t('shipment.shipping-notice.delivery-date-excludes-standard-shipments')) + " ")]) : _vm._e(), _c('b-form-group', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "label": _vm.$t('shipment.shipping-time'),
                  "label-cols": "4"
                }
              }, [_c('b-form-group', {
                staticClass: "mb-3 mt-0",
                attrs: {
                  "label": _vm.$t('time.from'),
                  "label-for": "timeFrom",
                  "label-cols": "2"
                }
              }, [_c('time-input', {
                attrs: {
                  "id": _vm.id + '-timeFrom',
                  "name": "timeFrom"
                },
                model: {
                  value: _vm.timeFrom,
                  callback: function callback($$v) {
                    _vm.timeFrom = $$v;
                  },
                  expression: "timeFrom"
                }
              })], 1), _c('b-form-group', {
                staticClass: "mb-3 mt-0",
                attrs: {
                  "label": _vm.$t('time.to'),
                  "label-for": "timeUntil",
                  "label-cols": "2",
                  "label-class": "required"
                }
              }, [_c('time-input', {
                attrs: {
                  "id": _vm.id + '-timeUntil',
                  "name": "timeUntil"
                },
                model: {
                  value: _vm.timeUntil,
                  callback: function callback($$v) {
                    _vm.timeUntil = $$v;
                  },
                  expression: "timeUntil"
                }
              })], 1)], 1), _c('b-form-group', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "label": _vm.$t('shipment.shipping-notice.notice'),
                  "label-cols": "4"
                }
              }, [_c('e-form-text-input', {
                attrs: {
                  "id": _vm.id + '-notice',
                  "maxlength": "35",
                  "name": "notice"
                },
                model: {
                  value: _vm.notice,
                  callback: function callback($$v) {
                    _vm.notice = $$v;
                  },
                  expression: "notice"
                }
              })], 1), _vm.hasMultipleDeliveryDateWindows ? _c('div', {
                staticClass: "alert alert-warning mt-4"
              }, [_c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('shipment.shipping-notice.multiple-delivery-windows')))])]), _c('div', [_vm._v(_vm._s(_vm.$t('shipment.shipping-notice.delivery-date-rescheduled')))])]) : _vm._e()], 1) : _c('div', {
                staticClass: "alert alert-danger"
              }, [_vm._v(" " + _vm._s(_vm.$t('shipment.shipping-notice.no-shipments-available')) + " ")])];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn(_ref2) {
              var ok = _ref2.ok,
                  cancel = _ref2.cancel,
                  hide = _ref2.hide;
              return [_c('b-button', {
                attrs: {
                  "variant": "secondary"
                },
                on: {
                  "click": cancel
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.abort')))]), _c('b-button', {
                attrs: {
                  "variant": "primary",
                  "disabled": invalid || !(_vm.passedShipments && _vm.passedShipments.length > 0)
                },
                on: {
                  "click": ok
                }
              }, [_vm._v(_vm._s(_vm.$t('shipment.shipping-notice.announce')))])];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }