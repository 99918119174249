var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-search-filter-page', {
    attrs: {
      "service": _vm.service
    },
    on: {
      "enableSpinner": function enableSpinner($event) {
        return _vm.enableSpinner();
      },
      "disableSpinner": function disableSpinner($event) {
        return _vm.disableSpinner();
      }
    },
    scopedSlots: _vm._u([{
      key: "header-controls",
      fn: function fn(_ref) {
        var _links$create, _links$modify, _links$commission, _links$modify2, _links$delete, _links$exportXLS;

        var created = _ref.created,
            deleted = _ref.deleted,
            saved = _ref.saved,
            items = _ref.items,
            page = _ref.page,
            searchText = _ref.searchText,
            sortProperty = _ref.sortProperty,
            sortDirection = _ref.sortDirection,
            links = _ref.links;
        return [_c('announce-shipment-modal', {
          ref: "announceShipmentModal",
          attrs: {
            "id": "announceShipment"
          },
          on: {
            "confirmed": function confirmed($event) {
              return _vm.announceShipments($event, items, saved);
            }
          }
        }), _c('printer-type-modal', {
          ref: "printerTypeModal",
          on: {
            "confirmed": _vm.printerConfirmed
          }
        }), _c('b-button-toolbar', {
          staticClass: "float-right",
          attrs: {
            "key-nav": ""
          }
        }, [links !== null && links !== void 0 && (_links$create = links.create) !== null && _links$create !== void 0 && _links$create.href ? _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-dropdown', {
          attrs: {
            "id": "dropdown-createOrder",
            "size": "sm",
            "variant": "primary",
            "right": "",
            "disabled": !!items.find(function (s) {
              var _s$_links;

              return !(s !== null && s !== void 0 && (_s$_links = s._links) !== null && _s$_links !== void 0 && _s$_links.self);
            })
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('b-icon', {
                attrs: {
                  "icon": "plus"
                }
              }), _vm._v(" " + _vm._s(_vm.$t('controls.create')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              _vm.newShipmentCreated(_vm.service.create(), created);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('shipment.create.form')) + " ")]), _c('router-link', {
          staticClass: "dropdown-item",
          attrs: {
            "to": "/shipments/create"
          }
        }, [_vm._v(_vm._s(_vm.$t('shipment.create.wizard')))])], 1)], 1) : _vm._e(), links !== null && links !== void 0 && (_links$modify = links.modify) !== null && _links$modify !== void 0 && _links$modify.href ? _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary",
            "disabled": _vm.checkedOrders.length === 0 || !_vm.checkedOrders.find(function (s) {
              var _s$_links2, _s$_links2$announce;

              return !!(s !== null && s !== void 0 && (_s$_links2 = s._links) !== null && _s$_links2 !== void 0 && (_s$_links2$announce = _s$_links2.announce) !== null && _s$_links2$announce !== void 0 && _s$_links2$announce.href);
            })
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.announceShipmentModal.show(_vm.checkedOrders);
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "info-circle"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('shipment.shipping-notice')) + " ")], 1)], 1) : _vm._e(), links !== null && links !== void 0 && (_links$commission = links.commission) !== null && _links$commission !== void 0 && _links$commission.href ? _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary",
            "disabled": _vm.axiosSaveInProgress || _vm.checkedOrders.length === 0 || !_vm.checkedOrders.find(function (s) {
              var _s$_links3, _s$_links3$commission;

              return !!((_s$_links3 = s._links) !== null && _s$_links3 !== void 0 && (_s$_links3$commission = _s$_links3.commission) !== null && _s$_links3$commission !== void 0 && _s$_links3$commission.href);
            })
          },
          on: {
            "click": function click($event) {
              return _vm.finalizeOrders(items, saved);
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "check-circle"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('shipment.commission')) + " ")], 1)], 1) : _vm._e(), links !== null && links !== void 0 && (_links$modify2 = links.modify) !== null && _links$modify2 !== void 0 && _links$modify2.href ? _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-dropdown', {
          attrs: {
            "id": "dropdown-batch",
            "size": "sm",
            "variant": "secondary",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('b-icon-stack'), _vm._v(" " + _vm._s(_vm.$t('shipment.batch-announce-and-commission')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": _vm.batchAnnouncement
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('shipment.batch-announcement')) + " ")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.batchCommision(items, saved);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('shipment.batch-commissioning')) + " ")])], 1)], 1) : _vm._e(), _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary",
            "disabled": _vm.checkedOrders.length == 0
          },
          on: {
            "click": _vm.downloadLabels
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "printer"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('shipment.print.labels')) + " ")], 1)], 1), _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary",
            "disabled": _vm.checkedOrders.length == 0 || !_vm.checkedOrders.find(function (order) {
              return ['FINALIZED'].includes(order.state);
            })
          },
          on: {
            "click": function click($event) {
              return _vm.printLoadingList(items, saved);
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "file-text"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('shipment.print.cargo-list')) + " ")], 1)], 1), links !== null && links !== void 0 && (_links$delete = links.delete) !== null && _links$delete !== void 0 && _links$delete.href ? _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary",
            "disabled": _vm.checkedOrders.length == 0 || !_vm.checkedOrders.find(function (order) {
              return ['NEW', 'DRAFT', 'ANNOUNCED'].includes(order.state);
            })
          },
          on: {
            "click": function click($event) {
              return _vm.confirmDeleteOrders(items, deleted);
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "trash"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('controls.delete')) + " ")], 1)], 1) : _vm._e(), links !== null && links !== void 0 && (_links$exportXLS = links.exportXLS) !== null && _links$exportXLS !== void 0 && _links$exportXLS.href ? _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary",
            "disabled": !(page !== null && page !== void 0 && page.totalElements)
          },
          on: {
            "click": function click($event) {
              return _vm.exportToExcel(page, searchText, sortProperty, sortDirection);
            }
          }
        }, [_c('b-icon-file-earmark-excel'), _vm._v(" " + _vm._s(_vm.$t('shipments.export-xls')) + " ")], 1)], 1) : _vm._e()], 1)];
      }
    }, {
      key: "spinner",
      fn: function fn() {
        return [_vm.loading ? _c('b-spinner', {
          staticClass: "spinner",
          attrs: {
            "label": "Loading..."
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "table-header",
      fn: function fn(_ref2) {
        var items = _ref2.items,
            sortBy = _ref2.sortBy,
            sortIcon = _ref2.sortIcon;
        return [_c('div', {
          staticClass: "col-2"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [_c('b-checkbox', {
          attrs: {
            "checked": _vm.checkedOrders.length == (items === null || items === void 0 ? void 0 : items.length)
          },
          on: {
            "change": function change($event) {
              return _vm.toggleSelectAll(items, $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "col-6 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('shipmentNumber');
            }
          }
        }, [_c(sortIcon('shipmentNumber'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.number.short')))])], 1), _c('div', {
          staticClass: "col-4 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('sender.customerId');
            }
          }
        }, [_c(sortIcon('sender.customerId'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('customer.id.short')))])], 1)])]), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('sender.name', _vm.sortBySender);
            }
          }
        }, [_c(sortIcon('sender.name', _vm.sortBySender), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.sender')))])], 1), _c('div', {
          staticClass: "col-4"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('state');
            }
          }
        }, [_c(sortIcon('state'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.state.short')))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('lastModifiedBy');
            }
          }
        }, [_c(sortIcon('lastModifiedBy'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.lastModifiedBy.short')))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('orderNumber');
            }
          }
        }, [_c(sortIcon('orderNumber'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('order.number.short')))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('shippingDate');
            }
          }
        }, [_c(sortIcon('shippingDate'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.shipping-date.short')))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('deliveryDate');
            }
          }
        }, [_c(sortIcon('deliveryDate'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.delivery-date.short')))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('productType', _vm.sortByProduct);
            }
          }
        }, [_c(sortIcon('productType', _vm.sortByProduct), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('product.type')))])], 1)])]), _c('div', {
          staticClass: "col-4"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-5 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('consignee.name', _vm.sortByConsignee);
            }
          }
        }, [_c(sortIcon('consignee.name', _vm.sortByConsignee), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.consignee')))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('shippingNoticeCreated');
            }
          }
        }, [_c(sortIcon('shippingNoticeCreated'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.shipping-notice.short')))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('commissionedOn');
            }
          }
        }, [_c(sortIcon('commissionedOn'), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.commissioned.short')))])], 1), _c('div', {
          staticClass: "col-1 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('hazmat', _vm.sortByHazmat);
            }
          }
        }, [_c(sortIcon('hazmat', _vm.sortByHazmat), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.dangerous-goods.short')))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text",
          on: {
            "click": function click($event) {
              return sortBy('type', _vm.sortByHazmat);
            }
          }
        }, [_c(sortIcon('type', _vm.sortByHazmat), {
          tag: "component"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('shipment.type.short')))])], 1)])])];
      }
    }, {
      key: "entity-header",
      fn: function fn(_ref3) {
        var _entity$_links, _entity$_links$trackA, _entity$_links2, _entity$_links2$track, _entity$sender, _entity$sender2, _entity$sender3, _entity$_links3, _entity$_links3$loadi, _entity$consignee, _entity$consignee2, _entity$consignee3;

        var id = _ref3.id,
            items = _ref3.items,
            entity = _ref3.entity,
            index = _ref3.index,
            deleted = _ref3.deleted,
            saved = _ref3.saved;
        return [_c('div', {
          staticClass: "col-2"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-2 pl-1 pr-1"
        }, [_c('span', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-checkbox', {
          attrs: {
            "value": entity,
            "disabled": ['NEW'].includes(entity.state)
          },
          model: {
            value: _vm.checkedOrders,
            callback: function callback($$v) {
              _vm.checkedOrders = $$v;
            },
            expression: "checkedOrders"
          }
        })], 1)]), _c('div', {
          staticClass: "col-6 pl-1 pr-1 clip-text"
        }, [entity !== null && entity !== void 0 && (_entity$_links = entity._links) !== null && _entity$_links !== void 0 && (_entity$_links$trackA = _entity$_links.trackAndTrace) !== null && _entity$_links$trackA !== void 0 && _entity$_links$trackA.href ? _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": _vm.$t('shipment.track-and-trace.link-description')
          }
        }, [_c('a', {
          attrs: {
            "href": _vm.formatTrackAndTraceLink(entity === null || entity === void 0 ? void 0 : (_entity$_links2 = entity._links) === null || _entity$_links2 === void 0 ? void 0 : (_entity$_links2$track = _entity$_links2.trackAndTrace) === null || _entity$_links2$track === void 0 ? void 0 : _entity$_links2$track.href),
            "target": "_blank"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._v(_vm._s(entity.shipmentNumber))])]) : _c('span', [_vm._v(_vm._s(entity.shipmentNumber))])]), _c('div', {
          staticClass: "col-4 pl-1 pr-1 clip-text"
        }, [_vm._v(_vm._s(entity.sender ? entity.sender.customerId : ''))])])]), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [entity !== null && entity !== void 0 && (_entity$sender = entity.sender) !== null && _entity$sender !== void 0 && _entity$sender.name ? _c('strong', [_vm._v(_vm._s(entity.sender.name))]) : _vm._e(), entity !== null && entity !== void 0 && (_entity$sender2 = entity.sender) !== null && _entity$sender2 !== void 0 && _entity$sender2.city ? _c('span', [_vm._v(_vm._s(entity !== null && entity !== void 0 && (_entity$sender3 = entity.sender) !== null && _entity$sender3 !== void 0 && _entity$sender3.name ? ', ' : '') + _vm._s(entity.sender.city))]) : _vm._e()]), _c('div', {
          staticClass: "col-4"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [entity !== null && entity !== void 0 && (_entity$_links3 = entity._links) !== null && _entity$_links3 !== void 0 && (_entity$_links3$loadi = _entity$_links3.loadinglist) !== null && _entity$_links3$loadi !== void 0 && _entity$_links3$loadi.href ? _c('b-link', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.downloadLoadingList(entity);
            }
          }
        }, [_vm._v(" " + _vm._s(entity.state ? _vm.$t('shipment.state.' + entity.state) : '') + " ")]) : _c('span', [_vm._v(_vm._s(entity.state ? _vm.$t('shipment.state.' + entity.state) : ''))])], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [_vm._v(" " + _vm._s(entity.lastModifiedBy) + " ")]), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [_vm._v(" " + _vm._s(entity.orderNumber) + " ")]), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [_vm._v(" " + _vm._s(entity.shippingDate ? _vm.$d(new Date(entity.shippingDate), 'dateShort') : '') + " ")]), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [_vm._v(" " + _vm._s(entity.deliveryDate ? _vm.$d(new Date(entity.deliveryDate), 'dateShort') : '') + " ")]), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [_vm._v(_vm._s(entity.product ? entity.product.name : ''))])])]), _c('div', {
          staticClass: "col-4"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-5 pl-1 pr-1 clip-text"
        }, [entity !== null && entity !== void 0 && (_entity$consignee = entity.consignee) !== null && _entity$consignee !== void 0 && _entity$consignee.name ? _c('strong', [_vm._v(_vm._s(entity.consignee.name))]) : _vm._e(), entity !== null && entity !== void 0 && (_entity$consignee2 = entity.consignee) !== null && _entity$consignee2 !== void 0 && _entity$consignee2.city ? _c('span', [_vm._v(_vm._s(entity !== null && entity !== void 0 && (_entity$consignee3 = entity.consignee) !== null && _entity$consignee3 !== void 0 && _entity$consignee3.name ? ', ' : '') + _vm._s(entity.consignee.city))]) : _vm._e()]), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [_vm._v(" " + _vm._s(entity.shippingNoticeCreated ? _vm.$d(new Date(entity.shippingNoticeCreated), 'dateShort') : '') + " ")]), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [_vm._v(_vm._s(entity.commissionedOn ? _vm.$d(new Date(entity.commissionedOn), 'dateShort') : ''))]), _c('div', {
          staticClass: "col-1 pl-1 pr-1 clip-text"
        }, [_vm.hasHazmat(entity) ? _c('b-icon', {
          attrs: {
            "icon": "check-circle"
          }
        }) : _vm._e()], 1), _c('div', {
          staticClass: "col-2 pl-1 pr-1 clip-text"
        }, [entity.type ? _c('span', [_vm._v(_vm._s(_vm.$t('shipment.mainType.' + entity.type)))]) : _vm._e()])])])];
      }
    }, {
      key: "entity-content",
      fn: function fn(_ref4) {
        var id = _ref4.id,
            entity = _ref4.entity,
            index = _ref4.index,
            deleted = _ref4.deleted,
            saved = _ref4.saved;
        return [_c('order-details', {
          attrs: {
            "id": id,
            "order": entity,
            "default-country": _vm.defaultCountry,
            "branch-currency": _vm.branchCurrency
          },
          on: {
            "delete": function _delete($event) {
              return deleted(index);
            },
            "save": function save($event) {
              saved(index, $event);

              _vm.orderSaved(index, $event);
            },
            "enableSpinner": function enableSpinner($event) {
              return _vm.enableSpinner();
            },
            "disableSpinner": function disableSpinner($event) {
              return _vm.disableSpinner();
            }
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }