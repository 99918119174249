<template>
  <form @submit.prevent="handleSubmit(onSubmit)">
    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-notificationCode-identifier'" class="col-4 col-form-label">{{ $t('delivery-notes.identifier') }}</label>
          <div class="col-8">
            <e-form-text :id="id + '-notificationCode-identifier'" :value="entity.identifier"/>
          </div>
        </div>

      </div>

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-notificationCode-name'" class="col-4 col-form-label">{{ $t('delivery-notes.label') }}</label>
          <div class="col-8">
            <e-form-text :id="id + '-notificationCode-name'" :value="entity.name"/>
          </div>
        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-notificationCode-attachment'" class="col-4 col-form-label">{{ $t('delivery-notes.attachment') }}</label>
          <div class="col-8">
            <e-form-text :id="id + '-notificationCode-attachment'" :value="entity.attachment ? entity.attachment.name : ''"/>
          </div>
        </div>

      </div>

      <div class="col-4">



      </div>

    </div>

    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-notificationCode-name'" class="col-4 col-form-label">{{ $t('delivery-notes.multiple-usage') }}</label>
          <div class="col-8">
            <e-form-text :id="id + '-notificationCode-name'" :value="$t('delivery-notes.multiple-usage.allowed')" v-if="entity.multiUsage" />
            <e-form-text :id="id + '-notificationCode-name'" :value="$t('delivery-notes.multiple-usage.disallowed')" v-else />
          </div>
        </div>

      </div>

      <div class="col-4">

      </div>

    </div>

  </form>
</template>

<script>

import service from "@/services/notificationCode.service";
import {log} from "@emons/emons-vue"

export default {
  name: "NotificationCodeView",
  components: {},
  mounted() {
    log('debug', 'Mounted NotificationCodeView...')
    this.getEntityDetails()
  },
  props: {
    id: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      entity: {
        abbreviation: null,
        name: null,
        attachment: {
          name: null
        },
        multiUsage: null
      }
    }
  },
  methods: {
    getEntityDetails: function () {
      this.$emit('enableSpinner');
      service
          .getEntityDetails(this.link)
          .then(response => {
            this.entity = response.data
            this.$emit('disableSpinner');
          })
    }
  }
}
</script>

<style scoped>

</style>