var render = function () {
  var _vm$branchCurrency;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-page', [_c('template', {
    slot: "content"
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "vertical": "",
      "nav-class": "h5 font-weight-normal",
      "nav-wrapper-class": "mr-4"
    }
  }, [_c('h5', {
    staticClass: "mb-5"
  }, [_vm._v(_vm._s(_vm.$t('settings')))]), _c('b-tab', {
    attrs: {
      "title": _vm.$t('settings.printer')
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t('settings.printer')))]), _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t('settings.printer.configure-labels')))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.printer.labels.type'),
      "label-for": "printerType",
      "content-cols": "7",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio-group', {
          attrs: {
            "size": "lg",
            "aria-describedby": ariaDescribedby,
            "name": "printerType",
            "stacked": ""
          },
          on: {
            "change": function change($event) {
              return _vm.settingChanged(_vm.printerType);
            }
          },
          model: {
            value: _vm.printerType.value,
            callback: function callback($$v) {
              _vm.$set(_vm.printerType, "value", $$v);
            },
            expression: "printerType.value"
          }
        }, [_c('b-form-radio', {
          staticClass: "p-2",
          attrs: {
            "value": ""
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings.printer.labels.none')))]), _c('b-form-text', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('settings.printer.labels.none.description'))
          }
        })])], 1), _c('b-form-radio', {
          staticClass: "p-2",
          attrs: {
            "value": "LASER"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings.printer.labels.laser')))]), _c('b-form-text', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('settings.printer.labels.laser.description'))
          }
        })])], 1), _c('b-form-radio', {
          staticClass: "p-2",
          attrs: {
            "value": "LASER_POSITION"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings.printer.labels.laser_position')))]), _c('b-form-text', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('settings.printer.labels.laser.description'))
          }
        }), _c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('settings.printer.labels.laser_position.description'))
          }
        })])], 1), _c('b-form-radio', {
          staticClass: "p-2",
          attrs: {
            "value": "ZEBRA"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings.printer.labels.zebra')))]), _c('b-form-text', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('settings.printer.labels.zebra.description'))
          }
        })])], 1)], 1)];
      }
    }])
  })], 1)]), _c('b-tab', {
    attrs: {
      "title": _vm.$t('settings.shipments')
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t('settings.shipments')))]), _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t('settings.shipments.configure-shipments')))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.shipments.mandatory-fields'),
      "label-for": "markingRequired",
      "content-cols": "7",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "name": "requiredFields",
            "aria-describedby": ariaDescribedby,
            "options": _vm.requiredFieldNames
          },
          on: {
            "change": function change($event) {
              return _vm.settingChanged(_vm.requiredFields);
            }
          },
          model: {
            value: _vm.requiredFields.value,
            callback: function callback($$v) {
              _vm.$set(_vm.requiredFields, "value", $$v);
            },
            expression: "requiredFields.value"
          }
        })];
      }
    }])
  }), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.shipments.incoterms.national'),
      "label-for": "incoTermsNational",
      "content-cols": "2",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var ariaDescribedby = _ref3.ariaDescribedby;
        return [_c('b-form-select', {
          attrs: {
            "id": "incoTermsNational",
            "name": "incoTermsNational",
            "aria-describedby": ariaDescribedby
          },
          on: {
            "change": function change($event) {
              return _vm.settingChanged(_vm.incoTermsNat);
            }
          },
          model: {
            value: _vm.incoTermsNat.value,
            callback: function callback($$v) {
              _vm.$set(_vm.incoTermsNat, "value", $$v);
            },
            expression: "incoTermsNat.value"
          }
        }, _vm._l(_vm.incoTermsNational, function (term, idx) {
          return _c('option', {
            domProps: {
              "value": term.name
            }
          }, [_vm._v(_vm._s(term.name))]);
        }), 0)];
      }
    }])
  }), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.shipments.incoterms.international'),
      "label-for": "incoTermsNational",
      "content-cols": "2",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var ariaDescribedby = _ref4.ariaDescribedby;
        return [_c('b-form-select', {
          attrs: {
            "id": "incoTermsInternational",
            "name": "incoTermsInternational",
            "aria-describedby": ariaDescribedby
          },
          on: {
            "change": function change($event) {
              return _vm.settingChanged(_vm.incoTermsInternat);
            }
          },
          model: {
            value: _vm.incoTermsInternat.value,
            callback: function callback($$v) {
              _vm.$set(_vm.incoTermsInternat, "value", $$v);
            },
            expression: "incoTermsInternat.value"
          }
        }, _vm._l(_vm.incoTermsInternational, function (term, idx) {
          return _c('option', {
            domProps: {
              "value": term.name
            }
          }, [_vm._v(_vm._s(term.name))]);
        }), 0)];
      }
    }])
  }), ((_vm$branchCurrency = _vm.branchCurrency) === null || _vm$branchCurrency === void 0 ? void 0 : _vm$branchCurrency.isoCode) != 'EUR' ? _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.shipments.default-currency'),
      "label-for": "defaultCurrency",
      "content-cols": "2",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var _vm$branchCurrency2, _vm$branchCurrency3;

        var ariaDescribedby = _ref5.ariaDescribedby;
        return [_c('b-form-select', {
          attrs: {
            "id": "defaultCurrency",
            "name": "defaultCurrency",
            "aria-describedby": ariaDescribedby
          },
          on: {
            "change": function change($event) {
              return _vm.settingChanged(_vm.defaultCurrency);
            }
          },
          model: {
            value: _vm.defaultCurrency.value,
            callback: function callback($$v) {
              _vm.$set(_vm.defaultCurrency, "value", $$v);
            },
            expression: "defaultCurrency.value"
          }
        }, [_c('option', {
          domProps: {
            "value": (_vm$branchCurrency2 = _vm.branchCurrency) === null || _vm$branchCurrency2 === void 0 ? void 0 : _vm$branchCurrency2.isoCode
          }
        }, [_vm._v(_vm._s((_vm$branchCurrency3 = _vm.branchCurrency) === null || _vm$branchCurrency3 === void 0 ? void 0 : _vm$branchCurrency3.isoCode))]), _c('option', {
          attrs: {
            "value": "EUR"
          }
        }, [_vm._v("EUR")])])];
      }
    }], null, false, 3786889462)
  }) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.shipments.shipping-date'),
      "label-for": "shippingDateOffset",
      "content-cols": "2",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var ariaDescribedby = _ref6.ariaDescribedby;
        return [_c('b-form-select', {
          attrs: {
            "id": "shippingDateOffset",
            "name": "shippingDateOffset",
            "aria-describedby": ariaDescribedby
          },
          on: {
            "change": function change($event) {
              return _vm.settingChanged(_vm.shippingDateOffset);
            }
          },
          model: {
            value: _vm.shippingDateOffset.value,
            callback: function callback($$v) {
              _vm.$set(_vm.shippingDateOffset, "value", $$v);
            },
            expression: "shippingDateOffset.value"
          }
        }, [_c('option', {
          attrs: {
            "value": "0"
          }
        }, [_vm._v(_vm._s(_vm.$t('settings.shipments.shipping-date.current')))]), _c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v(_vm._s(_vm.$t('settings.shipments.shipping-date.current-plus-1')))]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v(_vm._s(_vm.$t('settings.shipments.shipping-date.current-plus-2')))])])];
      }
    }])
  }), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.shipments.shipping-time'),
      "content-cols": "4",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var ariaDescribedby = _ref7.ariaDescribedby;
        return [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-6"
        }, [_c('label', [_vm._v(_vm._s(_vm.$t('time.from')))]), _c('time-input', {
          attrs: {
            "name": "timeFrom",
            "placeholder": _vm.$t('time.select.short')
          },
          on: {
            "input": function input($event) {
              return _vm.settingChanged(_vm.shippingTimeFrom);
            }
          },
          model: {
            value: _vm.shippingTimeFrom.value,
            callback: function callback($$v) {
              _vm.$set(_vm.shippingTimeFrom, "value", $$v);
            },
            expression: "shippingTimeFrom.value"
          }
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('label', [_vm._v(_vm._s(_vm.$t('time.to')))]), _c('time-input', {
          attrs: {
            "name": "timeUntil",
            "placeholder": _vm.$t('time.select.short')
          },
          on: {
            "input": function input($event) {
              return _vm.settingChanged(_vm.shippingTimeUntil);
            }
          },
          model: {
            value: _vm.shippingTimeUntil.value,
            callback: function callback($$v) {
              _vm.$set(_vm.shippingTimeUntil, "value", $$v);
            },
            expression: "shippingTimeUntil.value"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "sameShippingTimeOnFriday"
          },
          on: {
            "change": function change($event) {
              return _vm.setFridayShippingTime($event);
            }
          },
          model: {
            value: _vm.shippingTimeSameOnFriday,
            callback: function callback($$v) {
              _vm.shippingTimeSameOnFriday = $$v;
            },
            expression: "shippingTimeSameOnFriday"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('settings.shipments.sameShippingTimeOnFriday')))])], 1)];
      }
    }])
  }), !_vm.shippingTimeSameOnFriday ? _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.shipments.shipping-time.friday'),
      "content-cols": "4",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var ariaDescribedby = _ref8.ariaDescribedby;
        return [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-6"
        }, [_c('label', [_vm._v(_vm._s(_vm.$t('time.from')))]), _c('time-input', {
          attrs: {
            "name": "timeFrom",
            "placeholder": _vm.$t('time.select.short')
          },
          on: {
            "input": function input($event) {
              return _vm.settingChanged(_vm.shippingTimeFromFr);
            }
          },
          model: {
            value: _vm.shippingTimeFromFr.value,
            callback: function callback($$v) {
              _vm.$set(_vm.shippingTimeFromFr, "value", $$v);
            },
            expression: "shippingTimeFromFr.value"
          }
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('label', [_vm._v(_vm._s(_vm.$t('time.to')))]), _c('time-input', {
          attrs: {
            "name": "timeUntil",
            "placeholder": _vm.$t('time.select.short')
          },
          on: {
            "input": function input($event) {
              return _vm.settingChanged(_vm.shippingTimeUntilFr);
            }
          },
          model: {
            value: _vm.shippingTimeUntilFr.value,
            callback: function callback($$v) {
              _vm.$set(_vm.shippingTimeUntilFr, "value", $$v);
            },
            expression: "shippingTimeUntilFr.value"
          }
        })], 1)])];
      }
    }], null, false, 1529303771)
  }) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.shipments.deliverynotes'),
      "label-for": "notificationCodes",
      "content-cols": "9",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var ariaDescribedby = _ref9.ariaDescribedby;
        return [_c('b-table', {
          attrs: {
            "striped": "",
            "hover": "",
            "sticky-header": "350px",
            "items": _vm.notificationCodes,
            "fields": [{
              key: 'active',
              stickyColumn: true,
              sortable: false,
              label: _vm.$t('settings.shipments.deliverynotes.enabled')
            }, {
              key: 'default',
              stickyColumn: true,
              sortable: false,
              label: _vm.$t('settings.shipments.deliverynotes.default')
            }, {
              key: 'identifier',
              stickyColumn: true,
              sortable: true,
              label: _vm.$t('settings.shipments.deliverynotes.identifier')
            }, {
              key: 'name',
              stickyColumn: true,
              sortable: true,
              label: _vm.$t('settings.shipments.deliverynotes.name')
            }, {
              key: 'attachment',
              stickyColumn: true,
              sortable: false,
              label: _vm.$t('settings.shipments.deliverynotes.attachment')
            }]
          },
          scopedSlots: _vm._u([{
            key: "cell(active)",
            fn: function fn(data) {
              return [_c('b-form-checkbox', {
                attrs: {
                  "checked": data.item.active
                },
                on: {
                  "change": function change($event) {
                    return _vm.toggleDisabledNotificationCode(data.item, $event);
                  }
                }
              })];
            }
          }, {
            key: "cell(default)",
            fn: function fn(data) {
              return [_c('b-form-checkbox', {
                attrs: {
                  "checked": data.item.default,
                  "disabled": !data.item.active
                },
                on: {
                  "change": function change($event) {
                    return _vm.toggleDefaultNotificationCode(data.item, $event);
                  }
                }
              })];
            }
          }, {
            key: "cell(attachment)",
            fn: function fn(data) {
              var _data$item, _data$item$attachment, _data$item$attachment2, _data$item2, _data$item2$requiredF;

              return [!(data !== null && data !== void 0 && (_data$item = data.item) !== null && _data$item !== void 0 && (_data$item$attachment = _data$item.attachment) !== null && _data$item$attachment !== void 0 && (_data$item$attachment2 = _data$item$attachment.name) !== null && _data$item$attachment2 !== void 0 && _data$item$attachment2.startsWith('Ja')) || (data === null || data === void 0 ? void 0 : (_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$requiredF = _data$item2.requiredFields) === null || _data$item2$requiredF === void 0 ? void 0 : _data$item2$requiredF.length) > 0 ? _c('span', [_vm._v("-")]) : _c('div', {
                staticClass: "input-group input-group-sm"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.item.attachment.defaultValue,
                  expression: "data.item.attachment.defaultValue"
                }],
                staticClass: "form-control form-control-sm",
                attrs: {
                  "type": "text",
                  "maxlength": "35"
                },
                domProps: {
                  "value": data.item.attachment.defaultValue
                },
                on: {
                  "blur": function blur($event) {
                    return _vm.setDefaultAttachment(data.item);
                  },
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(data.item.attachment, "defaultValue", $event.target.value);
                  }
                }
              })])];
            }
          }], null, true)
        })];
      }
    }])
  })], 1)]), _c('b-tab', {
    attrs: {
      "title": _vm.$t('settings.notifications')
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t('settings.notifications')))]), _c('div', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t('settings.notifications.configure-notifications')))]), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.notifications.shipping-notices'),
      "description": _vm.$t('settings.notifications.shipping-notices.description'),
      "content-cols": "3",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var ariaDescribedby = _ref10.ariaDescribedby;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('b-form-checkbox', {
          staticClass: "mr-n2 mb-n1",
          attrs: {
            "switch": "",
            "value": "ON",
            "unchecked-value": "OFF"
          },
          on: {
            "change": function change($event) {
              return _vm.settingChanged(_vm.announcementSendmail);
            }
          },
          model: {
            value: _vm.announcementSendmail.value,
            callback: function callback($$v) {
              _vm.$set(_vm.announcementSendmail, "value", $$v);
            },
            expression: "announcementSendmail.value"
          }
        })], 1), _c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": _vm.$t('settings.notifications.specify-email'),
            "disabled": _vm.announcementSendmail.value != 'ON'
          },
          on: {
            "blur": function blur($event) {
              return _vm.settingChanged(_vm.announcementMailAddress);
            }
          },
          model: {
            value: _vm.announcementMailAddress.value,
            callback: function callback($$v) {
              _vm.$set(_vm.announcementMailAddress, "value", $$v);
            },
            expression: "announcementMailAddress.value"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('b-icon', {
          attrs: {
            "id": "info-icon-avis",
            "icon": "info-circle",
            "font-scale": "1.1"
          }
        }), _c('b-tooltip', {
          attrs: {
            "target": "info-icon-avis",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('settings.notifications.single-email-only')) + " ")])], 1)], 1)];
      }
    }])
  }), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.notifications.belog'),
      "description": _vm.$t('settings.notifications.belog.description'),
      "content-cols": "3",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var ariaDescribedby = _ref11.ariaDescribedby;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('b-form-checkbox', {
          staticClass: "mr-n2 mb-n1",
          attrs: {
            "switch": "",
            "value": "ON",
            "unchecked-value": "OFF"
          },
          on: {
            "change": function change($event) {
              return _vm.settingChanged(_vm.belogSendmail);
            }
          },
          model: {
            value: _vm.belogSendmail.value,
            callback: function callback($$v) {
              _vm.$set(_vm.belogSendmail, "value", $$v);
            },
            expression: "belogSendmail.value"
          }
        })], 1), _c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": _vm.$t('settings.notifications.specify-email'),
            "disabled": _vm.belogSendmail.value != 'ON'
          },
          on: {
            "blur": function blur($event) {
              return _vm.settingChanged(_vm.belogMailAddress);
            }
          },
          model: {
            value: _vm.belogMailAddress.value,
            callback: function callback($$v) {
              _vm.$set(_vm.belogMailAddress, "value", $$v);
            },
            expression: "belogMailAddress.value"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('b-icon', {
          attrs: {
            "id": "info-icon-avis",
            "icon": "info-circle",
            "font-scale": "1.1"
          }
        }), _c('b-tooltip', {
          attrs: {
            "target": "info-icon-avis",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('settings.notifications.single-email-only')) + " ")])], 1)], 1)];
      }
    }])
  })], 1)])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }