import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"
import {extend} from "@emons/emons-vue"
import AwaitLock from "await-lock";

extend('fields_required', {
    validate: (value,  fields) => {
        for(let i in fields) {
            let target = fields[i]
            if (target != null && target != undefined && !!String(target).length) {
                console.log('fields_required matched - value, fields, i, target', value, fields, i, target)
                return true
            }
        }
        console.log('fields_required not matched - fields', value, fields)
        return false
    },
})

const rel = 'notificationCodes'
const schema = {
    abbreviation: null,
    name: null,
    attachment: {
        name: null,
        defaultValue: null,
        validator: null
    },
    multiUsage: null,
    active: false,
    default: false
}
const header = {
    abbreviation: {
        name: 'Schlüssel',
        col: 'col-2',
        property: 'identifier'
    },
    name: {
        name: 'Bezeichnung',
        col: 'col-3',
        property: 'name'
    }
}
const filter = null;
const sync = null;
const initialSort = 'identifier'
const projection = 'list'
const create = false

const constraints = {
    "150": {
        isSelectable: function(product) {
            return (product?.deliveryDateWindow == null || product?.deliveryDateIsOptional == true)
        },
        requiredFields: ['consignee.phone', 'consignee.mobile'],
    },
    "166": {
        isSelectable: function(product) {
            return true
        },
        requiredFields: ['consignee.phone', 'consignee.mobile'],
    },
    "230": {
        isSelectable: function(product) {
            return (product?.deliveryDateWindow == null || product?.deliveryDateIsOptional == true)
        },
        requiredFields: ['consignee.web'],
    },
    "352": {
        isSelectable: function(product) {
            return (product?.deliveryDateWindow == null || product?.deliveryDateIsOptional == true)
        },
        requiredFields: ['consignee.mobile', 'consignee.email', 'consignee.phone'],
    },
    "400": {
        isSelectable: function(product) {
            return true
        },
        requiredFields: ['consignee.email']
    },
    "601": {
        isSelectable: function(product) {
            return true
        },
        requiredFields: ['sender.phone'],
    },
}

const addConstraints = function(codes) {
    if (codes) {
        codes.forEach(code => {
            let codeConstraints = constraints[code.identifier]
            if (codeConstraints?.requiredFields) {
                code.requiredFields = codeConstraints.requiredFields
            }
            if (codeConstraints && codeConstraints.isSelectable) {
                code.isSelectable = codeConstraints.isSelectable
            }
        })
    }
}

const lock = new AwaitLock()

let allNotificationCodes = null
let activeNotificationCodes = null


const Service = {
    isSelectable: function (code, product) {
        const constraint = constraints[code.identifier]
        if (constraint) {
            return constraint.isSelectable(product)
        }
        return true
    },
    getHeader: function () {
        return header
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function() {
        return initialSort
    },
    getProjection: function () {
        return projection
    },
    findAll: async function() {
        if (allNotificationCodes == null) {
            await lock.acquireAsync()
            try {
                if (allNotificationCodes == null) {
                    allNotificationCodes = await this._findAllInternal()
                }
            } finally {
                lock.release()
            }
        }
        return allNotificationCodes
    },
    findActive: async function() {
        if (activeNotificationCodes == null) {
            await lock.acquireAsync()
            try {
                if (activeNotificationCodes == null) {
                    activeNotificationCodes = await this._findAllInternal(true)
                }
            } finally {
                lock.release()
            }
        }
        return activeNotificationCodes
    },
    _findAllInternal: async function(active = null) {
        try {
            return ApiService.get('/api/notificationCodes', {
                params: {
                    active: active
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })
                            addConstraints(response.items)

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying " + rel + ": ", error)
        }
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size) {
        try {
            return ApiService.get('/api/notificationCodes', {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })
                            addConstraints(response.items)

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    nextPage: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })
                            addConstraints(response.items)

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    save: async function (entity) {
        await lock.acquireAsync()
        let response
        try {
            allNotificationCodes = null
            activeNotificationCodes = null
            if (entity._links && entity._links.self) {
                response = await ApiService.put(this.cleanLink(entity._links.self.href), entity)
            } else {
                response = await ApiService.post(path, entity)
            }
            if (response?.data) {
                ApiService.ensureSchema(schema, response.data)
            }
        } finally {
            lock.release()
        }
        return response
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    }
}

export default Service
export { addConstraints }
