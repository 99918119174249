var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$details$types, _vm$details, _vm$details$_links, _vm$details$_links$in, _vm$details2, _vm$details2$types;

        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty,
            failed = _ref.failed,
            handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('label', [_c('strong', [_vm._v(_vm._s(_vm.$t('address.address-data')))])])])]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "d-inline-flex"
        }, [_c('validation-provider', {
          attrs: {
            "name": "addressType",
            "vid": _vm.id + '-addressType',
            "rules": 'required',
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var classes = _ref2.classes,
                  errors = _ref2.errors;
              return [_c('b-form-checkbox-group', {
                staticClass: "mb-4 address-type-checkbox-group",
                attrs: {
                  "disabled": !_vm.canEdit(),
                  "name": "addressTypes",
                  "state": _vm.state
                },
                on: {
                  "input": function input($event) {
                    return _vm.$emit('input', _vm.details);
                  }
                },
                model: {
                  value: _vm.details.types,
                  callback: function callback($$v) {
                    _vm.$set(_vm.details, "types", $$v);
                  },
                  expression: "details.types"
                }
              }, [_c('b-form-checkbox', {
                attrs: {
                  "value": "SENDER"
                },
                on: {
                  "input": function input($event) {
                    return _vm.checkTypes($event);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('addressType.SENDER')) + " ")]), _c('b-form-checkbox', {
                attrs: {
                  "value": "DEFAULT_SENDER",
                  "disabled": _vm.isDefaultSenderDisabled
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('addressType.DEFAULT_SENDER')) + " ")]), _c('b-form-checkbox', {
                attrs: {
                  "value": "CONSIGNEE"
                },
                on: {
                  "input": function input($event) {
                    return _vm.checkTypes($event);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('addressType.CONSIGNEE')) + " ")]), _c('b-form-checkbox', {
                attrs: {
                  "value": "DEFAULT_CONSIGNEE",
                  "disabled": _vm.isDefaultConsigneeDisabled
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('addressType.DEFAULT_CONSIGNEE')) + " ")]), _c('b-form-checkbox', {
                attrs: {
                  "value": "LOADING_SITE",
                  "disabled": !_vm.isLoadingSiteSelectable
                },
                on: {
                  "input": function input($event) {
                    return _vm.checkTypes($event);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('addressType.LOADING_SITE')) + " ")]), _c('b-form-checkbox', {
                attrs: {
                  "value": "NEUTRAL",
                  "disabled": _vm.isNeutralDisabled
                }
              }, [_vm._v(_vm._s(_vm.$t(_vm.getNeutralCheckboxTranslationKey())) + " ")]), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.state
                }
              }, [_vm._v("Es muss mindestens Versender, Empfänger oder Ladestelle ausgewählt werden!")])], 1)];
            }
          }], null, true)
        })], 1)])]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-name',
            "name": "name",
            "rules": "required",
            "maxlength": "35",
            "placeholder": _vm.$t('address.name'),
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.name,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "name", $$v);
            },
            expression: "details.name"
          }
        })], 1), _vm.isAdmin ? _c('div', {
          staticClass: "col-3"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-emnonsCustomerId',
            "name": "emonsCustomerId",
            "maxlength": "6",
            "placeholder": _vm.$t('address.emonsCustomerId'),
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.emonsCustomerId,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "emonsCustomerId", $$v);
            },
            expression: "details.emonsCustomerId"
          }
        })], 1) : _vm._e(), _c('div', {
          staticClass: "col-3"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-customerId',
            "name": "customerId",
            "maxlength": "35",
            "placeholder": _vm.$t('address.customerId'),
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.customerId,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "customerId", $$v);
            },
            expression: "details.customerId"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-additionalName',
            "name": "additionalName",
            "maxlength": "35",
            "placeholder": _vm.$t('address.additionalName'),
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.additionalName,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "additionalName", $$v);
            },
            expression: "details.additionalName"
          }
        })], 1), _c('div', {
          staticClass: "col-5"
        }, [_c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-isRetail',
            "name": "isRetail",
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly,
            "placeholder": _vm.$t('address.isRetail')
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.isRetail,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "isRetail", $$v);
            },
            expression: "details.isRetail"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-street',
            "name": "street",
            "rules": "required",
            "maxlength": "35",
            "placeholder": _vm.$t('address.streetWithNumber'),
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.street,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "street", $$v);
            },
            expression: "details.street"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-3"
        }, [_c('country-select', {
          attrs: {
            "id": _vm.id + '-country',
            "name": "country",
            "required": true,
            "placeholder": _vm.$t('address.country'),
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.country,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "country", $$v);
            },
            expression: "details.country"
          }
        })], 1), _c('div', {
          staticClass: "col-3"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-zipCode',
            "name": "zipCode",
            "rules": {
              required: true,
              zipCode: {
                country: '@' + _vm.id + '-country'
              }
            },
            "placeholder": _vm.$t('address.zipCode'),
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly
          },
          on: {
            "blur": function blur($event) {
              return _vm.formatZipCode($event);
            },
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.zipCode,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "zipCode", $$v);
            },
            expression: "details.zipCode"
          }
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('city-select', {
          attrs: {
            "id": _vm.id + '-city',
            "rules": "required",
            "disabled": !_vm.canEdit() || _vm.isAdministrativeDataReadOnly,
            "country": _vm.details.country,
            "zip-code": _vm.details.zipCode
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            },
            "citySelect": _vm.onCitySelect
          },
          model: {
            value: _vm.details.city,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "city", $$v);
            },
            expression: "details.city"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('order-invoice-recipient-view', {
          attrs: {
            "id": _vm.id + '-invoiceRecipient',
            "editable": _vm.canEdit() && ((_vm$details$types = _vm.details.types) === null || _vm$details$types === void 0 ? void 0 : _vm$details$types.some(function (t) {
              return t == 'SENDER' || t == 'LOADING_SITE';
            })),
            "data-editable": false,
            "api-path": (_vm$details = _vm.details) === null || _vm$details === void 0 ? void 0 : (_vm$details$_links = _vm$details._links) === null || _vm$details$_links === void 0 ? void 0 : (_vm$details$_links$in = _vm$details$_links.invoiceRecipients) === null || _vm$details$_links$in === void 0 ? void 0 : _vm$details$_links$in.href
          },
          model: {
            value: _vm.details.invoiceRecipient,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "invoiceRecipient", $$v);
            },
            expression: "details.invoiceRecipient"
          }
        })], 1)])]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('label', [_c('strong', [_vm._v(_vm._s(_vm.$t('address.contact-data')))])])])]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-contactName',
            "name": "contactName",
            "maxlength": "255",
            "placeholder": _vm.$t('address.contactName'),
            "disabled": !_vm.canEdit()
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.contactName,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "contactName", $$v);
            },
            expression: "details.contactName"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-phone',
            "name": "phone",
            "rules": "phoneNumber",
            "placeholder": _vm.$t('address.phone'),
            "disabled": !_vm.canEdit()
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "phone", $$v);
            },
            expression: "details.phone"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-email',
            "name": "email",
            "placeholder": _vm.$t('address.email'),
            "disabled": !_vm.canEdit(),
            "rules": {
              email: true,
              required: _vm.details.autosendShippingDocuments === true && _vm.isLoadingSiteSelected
            }
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.email,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "email", $$v);
            },
            expression: "details.email"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-mobile',
            "name": "mobile",
            "rules": "phoneNumber:MOBILE",
            "placeholder": _vm.$t('address.mobile'),
            "disabled": !_vm.canEdit()
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.mobile,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "mobile", $$v);
            },
            expression: "details.mobile"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-web',
            "name": "web",
            "placeholder": _vm.$t('address.url'),
            "disabled": !_vm.canEdit()
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.web,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "web", $$v);
            },
            expression: "details.web"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-autosendShippingDocuments',
            "name": "autosendShippingDocuments",
            "disabled": !((_vm$details2 = _vm.details) !== null && _vm$details2 !== void 0 && (_vm$details2$types = _vm$details2.types) !== null && _vm$details2$types !== void 0 && _vm$details2$types.some(function (type) {
              return type === 'LOADING_SITE';
            })) || !_vm.canEdit(),
            "placeholder": _vm.$t('address.autosendShippingDocuments')
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.autosendShippingDocuments,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "autosendShippingDocuments", $$v);
            },
            expression: "details.autosendShippingDocuments"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('validation-provider', {
          attrs: {
            "vid": _vm.id + '-deliveryNotes'
          }
        }, [_c('delivery-notes-view', {
          attrs: {
            "id": _vm.id + '-deliveryNotes',
            "editable": _vm.canEdit(),
            "consignee": _vm.details,
            "sender": _vm.details,
            "add-defaults": false
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', _vm.details);
            }
          },
          model: {
            value: _vm.details.deliveryNotes,
            callback: function callback($$v) {
              _vm.$set(_vm.details, "deliveryNotes", $$v);
            },
            expression: "details.deliveryNotes"
          }
        })], 1)], 1)])])]), _vm.showActions && (_vm.canDelete() || _vm.canEdit()) ? _c('div', {
          staticClass: "row pt-2"
        }, [_c('div', {
          staticClass: "col-md-6 offset-md-6"
        }, [_c('div', {
          staticClass: "form-group row justify-content-end"
        }, [_vm.canEdit() && !_vm.isNew() ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": "abortButton",
            "disabled": !(changed || dirty)
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.abortEdit();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.abort')) + " ")])]) : _vm._e(), _vm.canEdit() ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-primary",
          attrs: {
            "id": "saveButton",
            "type": "submit",
            "disabled": _vm.axiosSaveInProgress || !changed || invalid
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveEntity();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.save')) + " ")])]) : _vm._e(), _vm.canDelete() ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-danger",
          attrs: {
            "id": "deleteButton"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.confirmDelete();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.delete')) + " ")])]) : _vm._e()])])]) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }