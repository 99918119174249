<template>
    <b-input-group class="mb-3 time-input">
    <b-input-group-prepend>
      <b-form-timepicker :value="inputVal" @input="setFromTimepicker($event)"
                         :class="['form-control'].concat(filterValidationClasses($refs.validator?.classes))" style="padding-right: unset !important;"
                         :disabled="disabled"
                         :id="id + '-timepicker'"
                         minutes-step="15" :hour12="false"
                         :placeholder="$t('time.not-selected')"
                         reset-button
                         :label-no-time-selected="$t('time.not-selected')"
                         :label-close-button="$t('controls.close')"
                         :label-reset-button="$t('controls.reset')"
                         button-only dropright />
    </b-input-group-prepend>

    <validation-provider :disabled="disabled" :vid="id" :name="name" ref="validator"
                         :immediate="hasValue && !disabled"
                         :rules="{
                            regex: hoursMinutesRE,
                            ...rules
                          }"
                         v-slot="{classes, errors}"
                         slim>
    <b-form-input ref="input" type="text" :id="id" :name="name" minlength="5" maxlength="5"

           :value="stripSeconds(inputVal)" @input="setFromInput($event)"
           class="form-control"
           :class="filterValidationClasses(classes)"
           @keydown="onInputKeyDown($event)"
           :placeholder="placeholder || $t('time.not-selected')"
           :disabled="disabled"/>
    </validation-provider>

    <b-input-group-append>
      <div :class="['input-group-text', 'form-control', 'form-control-' + this.size, 'input-border-right'].concat(filterValidationClasses($refs.validator?.classes))">
      <b-icon-backspace-fill @click="clearTime()" v-if="!disabled" />
      <b-icon-backspace-fill v-else />
      </div>
    </b-input-group-append>
    <span class="invalid-feedback">{{ $refs.validator?.errors[0] }}</span>
  </b-input-group>
</template>

<script>
import {ValidationProvider} from '@emons/emons-vue'
import {RequiredFieldsMixin} from '@emons/emons-vue'

export default {
  name: "time-input",
  components: {ValidationProvider},
  mixins: [RequiredFieldsMixin],
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
    },
    value: {
      required: true
    }
  },
  data() {
    return {
      inputVal: this.value,
      hoursMinutesRE: /^(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])$/,
      hoursMinutesSecondsRE: /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
    }
  },
  methods: {
    stripSeconds(time) {
      if (this.hoursMinutesSecondsRE.test(time)) {
        return time.substring(0, time.length - 3)
      }
      return time
    },
    setFromTimepicker(time) {
      this.inputVal = time
      this.$emit('input', time)
    },
    setFromInput(time) {
      let newTime = time
      if (this.hoursMinutesRE.test(newTime)) {
        newTime = time + ":00"
      }
      this.inputVal = newTime
      this.$emit('input', newTime)
    },
    onInputKeyDown(event) {
      // backspace, delete, tab, left, right, up, down
      if (event.key === "Backspace" || event.key == "Delete" || event.keyCode === 9 || event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
        return
      } else if (event.key == ':' && this.inputVal.indexOf(':') < 0) {
        return
      } else if (!/^[0-9]$/.test(event.key)) {
        event.preventDefault()
      }
    },
    clearTime() {
      this.inputVal = ""
      this.$emit('input', '')
    },
    filterValidationClasses: function (classes) {
      const newClasses = classes || []
      if (!this.isRequired && !this.hasValue) {
        delete newClasses['is-valid']
      }
      return newClasses
    }
  },
  computed: {
    inputClass() {
      return [this.size ? `form-control-${this.size}` : null]
    },
    hasValue() {
      const value = this.inputVal
      return value !== null
          && value !== undefined
          && ((typeof value === 'string' && value.length > 0) || (typeof value === 'number'))
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newVal) {
        this.inputVal = newVal
      }
    }
  }
}

</script>

<style scoped>

.time-input .input-group-prepend > .btn.is-invalid,
.time-input .input-group-prepend > .btn.is-valid,
.time-input .input-group-append > .btn.is-invalid,
.time-input .input-group-append > .btn.is-valid {
  background-image: none !important;
  background-position: unset !important;
  padding-right: 0.25rem !important;
  background-size: unset !important;
}
.time-input .input-group-prepend > .is-invalid,
.time-input .input-group-prepend > .is-valid,
.time-input .input-group-append > .is-invalid,
.time-input .input-group-append > .is-valid {
  background-image: none !important;
  background-position: unset !important;
  padding-right: 0.375rem!important;
  background-size: unset !important;
}
</style>