var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-page', [_c('template', {
    slot: "content"
  }, [_c('h5', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.$t('nav.admin')))]), _c('h2', [_vm._v(_vm._s(_vm.$t('nav.admin.services')))]), _c('div', {
    staticClass: "text-muted"
  }, [_vm._v("Status und Verwalung einzelner myEmons Dienste")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-form-group', {
    attrs: {
      "label": "SFTP Verarbeitung",
      "content-cols": "9",
      "label-cols": "3",
      "label-size": "lg",
      "label-class": "font-weight-bold pt-0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$sftpServiceStatus;

        var ariaDescribedby = _ref.ariaDescribedby;
        return [(_vm$sftpServiceStatus = _vm.sftpServiceStatus) !== null && _vm$sftpServiceStatus !== void 0 && _vm$sftpServiceStatus.enabled ? _c('div', [_c('span', {
          staticClass: "text-white bg-success pt-2 pb-2 pl-2 pr-2 text-center m-2"
        }, [_vm._v("Gestartet")]), _c('b-btn', {
          attrs: {
            "size": "sm",
            "variant": "link"
          },
          on: {
            "click": _vm.disableSftp
          }
        }, [_vm._v("Dienst stoppen")])], 1) : _c('div', [_c('span', {
          staticClass: "text-white bg-danger pt-2 pb-2 pl-2 pr-2 text-center m-2"
        }, [_vm._v("Gestoppt")]), _c('b-btn', {
          attrs: {
            "size": "sm",
            "variant": "link"
          },
          on: {
            "click": _vm.enableSftp
          }
        }, [_vm._v("Dienst starten")])], 1)];
      }
    }])
  })], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }