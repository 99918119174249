import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"
import AwaitLock from "await-lock";

const rel = 'packagingCodes'
const schema = {
    abbreviation: null,
    name: null
}
const header = {
    abbreviation: {
        name: 'Schlüssel',
        col: 'col-2',
        property: 'identifier'
    },
    name: {
        name: 'Bezeichnung',
        col: 'col-3',
        property: 'name'
    }
}
const filter = null;
const sync = null;
const initialSort = 'identifier'
const projection = 'list'
const create = false

const lock = new AwaitLock()

let allPackagingCodes = null

const Service = {
    getHeader: function () {
        return header
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function() {
        return initialSort
    },
    getProjection: function () {
        return projection
    },
    findAll: async  function() {
        if (allPackagingCodes == null) {
            await lock.acquireAsync()
            try {
                if (allPackagingCodes == null) {
                    allPackagingCodes = await this.findAllInternal()
                }
            } finally {
                lock.release()
            }
        }
        return allPackagingCodes
    },
    findAllInternal: async function() {
        try {
            return ApiService.get('/api/packagingCodes', {
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying " + rel + ": ", error)
        }
    },
    findByQuery: async function (context, filterItem, searchText) {
        try {
            return ApiService.get('/api/packagingCodes', {
                params: {
                    query: searchText,
                    sort: filterItem.orderBy,
                    size: 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying filter elements:", error)
        }
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size) {
        try {
            return ApiService.get('/api/packagingCodes', {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    nextPage: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    }
}

export default Service