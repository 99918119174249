var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
            changed = _ref.changed;
        return [_c('b-modal', {
          ref: "modal",
          attrs: {
            "size": "xl",
            "id": _vm.id
          },
          on: {
            "hidden": _vm.reset
          },
          scopedSlots: _vm._u([{
            key: "modal-title",
            fn: function fn() {
              var _vm$invoiceRecipient, _vm$invoiceRecipient$, _vm$invoiceRecipient$2;

              return [(_vm$invoiceRecipient = _vm.invoiceRecipient) !== null && _vm$invoiceRecipient !== void 0 && (_vm$invoiceRecipient$ = _vm$invoiceRecipient._links) !== null && _vm$invoiceRecipient$ !== void 0 && (_vm$invoiceRecipient$2 = _vm$invoiceRecipient$.self) !== null && _vm$invoiceRecipient$2 !== void 0 && _vm$invoiceRecipient$2.href ? _c('span', [_vm._v(_vm._s(_vm.$t('customer.edit-invoice-recipient', {
                customerId: _vm.customerId
              })))]) : _c('span', [_vm._v(_vm._s(_vm.$t('customer.add-invoice-recipient', {
                customerId: _vm.customerId
              })))])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function fn() {
              return [_c('invoice-recipient-address-view', {
                ref: "invoiceRecipientView",
                attrs: {
                  "id": _vm.id,
                  "show-actions": false
                },
                on: {
                  "save": function save($event) {
                    return _vm.$emit('save', $event);
                  }
                },
                model: {
                  value: _vm.invoiceRecipient,
                  callback: function callback($$v) {
                    _vm.invoiceRecipient = $$v;
                  },
                  expression: "invoiceRecipient"
                }
              })];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn(_ref2) {
              var _vm$$refs$invoiceReci, _vm$$refs$invoiceReci2;

              var ok = _ref2.ok,
                  cancel = _ref2.cancel,
                  hide = _ref2.hide;
              return [_c('b-button', {
                attrs: {
                  "variant": "secondary"
                },
                on: {
                  "click": cancel
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.cancel')))]), _c('b-button', {
                attrs: {
                  "variant": "primary",
                  "disabled": ((_vm$$refs$invoiceReci = _vm.$refs['invoiceRecipientView']) === null || _vm$$refs$invoiceReci === void 0 ? void 0 : _vm$$refs$invoiceReci.isSaveInProgress()) || !changed || invalid
                },
                on: {
                  "click": function click($event) {
                    return _vm.submit(ok);
                  }
                }
              }, [(_vm$$refs$invoiceReci2 = _vm.$refs['invoiceRecipientView']) !== null && _vm$$refs$invoiceReci2 !== void 0 && _vm$$refs$invoiceReci2.isSaveInProgress() ? _c('b-spinner', {
                attrs: {
                  "small": ""
                }
              }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t('controls.save')) + " ")], 1)];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }