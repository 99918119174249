var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header p-2"
  }, [_c('div', {
    staticClass: "form-row align-items-center"
  }, [_c('label', {
    staticClass: "col-6 mb-0"
  }, [_vm._v(_vm._s(_vm.$t('orders.cargo-list')))]), _c('div', {
    staticClass: "col-6 text-right"
  }, [_vm.editable ? _c('b-dropdown', {
    staticClass: "template-dropdown-menu",
    attrs: {
      "variant": "link",
      "toggle-class": "p-0",
      "right": ""
    },
    on: {
      "shown": _vm.onDropdownShown,
      "hidden": _vm.onDropdownHidden
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.$t('orders.add-cargo-item')))];
      },
      proxy: true
    }], null, false, 557797036)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.addCargoItem();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('orders.add-empty-cargo-item')))]), _c('b-dropdown-divider'), _c('b-dropdown-form', {
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
      },
      "focus": function focus($event) {
        return _vm.$refs.filterCargoItemTemplates.focus();
      }
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "debounce",
      rawName: "v-debounce:500ms",
      value: _vm.findCargoItemTemplates,
      expression: "findCargoItemTemplates",
      arg: "500ms"
    }],
    ref: "filterCargoItemTemplates",
    attrs: {
      "id": "filter-cargoItemTemplates",
      "size": "sm",
      "autocomplete": "off",
      "placeholder": _vm.$t('orders.search-templates')
    },
    model: {
      value: _vm.filterCargoItemTemplates,
      callback: function callback($$v) {
        _vm.filterCargoItemTemplates = $$v;
      },
      expression: "filterCargoItemTemplates"
    }
  })], 1), _vm.cargoItemTemplates.length > 0 ? _c('b-dropdown-divider') : _vm._e(), _c('b-dropdown-group', {
    staticClass: "template-dropdown-group"
  }, _vm._l(_vm.cargoItemTemplates, function (cargoItemTemplate, idx) {
    return _vm.cargoItemTemplates.length > 0 ? _c('b-dropdown-item', {
      key: 'template-' + idx,
      on: {
        "click": function click($event) {
          return _vm.addCargoItemTemplate(cargoItemTemplate);
        }
      }
    }, [_c('div', [_c('strong', [_vm._v(_vm._s(cargoItemTemplate.name))])])]) : _vm._e();
  }), 1)], 1) : _vm._e()], 1)])]), _c('ul', {
    staticClass: "list-group list-group-flush"
  }, _vm._l(_vm.value.items, function (item, idx) {
    var _vm$optionalFields, _vm$optionalFields2, _vm$optionalFields3;

    return _c('validation-observer', {
      key: idx,
      ref: "cargoListObserver",
      refInFor: true,
      attrs: {
        "vid": 'cargoListObserver-' + _vm.id + '[' + idx + ']'
      }
    }, [_c('li', {
      staticClass: "list-group-item"
    }, [_c('div', {
      staticClass: "d-flex w-100 align-items-center"
    }, [_c('h6', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(_vm.$t('cargo-item.position', {
      position: idx + 1
    })))])]), _c('div', [_c('div', {
      staticClass: "form-row"
    }, [_c('div', {
      staticClass: "col-12 col-lg-6 col-xl-6"
    }, [_c('div', {
      staticClass: "form-row"
    }, [_c('div', {
      staticClass: "col-2 col-lg-3"
    }, [_c('e-form-text-input', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].colliQty',
        "type": "number",
        "min": "1",
        "step": "1",
        "name": "colliQty",
        "rules": {
          required: true,
          numeric: true,
          positive: true,
          min_value: 1
        },
        "size": "sm",
        "placeholder": _vm.$t('cargo-item.colli.short'),
        "disabled": !_vm.editable
      },
      on: {
        "input": _vm.calculateVolume
      },
      model: {
        value: item.colliQty,
        callback: function callback($$v) {
          _vm.$set(item, "colliQty", $$v);
        },
        expression: "item.colliQty"
      }
    })], 1), _c('div', {
      staticClass: "col-3 col-lg-3"
    }, [_c('packaging-code-select', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].packingCode',
        "name": "packingCode",
        "size": "sm",
        "required": true,
        "disabled": !_vm.editable,
        "filter": _vm.packagingCodeFilter,
        "placeholder": _vm.$t('cargo-item.packingCode.short')
      },
      model: {
        value: item.packingCode,
        callback: function callback($$v) {
          _vm.$set(item, "packingCode", $$v);
        },
        expression: "item.packingCode"
      }
    })], 1), _c('div', {
      staticClass: "col-7 col-lg-6"
    }, [_c('e-form-text-input', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].goods',
        "name": "goods",
        "maxlength": "30",
        "rules": "required",
        "size": "sm",
        "placeholder": _vm.$t('cargo-item.goods'),
        "disabled": !_vm.editable
      },
      model: {
        value: item.goods,
        callback: function callback($$v) {
          _vm.$set(item, "goods", $$v);
        },
        expression: "item.goods"
      }
    })], 1)])]), _c('div', {
      staticClass: "col-12 col-lg-6 col-xl-6"
    }, [_c('div', {
      staticClass: "form-row"
    }, [_c('div', {
      staticClass: "col-6"
    }, [_c('e-form-text-input', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].marking',
        "name": "marking",
        "maxlength": "17",
        "size": "sm",
        "rules": _vm.requiredFields.includes('marking') ? 'required' : '',
        "placeholder": _vm.$t('cargo-item.marking'),
        "disabled": !_vm.editable
      },
      model: {
        value: item.marking,
        callback: function callback($$v) {
          _vm.$set(item, "marking", $$v);
        },
        expression: "item.marking"
      }
    })], 1), _c('div', {
      staticClass: "col-5"
    }, [_c('e-form-text-input', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].orderNumber',
        "name": "orderNumber",
        "maxlength": "35",
        "size": "sm",
        "rules": _vm.requiredFields.includes('orderNumber') ? 'required' : '',
        "placeholder": _vm.$t('cargo-item.orderNumber.short'),
        "disabled": !_vm.editable
      },
      model: {
        value: item.orderNumber,
        callback: function callback($$v) {
          _vm.$set(item, "orderNumber", $$v);
        },
        expression: "item.orderNumber"
      }
    })], 1), _c('div', {
      staticClass: "col-1 text-right"
    }, [_vm.editable && _vm.value.items.length > 1 ? _c('b-icon', {
      staticStyle: {
        "margin-top": "7px",
        "cursor": "pointer"
      },
      attrs: {
        "icon": "trash",
        "font-scale": "1.2",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeCargoItem(idx);
        }
      }
    }) : _vm._e()], 1)])])]), _c('div', {
      staticClass: "form-row"
    }, [_c('div', {
      staticClass: "col-12 col-lg-8 col-xl-8"
    }, [_c('div', {
      staticClass: "form-row"
    }, [_c('div', {
      staticClass: "col-3"
    }, [_c('e-form-text-input', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].length',
        "type": "number",
        "name": "length",
        "maxlength": "3",
        "size": "sm",
        "rules": {
          required: !((_vm$optionalFields = _vm.optionalFields) !== null && _vm$optionalFields !== void 0 && _vm$optionalFields.includes('dimensions')),
          numeric: true,
          positive: true,
          min_value: 1
        },
        "placeholder": _vm.$t('cargo-item.length'),
        "disabled": !_vm.editable,
        "append": _vm.$t('unit.centimeter')
      },
      on: {
        "blur": function blur($event) {
          return _vm.checkLength($event);
        },
        "input": _vm.calculateVolume
      },
      model: {
        value: item.length,
        callback: function callback($$v) {
          _vm.$set(item, "length", $$v);
        },
        expression: "item.length"
      }
    })], 1), _c('div', {
      staticClass: "col-3"
    }, [_c('e-form-text-input', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].width',
        "type": "number",
        "maxlength": "3",
        "name": "width",
        "size": "sm",
        "rules": {
          required: !((_vm$optionalFields2 = _vm.optionalFields) !== null && _vm$optionalFields2 !== void 0 && _vm$optionalFields2.includes('dimensions')),
          numeric: true,
          positive: true,
          min_value: 1
        },
        "placeholder": _vm.$t('cargo-item.width'),
        "disabled": !_vm.editable,
        "append": _vm.$t('unit.centimeter')
      },
      on: {
        "input": _vm.calculateVolume
      },
      model: {
        value: item.width,
        callback: function callback($$v) {
          _vm.$set(item, "width", $$v);
        },
        expression: "item.width"
      }
    })], 1), _c('div', {
      staticClass: "col-3"
    }, [_c('e-form-text-input', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].height',
        "type": "number",
        "maxlength": "3",
        "name": "height",
        "size": "sm",
        "rules": {
          required: !((_vm$optionalFields3 = _vm.optionalFields) !== null && _vm$optionalFields3 !== void 0 && _vm$optionalFields3.includes('dimensions')),
          numeric: true,
          positive: true,
          min_value: 1
        },
        "placeholder": _vm.$t('cargo-item.height'),
        "disabled": !_vm.editable,
        "append": _vm.$t('unit.centimeter')
      },
      on: {
        "blur": function blur($event) {
          return _vm.checkHeight($event);
        },
        "input": _vm.calculateVolume
      },
      model: {
        value: item.height,
        callback: function callback($$v) {
          _vm.$set(item, "height", $$v);
        },
        expression: "item.height"
      }
    })], 1), _c('div', {
      staticClass: "col-3"
    }, [_c('e-form-text-input', {
      attrs: {
        "id": _vm.id + '-cargoList.items[' + idx + '].weight',
        "type": "number",
        "maxlength": "5",
        "name": "weight",
        "rules": {
          required: true,
          numeric: true,
          positive: true,
          min_value: 1
        },
        "size": "sm",
        "placeholder": _vm.$t('cargo-item.weight'),
        "disabled": !_vm.editable,
        "append": _vm.$t('unit.kilogram')
      },
      model: {
        value: item.weight,
        callback: function callback($$v) {
          _vm.$set(item, "weight", $$v);
        },
        expression: "item.weight"
      }
    })], 1)])]), _c('div', {
      staticClass: "col-12 col-lg-2 col-xl-2"
    }, [_c('div', {
      staticClass: "form-check form-check-inline"
    }, [_c('b-checkbox', {
      staticStyle: {
        "margin-top": "5px"
      },
      attrs: {
        "name": "dangerousGoods",
        "size": "sm",
        "disabled": !_vm.editable || item.hazmats && item.hazmats.length > 0,
        "checked": item.hazmats && item.hazmats.length > 0
      },
      on: {
        "change": function change($event) {
          return _vm.enableHazmat($event, item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('cargo-item.dangerousGoods')) + " ")])], 1)])]), item.hazmats && item.hazmats.length > 0 ? _c('ul', {
      staticClass: "list-group list-group-flush"
    }, [_c('div', {
      staticClass: "d-flex w-100 align-items-center"
    }, [_c('h6', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.for-position', {
      position: idx + 1
    })))]), _c('div', {
      staticClass: "col d-flex justify-content-end"
    }, [_vm.editable ? _c('button', {
      staticClass: "btn btn-sm btn-link",
      on: {
        "click": function click($event) {
          return _vm.addHazmat(item);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "plus"
      }
    }), _vm._v(" " + _vm._s(_vm.$t('cargo-item.adr.add')) + " ")], 1) : _vm._e()])]), _vm._l(item.hazmats, function (hazmat, hazmatIdx) {
      return _c('li', {
        key: hazmatIdx,
        staticClass: "list-group-item"
      }, [_c('div', {
        staticClass: "form-row"
      }, [_c('div', {
        staticClass: "col-12 col-lg-6 col-xl-6"
      }, [_c('div', {
        staticClass: "form-row"
      }, [_c('div', {
        staticClass: "col-2 hazmat-unnr"
      }, [_c('un-number-select', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].unNumber',
          "rules": "required",
          "size": "sm",
          "editable": _vm.editable,
          "value": hazmat.unNumber
        },
        on: {
          "input": function input($event) {
            return _vm.hazmatTemplateSelected($event, hazmat);
          }
        }
      })], 1), _c('div', {
        staticClass: "col-3"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].quantity',
          "type": "number",
          "min": "1",
          "step": "1",
          "name": "quantity",
          "rules": {
            required: true,
            numeric: true,
            positive: true,
            min_value: 1
          },
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.quantity.short'),
          "disabled": !_vm.editable
        },
        model: {
          value: hazmat.quantity,
          callback: function callback($$v) {
            _vm.$set(hazmat, "quantity", $$v);
          },
          expression: "hazmat.quantity"
        }
      })], 1), _c('div', {
        staticClass: "col-2"
      }, [_c('hazmat-packaging-code-select', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].packagingCode',
          "name": "packagingCode",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.packingCode'),
          "disabled": !_vm.editable,
          "required": true
        },
        model: {
          value: hazmat.packagingCode,
          callback: function callback($$v) {
            _vm.$set(hazmat, "packagingCode", $$v);
          },
          expression: "hazmat.packagingCode"
        }
      })], 1), _c('div', {
        staticClass: "col-5"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].shippingName',
          "name": "shippingName",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.shipping-name'),
          "disabled": true,
          "value": hazmat.shippingName
        }
      })], 1)])]), _c('div', {
        staticClass: "col-12 col-lg-6 col-xl-6"
      }, [_c('div', {
        staticClass: "form-row"
      }, [_c('div', {
        staticClass: "col-2"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].packagingGroup',
          "name": "packagingGroup",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.packaging-group.short'),
          "disabled": "",
          "rules": "required",
          "value": hazmat.packagingGroup || '-'
        }
      })], 1), _c('div', {
        staticClass: "col-2"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].hazardousSubstanceLabel',
          "name": "hazardousSubstanceLabel",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.hazardous-substance-label.short'),
          "disabled": "",
          "value": hazmat.hazardousSubstanceLabel || '-'
        }
      })], 1), _c('div', {
        staticClass: "col-2"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].classificationCode',
          "name": "classificationCode",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.classification-code.short'),
          "disabled": "",
          "value": hazmat.classificationCode || '-'
        }
      })], 1), _c('div', {
        staticClass: "col-2"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].tunnelRestrictionCode',
          "name": "tunnelRestrictionCode",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.tunnel-restriction-code.short'),
          "disabled": "",
          "value": hazmat.tunnelRestrictionCode || '-'
        }
      })], 1), _c('div', {
        staticClass: "col-2"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].transportCategory',
          "name": "transportCategory",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.transport-category.short'),
          "disabled": "",
          "value": hazmat.transportCategory || '-'
        }
      })], 1), _c('div', {
        staticClass: "col-2"
      })])]), _c('div', {
        staticClass: "col-12 col-lg-4 col-xl-4"
      }, [_c('div', {
        staticClass: "form-row"
      }, [_c('div', {
        staticClass: "col-12"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].technicalName',
          "name": "technicalName",
          "rules": {
            required: hazmat.technicalNameRequired
          },
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.technical-name.short'),
          "disabled": !_vm.editable
        },
        model: {
          value: hazmat.technicalName,
          callback: function callback($$v) {
            _vm.$set(hazmat, "technicalName", $$v);
          },
          expression: "hazmat.technicalName"
        }
      })], 1)])]), _c('div', {
        staticClass: "col-12 col-lg-8 col-xl-8"
      }, [_c('div', {
        staticClass: "form-row"
      }, [_c('div', {
        staticClass: "col-6"
      }, [_c('e-form-checkbox', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].limitedQuantities',
          "name": "limitedQuantities",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.limited-quantities'),
          "disabled": !_vm.editable
        },
        model: {
          value: hazmat.limitedQuantities,
          callback: function callback($$v) {
            _vm.$set(hazmat, "limitedQuantities", $$v);
          },
          expression: "hazmat.limitedQuantities"
        }
      }), _c('e-form-checkbox', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].environmentalHazard',
          "name": "environmentalHazard",
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.environmental-hazard'),
          "disabled": !_vm.editable
        },
        model: {
          value: hazmat.environmentalHazard,
          callback: function callback($$v) {
            _vm.$set(hazmat, "environmentalHazard", $$v);
          },
          expression: "hazmat.environmentalHazard"
        }
      })], 1), _c('div', {
        staticClass: "col-3"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].weight',
          "type": "number",
          "name": "weight",
          "rules": {
            required: true,
            positive: true,
            max_value: hazmat.massUnit == 'kg' ? item.weight : false
          },
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.weight'),
          "disabled": !_vm.editable,
          "append": hazmat.massUnit
        },
        scopedSlots: _vm._u([_vm.editable ? {
          key: "append",
          fn: function fn(_ref) {
            var additionalClasses = _ref.additionalClasses;
            return [_c('b-dropdown', {
              staticClass: "p-0",
              class: additionalClasses,
              attrs: {
                "variant": "secondary",
                "toggle-class": "input-group-text dropdown-input",
                "text": hazmat.massUnit || 'kg'
              }
            }, [_c('b-dropdown-item', {
              on: {
                "click": function click($event) {
                  hazmat.massUnit = 'kg';
                }
              }
            }, [_vm._v(_vm._s(_vm.$t('unit.kilogram')))]), _c('b-dropdown-item', {
              on: {
                "click": function click($event) {
                  hazmat.massUnit = 'ltr';
                }
              }
            }, [_vm._v(_vm._s(_vm.$t('unit.litres')))])], 1)];
          }
        } : null], null, true),
        model: {
          value: hazmat.weight,
          callback: function callback($$v) {
            _vm.$set(hazmat, "weight", $$v);
          },
          expression: "hazmat.weight"
        }
      })], 1), _c('div', {
        staticClass: "col-2"
      }, [_c('e-form-text-input', {
        attrs: {
          "id": _vm.id + '-cargoList.items[' + idx + '].hazmats[' + hazmatIdx + '].neq',
          "type": "number",
          "name": "neq",
          "rules": {
            required: hazmat.hazardClass.startsWith('1')
          },
          "size": "sm",
          "placeholder": _vm.$t('cargo-item.adr.net-explosive-quantity.short'),
          "disabled": !_vm.editable || !hazmat.hazardClass.startsWith('1'),
          "append": _vm.$t('unit.kilogram')
        },
        model: {
          value: hazmat.neq,
          callback: function callback($$v) {
            _vm.$set(hazmat, "neq", $$v);
          },
          expression: "hazmat.neq"
        }
      })], 1), _c('div', {
        staticClass: "col-1 text-right"
      }, [_vm.editable ? _c('b-icon', {
        staticStyle: {
          "margin-top": "7px",
          "cursor": "pointer"
        },
        attrs: {
          "icon": "trash",
          "font-scale": "1.2",
          "variant": "danger"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteHazmat(item, hazmatIdx);
          }
        }
      }) : _vm._e()], 1)])])])]);
    })], 2) : _vm._e()])])]);
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }