<template>
    <b-modal :id="id">
      <template #modal-title>
        {{ $t('myemons.about') }}
      </template>
      <template #default>
        <ul>
          <li>MyEmons Frontend Version: {{ $root.$options.version }}</li>
          <li>MyEmons Backend Version: {{ backendVersion }}</li>
        </ul>
      </template>
      <template #modal-footer="{ok}">
        <b-button variant="primary" @click="ok()">{{ $t('controls.ok') }}</b-button>
      </template>
    </b-modal>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "AboutModal",
  props: {
    id: {
      type: String,
      default: 'about-modal'
    }
  },
  asyncComputed: {
    async backendVersion() {
      const response = await ApiService.get("/api/status")
      return response?.data?.backendVersion
    }
  },
  methods: {
    show() {
      this.$bvModal.show(this.id)
    },
  }
}
</script>

<style scoped>
</style>