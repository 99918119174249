import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const rel = 'invoiceRecipientAddresses'
const path = '/api/invoiceRecipientAddresses';
const schema = {
    customerId: null,
    name: null,
    additionalName: null,
    street: null,
    zipCode: null,
    city: null,
    country: null,
    contactName: null,
    phone: null
}
const header = {
    abbreviation: {
        name: 'address.customerId',
        col: 'col-2',
        property: 'customerId'
    },
    name: {
        name: 'address.name',
        col: 'col-3',
        property: 'name'
    },
    street: {
        name: 'address.street',
        col: 'col-3',
        property: 'street'
    },
    zipCode: {
        name: 'address.zipCode',
        col: 'col-1',
        property: 'zipCode'
    },
    city: {
        name: 'address.city',
        col: 'col-3',
        property: 'city'
    }
}
const filter = null;
const sync = null;
const initialSort = 'name'
const projection = 'list'
const create = true;

const Service = {
    getHeader: function () {
        return header
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function() {
        return initialSort
    },
    getProjection: function() {
        return projection
    },
    findWithPath: async function (customPath = path, query = null,
                          sortProperty = 'name',
                          sortDirection = 'asc',
                          size = 100,
                          page = 0) {
        try {
            return ApiService.get(customPath, {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size,
                    page: page
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'] && parsedData['_embedded'][rel]?parsedData['_embedded'][rel]:[]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size,
                          page = 0) {
        return this.findWithPath(path, query, sortProperty, sortDirection, size, page)
    },
    nextPage: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'] && parsedData['_embedded'][rel]?parsedData['_embedded'][rel]:[]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    _get: async  function(customerId, path) {
        try {
            const response = await ApiService.get(path + '/' + customerId, {
                params: {},

                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "_get():", parsedData)

                        return parsedData
                    }
                }]
            })
            return response?.data
        } catch (error) {
            if (error?.response?.status == 404) {
                log("debug", "entity not found")
            } else {
                log("error", "Error querying entity:", error)
            }
        }
        return null
    },
    getByCustomerId: async function (customerId, fallbackToEuromistral = false) {
        let customer = await this._get(customerId, path + '/find/byCustomerId')
        if (customer == null && fallbackToEuromistral) {
            customer = await this._get(customerId, path + '/find/byEuromistralCustomerId')
        }
        return customer
    },
    getDefault: async function(customerId = null) {
        try {
            const response = await ApiService.get(path + '/default', {
                params: {
                    customerId: customerId
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
            return response?.data
        } catch (error) {
            if (error?.response?.status == 404) {
                log("debug", "default invoice recipient not found")
            } else {
                log("error", "Error querying entity:", error)
            }
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    save: async function(entity, pathForCreate = null) {
        if (entity._links && entity._links.self)
            return ApiService.put(this.cleanLink(entity._links.self.href), entity).then(
                response => {
                    if(response.data)
                        ApiService.ensureSchema(schema, response.data)

                    return response
                })
        else
            return ApiService.post(!!pathForCreate ? pathForCreate : path, entity).then(
                response => {
                    if(response.data)
                        ApiService.ensureSchema(schema, response.data)

                    return response
                })
    },
    delete: async function(entity) {
        if (entity._links && entity._links.self) {
            return ApiService.delete(this.cleanLink(entity._links.self.href))
        }
    },
    create: function () {
        log('debug', 'create() called...')
        return {
            isOpen: true,
            changed: true,
            country: 'DE'
        };
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    }
}

export default Service