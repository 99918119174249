<template>
  <validation-provider v-if="!disabled" :vid="id" :name="name"
                       :rules="required?'required':''" v-slot="{ classes, errors, valid }" ref="validator" slim>
    <div :class="['form-label-group', 'input-group'].concat(size?'input-group-' + size:'')">
      <v-select
          :name="name"
          :id="id"
          :class="getCSSClasses(classes, {valid: valid})"
          @search:focus="onFocus"
          @search:blur="onBlur"
          @input="onInput"
          @open="onOpen"
          @close="onClose"
          :options="countries"
          :disabled="disabled"
          :multiple="multiple"
          :filterable="true"
          :filterBy="(country, label, query) => {
                                      return country.alpha2Code.toLowerCase().includes(query.toLowerCase())
                                             || $t('countries.' + country.alpha2Code).toLowerCase().includes(query.toLowerCase())
                                  }"
          :reduce="country => country.alpha2Code"
          :value="value"
          label="alpha2Code">
        <template slot="option" slot-scope="option">
          {{ option.alpha2Code }} - {{ $t('countries.' + option.alpha2Code) }}
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.alpha2Code }}
        </template>
      </v-select>
      <label v-if="placeholder" class="required">{{ placeholder }}</label>
      <span class="invalid-feedback">{{ errors[0] }}</span>
    </div>
  </validation-provider>
  <e-form-text-input v-else :id="id" :name="name" :size="size" :placeholder="placeholder" disabled :value="value" />
</template>

<script>
import {ValidationProvider} from '@emons/emons-vue'
import CountryService from "@/services/country.service";

export default {
  name: "CountrySelect",
  components: {ValidationProvider},
  props: {
    id: {
      type: String,
      default: 'country'
    },
    name: {
      type: String,
      default: 'country'
    },
    value: {
      type: [String, Array],
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    filter: {
      type: Function
    }
  },
  data() {
    return {
      countries: [],
    }
  },
  methods: {
    async onOpen() {
      // UGLY UGLY UGLY hack:
      // - add event listeners for mouseenter / mouseleave on dropdown menu using plain old js
      // - as vue-custom-scrollbar doesn't emit for these yet events yet
      await this.$nextTick() // dropdown-menu might not be in DOM yet
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.addEventListener('mouseenter', this.mouseListener, false)
      menu.addEventListener('mouseleave', this.mouseListener, false)
    },
    onClose() {
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.removeEventListener('mouseenter', this.mouseListener, false)
      menu.removeEventListener('mouseleave', this.mouseListener, false)
      this.lockParentScroll(false)
    },
    mouseListener(event) {
      this.lockParentScroll(event.type == 'mouseenter')
    },
    lockParentScroll(lock) {
      this.$eventBus.$emit('scroll:lock', lock)
    },
    onInput: function ($event) {
      this.$emit('input', $event)
    },
    onBlur: function () {
      this.$refs.validator.validate()
      this.$emit('blur')
    },
    onFocus: function () {
      this.findCountries()
      this.$emit('focus')
    },
    findCountries: async function () {
      if (!this.countries?.length) {
        this.$log('debug', 'findCountries() called...')
        const response = await CountryService.findAll()
        this.$log('debug', 'response', response)
        let countries = response?.data || []
        if (!!this.filter) {
          countries = countries.filter(this.filter)
        }
        this.countries = countries
      }
    },
    getCSSClasses(additionalClasses, flags) {
      let classes = {
        'form-control': true,
        'country-select': true,
        // for some unknown reason the 'is-valid' property is swalloewd from the additionalClasses object although
        // it is present, so we have to manually set it from validationprovider flags
        'is-valid': flags.valid,
        ...additionalClasses
      }
      if (this.size) {
        classes['form-control-' + this.size] = true
      }
      if (!!this.value) {
        classes['has-value'] = true
      }
      if (!this.placeholder) {
        classes['no-placeholder'] = true
      }
      return classes
    }
  }
}
</script>

<style>

.country-select .vs__search::placeholder,
.country-select .vs__dropdown-toggle {
  height: inherit;
  border: none;
  background-color: transparent !important;
}

.country-select.is-valid,
.was-validated .country-select:valid {
  background-position: right calc(32px + 0.3375em + .2625rem) center;
}

.country-select.is-invalid,
.was-validated .country-select:invalid {
  /* 14 px (actions size) + original background position */
  background-position: right calc(14px + 0.3375em + .2625rem) center;
}

.country-select.form-control {
  padding: unset !important;
}

.country-select.has-value.form-control-lg ~ label,
.country-select.vs--open.form-control-lg ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .825rem;
  color: #777;
}

.country-select.has-value.form-control-sm ~ label,
.country-select.vs--open.form-control-sm ~ label{
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .6rem;
  color: #777;
}

.country-select.has-value ~ label,
.country-select.vs--open ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .675rem;
  color: #778;
}

.country-select .vs__clear {
  line-height: 10px;
}

.vs--single.vs--open .vs__selected {
  position: absolute;
  top: 8px;
}

.vs--disabled .vs__search {
  background-color: transparent !important;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator {
  background-color: #e9ecef !important;
}

.country-select.form-control-lg .vs__selected,
.country-select.form-control-lg .vs__search {
  font-size: 1.1rem;
  line-height: 1.5;
  padding: .5rem 1rem;
}

.country-select.form-control-sm .vs__selected,
.country-select.form-control-sm .vs__search {
  font-size: .8rem;
  line-height: 1.2;
  padding: .45rem .25rem;
}

.country-select .vs__selected,
.country-select .vs__search {
  font-size: .9rem;
  line-height: 1.35;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.country-select.no-placeholder .vs__selected {
  margin: 0;
  position: absolute;
  top: 0
}

.country-select.no-placeholder .vs__search {
  margin: 0;
  position: relative;
}

.country-select.vs--multiple .vs__selected-options .vs__selected {
  height: 1.35rem;
  margin-top: var(--input-padding-x);
  z-index: 1;
}
</style>