var ClientSideSortMixin = {
    data() {
        return {
            items: [],
            sortKey: null,
            sortDir: 'asc',
            sortFn: null
        }
    },
    methods: {
        sortBy: function (key, fn) {
            if (key === this.sortKey && fn == this.sortFn) {
                this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
            } else {
                this.sortDir = 'asc'
            }
            this.sortKey = key
            this.sortFn = fn
        },
        sortIcon: function (key, fn) {
            if (key === this.sortKey && fn == this.sortFn) {
                if (this.sortDir === 'asc') return 'b-icon-caret-down-fill'
                else return 'b-icon-caret-up-fill'
            }
            return null
        },
        isNew: function (item) {
            return !(item._links && item._links.self)
        }
    },
    computed: {
        sortedItems: function () {
            return this.sortKey == null ? this.items : this.items.sort((a, b) => {
                if (this.isNew(a) !== this.isNew(b))
                    return this.isNew(a) ? -1 : 1

                let modifier = this.sortDir === 'desc' ? -1 : 1;

                let keyA = this.sortFn == null ? a[this.sortKey] : this.sortFn(a)
                let keyB = this.sortFn == null ? b[this.sortKey] : this.sortFn(b)

                if (keyA == null) keyA = ''
                if (keyB == null) keyB = ''

                return keyA.localeCompare(keyB) * modifier
            })
        }
    }
}

export default ClientSideSortMixin
