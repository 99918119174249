<template>
  <e-scrollable-page>
    <template slot="content">
      <validation-observer v-if="!registrationStatus"
                           ref="observer"
                           v-slot="{ invalid, changed, dirty, failed }" slim>
          <div style="max-width: 900px; margin: auto;" class="jumbotron d-flex center justify-content-center align-items-center">
            <div v-if="customer" class="clearfix" style="width: 100%">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-row">
                    <div class="offset-sm-2 col-sm-6"><b>{{ $t('registration.companyData') }}</b></div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="company"
                           class="col-sm-2 col-form-label text-right">{{ $t('address.company') }}</label>
                    <div class="col-sm-7">
                      <e-form-text-input v-if="!customer" id="company" rules="required" v-model="registration.company" />
                      <e-form-text-input v-else id="company" rules="required" disabled :value="customer.name" />
                    </div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="additionalName"
                           class="col-sm-2 col-form-label text-right">{{ $t('address.additionalName') }}</label>
                    <div class="col-sm-7">
                      <e-form-text-input v-if="!customer" id="additionalName" v-model="registration.additionalName" />
                      <e-form-text-input v-else id="additionalName" disabled :value="customer.additionalName" />
                    </div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="street"
                           class="col-sm-2 col-form-label text-right">{{ $t('address.street') }}</label>
                    <div class="col-sm-7">
                      <e-form-text-input v-if="!customer" id="street" rules="required" v-model="registration.streetAddress" />
                      <e-form-text-input v-else id="street" rules="required" disabled :value="customer.street" />
                    </div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="country"
                           class="col-sm-2 col-form-label text-right">{{ $t('address.country') }} / {{ $t('address.zipCode') }} / {{ $t('address.city') }}</label>
                    <div class="col-sm-2">
                      <country-select v-if="!customer" id="country" name="country" :required="true"
                                      v-model="registration.country"
                                      :placeholder="$t('address.country')"  />
                      <e-form-text-input v-else id="country" rules="required" disabled :value="customer.country" />
                    </div>
                    <div class="col-sm-2">
                      <e-form-text-input v-if="!customer" id="zipCode" name="zipCode"
                                         :rules="{required: true, zipCode:{country: '@country'}}"
                                         @blur="formatZipCode($event)"
                                         v-model="registration.zipCode"/>
                      <e-form-text-input v-else id="zipCode" rules="required" disabled :value="customer.zipCode" />
                    </div>
                    <div class="col-sm-3">
                      <city-select v-if="!customer" id="city" rules="required" :disabled="false"
                                   :country="registration.country" :zip-code="registration.zipCode" v-model="registration.city" />
                      <e-form-text-input v-else id="city" rules="required" disabled :value="customer.city" />
                    </div>
                  </div>

                  <div class="form-group form-row mt-3">
                    <div class="offset-sm-2 col-sm-6"><b>{{ $t('registration.contactData') }}</b></div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="firstName"
                           class="col-sm-2 col-form-label text-right">{{ $t('user.firstName') }}</label>
                    <div class="col-sm-6">
                      <e-form-text-input id="firstName"
                                         v-model="registration.firstName" />
                    </div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="lastName"
                           class="col-sm-2 col-form-label text-right">{{ $t('user.lastName') }}</label>
                    <div class="col-sm-6">
                      <e-form-text-input id="lastName" rules="required"
                                         v-model="registration.lastName" />
                    </div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="phone"
                           class="col-sm-2 col-form-label text-right">{{ $t('user.phone') }}</label>
                    <div class="col-sm-6">
                      <e-form-text-input id="phone" rules="required|phoneNumber"
                                         v-model="registration.phoneNumber" />
                    </div>
                  </div>

                  <div class="form-group form-row mt-3">
                    <div class="offset-sm-2 col-sm-6"><b>{{ $t('registration.loginData') }}</b></div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="email"
                           class="col-sm-2 col-form-label text-right">{{ $t('user.email') }}</label>
                    <div class="col-sm-6">
                      <e-form-text-input id="email" rules="required|email"
                                         v-model="registration.email" />
                    </div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="emailConfirmation"
                           class="col-sm-2 col-form-label text-right">{{ $t('user.emailConfirmation') }}</label>
                    <div class="col-sm-6">
                      <e-form-text-input id="emailConfirmation" rules="required|confirmed:email"
                                         v-model="emailConfirmation" />
                    </div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="password"
                           class="col-sm-2 col-form-label text-right">{{ $t('user.password') }}</label>
                    <div class="col-sm-6">
                      <e-form-text-input id="password" maxlength="30" rules="required|passwordConstraints|min:8|max:30" type="password"
                                         v-model="registration.password" />
                    </div>
                  </div>
                  <div class="form-group form-row mb-1">
                    <label for="passwordConfirmation"
                           class="col-sm-2 col-form-label text-right">{{ $t('user.passwordConfirmation') }}</label>
                    <div class="col-sm-6">
                      <e-form-text-input id="passwordConfirmation" rules="required|confirmed:password" type="password" v-model="passwordConfirmation" />
                    </div>
                  </div>

                  <div class="form-group form-row mt-3">
                    <div class="offset-sm-2 col-sm-6">
                      <vue-recaptcha
                          ref="recaptcha"
                          @verify="onRecaptchaVerified"
                          sitekey="6LcDPd4cAAAAABY1aF1y5hiB1q7C0OpvxprIxE-z" />
                    </div>
                  </div>

                  <div class="form-group form-row mt-3">
                    <div class="offset-sm-2 col-sm-6">
                      <b-button variant="danger"
                                ype="submit"
                                style="width: 100%" :disabled="invalid || registration.captcha == null"
                                @click.prevent="submitRegistration">
                        {{ $t('registration.register') }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 v-else>
              Falls Sie auf unserer neuen Sendungserfassung <strong>myEmons</strong> noch nicht registriert sind,
              wenden Sie sich bitte an Ihren zuständigen Vertriebsmitarbeiter. Von diesem erhalten Sie dann den für Sie
              gültigen Registrierungslink.
            </h4>
          </div>
      </validation-observer>
      <div v-else-if="registrationStatus == 'registered'"
           style="max-width: 900px; margin: auto;" class="jumbotron d-flex center justify-content-center align-items-center">
        <div class="clearfix" style="width: 100%">
          <div class="row">
            <div class="col-1">
              <h1><b-icon icon="check-circle" variant="success" /></h1>
            </div>
            <div class="col-11">
              <h1 class="text-success">{{ $t('registration.registered') }}</h1>
              <h5 v-if="!!customer">{{ $t('registration.continue-to-login') }}</h5>
              <h5 v-else>{{ $t('registration.wait-for-email-confirmation') }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div v-else
           style="max-width: 900px; margin: auto;" class="jumbotron d-flex center justify-content-center align-items-center">
        <div class="clearfix" style="width: 100%">
          <div class="row">
            <div class="col-1">
              <h1><b-icon icon="exclamation-triangle" variant="warning" /></h1>
            </div>
            <div class="col-11">
              <h1 class="text-warning">{{ $t('registration.already-registered') }}</h1>
              <h5>{{ $t('registration.user-already-exists') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
  </e-scrollable-page>
</template>s

<style scoped="true">
.v-select-custom {
  background-color: white;
}
</style>

<script>
import { VueRecaptcha } from 'vue-recaptcha'
import {formatZipCode, ValidationObserver, ValidationProvider, extend} from '@emons/emons-vue'
import ApiService from "@/services/api.service";
import AxiosServiceMixin from "@/components/mixins/AxiosServiceMixin";
import CitySelect from "@/components/form-controls/CitySelect"
import CountrySelect from "@/components/form-controls/CountrySelect";
import CustomerService from "@/services/customer.service";

extend('passwordConstraints', {
  validate(value) {
    return value.match(/[a-zA-Z]/)
        && !!value.match(/[0-9]/)
        && !!value.match(new RegExp("[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬\u00ad®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾]"))
        && !value.match(/ /)
  }
})

export default {
  name: "Registration",
  components: { CitySelect, CountrySelect, ValidationObserver, ValidationProvider, VueRecaptcha },
  mixins: [AxiosServiceMixin],
  data() {
    return {
      registration: {
        country: 'DE',
        city: null
      },
      emailConfirmation: null,
      passwordConfirmation: null,
      registrationStatus: null,
    }
  },
  asyncComputed: {
    customer: async function() {
      const customerId = this.$route.query.customerId
      const hash = this.$route.query.hash
      if (!!customerId && !!hash) {
        return CustomerService.getByCustomerIdAndHash(customerId, hash)
      }
    },
  },
  methods: {
    onRecaptchaVerified: function (token) {
      this.$log('DEBUG', 'recaptcha verified', token)
      this.$set(this.registration, 'captcha', token)
    },
    formatZipCode: function (zipCode) {
      this.registration.zipCode = formatZipCode(this.registration.zipCode, zipCode)
    },
    submitRegistration: async function () {
      if (!!this.customer) {
        this.registration.customerId = this.$route.query.customerId
        this.registration.hash = this.$route.query.hash
        this.registration.company = this.customer.name
        this.registration.additionalName = this.customer.additionalName
        this.registration.streetAddress = this.customer.street
        this.registration.country = this.customer.country
        this.registration.zipCode = this.customer.zipCode
        this.registration.city = this.customer.city
      }
      await this.doSave(
        {
          save: async function (obj) {
            return ApiService.post('/api/registration', obj)
          }},
          this.registration,
          () => {this.registrationStatus = 'registered'},
          (error) => {
            if (error?.response?.status == 409) {
              this.registrationStatus = "already-registered"
              return true // all errors handled - disable default axios error handling
            }},
          this.$refs.observer,
          ""
      )
    }
  }
}
</script>