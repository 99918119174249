<template>
  <div class="card">
    <div class="card-header p-2">
      <div class="form-row align-items-center">
        <div :class="isEnabled?'col-6':'col-12'">
          <e-form-checkbox :id="id + '-enableDivergentInvoiceRecipient'" name="enableDivergentInvoiceRecipient"
                           :placeholder="$t('orders.enable-divergent-invoice-recipient')"
                           :disabled="!editable"
                           :value="isEnabled"
                           @input="toggleInvoiceRecipient($event)"/>
          <span v-if="!isEnabled && !!defaultRecipient"
                class="font-italic font-weight-light"
                style="color: #6c757d">
            ({{ $t('invoiceRecipient.isDefault') }}: {{ formatRecipient(defaultRecipient) }})
          </span>
        </div>

        <div class="col-6" v-if="isEnabled">
          <addressbook-select :types="['INVOICE_RECIPIENT']" :editable="editable" :api-path="apiPath"
                              @input="selectedInvoiceRecipientChanged" />
        </div>
      </div>
    </div>
    <div class="card-body p-2"
         v-if="isEnabled && !!value">
      <div class="form-row">
        <div class="col-12 col-md-6">
          <div class="form-row">
            <div class="col-12"><label for="invoice-recipient-name">{{ $t('address.address-data') }}</label>
            </div>
            <div class="col-7">
              <e-form-text-input :id="id + '-invoiceRecipient.name'" name="name"
                                 :placeholder="$t('address.name')"
                                 :value="value.name" disabled/>
            </div>
            <div class="col-5">
              <e-form-text-input :id="id + '-invoiceRecipient.customerId'" name="customerId"
                                 :placeholder="$t('address.customerId')"
                                 :value="value.customerId" disabled/>
            </div>

            <div class="col-7">
              <e-form-text-input :id="id + '-invoiceRecipient.additionalName'" name="additionalName"
                                 :placeholder="$t('address.additionalName')"
                                 :value="value.additionalName" disabled/>
            </div>

            <div class="w-100"></div>
            <div class="col-4">
              <e-form-text-input :id="id + '-invoiceRecipient.country'" name="country"
                                 :placeholder="$t('address.country')"
                                 :value="$t('countries.' + value.country)" disabled/>
            </div>
            <div class="col-3">
              <e-form-text-input :id="id + '-invoiceRecipient.zipCode'" name="zipCode"
                                 :placeholder="$t('address.zipCode')"
                                 :value="value.zipCode" disabled/>
            </div>
            <div class="col-5">
              <e-form-text-input :id="id + '-invoiceRecipient.city'" name="city"
                                 :placeholder="$t('address.city')"
                                 :value="value.city" disabled/>
            </div>

            <div class="col-12">
              <e-form-text-input :id="id + '-invoiceRecipient.street'" name="street"
                                 :placeholder="$t('address.streetWithNumber')"
                                 :value="value.street" disabled/>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-row">
            <div class="col-12"><label for="invoice-recipient-contact-name">{{
                $t('address.contact-data')
              }}</label></div>
            <div class="col-12">
              <e-form-text-input :id="id + '-invoiceRecipient.contactName'" name="contactName"
                                 :placeholder="$t('address.contactName')"
                                 :disabled="!editable || !dataEditable"
                                 v-model="value.contactName"/>
            </div>
            <div class="col-12">
              <e-form-text-input :id="id + '-invoiceRecipient.phone'" name="phone" rules="phoneNumber"
                                 :placeholder="$t('address.phone')"
                                 :disabled="!editable || !dataEditable"
                                 v-model="value.phone"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressbookSelect from "@/components/form-controls/AddressbookSelect.vue";

export default {
  name: "OrderInvoiceRecipientView",
  components: {AddressbookSelect},
  props: {
    value: {
      type: Object,
    },
    id: {
      type: String,
      default: 'invoiceRecipient'
    },
    editable: {
      type: Boolean,
      default: false
    },
    dataEditable: {
      type: Boolean,
      default: true
    },
    defaultRecipient: {
      type: Object,
      default: null
    },
    apiPath: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedInvoiceRecipient: null,
      enableDivergentInvoiceRecipient: false,
      isLoading: false,
      query: null,
      invoiceRecipients: [],
    }
  },
  computed: {
    isEnabled: function() {
      const enableDivergentInvoiceRecipient = this.enableDivergentInvoiceRecipient
      const value = this.value
      const defaultRecipient = this.defaultRecipient

      if (enableDivergentInvoiceRecipient) return true
      this.$log('debug', 'checking default', this.value, this.defaultRecipient)
      return (value != null && (defaultRecipient == null || value?._links?.self?.href != defaultRecipient?._links?.self?.href))
    },
  },
  methods: {
    formatRecipient: function (r) {
      return r.name + (r.additionalName?' ' + r.additionalName:'') + ', ' + r.street + ', ' + r.country + '-' + r.zipCode + ' ' + r.city
    },
    resetInvoiceRecipient: async function (recipient) {
      this.$emit('input', recipient)
    },
    selectedInvoiceRecipientChanged: function (invoiceRecipientAddress) {
      if (invoiceRecipientAddress != null) {
        this.$log("debug", 'invoiceRecipientAddress address "%s" selected', invoiceRecipientAddress ? invoiceRecipientAddress._links.self.href : null)
        this.resetInvoiceRecipient({...invoiceRecipientAddress})
        // reset v-select
        this.selectedInvoiceRecipient = null
      }
    },
    toggleInvoiceRecipient: function (toggle) {
      if (!this.editable) {
        return
      }
      this.$log('debug', 'toggle', toggle)
      this.enableDivergentInvoiceRecipient = toggle
      if (!toggle) {
        this.selectedInvoiceRecipient = ""
        this.$emit('input', null)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (!newVal) this.enableDivergentInvoiceRecipient = false
      }
    },
  }
}
</script>

<style scoped>

</style>