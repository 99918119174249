<template>
    <b-modal :id="id" @ok="emitConfirmed">
      <template #modal-title>
        {{ $t('customer.delete.confirm.short') }}
      </template>
      <template #default>
        <div v-html="$t('customer.delete.confirm', customer)" />

        <div class="mt-2 text-center text-danger">
        <b-checkbox v-model="confirm">{{ $t('customer.delete.confirm.check') }}</b-checkbox>
        </div>
      </template>
      <template #modal-footer="{ok, cancel, hide}">
        <b-button variant="secondary" @click="cancel">{{ $t('controls.abort') }}</b-button>
        <b-button variant="primary" :disabled="!confirm" @click="ok">{{ $t('controls.delete') }}</b-button>
      </template>
    </b-modal>
</template>

<script>
export default {
  name: "DeleteCustomerModal",
  props: {
    id: {
      type: String,
      default: 'delete-customer-modal'
    }
  },
  data() {
    return {
      customer: null,
      confirm: false
    }
  },
  methods: {
    show(customer) {
      this.customer = customer
      this.confirm = false
      this.$bvModal.show(this.id)
    },
    emitConfirmed() {
      this.$emit('confirmed', this.customer)
    }
  }
}
</script>

<style scoped>
</style>