var render = function () {
  var _vm$members;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "p-1 pl-0 pr-0 row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('user.username')))])])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('user.email')))])])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('user.name')))])])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('user.actions')))])])])]), _vm._l((_vm$members = _vm.members) === null || _vm$members === void 0 ? void 0 : _vm$members.items, function (member, index) {
    var _member$_links, _member$_links$modify, _member$customerIds, _member$_links2, _member$_links2$delet, _member$_links3, _member$_links3$delet;

    return _c('div', {
      staticClass: "p-1 pl-0 pr-0 row"
    }, [_c('div', {
      staticClass: "col-md-3"
    }, [_vm._v(_vm._s(member.username))]), _c('div', {
      staticClass: "col-md-3"
    }, [_vm._v(_vm._s(member.email))]), _c('div', {
      staticClass: "col-md-3"
    }, [_vm._v(_vm._s(member.firstName) + " " + _vm._s(member.lastName))]), _c('div', {
      staticClass: "col-md-3"
    }, [member !== null && member !== void 0 && (_member$_links = member._links) !== null && _member$_links !== void 0 && (_member$_links$modify = _member$_links.modify) !== null && _member$_links$modify !== void 0 && _member$_links$modify.href && ((member === null || member === void 0 ? void 0 : (_member$customerIds = member.customerIds) === null || _member$customerIds === void 0 ? void 0 : _member$customerIds.length) > 1 || !(member !== null && member !== void 0 && (_member$_links2 = member._links) !== null && _member$_links2 !== void 0 && (_member$_links2$delet = _member$_links2.delete) !== null && _member$_links2$delet !== void 0 && _member$_links2$delet.href)) ? _c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.confirmUnassign(member);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('user.unassign')))]) : member !== null && member !== void 0 && (_member$_links3 = member._links) !== null && _member$_links3 !== void 0 && (_member$_links3$delet = _member$_links3.delete) !== null && _member$_links3$delet !== void 0 && _member$_links3$delet.href ? _c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.confirmDelete(member);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('controls.delete')))]) : _vm._e()], 1)]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }