import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const rel = 'users'
const path = "/api/admin/users"
const self_path = '/api/user'
const schema = {
    username: null,
    email: null,
    firstName: null,
    lastName: null,
    customerIds: null
}
const header = {
    username: {
        name: 'Benutzername',
        col: 'col-2',
        property: 'username',
    },
    email: {
        name: 'E-Mail',
        col: 'col-2',
        property: 'email'
    },
    name: {
        name: 'Vorname',
        col: 'col-3',
        property: 'firstName'
    },
    location: {
        name: 'Nachname',
        col: 'col-3',
        property: 'lastName'
    },
    customerIds: {
        name: 'Kundennr.',
        col: 'col-2',
        property: 'customerIds'
    },
}
const filterType = 'popover'
const filter = null
const sync = null
const initialSort = 'username'
const projection = ''
const create = false

const Service = {
    getHeader: function () {
        return header
    },
    getFilterType: function () {
        return filterType
    },
    getFilter: function () {
        return filter
    },
    getSyncItems: function () {
        return syncItems
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function () {
        return initialSort
    },
    getProjection: function () {
        return projection
    },
    membersOf: function(customer) {
        try {
            return ApiService.get(customer?._links?.members?.href, {
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'] && parsedData['_embedded'][rel]?parsedData['_embedded'][rel]:[]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    _get: async function (userId, path) {
        const userPath = userId ? '/' + userId : ''
        try {
            const params = {}
            const response = await ApiService.get(path + userPath, {
                params: params,

                transformResponse: [function (data) {
                    if (data) {
                        return JSON.parse(data)
                    }
                }]
            })
            return response?.data
        } catch (error) {
            if (error?.response?.status == 404) {
                log("debug", "user not not found", userId, path)
            } else {
                log("error", "Error querying user:", error)
            }
        }
        return null
    },
    getSelf: async function () {
        return await this._get('', self_path)
    },
    find: async function (query) {
        try {
            return ApiService.get(path, {
                params: {
                    query: query,
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    save: async function (customer) {
        if (customer?._links?.self) {
            return ApiService.put(customer._links.self.href, customer).then(
                response => {
                    if (response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        }
    },
    delete: async function (user) {
        if (user?._links?.delete?.href) {
            return ApiService.delete(user._links.delete.href)
        }
    },
}

export default Service