<template>
  <ul v-b-toggle="'audit-collapse-' + value.revision" v-if="value.type == 'MOD'">
    <b-icon-caret-right-fill v-if="!visible" /><b-icon-caret-down-fill v-else />
    <span style="margin-left: 0.5em" v-html="$t('auditlog.entry.MOD', formatterParams)" />
    <b-collapse :id="'audit-collapse-' + value.revision" v-model="visible">
      <li v-for="(value, key) in value.modifications">{{ $t(key) }} auf <strong>{{ value }}</strong> geändert</li>
    </b-collapse>
  </ul>
  <ul v-else-if="value.type == 'ADD'">
    <b-icon-pencil-fill />
    <span style="margin-left: 0.5em" v-html="$t('auditlog.entry.ADD', formatterParams)" />
  </ul>
  <ul v-else-if="value.type == 'DEL'">
    <span style="margin-left: 0.5em" v-html="$t('auditlog.entry.ADD', formatterParams)" />
  </ul>
  <ul v-else></ul>
</template>

<script>
export default {
  name: "AuditLogEntry",
  props: {
    value: {
      type: Object
    },
    open: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    formatterParams() {
      return {
        user: `<strong>${this.value.userFullname}</strong> <em>(${this.value.userLogin})</em>`,
        date: this.$d(new Date(this.value.date), 'dateShort'),
        time: this.$d(new Date(this.value.date), 'timeOfDayWithSeconds')
      }
    }
  },
  watch: {
    'open': {
      immediate: true,
      handler: function(open) {
        this.visible = !!open
      }
    }
  }
}
</script>
<style scoped>
li {
  margin-left: 40px;
}

</style>