var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmit(_vm.onSubmit);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-contextId'
    }
  }, [_vm._v("Sendungsnummer")]), _c('div', {
    staticClass: "col-8"
  }, [_c('p', {
    staticClass: "form-control-plaintext"
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: '/shipments/list',
        query: {
          searchText: _vm.entity.contextId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.entity.contextId) + " ")])], 1)])])]), _c('div', {
    staticClass: "col-8"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-2 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-recipients'
    }
  }, [_vm._v("Empfänger")]), _c('div', {
    staticClass: "col-10"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-recipients',
      "value": _vm.entity.recipients
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-emonsCustomerId'
    }
  }, [_vm._v("Kundennummer")]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-emonsCustomerId',
      "value": _vm.entity.emonsCustomerId
    }
  })], 1)])]), _c('div', {
    staticClass: "col-8"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-2 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-subject'
    }
  }, [_vm._v("Betreff")]), _c('div', {
    staticClass: "col-10"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-subject',
      "value": _vm.entity.subject
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-publicationDate'
    }
  }, [_vm._v("Datum")]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-publicationDate',
      "value": _vm.entity.publicationDate
    }
  })], 1)])]), _c('div', {
    staticClass: "col-8"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-2 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-content'
    }
  }, [_vm._v("Inhalt")]), _c('div', {
    staticClass: "col-10"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-content',
      "value": _vm.entity.content
    }
  }), _vm.entity.hasAttachment ? _c('b-icon', {
    attrs: {
      "icon": "paperclip"
    }
  }) : _vm._e()], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-status'
    }
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-status',
      "value": _vm.entity.status
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-retry'
    }
  }, [_vm._v("Anzahl Versuche")]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-retry',
      "value": _vm.entity.retry + ' von ' + _vm.entity.maxRetries
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-nextRetryAfter'
    }
  }, [_vm._v("Nächster Versuch")]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-nextRetryAfter',
      "value": _vm.entity.nextRetryAfter
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-8"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-2 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-statusMessage'
    }
  }, [_vm._v("Status-Message")]), _c('div', {
    staticClass: "col-10"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-statusMessage',
      "value": _vm.entity.statusMessage
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  })]), _vm.entity.completionDate ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-mailPersistenceEvent-completionDate'
    }
  }, [_vm._v("Versendet am")]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-mailPersistenceEvent-completionDate',
      "value": _vm.entity.completionDate
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }), _c('div', {
    staticClass: "col-4"
  })]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }