var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.disabled ? _c('validation-provider', {
    ref: "validator",
    attrs: {
      "vid": _vm.id,
      "name": _vm.name,
      "immediate": _vm.hasValue && !_vm.disabled,
      "rules": _vm.required ? 'required' : '',
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var classes = _ref.classes,
            errors = _ref.errors;
        return [_c('div', {
          class: ['form-label-group', 'input-group'].concat(_vm.size ? 'input-group-' + _vm.size : '')
        }, [_c('v-select', {
          class: _vm.getCSSClasses(_vm.filterValidationClasses(classes)),
          attrs: {
            "name": _vm.name,
            "id": _vm.id,
            "options": _vm.packagingCodes,
            "disabled": _vm.disabled,
            "filterable": true,
            "filterBy": function filterBy(code, label, query) {
              return code.code.toLowerCase().includes(query.toLowerCase()) || code.name.toLowerCase().includes(query.toLowerCase());
            },
            "reduce": function reduce(code) {
              return code.code;
            },
            "value": _vm.value,
            "label": "code"
          },
          on: {
            "search:focus": _vm.onFocus,
            "search:blur": _vm.onBlur,
            "input": _vm.onInput,
            "open": _vm.onOpen,
            "close": _vm.onClose
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(option) {
              return [_vm._v(" " + _vm._s(option.code) + " - " + _vm._s(option.name) + " ")];
            }
          }, {
            key: "selected-option",
            fn: function fn(option) {
              return [_vm._v(" " + _vm._s(option.code) + " ")];
            }
          }], null, true)
        }), _vm.placeholder ? _c('label', {
          staticClass: "required"
        }, [_vm._v(_vm._s(_vm.placeholder))]) : _vm._e(), _c('span', {
          staticClass: "invalid-feedback"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }], null, false, 2551915044)
  }) : _c('e-form-text-input', {
    attrs: {
      "id": _vm.id,
      "name": _vm.name,
      "size": _vm.size,
      "placeholder": _vm.placeholder,
      "disabled": "",
      "value": _vm.value
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }