<template>
  <address-list></address-list>
</template>

<script>
import AddressList from "@/components/AddressList";
export default {
  name: "Addresses",
  components: {AddressList}
}
</script>

<style scoped>

</style>