import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"
import CustomerService from "@/services/customer.service";
import LocationService from "@/services/location.service";

const path = "/api/currencies"
const rel = 'currencies'

const Service = {
    getSupportedCurrencies: async function() {
        try {
            const response = await ApiService.get(path, {})
            return response?.data?._embedded[rel]
        } catch (error) {
            if (error?.response?.status == 404) {
                log("debug", "currencies not found")
            } else {
                log("error", "Error querying supported currencies:", error)
            }
        }
        return null
    },
    getByIsoCode: async function(isoCode) {
        try {
            const response = await ApiService.get(path + '/' + isoCode, {})
            return response?.data
        } catch (error) {
            if (error?.response?.status == 404) {
                log("debug", "currency '" + isoCode + "' not found")
            } else {
                log("error", "Error querying currency '" + isoCode + "'", error)
            }
        }
        return null
    },
    getDefault: async function() {
        return this.getByIsoCode('EUR')
    },
    getForCustomerBranch: async function() {
        let self = await CustomerService.getSelf()
        let currency = null
        if (self?._links?.location?.href) {
            let location = await LocationService.getEntityDetails(self._links.location.href)
            currency = location?.data?.currency
        }
        if (!currency) {
            currency = await this.getDefault()
        }
        return currency
    }
}

export default Service