<template>
  <validation-observer :ref="id + '-observer'"
                       v-slot="{ invalid, changed, dirty, failed, handleSubmit }" slim>

    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-tabs @input="tabChanged">
        <b-tab :title="$t('customer.master-data')">
          <div class="row">
            <div class="col-md-6">

              <div class="form-group row">
                <label :for="id + '-activated'"
                       class="col-sm-3 col-form-label text-right">{{ $t('state') }}</label>

                <div class="col-sm-9">
                  <e-form-checkbox :id="id + '-customer-activated'" name="activated" v-model="value.activated">
                    {{ value.activated ? $t('state.enabled') : $t('state.disabled') }}
                  </e-form-checkbox>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-customerId'"
                       class="col-sm-3 col-form-label text-right">{{ $t('customer.id') }}</label>

                <div class="col-sm-9">
                  <e-form-text :id="id + '-customerId'" :value="value.customerId"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-location'"
                       class="col-sm-3 col-form-label text-right">{{ $t('customer.branch') }}</label>
                <div class="col-sm-9">

                  <e-form-select :id="id + '-customer-location'"
                                 name="location"
                                 :placeholder="$t('customer.select-branch')"
                                 v-model="value.location"
                                 rules="required"
                                 size="sm">

                    <option v-for="(location, idx) in myLocations"
                            :key="idx"
                            :value="location.abbreviation">{{ location.name }}
                    </option>

                  </e-form-select>

                </div>
              </div>

            </div>

            <div class="col-md-6">

              <div class="form-group row">
                <label :for="id + '-name'"
                       class="col-sm-3 col-form-label text-right">{{ $t('address.name') }}</label>

                <div class="col-sm-9">
                  <e-form-text-input :id="id + '-name'"
                                     name="name"
                                     size="sm"
                                     v-model="value.name"
                                     rules="required"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-additionalName'"
                       class="col-sm-3 col-form-label text-right">{{ $t('address.additionalName') }}</label>

                <div class="col-sm-9">
                  <e-form-text-input :id="id + '-additionalName'"
                                     name="name"
                                     size="sm"
                                     v-model="value.additionalName"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-street'"
                       class="col-sm-3 col-form-label text-right">{{ $t('address.street') }}</label>

                <div class="col-sm-9">
                  <e-form-text-input :id="id + '-street'"
                                     name="street"
                                     size="sm"
                                     v-model="value.street"
                                     rules="required"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-zipCode'"
                       class="col-sm-3 col-form-label text-right">{{ $t('address.zipCode') }}</label>

                <div class="col-sm-9">
                  <e-form-text-input :id="id + '-zipCode'"
                                     name="zipCode"
                                     size="sm"
                                     v-model="value.zipCode"
                                     rules="required"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-city'"
                       class="col-sm-3 col-form-label text-right">{{ $t('address.city') }}</label>

                <div class="col-sm-9">
                  <e-form-text-input :id="id + '-city'"
                                     name="city"
                                     size="sm"
                                     v-model="value.city"
                                     rules="required"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-country'"
                       class="col-sm-3 col-form-label text-right">{{ $t('address.country') }}</label>
                <div class="col-sm-9">

                  <!-- TODO: Validierung -->
                  <v-select v-on:search:focus="findCountries()"
                            @search="(query, loading) => findCountries(query)"
                            :options="countries['selectable']"
                            :filterable="false"
                            :reduce="country => country.alpha2Code"
                            v-model="value.country"
                            label="alpha2Code">
                  </v-select>

                </div>
              </div>


            </div>
          </div>

          <div class="row mt-4">

            <div class="col-md-6 offset-md-6">
              <div class="form-group row justify-content-end">
                <div class="col-sm-3" v-if="!isSaved() || canModify()">
                  <button id="saveButton"
                          type="submit"
                          class="btn btn-sm btn-block btn-outline-primary"
                          :disabled="!(changed || dirty) || invalid"
                          @click.prevent="saveEntity()">
                    {{ $t('controls.save') }}
                  </button>
                </div>
                <div class="col-sm-3" v-if="!isSaved() || canDelete()">
                  <delete-customer-modal ref="deleteCustomerModal" @confirmed="deleteConfirmed" />
                  <button id="deleteButton"
                          class="btn btn-sm btn-block btn-outline-danger"
                          @click.prevent="$refs.deleteCustomerModal.show(value)">
                    {{ $t('controls.delete') }}
                  </button>

                </div>
              </div>
            </div>

          </div>
        </b-tab>

        <b-tab :title="$t('customer.settings')">

          <b-form-group :label="$t('customer.secondary-branch')" label-for="secondary-location"
                        content-cols="10" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby}">
              <e-form-select :id="id + '-secondary-location'"
                             name="secondary-location"
                             :placeholder="$t('customer.select-branch')"
                             v-model="value.secondaryLocation"
                             @input="saveEntity"
                             size="sm">

                <option v-if="value.secondaryLocation != null" value="">{{ $t('customer.remove-secondary-branch') }}</option>

                <option v-for="(location, idx) in allLocations"
                        :key="idx"
                        :value="location.abbreviation">{{ location.name }}
                </option>

              </e-form-select>
          </b-form-group>

          <b-form-group :label="$t('customer.waiver-customer')" label-for="waiverCustomer"
                        content-cols="10" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-checkbox switch v-model="waiverCustomer.value" @change="saveSetting(waiverCustomer)">
              </b-form-checkbox>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('customer.invoicing-bulkiness')" label-for="invoicingBulkiness"
                        content-cols="10" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-radio-group name="invoicingBulkiness" :aria-describedby="ariaDescribedby"
                                     v-model="invoicingBulkiness.value" @change="saveSetting(invoicingBulkiness)"
                                     :options="invoicingBulkinessModes"/>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('customer.additionalPaletteSwapCountries')"
                        label-for="additionalPaletteSwapCountries"
                        content-cols="3" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <b-input-group size="sm">
              <country-select name="additionalPaletteSwapCountries" multiple :placeholder="$t('address.country')"
                              v-model="additionalPaletteSwapCountries.value"
                              @input="additionalPaletteSwapCountries.value?.sort(); saveSetting(additionalPaletteSwapCountries)"
                              :filter="c => !['DE', 'AT', 'BE', 'NL', 'LU', 'CH'].includes(c.alpha2Code)"
                              :validate="false"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group :label="$t('customer.manual-invoice-recipients')" label-for="manualInvoiceRecipients"
                        content-cols="10" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-checkbox switch v-model="manualInvoiceRecipients.value" @change="saveSetting(manualInvoiceRecipients)">
              </b-form-checkbox>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('customer.optional-fields')" label-for="optionalFields"
                        content-cols="10" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-checkbox-group name="optionalFields" :aria-describedby="ariaDescribedby"
                                     v-model="optionalFields.value" @change="saveSetting(optionalFields)"
                                     :options="optionalFieldNames"/>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('customer.mandatory-fields')" label-for="mandatoryFields"
                        content-cols="10" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-checkbox-group name="mandatoryFields" :aria-describedby="ariaDescribedby"
                                     v-model="mandatoryFields.value" @change="saveSetting(mandatoryFields)"
                                     :options="mandatoryFieldNames"/>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('customer.xls-export-enabled')" label-for="xlsExportEnabled"
                        content-cols="10" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-checkbox name="xlsExportEnabled" switch v-model="xlsExportEnabled.value" @change="saveSetting(xlsExportEnabled)">
              </b-form-checkbox>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('customer.sftp-enabled')" label-for="sftpEnabled"
                        content-cols="10" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-checkbox name="sftpEnabled" switch v-model="sftpEnabled.value" @change="saveSetting(sftpEnabled)">
              </b-form-checkbox>
            </validation-provider>
          </b-form-group>

          <b-form-group v-if="sftpEnabled.value"
                        label="SFTP-Passwort"
                        label-for="sftpPassword"
                        content-cols="3" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <b-input-group size="sm">
              <b-form-input name="sftpPassword" v-model="sftpPassword.value" disabled/>
              <b-input-group-append>
                <b-button variant="outline-danger" @click.prevent="generatePassword()">
                  <span>Generieren und speichern</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group v-if="sftpEnabled.value" label="SFTP automatische Beauftragung"
                        label-for="sftpAutoCommissioning"
                        content-cols="3" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-checkbox name="sftpAutoCommissioning" switch v-model="sftpAutoCommissioning.value" @change="saveSetting(sftpAutoCommissioning)">
              </b-form-checkbox>
            </validation-provider>
          </b-form-group>

          <b-form-group v-if="sftpEnabled.value" label="SFTP Standardlieferhinweise berücksichtigen"
                        label-for="sftpEnableDefaultNotificationCodes"
                        content-cols="3" label-cols="2" class="pt-2" label-class="pt-1"
                        v-slot="{ ariaDescribedby }">
            <validation-provider>
              <b-form-checkbox name="sftpEnableDefaultNotificationCodes" switch v-model="sftpEnableDefaultNotificationCodes.value" @change="saveSetting(sftpEnableDefaultNotificationCodes)">
              </b-form-checkbox>
            </validation-provider>
          </b-form-group>
        </b-tab>

        <b-tab :title="$t('customer.invoice-recipients')" lazy :disabled="!value?._links?.invoiceRecipients?.href">

          <b-navbar>
            <b-navbar-nav class="ml-auto">
              <b-nav-form>
                <b-input-group size="sm" v-if="!!(invoiceRecipients?.links?.create?.href)">
                  <invoice-recipient-modal :id="value.customerId + '-invoiceRecipient'" ref="invoiceRecipientModal"
                                           @save="refreshInvoiceRecipients++"
                                           :customer-id="value.customerId"
                                           :creation-link="invoiceRecipients?.links?.create?.href" />
                  <b-button size="sm" variant="primary" class="mr-2" @click="$refs.invoiceRecipientModal.show()"><b-icon-plus />
                    {{ $t('controls.add') }}</b-button>
                </b-input-group>
              </b-nav-form>
            </b-navbar-nav>
          </b-navbar>


          <div class="p-1 pl-0 pr-0 row">
            <div class="col-1 clip-text"><strong>{{ $t('invoiceRecipient.isDefault') }}</strong></div>
            <div class="col-1 clip-text"><strong>{{ $t('address.customerId') }}</strong></div>
            <div class="col-3 clip-text"><strong>{{ $t('address.name') }}</strong></div>
            <div class="col-6">
              <div class="row">
                <div class="col-5 clip-text"><strong>{{ $t('address.street') }}</strong></div>
                <div class="col-1 clip-text"><strong>{{ $t('address.country') }}</strong></div>
                <div class="col-2 clip-text"><strong>{{ $t('address.zipCode') }}</strong></div>
                <div class="col-4 clip-text"><strong>{{ $t('address.city') }}</strong></div>
              </div>
            </div>
          </div>

          <div class="scroll-embedded-header-line"></div>

          <scrollbar class="scroll-embedded-content"
                     :style="{'height': '300px', 'width': '100%'}"
                     :settings="scrollSettings">

            <div v-for="(recipient, index) in invoiceRecipients?.items" class="p-1 pl-0 pr-0 row">
              <div class="col-1 clip-text text-center">
                <span v-if="!!recipient.isDefault"><b-icon-check-circle /></span>
                <span v-else></span>
              </div>
              <div class="col-1 clip-text">
                <span>{{ recipient.customerId }}</span>
              </div>
              <div class="col-3 clip-text">
                <span>{{ recipient.name }}</span>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5 clip-text">
                    <span>{{ recipient.street }}</span>
                  </div>
                  <div class="col-1 clip-text">
                    <span>{{ recipient.country }}</span>
                  </div>
                  <div class="col-2 clip-text">
                    <span>{{ recipient.zipCode }}</span>
                  </div>
                  <div class="col-4 clip-text">
                    <span>{{ recipient.city }}</span>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <b-dropdown :text="$t('controls.actions')" right size="sm" variant="primary" v-if="recipient?._links?.modify?.href">
                  <b-dropdown-item-button href="#" @click="$refs.invoiceRecipientModal.show(recipient)" v-if="recipient?._links?.modify?.href">
                    <b-icon-pencil-square /> {{ $t('controls.edit') }}
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
          </scrollbar>
        </b-tab>

        <b-tab :title="$t('customer.addresses') + (value.addresses && value.addresses.page ? ' (' + value.addresses.page.totalElements + ')' : '')"
               class="tab-content"
               @click="findAddresses()"
               lazy>

          <div class="p-1 pl-0 pr-0 row">

            <div class="col-md-3">
              <div class="row">
                <div class="col-4"><b>{{ $t('address.defaultSenderAndConsignee.short') }}</b></div>
                <div class="col-8"><b><span>{{ $t('address.name') }}</span></b></div>
              </div>
            </div>

            <div class="col-md-1">
              <b><span>{{ $t('address.customerId') }}</span></b>
            </div>

            <div class="col-md-3">
              <div class="row">
                <div class="col-md-6">
                  <b><span>{{ $t('address.street') }}</span></b>
                </div>

                <div class="col-md-6">
                  <b><span>{{ $t('address.city') }}</span></b>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="row">
                <div class="col-md-6">
                  <b><span>{{ $t('address.email') }}</span></b>
                </div>

                <div class="col-md-6">
                  <b><span>{{ $t('address.phone') }}</span></b>
                </div>
              </div>
            </div>

            <div class="col-md-1">
            </div>

          </div>

          <div class="scroll-embedded-header-line"></div>

          <scrollbar v-if="value.addresses && value.addresses.items.length > 0"
                     class="scroll-embedded-content"
                     :style="{'height': '300px', 'width': '100%'}"
                     :settings="scrollSettings"
                     v-on:ps-y-reach-end="nextPageAddresses()">

            <address-modal :id="value.customerId + '-address'" ref="addressModal"
                               @save="findAddresses" @delete="findAddresses"
                               :is-admin="true"
                               :customer-id="value.customerId"
                               :creation-link="value?.addresses?.links?.create?.href" />
            <b-navbar sticky variant="secondary" class="col-md-5 offset-md-7">
              <b-navbar-nav class="ml-auto">
                <b-nav-form>
                  <b-input-group size="sm" v-if="!!(value?.addresses?.links?.create?.href)">
                    <b-button size="sm" variant="secondary" class="mr-2" @click="$refs.addressModal.show()"><b-icon-plus />
                      {{ $t('controls.add') }}</b-button>
                  </b-input-group>

                  <b-input-group size="sm" style="width: 150px;">
                    <b-input-group-prepend is-text>
                      <b-icon-search />
                    </b-input-group-prepend>
                    <b-form-input class="search-input" size="sm" type="text"
                                  v-model="value.searchTextAddress"
                                  v-debounce:1000ms="findAddresses">
                    </b-form-input>
                    <b-input-group-append v-if="value.searchTextAddress" is-text>
                      <b-icon-x @click="value.searchTextAddress = null; findAddresses();"/>
                    </b-input-group-append>
                  </b-input-group>

                  <b-input-group size="sm">
                    <b-button size="sm" variant="secondary" class="ml-2" @click="exportAddresses"><b-icon-download />
                      {{ $t('export') }}</b-button>
                  </b-input-group>

                  <b-input-group size="sm">
                    <import-address-data-modal :id="value.customerId" ref="importAddressDataModal" @confirmed="importAddresses" />
                    <b-button size="sm"
                              variant="secondary"
                              class="ml-2"
                              @click="$refs.importAddressDataModal.show('text/vcard,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')">
                      <b-icon-upload />
                      {{ $t('import') }}</b-button>
                  </b-input-group>

                  <b-input-group size="sm" v-if="!!(value?.addresses?.links?.deleteAll?.href)">
                    <b-button size="sm" variant="secondary" class="mr-2" @click="deleteAllAddresses"><b-icon-trash />
                      {{ $t('controls.delete-all') }}</b-button>
                  </b-input-group>

                </b-nav-form>
              </b-navbar-nav>
            </b-navbar>

            <div v-for="(address, index) in value.addresses.items" class="p-1 pl-0 pr-0 row">

              <div class="col-md-3">
                <div class="row">
                  <div class="col-4">
                    <b-checkbox :checked="address.isDefaultSenderAddress" class="custom-control-inline"
                                @change="setDefaultSenderAddress(address, $event)"></b-checkbox>
                    <b-checkbox :checked="address.isDefaultConsigneeAddress" class="custom-control-inline"
                                @change="setDefaultConsigneeAddress(address, $event)"></b-checkbox>
                  </div>
                  <div class="col-8 text-truncate">
                    <span>{{ address.name }}</span>
                    <br v-if="address.additionalName" />
                    <span>{{ address.additionalName }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-1 text-truncate">
                <span v-if="address.emonsCustomerId"><b-icon-truck title="Emons" /> {{ address.emonsCustomerId }}</span>
                <br v-if="!!address.emonsCustomerId && !!address.customerId" />
                <span v-if="!!address.customerId"><b-icon-person v-if="!!address.emonsCustomerId && !!address.customerId" /> {{ address.customerId }}</span>
              </div>

              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-6 text-truncate">
                    <span>{{ address.street }}</span>
                  </div>

                  <div class="col-md-6 text-truncate">
                    <span>{{ address.country }}-{{ address.zipCode }}&nbsp;{{ address.city }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-6 text-truncate">
                    <span>{{ address.email }}</span>
                  </div>

                  <div class="col-md-6 text-truncate">
                    <span v-if="!!address.phone"><b-icon-telephone /> {{ address.phone }} </span>
                    <br />
                    <span v-if="!!address.mobile"><b-icon-phone /> {{ address.mobile }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-1">
                <b-dropdown :text="$t('controls.actions')" right size="sm" variant="primary" v-if="address?._links?.modify?.href">
                  <b-dropdown-item-button href="#" @click="$refs.addressModal.show(address)" v-if="address?._links?.modify?.href">
                    <b-icon-pencil-square /> {{ $t('controls.edit') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button href="#" variant="danger" @click="confirmDelete(address)" v-if="address?._links?.delete?.href">
                    <b-icon-trash /> {{ $t('controls.delete') }}
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>

            </div>

          </scrollbar>

        </b-tab>

        <b-tab :title="$t('customer.members')" lazy>
          <customer-add-user-modal :id="id" :customer-id="value.customerId" ref="addUserModal" @confirmed="addUser($event)"/>
          <b-navbar>
            <b-navbar-nav v-if="value?._links?.registerUser?.href">
                <b-link :href="value?._links?.registerUser?.href" target="_blank" class="a">
                  <b-icon-link font-scale="1.0" /> Benutzer-Registrierung
                </b-link>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-input-group size="sm">
                <b-button variant="primary" class="mb-2" size="sm" @click="$refs.addUserModal.show()">
                  <b-icon icon="plus"/> {{ $t('customer.add-user') }}
                </b-button>
              </b-input-group>
            </b-navbar-nav>
          </b-navbar>
          <customer-members-view :customer="value" ref="members"/>
        </b-tab>
      </b-tabs>
    </form>

  </validation-observer>
</template>

<script>
import Vue from "vue";
import Scrollbar from 'vue-custom-scrollbar'
import {log, ValidationObserver, ValidationProvider} from '@emons/emons-vue'
import AddressService from "@/services/address.service";
import CustomerService from "@/services/customer.service"
import InvoiceRecipientAddressService from "@/services/invoiceRecipientAddress.service";
import LocationService from "@/services/location.service"
import CountryService from "@/services/country.service"
import SettingsService from "@/services/settings.service"
import CustomerMembersView from "@/components/CustomerMembersView";
import CustomerAddUserModal from "@/components/modals/CustomerAddUserModal";
import UserService from "@/services/user.service";
import ImportAddressDataModal from "@/components/modals/ImportAddressDataModal";
import AddressModal from "@/components/modals/AddressModal";
import DeleteCustomerModal from "@/components/modals/DeleteCustomerModal";
import InvoiceRecipientModal from "@/components/modals/InvoiceRecipientModal";
import CountrySelect from "@/components/form-controls/CountrySelect.vue";

export default {
  name: "CustomerView",
  components: {
    CountrySelect,
    InvoiceRecipientModal,
    DeleteCustomerModal,
    AddressModal,
    ImportAddressDataModal,
    CustomerAddUserModal, CustomerMembersView, ValidationObserver, ValidationProvider, 'scrollbar': Scrollbar},
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      scrollSettings: {
        suppressScrollX: true
      },
      myLocations: [], // fetched from server
      allLocations: [],
      optionalFields: this.createSetting("OPTIONAL_FIELDS", []),
      mandatoryFields: this.createSetting("MANDATORY_FIELDS", []),
      waiverCustomer: this.createSetting("WAIVER_CUSTOMER", false),
      invoicingBulkiness: this.createSetting("INVOICING_BULKINESS", ""),
      manualInvoiceRecipients: this.createSetting("MANUAL_INVOICE_RECIPIENTS", false),
      xlsExportEnabled: this.createSetting("XLS_EXPORT_ENABLED", false),
      sftpEnabled: this.createSetting("SFTP_ENABLED", false),
      sftpPassword: this.createSetting("SFTP_PASSWORD", null),
      sftpAutoCommissioning: this.createSetting("SFTP_AUTO_COMMISSIONING", false),
      sftpEnableDefaultNotificationCodes: this.createSetting("SFTP_ENABLE_DEFAULT_NOTIFICATION_CODES", false),
      additionalPaletteSwapCountries: this.createSetting("ADDITIONAL_PALETTE_SWAP_COUNTRIES", ""),
      invoicingBulkinessModes: [
        {text: this.$t('customer.invoicing-bulkiness.default'), value: ''},
        {text: this.$t('customer.invoicing-bulkiness.cbm'), value: 'cbm'},
        {text: this.$t('customer.invoicing-bulkiness.ldm'), value: 'ldm'},
        {text: this.$t('customer.invoicing-bulkiness.sqm'), value: 'sqm'},
      ],
      optionalFieldNames: [
        {text: this.$t('cargo-item.dimensions'), value: 'dimensions'}
      ],
      mandatoryFieldNames: [
        {text: this.$t('shipment-info.cbm'), value: 'cbm'},
        {text: this.$t('shipment-info.loadingLength'), value: 'loadingLength'},
        {text: this.$t('shipment-info.storingPosition'), value: 'storingPos'},
      ],
      countries: {
        selectable: [],
        orderBy: 'alpha2Code,asc',
        timeout: null
      },
      visitedTabs: [],
      refreshInvoiceRecipients: 0
    }
  },
  created() {
    LocationService
        .find(null, null, null, 'abbreviation', 'asc')
        .then(response => {
          this.myLocations = response.data.items
        })
    LocationService
        .find(null, null, null, 'abbreviation', 'asc', 100, true)
        .then(response => {
          this.allLocations = response.data.items
        })
  },
  asyncComputed: {
    invoiceRecipients: async function() {
      const refreshInvoiceRecipients = this.refreshInvoiceRecipients // reference refresh key for refreshing
      const tabVisited = this.visitedTabs.includes(2) // check if invoice-recipients tab is visited
      const link = this.value?._links?.invoiceRecipients?.href
      if (tabVisited && !!link) { // async call only triggerd if invoice-recipients tab visited
        const response = await InvoiceRecipientAddressService.findWithPath(link)
        this.$log('DEBUG', 'fetched invoice recipient addresses', link, response)
        return response?.data
      } else {
        return {}
      }
    }
  },
  methods: {
    confirmDelete(address) {
      this.$bvModal.msgBoxConfirm(
          this.$t('address.delete.confirm'), {title: this.$t('address.delete.confirm.short')}
      ).then(result => {
        if (result) {
          this.$emit('enableSpinner');
          AddressService.delete(address).then(() => {
            this.$emit('disableSpinner');
            this.findAddresses()
          })
        }
      })
    },
    tabChanged(value) {
        if(!this.visitedTabs.includes(value)){
          // push tab number (first tab == 0)
          this.visitedTabs.push(value)
        }
    },
    isSaved() {
      return !!this?.value?._links?.self
    },
    canDelete() {
      return !!this?.value?._links?.delete
    },
    canModify() {
      return !!this?.value?._links?.modify
    },
    loadSettings: async function (entity) {
      this.$log("DEBUG", "load settings for ", entity)
    },
    createSetting: function (name, value) {
      return {name: name, value: value}
    },
    getEntityDetails: async function () {
      this.$emit('enableSpinner');

      let details = await CustomerService.getEntityDetails(this.value._links.self.href)
      Object.assign(this.value, details)

      let tenantId = this.value.customerId
      this.$log("DEBUG", "entity tenant id: ", tenantId)

      let waiverCustomer = await SettingsService.findAdmin(tenantId, 'WAIVER_CUSTOMER')
      if (waiverCustomer) this.waiverCustomer = waiverCustomer

      let invoicingBulkiness = await SettingsService.findAdmin(tenantId, 'INVOICING_BULKINESS')
      if (invoicingBulkiness) this.invoicingBulkiness = invoicingBulkiness

      let manualInvoiceRecipients = await SettingsService.findAdmin(tenantId, 'MANUAL_INVOICE_RECIPIENTS')
      if (manualInvoiceRecipients) this.manualInvoiceRecipients = manualInvoiceRecipients

      this.optionalFields = await SettingsService.findAdmin(tenantId, 'OPTIONAL_FIELDS')
      if (!this.optionalFields) this.optionalFields = this.createSetting("OPTIONAL_FIELDS", [])

      this.mandatoryFields = await SettingsService.findAdmin(tenantId, 'ADMIN_MANDATORY_FIELDS')
      if (!this.mandatoryFields) this.mandatoryFields = this.createSetting("ADMIN_MANDATORY_FIELDS", [])

      let xlsExportEnabled = await SettingsService.findAdmin(tenantId, 'XLS_EXPORT_ENABLED')
      if (xlsExportEnabled) this.xlsExportEnabled = xlsExportEnabled

      let sftpEnabled = await SettingsService.findAdmin(tenantId, 'SFTP_ENABLED')
      if (sftpEnabled) this.sftpEnabled = sftpEnabled

      let sftpPassword = await SettingsService.findAdmin(tenantId, 'SFTP_PASSWORD')
      if (sftpPassword) this.sftpPassword = sftpPassword

      let sftpAutoCommissioning = await SettingsService.findAdmin(tenantId, 'SFTP_AUTO_COMMISSIONING')
      if (sftpAutoCommissioning) this.sftpAutoCommissioning = sftpAutoCommissioning

      let sftpEnableDefaultNotificationCodes = await  SettingsService.findAdmin(tenantId, 'SFTP_ENABLE_DEFAULT_NOTIFICATION_CODES')
      if (sftpEnableDefaultNotificationCodes) this.sftpEnableDefaultNotificationCodes = sftpEnableDefaultNotificationCodes

      let additionalPaletteSwapCountries = await SettingsService.findAdmin(tenantId, 'ADDITIONAL_PALETTE_SWAP_COUNTRIES')
      if (additionalPaletteSwapCountries) this.additionalPaletteSwapCountries = additionalPaletteSwapCountries

      this.$emit('disableSpinner')
    },
    async deleteConfirmed($event) {
      this.$emit('enableSpinner');
      await UserService.delete($event)
      this.$emit('delete', this.index)
      this.$emit('disableSpinner');
    },
    saveSetting: function (setting) {
      log('debug', 'Saving setting...', setting)
      SettingsService.saveAdmin(this.value.customerId, setting)
    },
    generatePassword: function () {
      let CharacterSet = '';
      let password = '';

      CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
      CharacterSet += 'ABCDEFGHJKLMNPQRSTUVWXYZ';
      CharacterSet += '123456789';

      for (let i = 0; i < 12; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }

      this.sftpPassword.value = password;
      SettingsService.saveAdmin(this.value.customerId, this.sftpPassword)
    },
    findCountries: function (queryDropDown) {
      log('debug', 'findCountries() called...')

      if (this.countries.timeout)
        clearTimeout(this.countries.timeout)

      this.countries.timeout = setTimeout(() => {
        CountryService.findByQuery(this, this.countries, queryDropDown)
      }, 500)
    },
    async saveEntity() {
      this.$emit('enableSpinner');
      let saved = await CustomerService.save(this.value)
      Object.assign(this.value, saved.data)
      this.optionalFields = await SettingsService.saveAdmin(this.value.customerId, this.optionalFields)
      this.mandatoryFields = await SettingsService.saveAdmin(this.value.customerId, this.mandatoryFields)
      this.value['isOpen'] = false
      this.$emit('disableSpinner')
    },
    onSubmit: function () {
      this.$log('INFO', 'saving customer', this.value)

      CustomerService
          .save(this.value)
          .then(response => {
            this.$refs[this.id + '-observer'].reset()
            this.$emit('save', response.data)
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              this.$refs[this.id + '-observer'].setErrors(error.response.data.errors)
            }
          })
    },
    deleteAllAddresses: function () {
      this.$bvModal.msgBoxConfirm(
          this.$t('address.delete-all.confirm'), {title: this.$t('address.delete-all.confirm.short')}
      ).then(result => {
        if (result) {
          this.$emit('enableSpinner');
          try {
            this.$log('DEBUG', 'delete', this.value)
            CustomerService.deleteAllAddresses(this.value)
          } finally {
            this.$emit('disableSpinner');
          }
          this.findAddresses()
        }
      })
    },
    exportAddresses: async function() {
      this.$log('debug', 'exportAddresses() called...');
      this.$emit('enableSpinner');
      await CustomerService.exportAddresses(this.value)
      this.$emit('disableSpinner')
    },
    importAddresses: async function(file) {
      this.$log('debug', 'importAddresses() called...', file);
      const response = await CustomerService.importAddresses(this.value, file)
      this.$log('debug', 'got response from import', response)
    },
    findAddresses: async function () {
      this.$log('debug', 'getAddresses() called...');
      this.$emit('enableSpinner');
      await CustomerService
          .findAddresses(this.value, this.value.searchTextAddress, 100, 'name', 'asc')
          .then(response => {

            if (response['data'])
              Vue.set(this.value, 'addresses', response['data'])

            this.$emit('disableSpinner');
          })
    },
    nextPageAddresses: async function () {
      this.$log('debug', 'nextPageAddresses() called...');
      if (this.value.addresses.links && this.value.addresses.links.next) {
        this.$emit('enableSpinner');
        await CustomerService
            .nextPageAddresses(this.value.addresses.links.next.href)
            .then(response => {
              Vue.set(this.value.addresses, 'links', response['data']['links'])
              Vue.set(this.value.addresses, 'page', response['data']['page'])
              Vue.set(this.value.addresses, 'items', this.value.addresses.items.concat(response['data']['items']))
              this.$emit('disableSpinner');
            })
      }
    },
    addUser: function (user) {
      this.$log('DEBBUG', 'add user confirmed', user)
      UserService.save(user).then(
          result => {
            this.$log('DEBUG', 'result of save', user)
            this.$refs.members.setDirty()
          }
      )
    },
    async setDefaultSenderAddress(address, isDefault) {
      this.$log('DEBUG', 'set default sender', isDefault)
      address.isDefaultSenderAddress = isDefault
      if (isDefault) {
        address.types.push('DEFAULT_SENDER')
      } else {
        address.types = address.types.filter(t => t != 'DEFAULT_SENDER')
      }
      await AddressService.save(address)
      this.findAddresses()
    },
    async setDefaultConsigneeAddress(address, isDefault) {
      this.$log('DEBUG', 'set default consignee', isDefault)
      address.isDefaultConsigneeAddress = isDefault
      if (isDefault) {
        address.types.push('DEFAULT_CONSIGNEE')
      } else {
        address.types = address.types.filter(t => t != 'DEFAULT_CONSIGNEE')
      }
      await AddressService.save(address)
      this.findAddresses()
    }
  },
  watch: {

    value: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (newVal._links)
          await this.getEntityDetails()
      }
    }
  }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 0;
}

.tab-pane {
  padding-top: 20px;
  padding-left: 20px;
}

.tabs >>> div.tab-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tabs >>> .nav-link {
  color: #000000;
}

.tabs >>> .nav-link.active {
  font-weight: bold;
}

.tabs >>> .nav-item.show .nav-link,
.tabs >>> .nav-link.active {
  background-color: transparent;
}

.scroll-embedded-header-line {
  border-bottom: 1px solid #dee2e6;
  margin-right: 5px;
}

.scroll-embedded-content {
  position: relative;
  top: 10px;
  overflow: hidden;
}

.search-embedded-list {
  margin-top: -15px;
}

.search-embedded-list [class^="fa-"], [class*=" fa-"] {
  display: inline-block;
  width: 100%;
}

.search-embedded-list .search-input:focus {
  box-shadow: none;
}

</style>