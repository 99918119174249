<template>
  <zipCodeArea-list />
</template>

<script>
import ZipCodeAreaList from "@/components/ZipCodeAreaList";

export default {
  name: "AdminZipCodeAreas",
  components: {ZipCodeAreaList}
}
</script>

<style scoped>

</style>