<template>
  <validation-provider :vid="id"
                       :immediate="true"
                       :rules="rules"
                       ref="noteValidator"
                       v-slot="{ errors }" slim>
    <input type="hidden" :id="id + '.requiredFields'" :name="validatorName" :value="note.identifier" />

    <div class="d-flex w-100 justify-content-between align-items-center">
      <div class="text-truncate">
        {{ note.identifier  + " - " + note.name }}
      </div>
      <button v-if="editable" class="btn btn-sm btn-link" @click="deleted">
        {{ $t('controls.delete') }}
      </button>
    </div>
    <div v-if="note.identifier == '199'" class="text-danger">
      {{ $t('delivery-notes.consult-customer-service') }}
    </div>
    <div id="errors" v-for="(error) in errors" class="text-danger">{{ error }}</div>
    <div v-if="errors.length == 0 && note.attachment?.name?.startsWith('Ja')">
      <validation-provider :vid="id + 'attachmentValue'" name="attachmentValue"
                           ref="attachmentValidator"
                           :immediate="true"
                           :rules="{required: enableRules}"
                           v-slot="{ errors }">
        <div class="input-group input-group-sm">
          <input
                 ref="input"
                 type="text"
                 maxlength="35"
                 :id="id + '.attachmentValue'"
                 v-model="note.attachmentValue"
                 class="form-control form-control-sm"
                 @blur="$emit('blur', $event.target.value)"
                 :disabled="!editable"
                 :readonly="note?.requiredFields?.length > 0"
          />
        </div>
        <div id="errors" v-for="(error) in errors" class="text-danger">{{ error }}</div>
      </validation-provider>
    </div>
  </validation-provider>
</template>

<script>
import {ValidationProvider} from "@emons/emons-vue"

export default {
  name: 'DeliveryNote',
  components: {ValidationProvider},
  props: {
    id: {
      type: String,
      required: true
    },
    note: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    enableRules: {
      type: Boolean,
      default: true
    },
    // required if rules are enabled -> for checking of required fields.
    consignee: {
      type: Object,
      required: false,
    },
    // required if rules are enabled -> for checking of required fields.
    sender: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      rules: null,
      validatorName: null
    }
  },
  methods: {
    reset() {
      this.rules = this.createRules()
      this.validatorName = this.getValidatorName()
      this.$nextTick(() => this.setDefaultValue())
    },
    deleted() {
      this.$emit('delete', this.note)
    },
    createRules() {
      if (this.enableRules) {
        let hasUnwatchedRequired = false
        const fields = []
        for (let i in this.note.requiredFields) {
          let field = this.note.requiredFields[i]
          fields.push('@' + field)
          if (this.note.attachment?.name?.startsWith('Ja')) {
            this.$watch("" + field, () => this.setDefaultValue())
          } else {
            hasUnwatchedRequired = true
          }
        }
        if (fields.length > 0) {
          if (hasUnwatchedRequired) {
            // [#1209] - unwatched reuqired fields must be validated immediately
            this.$nextTick(() => this.$refs.noteValidator.validate())
          }
          return {'fields_required': fields}
        }
      }
      return null
    },
    getValidatorName() {
      if (this.rules) {
        let rule = Object.values(this.rules)[0]
        this.$log('DEBUG', '[' + this.note.identifier + '] getValidatorName', this.rules, rule)
        if (rule?.length == 1) {
          return rule[0].replace('@', '')
        } else if (rule?.length > 1) {
          let prefix = "";
          for (let idx in rule) {
            let currentPrefix = rule[idx]
            this.$log('DEBUG', 'current prefix', currentPrefix)
            if (currentPrefix.indexOf('.') >= 0) {
              currentPrefix = currentPrefix.substring(0, currentPrefix.indexOf('.'))
              this.$log('DEBUG', 'found prefix', currentPrefix)
            }
            currentPrefix = currentPrefix.replaceAll('@', '')
            if (prefix.indexOf(currentPrefix) < 0) {
              if (prefix == "")
                prefix = currentPrefix
              else
                prefix = prefix + "_" + currentPrefix
            }
          }
          let ret = prefix + "." + rule.join('_').replaceAll('@consignee.', '').replaceAll('@sender.', '')
          this.$log('DEBUG', 'RET', ret)
          return ret
        }
      }
      return "none"
    },
    setDefaultValue() {
      if (this.note.attachment?.name?.startsWith('Ja')) {
        const currentDefault = this.getDefaultFieldAndValue()
        this.$log('DEBUG', '[' + this.note.identifier + '] setDefaultValue: currentDefault', currentDefault)
        if (currentDefault || currentDefault == '') {
          this.$set(this.note, 'attachmentValue', currentDefault.value)
          if (this.$refs.noteValidator) {
            this.$refs.noteValidator.validate()
          }
        }
      }
    },
    getDefaultFieldAndValue: function() {
      if (this.note?.requiredFields) {
        for (let i = 0; i < this.note?.requiredFields?.length; i++) {
          const refName = this.note.requiredFields[i]
          const ref = this.findParentRef(refName)
          const target = ref?.value
          if (target != null && target != undefined && !!String(target).length) {
            return {
              'field': refName,
              'value': target
            }
          }
        }
        return {
          'field': undefined,
          'value': undefined
        }
      }
    },
    findParentRef(ref_id, parent = this) {
      if (parent.$refs[ref_id]) {
        return parent.$refs[ref_id]
      }
      if (parent.$parent) {
        return this.findParentRef(ref_id, parent.$parent)
      }
      return null
    },
  },
  watch: {
    note: {
      immediate: true,
      handler: async function(newVal) {
        this.reset()
      }
    }
  }
}
</script>

<style scoped>
</style>
