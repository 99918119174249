var render = function () {
  var _vm$mandatoryFields, _vm$mandatoryFields2, _vm$mandatoryFields3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header p-2"
  }, [_c('div', {
    staticClass: "form-row align-items-center"
  }, [_c('label', {
    staticClass: "col-12 mb-0"
  }, [_vm._v(_vm._s(_vm.$t('orders.shipment-info')))])])]), _c('div', {
    staticClass: "card-body p-2"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-cargoList.storingPos',
      "name": "storingPos",
      "size": "sm",
      "type": "number",
      "rules": {
        required: (_vm$mandatoryFields = _vm.mandatoryFields) === null || _vm$mandatoryFields === void 0 ? void 0 : _vm$mandatoryFields.includes('storingPos'),
        numeric: true,
        min_value: 0
      },
      "placeholder": _vm.$t('shipment-info.storingPosition'),
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.value.storingPos,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "storingPos", $$v);
      },
      expression: "value.storingPos"
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('e-form-text-input', {
    ref: "cbm",
    attrs: {
      "id": _vm.id + '-cargoList.cbm',
      "name": "cbm",
      "size": "sm",
      "type": "number",
      "placeholder": _vm.$t('shipment-info.requiredSpace'),
      "rules": {
        required: (_vm$mandatoryFields2 = _vm.mandatoryFields) === null || _vm$mandatoryFields2 === void 0 ? void 0 : _vm$mandatoryFields2.includes('cbm'),
        double: true,
        positive: true,
        max_value: 99.999
      },
      "disabled": !_vm.editable || _vm.isCbmDisabled,
      "append": _vm.$t('unit.cubicmeter')
    },
    model: {
      value: _vm.value.cbm,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "cbm", $$v);
      },
      expression: "value.cbm"
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-cargoList.loadingLength',
      "name": "loadingLength",
      "type": "number",
      "rules": {
        required: (_vm$mandatoryFields3 = _vm.mandatoryFields) === null || _vm$mandatoryFields3 === void 0 ? void 0 : _vm$mandatoryFields3.includes('loadingLength'),
        double: true,
        positive: true,
        max_value: 99.99
      },
      "size": "sm",
      "placeholder": _vm.$t('shipment-info.loadingLength'),
      "disabled": !_vm.editable,
      "append": _vm.$t('unit.meter')
    },
    model: {
      value: _vm.value.loadingLength,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "loadingLength", $$v);
      },
      expression: "value.loadingLength"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-cargoList.loadingEquipmentQty',
      "name": "loadingEquipmentQty",
      "size": "sm",
      "type": "number",
      "min": "0",
      "step": "1",
      "rules": "numeric|min_value:0",
      "placeholder": _vm.$t('shipment-info.loadingEquipmentQty'),
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.value.loadingEquipmentQty,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "loadingEquipmentQty", $$v);
      },
      expression: "value.loadingEquipmentQty"
    }
  })], 1), _c('div', {
    staticClass: "col-8"
  }, [_c('packaging-code-select', {
    attrs: {
      "id": _vm.id + '-cargoList.loadingEquipmentType',
      "name": "loadingEquipmentType",
      "size": "sm",
      "disabled": !_vm.editable,
      "required": _vm.value.loadingEquipmentQty > 0,
      "placeholder": _vm.$t('shipment-info.loadingEquipmentType'),
      "filter": function filter(code) {
        return 'FP' == code.identifier || 'GP' == code.identifier;
      }
    },
    model: {
      value: _vm.value.loadingEquipmentType,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "loadingEquipmentType", $$v);
      },
      expression: "value.loadingEquipmentType"
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }