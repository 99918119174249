<template>
  <e-scrollable-search-filter-page :service="service"
                                   v-on:enableSpinner="enableSpinner()"
                                   v-on:disableSpinner="disableSpinner()">

    <template v-slot:header-controls="{ created, links }">
      <b-button-toolbar key-nav class="float-right">

        <b-button-group class="mx-1" v-if="links?.create?.href">
          <b-button type="button"
                    size="sm"
                    variant="secondary"
                    class="pl-2 pr-2"
                    @click="created(service.create())">
            <b-icon icon="plus"/>
            {{ $t('controls.create') }}
          </b-button>
        </b-button-group>

      </b-button-toolbar>
    </template>

    <template v-slot:spinner>

      <b-spinner v-if="loading"
                 class="spinner"
                 label="Loading..."/>

    </template>

    <template v-slot:entity-content="{ id, entity, index, deleted, saved }">
      <detail-view :id="id"
                   :value="entity"
                   @delete="deleted(index)"
                   @save="saved(index, $event)"
                   v-on:enableSpinner="enableSpinner()"
                   v-on:disableSpinner="disableSpinner()"/>
    </template>

  </e-scrollable-search-filter-page>
</template>

<script>
import service from "@/services/cargoListTemplate.service";
import DetailView from "@/components/CargoListTemplateView";
import {log} from "@emons/emons-vue"

export default {
  name: 'CargoTemplateList',
  components: {DetailView},
  data() {
    return {
      loading: false,
      service: service
    }
  },
  methods: {
    enableSpinner: function () {
      log('debug', 'enableSpinner() called...')
      this.loading = true
    },
    disableSpinner: function () {
      log('debug', 'disableSpinner() called...')
      this.loading = false
    },
    createEntity: function () {
      this.$log('DEBUG', 'createEntity() called...')
      return this.service.create()
    }
  }
}
</script>

<style scoped>

.spinner {
  position: absolute;
  z-index: 100000;
  top: 25%;
  left: 50%;
}

</style>