<template>
  <div class="card">
    <div class="card-header p-2">
      <div class="form-row align-items-center">
        <label class="col-12 mb-0">{{ $t('orders.shipment-info') }}</label>
      </div>
    </div>
    <div class="card-body p-2">
      <div class="form-row">
        <div class="col-12">
          <e-form-text-input :id="id + '-cargoList.storingPos'"
                             name="storingPos"
                             size="sm"
                             type="number"
                             :rules="{
                               required: mandatoryFields?.includes('storingPos'),
                               numeric: true,
                               min_value: 0
                             }"
                             :placeholder="$t('shipment-info.storingPosition')"
                             :disabled="!editable"
                             v-model="value.storingPos"/>
        </div>
        <div class="col-6">
          <e-form-text-input :id="id + '-cargoList.cbm'"
                             ref="cbm"
                             name="cbm"
                             size="sm"
                             type="number"
                             :placeholder="$t('shipment-info.requiredSpace')"
                             :rules="{
                               required: mandatoryFields?.includes('cbm'),
                               double: true,
                               positive: true,
                               max_value: 99.999
                             }"
                             :disabled="!editable || isCbmDisabled"
                             v-model="value.cbm"
                             :append="$t('unit.cubicmeter')"/>
        </div>
        <div class="col-6">
          <e-form-text-input :id="id + '-cargoList.loadingLength'"
                             name="loadingLength"
                             type="number"
                             :rules="{
                               required: mandatoryFields?.includes('loadingLength'),
                               double: true,
                               positive: true,
                               max_value: 99.99
                             }"
                             size="sm"
                             :placeholder="$t('shipment-info.loadingLength')"
                             :disabled="!editable"
                             v-model="value.loadingLength" :append="$t('unit.meter')"/>
        </div>


        <div class="col-4">
          <e-form-text-input :id="id + '-cargoList.loadingEquipmentQty'"
                             name="loadingEquipmentQty"
                             size="sm"
                             type="number"
                             min="0"
                             step="1"
                             rules="numeric|min_value:0"
                             :placeholder="$t('shipment-info.loadingEquipmentQty')"
                             :disabled="!editable"
                             v-model="value.loadingEquipmentQty">
            <!-- enable if reset/clear button is required
            <template v-slot:append >
              <b-button variant="outline-secondary" class="btn-plus form-control form-control-sm"
                        :disabled="!editable"
                        @click="value.loadingEquipmentQty = null">
                <b-icon-backspace-fill />
              </b-button>
            </template>
            -->
          </e-form-text-input>
        </div>

        <div class="col-8">
          <packaging-code-select :id="id + '-cargoList.loadingEquipmentType'"
                                 name="loadingEquipmentType"
                                 size="sm"
                                 :disabled="!editable"
                                 v-model="value.loadingEquipmentType"
                                 :required="value.loadingEquipmentQty > 0"
                                 :placeholder="$t('shipment-info.loadingEquipmentType')"
                                 :filter="(code) => 'FP' == code.identifier || 'GP' == code.identifier" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PackagingCodeSelect from "@/components/form-controls/PackagingCodeSelect"

export default {
  name: "OrderShipmentInfo",
  components: {PackagingCodeSelect},
  props: {
    value: {
      type: Object,
    },
    id: {
      type: String,
      default: 'shipmentInfo'
    },
    editable: {
      type: Boolean,
      default: false
    },
    optionalFields: {
      type: Array,
      default: () => []
    },
    mandatoryFields: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isCbmDisabled: function () {
      if (this.optionalFields?.includes('dimensions')) {
        if (this.value.items.find(i => !i.colliQty || !i.length || !i.width || !i.height)) {
          return false
        } else {
          return true
        }
      }
      if (this.mandatoryFields?.includes('cbm')) {
        return false
      }
      return true
    },
  },
  watch: {
    "value.cbm": function (val, oldVal) {
      if (this.isCbmDisabled) {
        this.$refs.cbm.$refs.validationProvider.validate()
      }
    }
  }
}
</script>

<style scoped>
</style>