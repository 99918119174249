<template>
  <form @submit.prevent="handleSubmit(onSubmit)">
    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-product-identifier'" class="col-3 col-form-label">{{ $t('product.identifier') }}</label>
          <div class="col-9">
            <e-form-text :id="id + '-product-identifier'" :value="entity.identifier"/>
          </div>
        </div>

      </div>

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-product-name'" class="col-3 col-form-label">{{ $t('product.label') }}</label>
          <div class="col-9">
            <e-form-text :id="id + '-product-name'" :value="entity.name"/>
          </div>
        </div>

      </div>

      <div class="col-4">


      </div>

    </div>
  </form>
</template>

<script>

import service from "@/services/product.service";
import {log} from "@emons/emons-vue"

export default {
  name: "ProductView",
  components: {},
  mounted() {
    log('debug', 'Mounted ProductView...')
    this.getEntityDetails()
  },
  props: {
    id: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      entity: {
        abbreviation: null,
        name: null
      }
    }
  },
  methods: {
    getEntityDetails: function () {
      this.$emit('enableSpinner');
      service
          .getEntityDetails(this.link)
          .then(response => {
            this.entity = response.data
            this.$emit('disableSpinner');
          })
    }
  }
}
</script>

<style scoped>

</style>