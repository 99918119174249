var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "pickUpOrder_observer",
    attrs: {
      "vid": "pickUpOrderObserver",
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty;
        return [_c('b-modal', {
          attrs: {
            "id": _vm.id,
            "title": _vm.$t('pick-up-order.title') + ' ' + _vm.shipment.shipmentNumber + ' | ' + _vm.$t('pick-up-order.client') + ' ' + _vm.shipment.emonsCustomerId,
            "size": "lg"
          },
          on: {
            "ok": _vm.sendPickUpOrder
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('validation-provider', {
                attrs: {
                  "name": "pickUpOrder",
                  "vid": _vm.id + '-pickUpOrder',
                  "rules": "required",
                  "slim": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref2) {
                    var classes = _ref2.classes,
                        errors = _ref2.errors;
                    return [_c('div', {
                      staticClass: "form-row",
                      staticStyle: {
                        "padding-bottom": "15px"
                      }
                    }, [_c('div', {
                      staticClass: "col-3"
                    }), _c('div', {
                      staticClass: "col-6"
                    }, [_c('b-form-select', {
                      attrs: {
                        "id": "type",
                        "name": "Auftragsart",
                        "size": "sm"
                      },
                      model: {
                        value: _vm.pickUpOrder.type,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "type", $$v);
                        },
                        expression: "pickUpOrder.type"
                      }
                    }, [_c('option', {
                      attrs: {
                        "value": "SPEDITIONSAUFTRAG"
                      }
                    }, [_vm._v("Speditionsauftrag")]), _c('option', {
                      attrs: {
                        "value": "QUERTRANSPORT"
                      }
                    }, [_vm._v("Quertransport")])])], 1), _c('div', {
                      staticClass: "col-3"
                    })]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-partnerName',
                        "name": "Name Partner",
                        "rules": "required",
                        "size": "sm",
                        "placeholder": 'An'
                      },
                      model: {
                        value: _vm.pickUpOrder.partnerName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "partnerName", $$v);
                        },
                        expression: "pickUpOrder.partnerName"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('div', {
                      staticClass: "float-right"
                    }, [_c('p', {
                      staticClass: "form-control-plaintext"
                    }, [_c('strong', [_vm._v(_vm._s(_vm.location.legalName))])])])])]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-partnerCC',
                        "name": "partnerCC",
                        "size": "sm",
                        "placeholder": 'CC'
                      },
                      model: {
                        value: _vm.pickUpOrder.partnerCC,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "partnerCC", $$v);
                        },
                        expression: "pickUpOrder.partnerCC"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('div', {
                      staticClass: "float-right"
                    }, [_c('p', {
                      staticClass: "form-control-plaintext"
                    }, [_vm._v(_vm._s(_vm.location.street))])])])]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-partnerAttentionOf',
                        "name": "partnerAttentionOf",
                        "size": "sm",
                        "placeholder": 'z.Hd.'
                      },
                      model: {
                        value: _vm.pickUpOrder.partnerAttentionOf,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "partnerAttentionOf", $$v);
                        },
                        expression: "pickUpOrder.partnerAttentionOf"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('div', {
                      staticClass: "float-right"
                    }, [_c('p', {
                      staticClass: "form-control-plaintext"
                    }, [_vm._v(_vm._s(_vm.location.country) + "-" + _vm._s(_vm.location.zipCode) + " " + _vm._s(_vm.location.city))])])])]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-partnerFax',
                        "name": "partnerFax",
                        "size": "sm",
                        "placeholder": 'Fax'
                      },
                      model: {
                        value: _vm.pickUpOrder.partnerFax,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "partnerFax", $$v);
                        },
                        expression: "pickUpOrder.partnerFax"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    })]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-partnerEmail',
                        "name": "Email Partner",
                        "rules": "required",
                        "size": "sm",
                        "placeholder": 'E-Mail'
                      },
                      model: {
                        value: _vm.pickUpOrder.partnerEmail,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "partnerEmail", $$v);
                        },
                        expression: "pickUpOrder.partnerEmail"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-locationContactName',
                        "name": "$t('address.consignee.contactName')",
                        "rules": "required",
                        "size": "sm",
                        "placeholder": _vm.$t('address.consignee.contactName')
                      },
                      model: {
                        value: _vm.pickUpOrder.locationContactName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "locationContactName", $$v);
                        },
                        expression: "pickUpOrder.locationContactName"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-partnerEmail',
                        "name": "Email-CC Partner",
                        "size": "sm",
                        "placeholder": 'CC-E-Mail'
                      },
                      model: {
                        value: _vm.pickUpOrder.partnerCCEmail,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "partnerCCEmail", $$v);
                        },
                        expression: "pickUpOrder.partnerCCEmail"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-locationEmail',
                        "name": "$t('address.consignee.contactName')",
                        "rules": "required",
                        "size": "sm",
                        "placeholder": _vm.$t('address.consignee.contactName') + ' ' + _vm.$t('address.email')
                      },
                      model: {
                        value: _vm.pickUpOrder.locationEmail,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "locationEmail", $$v);
                        },
                        expression: "pickUpOrder.locationEmail"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row",
                      staticStyle: {
                        "padding-top": "15px",
                        "padding-bottom": "5px"
                      }
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('label', {
                      staticStyle: {
                        "padding-top": "10px"
                      },
                      attrs: {
                        "for": "sender-name"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('pick-up-order.consignor')))])])]), _c('div', {
                      staticClass: "col-6"
                    }, [_c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('label', {
                      staticStyle: {
                        "padding-top": "10px"
                      },
                      attrs: {
                        "for": "sender-name"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('pick-up-order.consignee')))])])]), _c('div', {
                      staticClass: "col-6"
                    }, [_c('button', {
                      staticClass: "btn btn-sm btn-block btn-outline-danger",
                      attrs: {
                        "id": 'override-consignee'
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.overrideConsignee();
                        }
                      }
                    }, [_vm._v(_vm._s(_vm.$t('pick-up-order.override-consignee')) + " ")])])])])]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.name',
                        "name": "name",
                        "rules": "required",
                        "placeholder": _vm.$t('address.name'),
                        "size": "sm"
                      },
                      model: {
                        value: _vm.pickUpOrder.sender.name,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.sender, "name", $$v);
                        },
                        expression: "pickUpOrder.sender.name"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-consignee.name',
                        "name": "name",
                        "rules": "required",
                        "placeholder": _vm.$t('address.name'),
                        "size": "sm"
                      },
                      model: {
                        value: _vm.pickUpOrder.consignee.name,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.consignee, "name", $$v);
                        },
                        expression: "pickUpOrder.consignee.name"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.additionalName',
                        "name": "additionalName",
                        "size": "sm",
                        "placeholder": _vm.$t('address.additionalName')
                      },
                      model: {
                        value: _vm.pickUpOrder.sender.additionalName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.sender, "additionalName", $$v);
                        },
                        expression: "pickUpOrder.sender.additionalName"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-consignee.additionalName',
                        "name": "additionalName",
                        "size": "sm",
                        "placeholder": _vm.$t('address.additionalName')
                      },
                      model: {
                        value: _vm.pickUpOrder.consignee.additionalName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.consignee, "additionalName", $$v);
                        },
                        expression: "pickUpOrder.consignee.additionalName"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.street',
                        "name": "street",
                        "size": "sm",
                        "placeholder": _vm.$t('address.street')
                      },
                      model: {
                        value: _vm.pickUpOrder.sender.street,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.sender, "street", $$v);
                        },
                        expression: "pickUpOrder.sender.street"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-consignee.street',
                        "name": "street",
                        "size": "sm",
                        "placeholder": _vm.$t('address.street')
                      },
                      model: {
                        value: _vm.pickUpOrder.consignee.street,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.consignee, "street", $$v);
                        },
                        expression: "pickUpOrder.consignee.street"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-3"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.country',
                        "name": "country",
                        "size": "sm",
                        "placeholder": _vm.$t('address.country')
                      },
                      model: {
                        value: _vm.pickUpOrder.sender.country,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.sender, "country", $$v);
                        },
                        expression: "pickUpOrder.sender.country"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-3"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.zipCode',
                        "name": "zipCode",
                        "size": "sm",
                        "placeholder": _vm.$t('address.zipCode')
                      },
                      model: {
                        value: _vm.pickUpOrder.sender.zipCode,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.sender, "zipCode", $$v);
                        },
                        expression: "pickUpOrder.sender.zipCode"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.city',
                        "name": "city",
                        "size": "sm",
                        "placeholder": _vm.$t('address.city')
                      },
                      model: {
                        value: _vm.pickUpOrder.sender.city,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.sender, "city", $$v);
                        },
                        expression: "pickUpOrder.sender.city"
                      }
                    })], 1)])]), _c('div', {
                      staticClass: "col-6"
                    }, [_c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-3"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.country',
                        "name": "country",
                        "size": "sm",
                        "placeholder": _vm.$t('address.country')
                      },
                      model: {
                        value: _vm.pickUpOrder.consignee.country,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.consignee, "country", $$v);
                        },
                        expression: "pickUpOrder.consignee.country"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-3"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.zipCode',
                        "name": "zipCode",
                        "size": "sm",
                        "placeholder": _vm.$t('address.zipCode')
                      },
                      model: {
                        value: _vm.pickUpOrder.consignee.zipCode,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.consignee, "zipCode", $$v);
                        },
                        expression: "pickUpOrder.consignee.zipCode"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.city',
                        "name": "city",
                        "size": "sm",
                        "placeholder": _vm.$t('address.city')
                      },
                      model: {
                        value: _vm.pickUpOrder.consignee.city,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.consignee, "city", $$v);
                        },
                        expression: "pickUpOrder.consignee.city"
                      }
                    })], 1)])])]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.contactName',
                        "name": "contactName",
                        "size": "sm",
                        "placeholder": _vm.$t('address.consignee.contactName')
                      },
                      model: {
                        value: _vm.pickUpOrder.sender.contactName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.sender, "contactName", $$v);
                        },
                        expression: "pickUpOrder.sender.contactName"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-consignee.contactName',
                        "name": "contactName",
                        "size": "sm",
                        "placeholder": _vm.$t('address.consignee.contactName')
                      },
                      model: {
                        value: _vm.pickUpOrder.consignee.contactName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.consignee, "contactName", $$v);
                        },
                        expression: "pickUpOrder.consignee.contactName"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-sender.phone',
                        "name": "phone",
                        "size": "sm",
                        "placeholder": _vm.$t('address.phone')
                      },
                      model: {
                        value: _vm.pickUpOrder.sender.phone,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.sender, "phone", $$v);
                        },
                        expression: "pickUpOrder.sender.phone"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-consignee.phone',
                        "name": "phone",
                        "size": "sm",
                        "placeholder": _vm.$t('address.phone')
                      },
                      model: {
                        value: _vm.pickUpOrder.consignee.phone,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.consignee, "phone", $$v);
                        },
                        expression: "pickUpOrder.consignee.phone"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row",
                      staticStyle: {
                        "margin-bottom": "-10px"
                      }
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-datepicker', {
                      attrs: {
                        "id": _vm.id + '-shippingDate',
                        "name": "shippingDate",
                        "size": "sm",
                        "rules": {
                          dateEqualOrAfter: _vm.currentDateAsString()
                        },
                        "placeholder": _vm.$t('orders.choose-shipping-date'),
                        "min": new Date(),
                        "max": new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())
                      },
                      model: {
                        value: _vm.pickUpOrder.shippingDate,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "shippingDate", $$v);
                        },
                        expression: "pickUpOrder.shippingDate"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-datepicker', {
                      attrs: {
                        "id": _vm.id + '-deliveryDate',
                        "name": "deliveryDate",
                        "size": "sm",
                        "rules": {
                          dateEqualOrAfter: _vm.currentDateAsString()
                        },
                        "placeholder": _vm.$t('orders.choose-delivery-date'),
                        "min": new Date(),
                        "max": new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())
                      },
                      model: {
                        value: _vm.pickUpOrder.deliveryDate,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "deliveryDate", $$v);
                        },
                        expression: "pickUpOrder.deliveryDate"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('b-form-timepicker', {
                      attrs: {
                        "id": _vm.id + '-shippingTimeFrom',
                        "name": "shippingTimeFrom",
                        "size": "sm",
                        "minutes-step": "15",
                        "hour12": false,
                        "placeholder": _vm.$t('time.from'),
                        "reset-button": "",
                        "label-no-time-selected": _vm.$t('time.not-selected'),
                        "label-close-button": _vm.$t('controls.close'),
                        "label-reset-button": _vm.$t('controls.reset')
                      },
                      model: {
                        value: _vm.pickUpOrder.shippingTimeFrom,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "shippingTimeFrom", $$v);
                        },
                        expression: "pickUpOrder.shippingTimeFrom"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('b-form-timepicker', {
                      attrs: {
                        "id": _vm.id + '-shippingTimeUntil',
                        "name": "shippingTimeUntil",
                        "size": "sm",
                        "minutes-step": "15",
                        "hour12": false,
                        "placeholder": _vm.$t('time.to'),
                        "reset-button": "",
                        "label-no-time-selected": _vm.$t('time.not-selected'),
                        "label-close-button": _vm.$t('controls.close'),
                        "label-reset-button": _vm.$t('controls.reset')
                      },
                      model: {
                        value: _vm.pickUpOrder.shippingTimeUntil,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "shippingTimeUntil", $$v);
                        },
                        expression: "pickUpOrder.shippingTimeUntil"
                      }
                    })], 1)])]), _c('div', {
                      staticClass: "col-6"
                    }, [_c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('b-form-timepicker', {
                      attrs: {
                        "id": _vm.id + '-deliveryTimeFrom',
                        "name": "deliveryTimeFrom",
                        "size": "sm",
                        "minutes-step": "15",
                        "hour12": false,
                        "placeholder": _vm.$t('time.from'),
                        "reset-button": "",
                        "label-no-time-selected": _vm.$t('time.not-selected'),
                        "label-close-button": _vm.$t('controls.close'),
                        "label-reset-button": _vm.$t('controls.reset')
                      },
                      model: {
                        value: _vm.pickUpOrder.deliveryTimeFrom,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "deliveryTimeFrom", $$v);
                        },
                        expression: "pickUpOrder.deliveryTimeFrom"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('b-form-timepicker', {
                      attrs: {
                        "id": _vm.id + '-deliveryTimeUntil',
                        "name": "deliveryTimeUntil",
                        "size": "sm",
                        "minutes-step": "15",
                        "hour12": false,
                        "placeholder": _vm.$t('time.to'),
                        "reset-button": "",
                        "label-no-time-selected": _vm.$t('time.not-selected'),
                        "label-close-button": _vm.$t('controls.close'),
                        "label-reset-button": _vm.$t('controls.reset')
                      },
                      model: {
                        value: _vm.pickUpOrder.deliveryTimeUntil,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "deliveryTimeUntil", $$v);
                        },
                        expression: "pickUpOrder.deliveryTimeUntil"
                      }
                    })], 1)])])]), _c('div', {
                      staticClass: "form-row",
                      staticStyle: {
                        "padding-top": "5px"
                      }
                    }, [_c('div', {
                      staticClass: "col-12"
                    }, [_c('b-form-textarea', {
                      attrs: {
                        "type": "text",
                        "name": "pickUpOrderText",
                        "id": _vm.id + '-pickUpOrderText',
                        "placeholder": _vm.$t('pick-up-order.features-dimensions-markings'),
                        "rows": "3",
                        "max-rows": "6"
                      },
                      model: {
                        value: _vm.pickUpOrder.notes,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder, "notes", $$v);
                        },
                        expression: "pickUpOrder.notes"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row",
                      staticStyle: {
                        "padding-top": "10px"
                      }
                    }, [_c('div', {
                      staticClass: "col-12"
                    }, [_c('label', {
                      attrs: {
                        "for": "customs-data"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('pick-up-order.customs-documents')))])])])]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-6"
                    }, [_c('e-form-text-input', {
                      attrs: {
                        "id": _vm.id + '-customs-insuranceValue',
                        "name": "insuranceValue",
                        "size": "sm",
                        "placeholder": _vm.$t('order.insuranceValue'),
                        "append": _vm.$t('currency.' + _vm.pickUpOrder.customs.insuranceValue.currency)
                      },
                      model: {
                        value: _vm.pickUpOrder.customs.insuranceValue.value,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.customs.insuranceValue, "value", $$v);
                        },
                        expression: "pickUpOrder.customs.insuranceValue.value"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-6"
                    }, [_c('b-form-select', {
                      attrs: {
                        "id": "defaultCurrency",
                        "name": "$t('pick-up-order.customs-documents')",
                        "size": "sm"
                      },
                      model: {
                        value: _vm.pickUpOrder.customs.documents,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.customs, "documents", $$v);
                        },
                        expression: "pickUpOrder.customs.documents"
                      }
                    }, [_c('option', {
                      attrs: {
                        "value": "CLAIM"
                      }
                    }, [_vm._v(_vm._s(_vm.$t('pick-up-order.customs-documents.claim')))]), _c('option', {
                      attrs: {
                        "value": "CREATE"
                      }
                    }, [_vm._v(_vm._s(_vm.$t('pick-up-order.customs-documents.create')))]), _c('option', {
                      attrs: {
                        "value": "NOT_NECESSARY"
                      }
                    }, [_vm._v(_vm._s(_vm.$t('pick-up-order.customs-documents.not-necessary')))])])], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-3"
                    }, [_c('e-form-checkbox', {
                      attrs: {
                        "id": _vm.id + '-aeex1',
                        "name": "AE-EX1",
                        "placeholder": 'AE-EX1'
                      },
                      model: {
                        value: _vm.pickUpOrder.customs.aeex1,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.customs, "aeex1", $$v);
                        },
                        expression: "pickUpOrder.customs.aeex1"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-3"
                    }, [_c('e-form-checkbox', {
                      attrs: {
                        "id": _vm.id + '-eur1',
                        "name": "EUR1",
                        "placeholder": 'EUR1'
                      },
                      model: {
                        value: _vm.pickUpOrder.customs.eur1,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.customs, "eur1", $$v);
                        },
                        expression: "pickUpOrder.customs.eur1"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-3"
                    }, [_c('e-form-checkbox', {
                      attrs: {
                        "id": _vm.id + '-tpaper',
                        "name": "$t('pick-up-order.t-paper')",
                        "placeholder": _vm.$t('pick-up-order.t-paper')
                      },
                      model: {
                        value: _vm.pickUpOrder.customs.tpaper,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.customs, "tpaper", $$v);
                        },
                        expression: "pickUpOrder.customs.tpaper"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-3"
                    }, [_c('e-form-checkbox', {
                      attrs: {
                        "id": _vm.id + '-commercialInvoice',
                        "name": "$t('pick-up-order.commercial-invoice')",
                        "placeholder": _vm.$t('pick-up-order.commercial-invoice')
                      },
                      model: {
                        value: _vm.pickUpOrder.customs.commercialInvoice,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.customs, "commercialInvoice", $$v);
                        },
                        expression: "pickUpOrder.customs.commercialInvoice"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row",
                      staticStyle: {
                        "padding-top": "10px"
                      }
                    }, [_c('div', {
                      staticClass: "col-12"
                    }, [_c('label', {
                      attrs: {
                        "for": "customs-data"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm.$t('pick-up-order.invoicing')))])])])]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-12"
                    }, [_c('e-form-checkbox', {
                      attrs: {
                        "id": _vm.id + '-clearing-location',
                        "name": "$t('pick-up-order.invoicing.according')",
                        "placeholder": _vm.$t('pick-up-order.invoicing.according')
                      },
                      model: {
                        value: _vm.pickUpOrder.clearing.locationOkay,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.clearing, "locationOkay", $$v);
                        },
                        expression: "pickUpOrder.clearing.locationOkay"
                      }
                    }), _c('e-form-select', {
                      staticStyle: {
                        "padding-left": "25px",
                        "padding-top": "5px",
                        "width": "300px"
                      },
                      attrs: {
                        "id": _vm.id + '-clearing-location',
                        "name": "location",
                        "rules": ("" + (_vm.pickUpOrder.clearing.locationOkay ? 'required' : '')),
                        "placeholder": _vm.$t('customer.select-branch'),
                        "size": "sm"
                      },
                      model: {
                        value: _vm.pickUpOrder.clearing.location,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.clearing, "location", $$v);
                        },
                        expression: "pickUpOrder.clearing.location"
                      }
                    }, _vm._l(_vm.locations, function (location, idx) {
                      return _c('option', {
                        key: idx,
                        domProps: {
                          "value": location
                        }
                      }, [_vm._v(_vm._s(location.name) + " ")]);
                    }), 0)], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-12"
                    }, [_c('e-form-checkbox', {
                      attrs: {
                        "id": _vm.id + '-clearing-credit',
                        "name": "$t('pick-up-order.invoicing.credit')",
                        "placeholder": _vm.$t('pick-up-order.invoicing.credit')
                      },
                      model: {
                        value: _vm.pickUpOrder.clearing.credit,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.clearing, "credit", $$v);
                        },
                        expression: "pickUpOrder.clearing.credit"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-12"
                    }, [_c('e-form-checkbox', {
                      attrs: {
                        "id": _vm.id + '-clearing-agreement',
                        "name": "$t('pick-up-order.invoicing.agreement')",
                        "placeholder": _vm.$t('pick-up-order.invoicing.agreement')
                      },
                      model: {
                        value: _vm.pickUpOrder.clearing.agreement,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.clearing, "agreement", $$v);
                        },
                        expression: "pickUpOrder.clearing.agreement"
                      }
                    })], 1)]), _c('div', {
                      staticClass: "form-row"
                    }, [_c('div', {
                      staticClass: "col-12"
                    }, [_c('e-form-checkbox', {
                      attrs: {
                        "id": _vm.id + '-clearing-centralInvoiceDepartment',
                        "name": "$t('pick-up-order.invoicing.central')",
                        "placeholder": _vm.$t('pick-up-order.invoicing.central')
                      },
                      model: {
                        value: _vm.pickUpOrder.clearing.centralInvoiceDepartment,
                        callback: function callback($$v) {
                          _vm.$set(_vm.pickUpOrder.clearing, "centralInvoiceDepartment", $$v);
                        },
                        expression: "pickUpOrder.clearing.centralInvoiceDepartment"
                      }
                    })], 1)])];
                  }
                }], null, true)
              })];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn(_ref3) {
              var ok = _ref3.ok,
                  cancel = _ref3.cancel,
                  hide = _ref3.hide;
              return [_c('b-button', {
                attrs: {
                  "variant": "secondary"
                },
                on: {
                  "click": cancel
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.abort')))]), _c('b-button', {
                attrs: {
                  "variant": "primary"
                },
                on: {
                  "click": ok
                }
              }, [_vm._v("PDF generieren")])];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }