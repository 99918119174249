<template>
  <e-scrollable-page>
    <template slot="header">
      <div class="col-4">
        <div class="row">
          <div class="offset-1 col-11" @click="sortBy('alpha2Code', countryName)">
            <component v-bind:is="sortIcon('alpha2Code', countryName)" />
            {{ $t('country') }}
          </div>
        </div>
      </div>
      <div class="col-4" @click="sortBy('alpha2Code')">
        <component v-bind:is="sortIcon('alpha2Code')" />
        {{ $t('country.code.alpha2') }}
      </div>
      <div class="col-3" @click="sortBy('isoCode')">
        <component v-bind:is="sortIcon('isoCode')" />
        {{ $t('country.code.iso') }}
      </div>
      <div class="col-1 header-controls">
        <div v-if="links?.create?.href">
          <button class="btn btn-sm btn-block btn-secondary" @click="createCountry">{{ $t('controls.create') }}</button>
        </div>
      </div>
    </template>

    <template slot="content">
    <b-card no-body class="mb-1" v-for="(country, idx) in sortedItems" :key="idx">
      <b-card-header header-tag="header" class="p-1 pl-3 pr-3 row" style="outline: none; min-height: 1.8rem" v-b-toggle="'country-'+idx" role="tab" >
        <div class="col-4">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-11"><strong>{{ countryName(country) }}</strong></div>
          </div>
        </div>
        <div class="col-4">{{ country.alpha2Code }}</div>
        <div class="col-4">{{ country.isoCode }}</div>
      </b-card-header>
      <b-collapse :id="'country-'+idx" :visible="!country._links" role="tabpanel">
        <b-card-body>
          <country-view :id="'countryview-'+idx" :country="country"
                        @save="$root.$set(items, idx, $event)"
                        @delete="items.splice(idx, 1)"/>
        </b-card-body>
      </b-collapse>
    </b-card>
    </template>
  </e-scrollable-page>
</template>

<script>
import CountryView from "@/components/CountryView";
import CountryService from "@/services/country.service";
import LangFlag from "vue-lang-code-flags";
import ClientSideSortMixin from "@/components/mixins/ClientSideSortMixin";

export default {
  name: "CountryList",
  components: {CountryView, LangFlag},
  mixins: [ClientSideSortMixin],
  data() {
    return {
      sortKey: 'alpha2Code',
      sortFn: this.countryName
    }
  },
  mounted() {
    CountryService.findAll().then(response => {
      this.items = response.data
    })
  },
  methods: {
    countryName: function(country) {
      return this.$root.$i18n.t('countries.' + country.alpha2Code)
    },
    createCountry: function () {
      this.items.splice(0, 0, {})
    }
  }
}
</script>

<style scoped>

</style>