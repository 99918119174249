<template>
  <form @submit.prevent="handleSubmit(onSubmit)">
    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-zipCodeArea-country'" class="col-3 col-form-label">{{ $t('address.country') }}</label>
          <div class="col-9">
            <e-form-text :id="id + '-zipCodeArea-country'" :value="entity.country"/>
          </div>
        </div>

      </div>

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-zipCodeArea-zipCode'" class="col-3 col-form-label">{{ $t('address.zipCode') }}</label>
          <div class="col-9">
            <e-form-text :id="id + '-zipCodeArea-zipCode'" :value="entity.zipCode"/>
          </div>
        </div>

      </div>

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-zipCodeArea-city'" class="col-3 col-form-label">{{ $t('address.city') }}</label>
          <div class="col-9">
            <e-form-text :id="id + '-zipCodeArea-city'" :value="entity.city"/>
          </div>
        </div>

      </div>

      </div>

  </form>
</template>

<script>

import service from "@/services/zipCodeArea.service";
import {log} from "@emons/emons-vue"

export default {
  name: "ZipCodeAreaView",
  components: {},
  mounted() {
    log('debug', 'Mounted ZipCodeAreaView...')
    this.getEntityDetails()
  },
  props: {
    id: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      entity: {
        country: null,
        zipCode: null,
        city: null
      }
    }
  },
  methods: {
    getEntityDetails: function () {
      this.$emit('enableSpinner');
      service
          .getEntityDetails(this.link)
          .then(response => {
            this.entity = response.data
            this.$emit('disableSpinner');
          })
    }
  }
}
</script>

<style scoped>

</style>