<template>
  <e-scrollable-page>
    <template slot="content">
      <b-tabs pills vertical nav-class="h5 font-weight-normal" nav-wrapper-class="mr-4">
        <h5 class="mb-5">{{ $t('settings') }}</h5>
        <b-tab :title="$t('settings.printer')">
          <h2>{{ $t('settings.printer') }}</h2>
          <div class="text-muted">{{ $t('settings.printer.configure-labels') }}</div>
          <div class="mt-4">
            <b-form-group :label="$t('settings.printer.labels.type')" label-for="printerType"
                          content-cols="7" label-cols="3" label-size="lg" label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">
              <b-form-radio-group size="lg"
                                  v-model="printerType.value" @change="settingChanged(printerType)"
                                  :aria-describedby="ariaDescribedby"
                                  name="printerType" stacked>
                <b-form-radio value="" class="p-2">
                  <span>{{ $t('settings.printer.labels.none') }}</span>
                  <b-form-text>
                    <span v-html="$t('settings.printer.labels.none.description')" />
                  </b-form-text>
                </b-form-radio>
                <b-form-radio value="LASER" class="p-2">
                  <span>{{ $t('settings.printer.labels.laser') }}</span>
                  <b-form-text>
                    <span v-html="$t('settings.printer.labels.laser.description')" />
                  </b-form-text>
                </b-form-radio>
                <b-form-radio value="LASER_POSITION" class="p-2">
                  <span>{{ $t('settings.printer.labels.laser_position') }}</span>
                  <b-form-text>
                    <span v-html="$t('settings.printer.labels.laser.description')" />
                    <span v-html="$t('settings.printer.labels.laser_position.description')" />
                  </b-form-text>
                </b-form-radio>
                <b-form-radio value="ZEBRA" class="p-2">
                  <span>{{ $t('settings.printer.labels.zebra') }}</span>
                  <b-form-text>
                    <span v-html="$t('settings.printer.labels.zebra.description')" />
                  </b-form-text>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab :title="$t('settings.shipments')">
          <h2>{{ $t('settings.shipments') }}</h2>
          <div class="text-muted">{{  $t('settings.shipments.configure-shipments') }}</div>
          <div class="mt-4">
            <b-form-group :label="$t('settings.shipments.mandatory-fields')" label-for="markingRequired"
                          content-cols="7" label-cols="3" label-size="lg" label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group name="requiredFields"
                                     :aria-describedby="ariaDescribedby"
                                     v-model="requiredFields.value" :options="requiredFieldNames"
                                     @change="settingChanged(requiredFields)"/>
            </b-form-group>
            <b-form-group :label="$t('settings.shipments.incoterms.national')" label-for="incoTermsNational"
                          content-cols="2" label-cols="3" label-size="lg" label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">
              <b-form-select id="incoTermsNational" name="incoTermsNational" :aria-describedby="ariaDescribedby"
                             v-model="incoTermsNat.value" @change="settingChanged(incoTermsNat)">
                <option v-for="(term, idx) in incoTermsNational" :value="term.name">{{ term.name }}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group :label="$t('settings.shipments.incoterms.international')" label-for="incoTermsNational"
                          content-cols="2" label-cols="3" label-size="lg" label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">
              <b-form-select id="incoTermsInternational" name="incoTermsInternational" :aria-describedby="ariaDescribedby"
                             v-model="incoTermsInternat.value" @change="settingChanged(incoTermsInternat)">
                <option v-for="(term, idx) in incoTermsInternational" :value="term.name">{{ term.name }}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="branchCurrency?.isoCode != 'EUR'"
                          :label="$t('settings.shipments.default-currency')" label-for="defaultCurrency"
                          content-cols="2" label-cols="3" label-size="lg" label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">
              <b-form-select id="defaultCurrency" name="defaultCurrency" :aria-describedby="ariaDescribedby"
                             v-model="defaultCurrency.value" @change="settingChanged(defaultCurrency)">
                <option :value="branchCurrency?.isoCode">{{ branchCurrency?.isoCode }}</option>
                <option value="EUR">EUR</option>
              </b-form-select>
            </b-form-group>

            <b-form-group :label="$t('settings.shipments.shipping-date')" label-for="shippingDateOffset"
                          content-cols="2" label-cols="3" label-size="lg" label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">
              <b-form-select id="shippingDateOffset" name="shippingDateOffset" :aria-describedby="ariaDescribedby"
                             v-model="shippingDateOffset.value" @change="settingChanged(shippingDateOffset)">
                <option value="0">{{ $t('settings.shipments.shipping-date.current') }}</option>
                <option value="1">{{ $t('settings.shipments.shipping-date.current-plus-1') }}</option>
                <option value="2">{{ $t('settings.shipments.shipping-date.current-plus-2') }}</option>
              </b-form-select>
            </b-form-group>

            <b-form-group :label="$t('settings.shipments.shipping-time')"
                          content-cols="4"
                          label-cols="3"
                          label-size="lg"
                          label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">
              <div class="form-row">
                <div class="col-6">

                <label>{{ $t('time.from') }}</label>
                  <time-input name="timeFrom" v-model="shippingTimeFrom.value" @input="settingChanged(shippingTimeFrom)"
                              :placeholder="$t('time.select.short')"/>
                </div>
                <div class="col-6">
                <label>{{ $t('time.to') }}</label>
                  <time-input name="timeUntil" v-model="shippingTimeUntil.value" @input="settingChanged(shippingTimeUntil)"
                              :placeholder="$t('time.select.short')" />
                </div>
              </div>
              <div class="form-row">
                <b-form-checkbox name="sameShippingTimeOnFriday" v-model="shippingTimeSameOnFriday" @change="setFridayShippingTime($event)">
                  {{ $t('settings.shipments.sameShippingTimeOnFriday') }}</b-form-checkbox>
              </div>

            </b-form-group>

            <b-form-group :label="$t('settings.shipments.shipping-time.friday')"
                          v-if="!shippingTimeSameOnFriday"
                          content-cols="4"
                          label-cols="3"
                          label-size="lg"
                          label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">
              <div class="form-row">
                <div class="col-6">

                  <label>{{ $t('time.from') }}</label>
                  <time-input name="timeFrom" v-model="shippingTimeFromFr.value" @input="settingChanged(shippingTimeFromFr)"
                              :placeholder="$t('time.select.short')"/>
                </div>
                <div class="col-6">
                  <label>{{ $t('time.to') }}</label>
                  <time-input name="timeUntil" v-model="shippingTimeUntilFr.value" @input="settingChanged(shippingTimeUntilFr)"
                              :placeholder="$t('time.select.short')" />
                </div>
              </div>

            </b-form-group>

            <b-form-group :label="$t('settings.shipments.deliverynotes')"
                          label-for="notificationCodes"
                          content-cols="9"
                          label-cols="3"
                          label-size="lg"
                          label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">

              <b-table striped hover sticky-header="350px"
                       :items="notificationCodes"
                       :fields="[{key: 'active', stickyColumn: true, sortable: false, label: $t('settings.shipments.deliverynotes.enabled')},
                          {key: 'default', stickyColumn: true, sortable: false, label: $t('settings.shipments.deliverynotes.default')},
                          {key: 'identifier', stickyColumn: true, sortable: true, label: $t('settings.shipments.deliverynotes.identifier')},
                          {key: 'name', stickyColumn: true, sortable: true, label: $t('settings.shipments.deliverynotes.name')},
                          {key: 'attachment', stickyColumn: true, sortable:false, label: $t('settings.shipments.deliverynotes.attachment')}]">

                <template #cell(active)="data">
                  <b-form-checkbox :checked="data.item.active" @change="toggleDisabledNotificationCode(data.item, $event)"/>
                </template>

                <template #cell(default)="data">
                  <b-form-checkbox :checked="data.item.default" @change="toggleDefaultNotificationCode(data.item, $event)" :disabled="!data.item.active"/>
                </template>

                <template #cell(attachment)="data">
                  <span v-if="!data?.item?.attachment?.name?.startsWith('Ja') || data?.item?.requiredFields?.length > 0">-</span>
                  <div class="input-group input-group-sm" v-else>
                    <input type="text" class="form-control form-control-sm" maxlength="35" v-model="data.item.attachment.defaultValue" @blur="setDefaultAttachment(data.item)" />
                  </div>
                </template>

              </b-table>

            </b-form-group>

          </div>
        </b-tab>
        <b-tab :title="$t('settings.notifications')">
          <h2>{{ $t('settings.notifications') }}</h2>
          <div class="text-muted">{{ $t('settings.notifications.configure-notifications') }}</div>
          <div class="mt-4">
            <b-form-group :label="$t('settings.notifications.shipping-notices')"
                        :description="$t('settings.notifications.shipping-notices.description')"
                        content-cols="3"
                        label-cols="3"
                        label-size="lg"
                        label-class="font-weight-bold pt-0"
                        v-slot="{ ariaDescribedby }">

            <b-input-group>

              <b-input-group-prepend is-text>
                <b-form-checkbox switch class="mr-n2 mb-n1"
                                 value="ON"
                                 unchecked-value="OFF"
                                 v-model="announcementSendmail.value"
                                 @change="settingChanged(announcementSendmail)">
                </b-form-checkbox>
              </b-input-group-prepend>

              <b-form-input type="text"
                            :placeholder="$t('settings.notifications.specify-email')"
                            :disabled="announcementSendmail.value != 'ON'"
                            v-model="announcementMailAddress.value"
                            @blur="settingChanged(announcementMailAddress)">
              </b-form-input>

              <b-input-group-append is-text>
                <b-icon id="info-icon-avis"
                        icon="info-circle"
                        font-scale="1.1"/>
                <b-tooltip target="info-icon-avis"
                           triggers="hover">
                  {{  $t('settings.notifications.single-email-only') }}
                </b-tooltip>
              </b-input-group-append>

            </b-input-group>

          </b-form-group>
            <b-form-group :label="$t('settings.notifications.belog')"
                          :description="$t('settings.notifications.belog.description')"
                          content-cols="3"
                          label-cols="3"
                          label-size="lg"
                          label-class="font-weight-bold pt-0"
                          v-slot="{ ariaDescribedby }">

              <b-input-group>

                <b-input-group-prepend is-text>
                  <b-form-checkbox switch class="mr-n2 mb-n1"
                                   value="ON"
                                   unchecked-value="OFF"
                                   v-model="belogSendmail.value"
                                   @change="settingChanged(belogSendmail)">
                  </b-form-checkbox>
                </b-input-group-prepend>

                <b-form-input type="text"
                              :placeholder="$t('settings.notifications.specify-email')"
                              :disabled="belogSendmail.value != 'ON'"
                              v-model="belogMailAddress.value"
                              @blur="settingChanged(belogMailAddress)">
                </b-form-input>

                <b-input-group-append is-text>
                  <b-icon id="info-icon-avis"
                          icon="info-circle"
                          font-scale="1.1"/>
                  <b-tooltip target="info-icon-avis"
                             triggers="hover">
                    {{  $t('settings.notifications.single-email-only') }}
                  </b-tooltip>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </template>
  </e-scrollable-page>
</template>

<script>
import SettingsService from "@/services/settings.service"
import IncoTermCodeService from "@/services/incoTermCode.service"
import NotificationCodeService from "@/services/notificationCode.service"
import CurrencyService from "@/services/currency.service";
import TimeInput from "@/components/form-controls/TimeInput.vue";

export default {
  name: "Settings",
  components: {TimeInput},
  data() {
    return {
      settings: [],
      incoTerms: [],
      notificationCodes: [],

      printerType: this.createSetting('PRINTER_TYPE', ''),
      requiredFields: this.createSetting('REQUIRED_FIELDS', []),
      requiredFieldNames: [
        {text: this.$t('cargo-item.marking'), value: 'marking'},
        {text: this.$t('cargo-item.orderNumber'), value: 'orderNumber'},
        {text: this.$t('order.number'), value: 'shipment.orderNumber'}
      ],
      incoTermsNat: this.createSetting('INCOTERMS_NATIONAL', ''),
      incoTermsInternat: this.createSetting('INCOTERMS_INTERNATIONAL', ''),
      defaultCurrency: this.createSetting('CURRENCY_DEFAULT', ''),
      shippingDateOffset: this.createSetting('SHIPPING_DATE_OFFSET', 0),
      shippingTimeFrom: this.createSetting('SHIPPING_TIME_FROM', ''),
      shippingTimeUntil: this.createSetting('SHIPPING_TIME_UNTIL', ''),
      shippingTimeSameOnFriday: false,
      shippingTimeFromFr: this.createSetting('SHIPPING_TIME_FROM_FR', ''),
      shippingTimeUntilFr: this.createSetting('SHIPPING_TIME_UNTIL_FR', ''),
      announcementSendmail: this.createSetting('ANNOUNCEMENT_SEND_MAIL', ''),
      announcementMailAddress: this.createSetting('ANNOUNCEMENT_MAIL_ADDRESS', ''),
      belogSendmail: this.createSetting('BELOG_SEND_MAIL', ''),
      belogMailAddress: this.createSetting('ANNOUNCEMENT_MAIL_ADDRESS', ''),
    }
  },
  mounted() {
    this.reloadSettings()
    IncoTermCodeService.findAll().then(response => this.incoTerms = response?.data?.items)
    NotificationCodeService.findAll().then(response => this.notificationCodes = response?.data?.items)
  },
  asyncComputed: {
    async branchCurrency() {
      return await CurrencyService.getForCustomerBranch()
    }
  },
  methods: {
    setFridayShippingTime(sameAsMondayToThursday) {
      this.$log('DEBUG', 'SET FRIDAY shipping time', sameAsMondayToThursday)
      if (sameAsMondayToThursday) {
        this.shippingTimeFromFr.value = this.shippingTimeFrom.value
        this.shippingTimeUntilFr.value = this.shippingTimeUntil.value
        SettingsService.save(this.shippingTimeFromFr)
        SettingsService.save(this.shippingTimeUntilFr)
      }
    },
    createSetting: function (name, value) {
      return {name: name, value: value}
    },
    reloadSettings: function () {
      SettingsService.findAll().then(
          response => {
            this.settings = response.data

            this.printerType = response.data.find(setting => setting.name == 'PRINTER_TYPE')
            if (!this.printerType) this.printerType = this.createSetting('PRINTER_TYPE', '')

            this.requiredFields = response.data.find(setting => setting.name == 'REQUIRED_FIELDS')
            if (!this.requiredFields) this.requiredFields = this.createSetting('REQUIRED_FIELDS', [])
            else if (!Array.isArray(this.requiredFields.value)) this.requiredFields.value = []

            this.incoTermsNat = response.data.find(setting => setting.name == 'INCOTERMS_NATIONAL')
            if (!this.incoTermsNat) this.incoTermsNat = this.createSetting('INCOTERMS_NATIONAL', '')

            this.incoTermsInternat = response.data.find(setting => setting.name == 'INCOTERMS_INTERNATIONAL')
            if (!this.incoTermsInternat) this.incoTermsInternat = this.createSetting('INCOTERMS_INTERNATIONAL', '')

            this.defaultCurrency = response.data.find(setting => setting.name == 'CURRENCY_DEFAULT')
            if (!this.defaultCurrency) this.defaultCurrency = this.createSetting('CURRENCY_DEFAULT', '')

            this.shippingDateOffset = response.data.find(setting => setting.name == 'SHIPPING_DATE_OFFSET')
            if (!this.shippingDateOffset) this.shippingDateOffset = this.createSetting('SHIPPING_DATE_OFFSET', 0)

            this.shippingTimeFrom = response.data.find(setting => setting.name === 'SHIPPING_TIME_FROM')
            if (!this.shippingTimeFrom) this.shippingTimeFrom = this.createSetting('SHIPPING_TIME_FROM', '')

            this.shippingTimeUntil = response.data.find(setting => setting.name === 'SHIPPING_TIME_UNTIL')
            if (!this.shippingTimeUntil) this.shippingTimeUntil = this.createSetting('SHIPPING_TIME_UNTIL', '')

            this.shippingTimeFromFr = response.data.find(setting => setting.name === 'SHIPPING_TIME_FROM_FR')
            if (!this.shippingTimeFromFr) this.shippingTimeFromFr = this.createSetting('SHIPPING_TIME_FROM_FR', '')

            if ((this.shippingTimeFromFr.value != '' || this.shippingTimeUntilFr.value != '')
                && this.shippingTimeFromFr.value == this.shippingTimeFrom.value
                && this.shippingTimeUntilFr.value == this.shippingTimeUntil.value) {
              this.shippingTimeSameOnFriday = true
            }

            this.shippingTimeUntilFr = response.data.find(setting => setting.name === 'SHIPPING_TIME_UNTIL_FR')
            if (!this.shippingTimeUntilFr) this.shippingTimeUntilFr = this.createSetting('SHIPPING_TIME_UNTIL_FR', '')

            this.announcementSendmail = response.data.find(setting => setting.name === 'ANNOUNCEMENT_SEND_MAIL')
            if (!this.announcementSendmail) this.announcementSendmail = this.createSetting('ANNOUNCEMENT_SEND_MAIL', 'OFF')

            this.announcementMailAddress = response.data.find(setting => setting.name === 'ANNOUNCEMENT_MAIL_ADDRESS')
            if (!this.announcementMailAddress) this.announcementMailAddress = this.createSetting('ANNOUNCEMENT_MAIL_ADDRESS', '')

            this.belogSendmail = response.data.find(setting => setting.name === 'BELOG_SEND_MAIL')
            if (!this.belogSendmail) this.belogSendmail = this.createSetting('BELOG_SEND_MAIL', 'OFF')

            this.belogMailAddress = response.data.find(setting => setting.name === 'BELOG_MAIL_ADDRESS')
            if (!this.belogMailAddress) this.belogMailAddress = this.createSetting('BELOG_MAIL_ADDRESS', '')
          }
      );
    },
    settingChanged: function (setting) {
      this.$log('DEBUG', 'setting changed', setting)
      SettingsService.save(setting)
      if (this.shippingTimeSameOnFriday) {
        if (setting.name == this.shippingTimeFrom.name) {
          this.shippingTimeFromFr.value = setting.value
          SettingsService.save(this.shippingTimeFromFr)
        } else if (setting.name == this.shippingTimeUntil.name) {
          this.shippingTimeUntilFr.value = setting.value
          SettingsService.save(this.shippingTimeUntilFr)
        }
      }
    },
    toggleDisabledNotificationCode: async function (code, value) {
      this.$log('DEBUG', 'disable notification code', code, value)
      code.active = value
      
      if (!code.active && code.default) {
        code.default = false
      }

      let response = await NotificationCodeService.save(code)
    },

    toggleDefaultNotificationCode: async function (code, value) {
      this.$log('DEBUG', 'default notification code', code, value)
      code.default = value

      let response = await NotificationCodeService.save(code)
    },
    setDefaultAttachment: async function (code) {
      this.$log('DEBUG', 'set default attachment value', code, this.notificationCodes)

      let response = await NotificationCodeService.save(code)
    }
  },
  computed: {
    incoTermsNational: function () {
      return this.incoTerms ? this.incoTerms.filter(code => code.international == false) : []
    },
    incoTermsInternational: function () {
      return this.incoTerms ? this.incoTerms.filter(code => code.international == true) : []
    },
  }
}
</script>

<style>
.input-group-custom .input-group-text {
  min-width: 40px;
}

.b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,
.b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,
.b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,
.table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,
.table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,
.table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,
[class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,
[class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column,
[class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
}

</style>
