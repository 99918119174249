var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-search-filter-page', {
    attrs: {
      "service": _vm.service
    },
    on: {
      "enableSpinner": function enableSpinner($event) {
        return _vm.enableSpinner();
      },
      "disableSpinner": function disableSpinner($event) {
        return _vm.disableSpinner();
      }
    },
    scopedSlots: _vm._u([{
      key: "header-controls",
      fn: function fn(_ref) {
        var _links$create;

        var created = _ref.created,
            links = _ref.links;
        return [_c('b-button-toolbar', {
          staticClass: "float-right",
          attrs: {
            "key-nav": ""
          }
        }, [links !== null && links !== void 0 && (_links$create = links.create) !== null && _links$create !== void 0 && _links$create.href ? _c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              created(_vm.service.create());
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "plus"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('controls.create')) + " ")], 1)], 1) : _vm._e()], 1)];
      }
    }, {
      key: "spinner",
      fn: function fn() {
        return [_vm.loading ? _c('b-spinner', {
          staticClass: "spinner",
          attrs: {
            "label": "Loading..."
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "entity-content",
      fn: function fn(_ref2) {
        var id = _ref2.id,
            entity = _ref2.entity,
            index = _ref2.index,
            deleted = _ref2.deleted,
            saved = _ref2.saved;
        return [_c('detail-view', {
          attrs: {
            "id": id,
            "value": entity
          },
          on: {
            "delete": function _delete($event) {
              return deleted(index);
            },
            "save": function save($event) {
              return saved(index, $event);
            },
            "enableSpinner": function enableSpinner($event) {
              return _vm.enableSpinner();
            },
            "disableSpinner": function disableSpinner($event) {
              return _vm.disableSpinner();
            }
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }