var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-search-filter-page', {
    attrs: {
      "service": _vm.service
    },
    on: {
      "enableSpinner": function enableSpinner($event) {
        return _vm.enableSpinner();
      },
      "disableSpinner": function disableSpinner($event) {
        return _vm.disableSpinner();
      }
    },
    scopedSlots: _vm._u([{
      key: "spinner",
      fn: function fn() {
        return [_vm.loading ? _c('b-spinner', {
          staticClass: "spinner",
          attrs: {
            "label": "Loading..."
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "entity-content",
      fn: function fn(_ref) {
        var id = _ref.id,
            entity = _ref.entity,
            index = _ref.index,
            saved = _ref.saved;
        return [_c('detail-view', {
          attrs: {
            "value": entity,
            "id": id
          },
          on: {
            "save": function save($event) {
              return saved(index, $event);
            },
            "enableSpinner": function enableSpinner($event) {
              return _vm.enableSpinner();
            },
            "disableSpinner": function disableSpinner($event) {
              return _vm.disableSpinner();
            }
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }