import ApiService from "@/services/api.service";
import { log } from "@emons/emons-vue"

const schema = {
    name: null,
    value: null
}
const path = '/api/settings'
const admin_path = '/api/admin/customers'

const SettingsService = {
    findAdmin: async function(tenantId, name) {
        try {
            let r = await ApiService.get(admin_path + "/" + tenantId + "/settings/" + name, {
                transformResponse: [function (data) {
                    let setting = data?JSON.parse(data):null
                    log("DEBUG", "SettingsService.findAdmin(" + tenantId + ", " + name + "):", setting)
                    if (setting) ApiService.ensureSchema(schema, setting)
                    return setting
                }]
            })
            return r.data
        } catch (error) {
            if (error.response && error.response.status == 404) {
                log("info", "Setting " + name + " not found")
            } else {
                log("error", "Error querying setting " + name + ":", error)
            }
        }
    },
    saveAdmin: async function(tenantId, settings) {
        if (settings._links && settings._links.self) {
            return ApiService.put(settings._links.self.href, settings).then(
                response => {
                    if(response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        } else {
            return ApiService.put(admin_path + "/" + tenantId + "/settings/" + settings.name, settings).then(
                response => {
                    if(response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        }
    },
    find: async function(name) {
        try {
            let r = await ApiService.get(path + "/" + name, {
                transformResponse: [function (data) {
                    let setting = data?JSON.parse(data):null
                    log("DEBUG", "SettingsService.find(" + name + "):", setting)
                    if (setting) ApiService.ensureSchema(schema, setting)
                    return setting
                }]
            })
            return r.data
        } catch (error) {
            if (error.response && error.response.status == 404) {
                log("info", "Setting " + name + " not found")
            } else {
                log("error", "Error querying setting " + name + ":", error)
            }
        }
    },
    findAll: async function() {
        try {
            return ApiService.get(path, {
                transformResponse: [function (data) {
                    let parsed = data?JSON.parse(data):null
                    let response = (parsed && parsed._embedded)?parsed._embedded.settings:[]
                    response.forEach(obj => {
                        ApiService.ensureSchema(schema, obj)
                    })
                    log("debug", "SettingsService.findAll():", response)
                    return response
                }]
            })
        } catch (error) {
            log("error", "Error querying settings:", error)
        }
    },
    save: async function(settings) {
        if (settings._links && settings._links.self) {
            return ApiService.put(settings._links.self.href, settings).then(
                response => {
                    if(response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        } else {
            return ApiService.put(path + "/" + settings.name, settings).then(
                response => {
                    if(response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        }
    },
    delete: async function(settings) {
        if (settings._links && settings._links.self) {
            return ApiService.delete(settings._links.self.href)
        }
    }
}

export default SettingsService