var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('about-modal', {
    ref: "aboutModal"
  }), _c('e-bug-report-modal', {
    ref: "bugReportModal",
    attrs: {
      "project-id": "f41d0080-308e-4a25-a35c-2e943f6e49e6",
      "area-path": "myEmons",
      "auth-header": "Basic Om1jc2J6NnB2ZmtsbTZzenJlNWVta3h3eWx0aXo1b3F0NHBibm9pemJ6YXNqdDJ2cGc0bWE=",
      "version": _vm.$root.$options.version
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.bugReportSubmitted($event);
      }
    }
  }), _c('e-dev-ribbon'), _c('e-menu-bar', {
    attrs: {
      "user-data": _vm.userData
    },
    on: {
      "customerChanged": function customerChanged($event) {
        _vm.forceUpdate++;
      }
    },
    scopedSlots: _vm._u([{
      key: "help-items",
      fn: function fn() {
        return [_c('b-dropdown-item', {
          attrs: {
            "href": "/MyEmons_Handout_26042023.pdf",
            "target": "_blank"
          }
        }, [_c('b-icon-book'), _vm._v(" " + _vm._s(_vm.$t('myemons.user-manual')) + " ")], 1), _c('b-dropdown-divider'), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.$refs.aboutModal.show();
            }
          }
        }, [_c('b-icon-info-circle'), _vm._v(" " + _vm._s(_vm.$t('myemons.about')) + " ")], 1), _c('b-dropdown-divider'), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.$refs.bugReportModal.show();
            }
          }
        }, [_c('b-icon-bug'), _vm._v(" " + _vm._s(_vm.$t('report.bug')) + " ")], 1), _c('b-dropdown-divider'), _c('b-dropdown-item', {
          attrs: {
            "href": "https://emons-cms-public.s3-de-central.profitbricks.com/5f7efb140a2f05d7b0e08319/_1602600244798.pdf",
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('myemons.adsp')))]), _c('b-dropdown-item', {
          attrs: {
            "href": "https://www.emons.de/datenschutz",
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(_vm.$t('myemons.privacy-statement')))]), _c('b-dropdown-item', {
          attrs: {
            "href": "https://www.emons.de/impressum",
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(_vm.$t('myemons.imprint')))])];
      },
      proxy: true
    }, _vm.$router.checkPermission('/settings/index') ? {
      key: "popover-items-before-logout",
      fn: function fn(_ref) {
        var loggedIn = _ref.loggedIn;
        return [loggedIn ? _c('b-dropdown-item', {
          attrs: {
            "to": "/settings/index"
          }
        }, [_c('b-icon-wrench'), _vm._v(" " + _vm._s(_vm.$t('settings')) + " ")], 1) : _vm._e()];
      }
    } : null, {
      key: "popover-items-after-logout",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "nav-links",
      fn: function fn() {
        return [!_vm.$router.isLoggedIn() ? _c('li', [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": _vm.login
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('nav.login')) + " ")])]) : _vm._e(), !_vm.$router.isLoggedIn() ? _c('li', {
          class: {
            'router-link-active': _vm.subIsActive('/registration')
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/registration"
          }
        }, [_vm._v(_vm._s(_vm.$t('nav.registration')))])], 1) : _vm._e(), _vm.$router.checkPermission('/shipments/list') ? _c('li', {
          class: {
            'router-link-active': _vm.subIsActive('/shipments')
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/shipments/list"
          }
        }, [_vm._v(_vm._s(_vm.$t('nav.shipments')))])], 1) : _vm._e(), _vm.$router.checkPermission('/addresses/addresses') ? _c('li', {
          class: {
            'router-link-active': _vm.subIsActive('/addresses')
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/addresses/addresses"
          }
        }, [_vm._v(_vm._s(_vm.$t('nav.addresses')))])], 1) : _vm._e(), _vm.$router.checkPermission('/templates/cargoList') ? _c('li', {
          class: {
            'router-link-active': _vm.subIsActive('/templates')
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/cargoList"
          }
        }, [_vm._v(_vm._s(_vm.$t('nav.templates')))])], 1) : _vm._e(), _vm.$router.checkPermission('/administration/customers') ? _c('li', {
          class: {
            'router-link-active': _vm.subIsActive('/administration')
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/administration/customers"
          }
        }, [_vm._v(_vm._s(_vm.$t('nav.admin')))])], 1) : _vm._e()];
      },
      proxy: true
    }], null, true)
  }), _c('div', {
    attrs: {
      "id": "app-content"
    }
  }, [_c('router-view', {
    key: _vm.forceUpdate
  })], 1), _c('error-modal', {
    ref: "errorModal",
    attrs: {
      "id": "errorModal"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }