var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header p-2"
  }, [_c('div', {
    staticClass: "form-row align-items-center"
  }, [_c('label', {
    staticClass: "col-4 mb-0"
  }, [_vm._v(_vm._s(_vm.$t('shipment.sender_or_loadingsite')))]), _c('div', {
    staticClass: "col-8 text-right"
  }, [_c('addressbook-select', {
    ref: "addressbookSelect",
    attrs: {
      "types": ['SENDER', 'LOADING_SITE', 'NEUTRAL'],
      "show-type": "",
      "editable": _vm.editable
    },
    on: {
      "input": _vm.selectedSenderChanged
    }
  })], 1)])]), _c('div', {
    staticClass: "card-body p-2"
  }, [_vm.value == null ? _c('div', [_vm._v(" Keine Adresse ausgewählt. ")]) : _c('div', [_c('validation-observer', {
    ref: "observer",
    attrs: {
      "vid": "sender_observer",
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$value$types;

        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty;
        return [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('label', {
          attrs: {
            "for": "sender-name"
          }
        }, [_vm._v(_vm._s(_vm.$t('address.address-data')))])])]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-7"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-sender.name',
            "name": "name",
            "rules": "required",
            "maxlength": "35",
            "placeholder": _vm.$t('address.name'),
            "disabled": !(_vm.editable && _vm.isAddressDataEditable)
          },
          model: {
            value: _vm.value.name,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "name", $$v);
            },
            expression: "value.name"
          }
        })], 1), _c('div', {
          staticClass: "col-5"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-sender.customerId',
            "name": "customerId",
            "maxlength": "35",
            "placeholder": _vm.$t('address.customerId'),
            "disabled": !(_vm.editable && _vm.isAddressDataEditable)
          },
          model: {
            value: _vm.value.customerId,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "customerId", $$v);
            },
            expression: "value.customerId"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-7"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-sender.additionalName',
            "name": "additionalName",
            "maxlength": "35",
            "placeholder": _vm.$t('address.additionalName'),
            "disabled": !(_vm.editable && _vm.isAddressDataEditable)
          },
          model: {
            value: _vm.value.additionalName,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "additionalName", $$v);
            },
            expression: "value.additionalName"
          }
        })], 1), _c('div', {
          staticClass: "col-5"
        }, [_c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-sender.isNeutral',
            "name": "isNeutral",
            "disabled": !_vm.editable,
            "value": (_vm$value$types = _vm.value.types) === null || _vm$value$types === void 0 ? void 0 : _vm$value$types.some(function (type) {
              return type === 'NEUTRAL';
            })
          },
          on: {
            "input": function input($event) {
              return _vm.toggleNeutral($event);
            }
          }
        }, [_c('e-tooltip', {
          attrs: {
            "disabled": !_vm.editable,
            "title": _vm.neutralCheckboxTooltip
          }
        }, [_vm._v(" " + _vm._s(_vm.neutralCheckboxLabel) + " ")])], 1), _c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-sender.isLoadingSite',
            "name": "isLoadingSite",
            "placeholder": _vm.$t('addressType.LOADING_SITE'),
            "disabled": !_vm.editable,
            "value": _vm.isLoadingSite
          },
          on: {
            "input": function input($event) {
              return _vm.setLoadingSite($event);
            }
          }
        }), _c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-sender.isRetail',
            "name": "sender.isRetail",
            "placeholder": _vm.$t('shipment.sender.isRetail'),
            "disabled": !(_vm.editable && _vm.isAddressDataEditable)
          },
          model: {
            value: _vm.value.isRetail,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "isRetail", $$v);
            },
            expression: "value.isRetail"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-sender.street',
            "name": "street",
            "rules": "required",
            "maxlength": "35",
            "placeholder": _vm.$t('address.streetWithNumber'),
            "disabled": !(_vm.editable && _vm.isAddressDataEditable)
          },
          model: {
            value: _vm.value.street,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "street", $$v);
            },
            expression: "value.street"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-3"
        }, [_c('country-select', {
          attrs: {
            "id": _vm.id + '-sender.country',
            "name": "country",
            "required": true,
            "placeholder": _vm.$t('address.country'),
            "disabled": !(_vm.editable && _vm.isAddressDataEditable)
          },
          model: {
            value: _vm.value.country,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "country", $$v);
            },
            expression: "value.country"
          }
        })], 1), _c('div', {
          staticClass: "col-3"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-sender.zipCode',
            "name": "zipCode",
            "rules": {
              required: true,
              zipCode: {
                country: '@' + _vm.id + '-sender.country'
              }
            },
            "placeholder": _vm.$t('address.zipCode'),
            "disabled": !(_vm.editable && _vm.isAddressDataEditable)
          },
          on: {
            "blur": function blur($event) {
              return _vm.formatZipCode($event);
            }
          },
          model: {
            value: _vm.value.zipCode,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "zipCode", $$v);
            },
            expression: "value.zipCode"
          }
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('city-select', {
          attrs: {
            "id": _vm.id + '-sender.city',
            "name": "city",
            "rules": "required",
            "disabled": !(_vm.editable && _vm.isAddressDataEditable),
            "country": _vm.value.country,
            "zip-code": _vm.value.zipCode
          },
          on: {
            "citySelect": _vm.onCitySelect
          },
          model: {
            value: _vm.value.city,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "city", $$v);
            },
            expression: "value.city"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_vm.editable && !_vm.isAddressDataEditable ? _c('div', {
          staticClass: "col-12 pb-2"
        }, [_c('em', [_vm._v(" " + _vm._s(_vm.$t('address.only-contact-data-editable')) + " " + _vm._s(_vm.$t('address.contact-support-for-changing-administrative-data')) + " ")])]) : _vm._e()]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('label', {
          attrs: {
            "for": "sender-contact-name"
          }
        }, [_vm._v(_vm._s(_vm.$t('address.contact-data')))])])]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          ref: "contactName",
          attrs: {
            "id": _vm.id + '-sender.contactName',
            "name": "contactName",
            "maxlength": "255",
            "placeholder": _vm.$t('address.contactName'),
            "disabled": !_vm.editable
          },
          model: {
            value: _vm.value.contactName,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "contactName", $$v);
            },
            expression: "value.contactName"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          ref: "phoneNumber",
          attrs: {
            "id": _vm.id + '-sender.phone',
            "name": "phone",
            "rules": "phoneNumber",
            "placeholder": _vm.$t('address.phone'),
            "disabled": !_vm.editable
          },
          model: {
            value: _vm.value.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "phone", $$v);
            },
            expression: "value.phone"
          }
        })], 1), _vm.isLoadingSite ? _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-sender.email',
            "name": "email",
            "placeholder": _vm.$t('address.email'),
            "disabled": !_vm.editable,
            "rules": {
              email: true,
              required: _vm.value.autosendShippingDocuments === true
            }
          },
          model: {
            value: _vm.value.email,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "email", $$v);
            },
            expression: "value.email"
          }
        })], 1) : _vm._e(), _vm.isLoadingSite ? _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-sender.autosendShippingDocuments',
            "name": "autosendShippingDocuments",
            "disabled": !_vm.editable,
            "placeholder": _vm.$t('address.autosendShippingDocuments')
          },
          model: {
            value: _vm.value.autosendShippingDocuments,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "autosendShippingDocuments", $$v);
            },
            expression: "value.autosendShippingDocuments"
          }
        })], 1) : _vm._e()]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_vm._t("default")], 2)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12 text-right"
        }, [_c('button', {
          staticClass: "btn btn-link",
          attrs: {
            "disabled": !_vm.editable || !(changed || dirty || _vm.value._links)
          },
          on: {
            "click": _vm.createNewSender
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('address.reset-addressbook-entry')))])]), _vm.editable ? _c('button', {
          staticClass: "btn btn-link",
          attrs: {
            "disabled": !(changed || dirty) || invalid
          },
          on: {
            "click": _vm.saveSender
          }
        }, [_vm._v(" " + _vm._s(_vm.value._links ? _vm.$t('address.update-addressbook-entry') : _vm.$t('address.create-addressbook-entry')) + " ")]) : _vm._e()])])];
      }
    }], null, true)
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }