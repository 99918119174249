<template>
  <hazmat-list />
</template>

<script>
import HazmatList from "@/components/HazmatList";

export default {
  name: "AdminHazmats",
  components: {HazmatList}
}
</script>

<style scoped>

</style>