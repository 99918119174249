<template>
  <validation-provider v-if="editable" name="unNumber"
                       :immediate="hasValue && editable"
                       :vid="id" :rules="rules" v-slot="{ classes, errors }" ref="vp" slim>
    <div class="form-label-group">
      <v-select ref="vselect"
                @open="onOpen" @close="onClose" @search="onSearch" @input="onInput"
                :class="['un-number-select', 'form-control'].concat(sizeClass).concat(value?'has-value':'').concat(filterValidationClasses(classes))"
                :disabled="!editable"
                :options="results"
                :loading="isLoading"
                :filterable="false"
                :clearable="false"
                :select-on-tab="false" class="unNumber-dropdown"
                :value="value"
                :placeholder="$t('cargo-item.adr.un-number.short')" label="unNumber">

        <template v-slot:option="option">
          <strong>{{ option.unNumber }}</strong>
          <br />
          <span v-if="option.packagingGroup">VG {{option.packagingGroup}}: </span>
          <span>{{option.name}}</span>
          <span v-if="option.transportCategory"> / {{option.transportCategory}}</span>
          <span v-if="option.tunnelRestrictionCode"> / {{option.tunnelRestrictionCode}}</span>
        </template>


        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            Loading...
          </li>
        </template>

      </v-select>
      <label class="required">{{ $t('cargo-item.adr.un-number.short') }}</label>
      <span class="invalid-feedback">{{ errors[0] }}</span>
    </div>
  </validation-provider>
  <e-form-text-input v-else :id="id" name="unNumber" :size="size" :placeholder="$t('cargo-item.adr.un-number.short')"
                     :rules="rules" disabled :value="value" />
</template>

<script>
import HazmatService from "@/services/hazmat.service"
import { ValidationProvider } from '@emons/emons-vue'

export default {
  name: "UnNumberSelect",
  components: { ValidationProvider },
  props: {
    id: {
      type: String,
      default: "unNumberSelect"
    },
    value: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    rules: {
      default: {}
    },
    pageSize: {
      type: Number,
      default: 25
    }
  },
  data() {
    return {
      isLoading: false,
      query: null,
      currentPage : 0,
      totalPages: -1,
      observer: null,
      typeAheadObserver: null,
      results: []
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  computed: {
    sizeClass() {
      return [this.size ? `form-control-${this.size}` : null]
    },
    hasNextPage() {
      return this.totalPages < 0 || this.currentPage < (this.totalPages - 1)
    },
    isRequired: function () {
      const rules = this.rules
      if (typeof rules === 'object' && rules.required) {
        return true
      } else if (typeof rules === 'string' && rules.includes('required')) {
        return true
      }
      return false
    },
    hasValue: function() {
      const value = this.value
      return value !== null
          && value !== undefined
          && ((typeof value === 'string' && value.length > 0) || (typeof value === 'number'))
    },
  },
  methods: {
    async search(page = 0) {
      this.$log('debug', 'UnNumberSelect.search() called...', this.query, page)
      //this.isLoading = true
      let results = null
      try {
        const response = await HazmatService.find(
            this.query,
            null,
            'dropDown',
            'unNumber',
            'asc',
            this.pageSize,
            page)
        if (response?.data) {
          results = response.data
        }
      } finally {
        //this.isLoading = false
      }
      return results
    },
    async loadPage(page) {
      const results = await this.search(page)
      let items
      if (results?.items) {
        this.totalPages = results?.page?.totalPages
        this.currentPage = results?.page?.number
        items = results.items
      } else {
        this.totalPages = -1
        this.currentPage = 0
        items = []
      }
      this.$log('DEBUG', 'results', this.results)
      if (this.currentPage > 0) {
        items.forEach(item => this.results.push(item))
      } else {
        this.results = items
      }
      this.$log('DEBUG', 'results', this.results)
    },
    mouseListener(event) {
      this.lockParentScroll(event.type == 'mouseenter')
    },
    lockParentScroll(lock) {
      this.$eventBus.$emit('scroll:lock', lock)
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
        // hacky solution for infinite scroll when navigating the dropdown with keyboard up/down:
        // - watch the vselect internal typeAheadPointer for changes
        // - if typeAheadPointer is at last result and more pages are available, then
        //   - fetch next results (= call regular infiniteScroll function
        //   - set typeAheadPointer to the value at function call, cause it gets reset when results are fetched
        this.typeAheadObserver = this.$watch("$refs.vselect.$data.typeAheadPointer",
            async (newValue) => {
              if (newValue == (this.results?.length - 1) && this.hasNextPage) {
                await this.infiniteScroll([{isIntersecting: true, target: this.$refs.load}])
                this.$refs.vselect.$data.typeAheadPointer = newValue
              }
            }
        )
      }

      // UGLY UGLY UGLY hack:
      // - add event listeners for mouseenter / mouseleave on dropdown menu using plain old js
      // - as vue-custom-scrollbar doesn't emit for these yet events yet
      await this.$nextTick() // dropdown-menu might not be in DOM yet
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.addEventListener('mouseenter', this.mouseListener, false)
      menu.addEventListener('mouseleave', this.mouseListener, false)
    },
    onClose() {
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.removeEventListener('mouseenter', this.mouseListener, false)
      menu.removeEventListener('mouseleave', this.mouseListener, false)
      this.lockParentScroll(false)

      this.observer.disconnect()
      if (!!this.typeAheadObserver) {
        this.typeAheadObserver()
        this.typeAheadObserver = null
      }
    },
    onInput($event) {
      this.$emit('input', $event)
    },
    async onSearch(searchString) {
      if (searchString != this.query) {
        this.query = searchString
        await this.loadPage(0)
      }
    },
    async infiniteScroll([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await this.loadPage(this.totalPages < 1?0:this.currentPage + 1)

        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    filterValidationClasses: function (classes) {
      const newClasses = classes
      if (!this.isRequired && !this.hasValue) {
        delete newClasses['is-valid']
      }
      return newClasses
    }
  }
}
</script>

<style>
.unNumber-dropdown .vs__dropdown-menu {
  overflow-x: hidden;
  min-width: 450px !important;
}

.un-number-select .vs__dropdown-menu {
  overflow-x: hidden;
  min-width: 750px !important;
}

.un-number-select .vs__search::placeholder,
.un-number-select .vs__dropdown-toggle {
  height: inherit!important;
  border: none;
  background-color: transparent !important;
}

.un-number-select.is-valid,
.was-validated .un-number-select:valid {
  /* 32 px (actions size) + original background position */
  background-position: right calc(14px + 0.3375em + .2625rem) center;
}

.un-number-select.is-invalid,
.was-validated .un-number-select:invalid {
  /* 14 px (actions size) + original background position */
  background-position: right calc(14px + 0.3375em + .2625rem) center;
}

.un-number-select.form-control {
  padding: unset !important;
}

.un-number-select.has-value.form-control-lg ~ label,
.un-number-select.vs--open.form-control-lg ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .825rem;
  color: #777;
}

.un-number-select.has-value.form-control-sm ~ label,
.un-number-select.vs--open.form-control-sm ~ label{
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .6rem;
  color: #777;
}

.un-number-select.has-value ~ label,
.un-number-select.vs--open ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .675rem;
  color: #778;
}

.un-number-select .vs__clear {
  line-height: 10px;
}

.vs--single.vs--open .vs__selected {
  position: absolute;
  top: 8px;
}

.vs--disabled .vs__search {
  background-color: transparent !important;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator {
  background-color: #e9ecef !important;
}

.un-number-select.form-control-lg .vs__selected,
.un-number-select.form-control-lg .vs__search {
  font-size: 1.1rem;
  line-height: 1.5;
  padding: .5rem 1rem;
}

.un-number-select.form-control-sm .vs__selected,
.un-number-select.form-control-sm .vs__search {
  font-size: .8rem;
  line-height: 1.2;
  padding: .45rem .25rem;
}

.un-number-select .vs__selected,
.un-number-select .vs__search {
  font-size: .9rem;
  line-height: 1.35;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.un-number-select.no-placeholder .vs__selected {
  margin: 0;
  position: absolute;
  top: 0
}

.un-number-select.no-placeholder .vs__search {
  margin: 0;
  position: relative;
}
</style>