import Holidays from "date-holidays"
//import {log} from "@emons/emons-vue"

function calculateDeliveryWindow(deliveryDateWindow, consigneeCountry, shippingDate) {
    if (deliveryDateWindow) {
        const start = shippingDate ? new Date(shippingDate) : new Date()
        start.setUTCHours(0)
        start.setUTCMinutes(0)
        start.setUTCSeconds(0)
        start.setUTCMilliseconds(0)

        let min = addWorkdays(start, deliveryDateWindow.min, consigneeCountry)
        let max = new Date(min.valueOf())
        if (deliveryDateWindow.max) {
            max = addWorkdays(min, deliveryDateWindow.max - deliveryDateWindow.min, consigneeCountry)
        } else {
            max.setMonth(start.getMonth() + 2)
        }

        min.setHours(0)
        min.setMinutes(0)
        min.setSeconds(0)
        min.setMilliseconds(0)

        max.setHours(23)
        max.setMinutes(59)
        max.setSeconds(59)
        max.setMilliseconds(999)

        return {
            min: min,
            max: max
        }
    } else {
        return false
    }
}

function stringToDate(dateStr) {
    let d = dateStr.split("-")
    return new Date(Date.UTC(d[0], d[1] - 1, d[2]))
}

function dateToString(date) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0]
}

function addWorkdays(startDate, workdays, countryCode) {
    let date = new Date(startDate.valueOf())
    let currentWorkdays = 0
    let daysOffset = 0;
    while(currentWorkdays <= workdays) {
        date.setMonth(startDate.getMonth())
        date.setDate(startDate.getDate() + daysOffset + currentWorkdays)
        if (date.getDay() === 0 || date.getDay() === 6 || isHoliday(date, countryCode)) {
            daysOffset++
        } else {
            currentWorkdays++
        }
    }
    return date
}

function isHoliday(date, countryCode) {
    //log('DEBUG', 'isHoliday(%s, %s)', date, countryCode)
    if (date.getDay() == 0 || date.getDay() == 6) {
        return true
    }
    if (!countryCode) {
        return false
    }
    let hd = new Holidays(countryCode, '', '', {types: ['public', 'bank']})
    return hd.isHoliday(date)
}

function differenceInWorkdays(d1, d2, countryCode) {
    let base, target
    if (d1.getTime() < d2.getTime()) {
        base = new Date(d1.getTime())
        target = new Date(d2.getTime())
    } else {
        base = new Date(d2.getTime())
        target = new Date(d1.getTime())
    }
    let difference = 0
    while(base.getTime() < target.getTime()) {
        base.setDate(base.getDate() + 1)
        if (!isHoliday(base, countryCode)) difference++
    }
    return difference
}

export { stringToDate, calculateDeliveryWindow, dateToString, isHoliday, addWorkdays, differenceInWorkdays }