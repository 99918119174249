var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-page', [_c('template', {
    slot: "content"
  }, [_c('validation-observer', {
    ref: "order_observer",
    attrs: {
      "vid": 'orderObserver-' + _vm.orderId,
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$formValues, _vm$formValues$consig;

        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty;
        return [_c('e-form-wizard', {
          attrs: {
            "steps": _vm.steps,
            "save-fn": _vm.saveOrder
          }
        }, [_c('e-wizard-step', {
          attrs: {
            "slot": "step1"
          },
          slot: "step1"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12 col-md-6"
        }, [_c('order-sender-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": true,
            "default-country": _vm.defaultCountry
          },
          on: {
            "input": function input($event) {
              return _vm.senderSelected($event);
            },
            "cleared": function cleared($event) {
              return _vm.$refs.senderDeliveryNotes.clear();
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('validation-provider', {
                attrs: {
                  "vid": _vm.orderId + '-sender.deliveryNotes'
                }
              }, [_c('delivery-notes-view', {
                ref: "senderDeliveryNotes",
                attrs: {
                  "id": _vm.orderId,
                  "editable": true,
                  "title": _vm.$t('shipment.sender.deliveryNotes'),
                  "add-defaults": _vm.formValues.state == 'NEW',
                  "sender": _vm.formValues.sender,
                  "product": _vm.formValues.product,
                  "filter": function filter(n) {
                    return n.identifier.startsWith('6');
                  }
                },
                model: {
                  value: _vm.formValues.deliveryNotes,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formValues, "deliveryNotes", $$v);
                  },
                  expression: "formValues.deliveryNotes"
                }
              })], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.formValues.sender,
            callback: function callback($$v) {
              _vm.$set(_vm.formValues, "sender", $$v);
            },
            expression: "formValues.sender"
          }
        })], 1), _c('div', {
          staticClass: "col-12 col-md-6 pt-4 pt-md-0"
        }, [_c('order-consignee-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": true,
            "default-country": _vm.defaultCountry
          },
          on: {
            "cleared": function cleared($event) {
              return _vm.$refs.consigneeDeliveryNotes.clear();
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('validation-provider', {
                attrs: {
                  "vid": _vm.orderId + '-consignee.deliveryNotes'
                }
              }, [_c('delivery-notes-view', {
                ref: "consigneeDeliveryNotes",
                attrs: {
                  "id": _vm.orderId,
                  "editable": true,
                  "title": _vm.$t('shipment.consignee.deliveryNotes'),
                  "add-defaults": _vm.formValues.state == 'NEW',
                  "consignee": _vm.formValues.consignee,
                  "product": _vm.formValues.product,
                  "filter": function filter(n) {
                    return !n.identifier.startsWith('6');
                  }
                },
                model: {
                  value: _vm.formValues.deliveryNotes,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formValues, "deliveryNotes", $$v);
                  },
                  expression: "formValues.deliveryNotes"
                }
              })], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.formValues.consignee,
            callback: function callback($$v) {
              _vm.$set(_vm.formValues, "consignee", $$v);
            },
            expression: "formValues.consignee"
          }
        })], 1), _c('div', {
          staticClass: "col-12 col-md-12 pt-4"
        }, [_c('order-invoice-recipient-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": true,
            "default-recipient": _vm.defaultInvoiceRecipient
          },
          model: {
            value: _vm.formValues.invoiceRecipient,
            callback: function callback($$v) {
              _vm.$set(_vm.formValues, "invoiceRecipient", $$v);
            },
            expression: "formValues.invoiceRecipient"
          }
        })], 1)])]), _c('e-wizard-step', {
          attrs: {
            "slot": "step2"
          },
          slot: "step2"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12 col-md-6"
        }, [_c('order-characteristics-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": true,
            "value": _vm.formValues,
            "shipping-date-offset": _vm.shippingDateOffset,
            "waiver-customer": _vm.waiverCustomer,
            "required-fields": _vm.requiredFields,
            "customer-currency": _vm.customerCurrency,
            "branch-currency": _vm.branchCurrency,
            "supported-currencies": _vm.supportedCurrencies,
            "default-term-national": _vm.defaultIncoTermNational,
            "default-term-international": _vm.defaultIncoTermInternational,
            "shipping-time-from": _vm.shippingTimeFrom,
            "shipping-time-until": _vm.shippingTimeUntil,
            "shipping-time-from-fr": _vm.shippingTimeFromFr,
            "shipping-time-until-fr": _vm.shippingTimeUntilFr
          }
        })], 1), _c('div', {
          staticClass: "col-12 col-md-6 pt-4 pt-md-0"
        }, [_c('order-product-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": true,
            "value": _vm.formValues
          }
        })], 1)])]), _c('e-wizard-step', {
          attrs: {
            "slot": "step3"
          },
          slot: "step3"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('order-cargo-list', {
          attrs: {
            "id": _vm.orderId,
            "editable": true,
            "value": _vm.formValues.cargoList,
            "consignee-country": (_vm$formValues = _vm.formValues) === null || _vm$formValues === void 0 ? void 0 : (_vm$formValues$consig = _vm$formValues.consignee) === null || _vm$formValues$consig === void 0 ? void 0 : _vm$formValues$consig.country,
            "additional-palette-swap-countries": _vm.additionalPaletteSwapCountries,
            "required-fields": _vm.requiredFields,
            "optional-fields": _vm.optionalFields
          }
        })], 1)]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pt-4"
        }, [_c('order-shipment-info', {
          attrs: {
            "id": _vm.orderId,
            "editable": true,
            "optional-fields": _vm.optionalFields,
            "mandatory-fields": _vm.mandatoryFields,
            "value": _vm.formValues.cargoList
          }
        })], 1)])])], 1)];
      }
    }])
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }