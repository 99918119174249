<template>
  <validation-observer vid="pickUpOrderObserver"
                       ref="pickUpOrder_observer"
                       v-slot="{ invalid, changed, dirty }"
                       slim>
    <b-modal :id="id"
             :title="$t('pick-up-order.title') + ' ' +  shipment.shipmentNumber + ' | ' + $t('pick-up-order.client') + ' ' + shipment.emonsCustomerId"
             @ok="sendPickUpOrder"
             size="lg">
      <template #default>
        <validation-provider name="pickUpOrder"
                             :vid="id + '-pickUpOrder'"
                             rules="required"
                             v-slot="{ classes, errors }"
                             slim>

          <div class="form-row" style="padding-bottom: 15px;">
            <div class="col-3"></div>
            <div class="col-6">
              <b-form-select id="type"
                             name="Auftragsart"
                             size="sm"
                             v-model="pickUpOrder.type">
                <option value="SPEDITIONSAUFTRAG">Speditionsauftrag</option>
                <option value="QUERTRANSPORT">Quertransport</option>
              </b-form-select>
            </div>
            <div class="col-3"></div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-partnerName'"
                                 name="Name Partner"
                                 rules="required"
                                 size="sm"
                                 :placeholder="'An'"
                                 v-model="pickUpOrder.partnerName"/>
            </div>
            <div class="col-6">
              <div class="float-right">
                <p class="form-control-plaintext"><strong>{{location.legalName}}</strong></p>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-partnerCC'"
                                 name="partnerCC"
                                 size="sm"
                                 :placeholder="'CC'"
                                 v-model="pickUpOrder.partnerCC"/>
            </div>
            <div class="col-6">
              <div class="float-right">
                <p class="form-control-plaintext">{{location.street}}</p>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-partnerAttentionOf'"
                                 name="partnerAttentionOf"
                                 size="sm"
                                 :placeholder="'z.Hd.'"
                                 v-model="pickUpOrder.partnerAttentionOf"/>
            </div>
            <div class="col-6">
              <div class="float-right">
                <p class="form-control-plaintext">{{location.country}}-{{location.zipCode}} {{location.city}}</p>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-partnerFax'"
                                 name="partnerFax"
                                 size="sm"
                                 :placeholder="'Fax'"
                                 v-model="pickUpOrder.partnerFax"/>
            </div>
            <div class="col-6"></div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-partnerEmail'"
                                 name="Email Partner"
                                 rules="required"
                                 size="sm"
                                 :placeholder="'E-Mail'"
                                 v-model="pickUpOrder.partnerEmail"/>
            </div>
            <div class="col-6">
              <e-form-text-input :id="id + '-locationContactName'"
                                 name="$t('address.consignee.contactName')"
                                 rules="required"
                                 size="sm"
                                 :placeholder="$t('address.consignee.contactName')"
                                 v-model="pickUpOrder.locationContactName"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-partnerEmail'"
                                 name="Email-CC Partner"
                                 size="sm"
                                 :placeholder="'CC-E-Mail'"
                                 v-model="pickUpOrder.partnerCCEmail"/>
            </div>
            <div class="col-6">
              <e-form-text-input :id="id + '-locationEmail'"
                                 name="$t('address.consignee.contactName')"
                                 rules="required"
                                 size="sm"
                                 :placeholder="$t('address.consignee.contactName') + ' ' + $t('address.email')"
                                 v-model="pickUpOrder.locationEmail"/>
            </div>
          </div>

          <div class="form-row" style="padding-top: 15px; padding-bottom: 5px;">
            <div class="col-6"><label for="sender-name" style="padding-top: 10px;"><strong>{{ $t('pick-up-order.consignor') }}</strong></label></div>
            <div class="col-6">
              <div class="form-row">
                <div class="col-6">
                  <label for="sender-name" style="padding-top: 10px;"><strong>{{ $t('pick-up-order.consignee') }}</strong></label>
                </div>
                <div class="col-6">
                  <button :id="'override-consignee'"
                          class="btn btn-sm btn-block btn-outline-danger"
                          @click="overrideConsignee()">{{$t('pick-up-order.override-consignee')}}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-sender.name'"
                                 name="name"
                                 rules="required"
                                 :placeholder="$t('address.name')"
                                 size="sm"
                                 v-model="pickUpOrder.sender.name"/>
            </div>
            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.name'"
                                 name="name"
                                 rules="required"
                                 :placeholder="$t('address.name')"
                                 size="sm"
                                 v-model="pickUpOrder.consignee.name"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-sender.additionalName'"
                                 name="additionalName"
                                 size="sm"
                                 :placeholder="$t('address.additionalName')"
                                 v-model="pickUpOrder.sender.additionalName"/>
            </div>
            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.additionalName'"
                                 name="additionalName"
                                 size="sm"
                                 :placeholder="$t('address.additionalName')"
                                 v-model="pickUpOrder.consignee.additionalName"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-sender.street'"
                                 name="street"
                                 size="sm"
                                 :placeholder="$t('address.street')"
                                 v-model="pickUpOrder.sender.street"/>
            </div>
            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.street'"
                                 name="street"
                                 size="sm"
                                 :placeholder="$t('address.street')"
                                 v-model="pickUpOrder.consignee.street"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <div class="form-row">
                <div class="col-3">
                  <e-form-text-input :id="id + '-sender.country'"
                                     name="country"
                                     size="sm"
                                     :placeholder="$t('address.country')"
                                     v-model="pickUpOrder.sender.country"/>
                </div>
                <div class="col-3">
                  <e-form-text-input :id="id + '-sender.zipCode'"
                                     name="zipCode"
                                     size="sm"
                                     :placeholder="$t('address.zipCode')"
                                     v-model="pickUpOrder.sender.zipCode"/>
                </div>
                <div class="col-6">
                  <e-form-text-input :id="id + '-sender.city'"
                                     name="city"
                                     size="sm"
                                     :placeholder="$t('address.city')"
                                     v-model="pickUpOrder.sender.city"/>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-row">
                <div class="col-3">
                  <e-form-text-input :id="id + '-sender.country'"
                                     name="country"
                                     size="sm"
                                     :placeholder="$t('address.country')"
                                     v-model="pickUpOrder.consignee.country"/>
                </div>
                <div class="col-3">
                  <e-form-text-input :id="id + '-sender.zipCode'"
                                     name="zipCode"
                                     size="sm"
                                     :placeholder="$t('address.zipCode')"
                                     v-model="pickUpOrder.consignee.zipCode"/>
                </div>
                <div class="col-6">
                  <e-form-text-input :id="id + '-sender.city'"
                                     name="city"
                                     size="sm"
                                     :placeholder="$t('address.city')"
                                     v-model="pickUpOrder.consignee.city"/>
                </div>
              </div>
            </div>

          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-sender.contactName'"
                                 name="contactName"
                                 size="sm"
                                 :placeholder="$t('address.consignee.contactName')"
                                 v-model="pickUpOrder.sender.contactName"/>
            </div>
            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.contactName'"
                                 name="contactName"
                                 size="sm"
                                 :placeholder="$t('address.consignee.contactName')"
                                 v-model="pickUpOrder.consignee.contactName"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-sender.phone'"
                                 name="phone"
                                 size="sm"
                                 :placeholder="$t('address.phone')"
                                 v-model="pickUpOrder.sender.phone"/>
            </div>
            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.phone'"
                                 name="phone"
                                 size="sm"
                                 :placeholder="$t('address.phone')"
                                 v-model="pickUpOrder.consignee.phone"/>
            </div>
          </div>

          <div class="form-row" style="margin-bottom: -10px;">
            <div class="col-6">
              <e-form-datepicker :id="id + '-shippingDate'"
                                 name="shippingDate"
                                 size="sm"
                                 :rules="{dateEqualOrAfter: currentDateAsString()}"
                                 :placeholder="$t('orders.choose-shipping-date')"
                                 :min="new Date()"
                                 :max="new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())"
                                 v-model="pickUpOrder.shippingDate"/>
            </div>
            <div class="col-6">
              <e-form-datepicker :id="id + '-deliveryDate'"
                                 name="deliveryDate"
                                 size="sm"
                                 :rules="{dateEqualOrAfter: currentDateAsString()}"
                                 :placeholder="$t('orders.choose-delivery-date')"
                                 :min="new Date()"
                                 :max="new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())"
                                 v-model="pickUpOrder.deliveryDate"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <div class="form-row">
                <div class="col-6">
                  <b-form-timepicker :id="id + '-shippingTimeFrom'"
                                     name="shippingTimeFrom"
                                     size="sm"
                                     minutes-step="15"
                                     :hour12="false"
                                     :placeholder="$t('time.from')"
                                     reset-button
                                     :label-no-time-selected="$t('time.not-selected')"
                                     :label-close-button="$t('controls.close')"
                                     :label-reset-button="$t('controls.reset')"
                                     v-model="pickUpOrder.shippingTimeFrom"/>
                </div>
                <div class="col-6">
                  <b-form-timepicker :id="id + '-shippingTimeUntil'"
                                     name="shippingTimeUntil"
                                     size="sm"
                                     minutes-step="15"
                                     :hour12="false"
                                     :placeholder="$t('time.to')"
                                     reset-button
                                     :label-no-time-selected="$t('time.not-selected')"
                                     :label-close-button="$t('controls.close')"
                                     :label-reset-button="$t('controls.reset')"
                                     v-model="pickUpOrder.shippingTimeUntil"/>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-row">
                <div class="col-6">
                  <b-form-timepicker :id="id + '-deliveryTimeFrom'"
                                     name="deliveryTimeFrom"
                                     size="sm"
                                     minutes-step="15"
                                     :hour12="false"
                                     :placeholder="$t('time.from')"
                                     reset-button
                                     :label-no-time-selected="$t('time.not-selected')"
                                     :label-close-button="$t('controls.close')"
                                     :label-reset-button="$t('controls.reset')"
                                     v-model="pickUpOrder.deliveryTimeFrom"/>
                </div>
                <div class="col-6">
                  <b-form-timepicker :id="id + '-deliveryTimeUntil'"
                                     name="deliveryTimeUntil"
                                     size="sm"
                                     minutes-step="15"
                                     :hour12="false"
                                     :placeholder="$t('time.to')"
                                     reset-button
                                     :label-no-time-selected="$t('time.not-selected')"
                                     :label-close-button="$t('controls.close')"
                                     :label-reset-button="$t('controls.reset')"
                                     v-model="pickUpOrder.deliveryTimeUntil"/>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row" style="padding-top: 5px;">
            <div class="col-12">
              <b-form-textarea type="text"
                               name="pickUpOrderText"
                               :id="id + '-pickUpOrderText'"
                               v-model="pickUpOrder.notes"
                               :placeholder="$t('pick-up-order.features-dimensions-markings')"
                               rows="3"
                               max-rows="6"/>
            </div>
          </div>

          <div class="form-row" style="padding-top: 10px;">
            <div class="col-12">
              <label for="customs-data"><strong>{{ $t('pick-up-order.customs-documents') }}</strong></label>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-customs-insuranceValue'"
                                 name="insuranceValue"
                                 size="sm"
                                 :placeholder="$t('order.insuranceValue')"
                                 :append="$t('currency.' + pickUpOrder.customs.insuranceValue.currency)"
                                 v-model="pickUpOrder.customs.insuranceValue.value"/>
            </div>
            <div class="col-6">
              <b-form-select id="defaultCurrency"
                             name="$t('pick-up-order.customs-documents')"
                             size="sm"
                             v-model="pickUpOrder.customs.documents">
                <option value="CLAIM">{{$t('pick-up-order.customs-documents.claim')}}</option>
                <option value="CREATE">{{$t('pick-up-order.customs-documents.create')}}</option>
                <option value="NOT_NECESSARY">{{$t('pick-up-order.customs-documents.not-necessary')}}</option>
              </b-form-select>
            </div>
          </div>

          <div class="form-row">
            <div class="col-3">
              <e-form-checkbox :id="id + '-aeex1'"
                               name="AE-EX1"
                               :placeholder="'AE-EX1'"
                               v-model="pickUpOrder.customs.aeex1"/>
            </div>
            <div class="col-3">
              <e-form-checkbox :id="id + '-eur1'"
                               name="EUR1"
                               :placeholder="'EUR1'"
                               v-model="pickUpOrder.customs.eur1"/>
            </div>
            <div class="col-3">
              <e-form-checkbox :id="id + '-tpaper'"
                               name="$t('pick-up-order.t-paper')"
                               :placeholder="$t('pick-up-order.t-paper')"
                               v-model="pickUpOrder.customs.tpaper"/>
            </div>
            <div class="col-3">
              <e-form-checkbox :id="id + '-commercialInvoice'"
                               name="$t('pick-up-order.commercial-invoice')"
                               :placeholder="$t('pick-up-order.commercial-invoice')"
                               v-model="pickUpOrder.customs.commercialInvoice"/>
            </div>
          </div>

          <div class="form-row" style="padding-top: 10px;">
            <div class="col-12">
              <label for="customs-data"><strong>{{ $t('pick-up-order.invoicing')}}</strong></label>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <e-form-checkbox :id="id + '-clearing-location'"
                               name="$t('pick-up-order.invoicing.according')"
                               :placeholder="$t('pick-up-order.invoicing.according')"
                               v-model="pickUpOrder.clearing.locationOkay"/>
              <e-form-select :id="id + '-clearing-location'"
                             style="padding-left: 25px; padding-top: 5px; width: 300px;"
                             name="location"
                             :rules="`${pickUpOrder.clearing.locationOkay ? 'required' : ''}`"
                             :placeholder="$t('customer.select-branch')"
                             v-model="pickUpOrder.clearing.location"
                             size="sm">

                <option v-for="(location, idx) in locations"
                        :key="idx"
                        :value="location">{{ location.name }}
                </option>

              </e-form-select>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <e-form-checkbox :id="id + '-clearing-credit'"
                               name="$t('pick-up-order.invoicing.credit')"
                               :placeholder="$t('pick-up-order.invoicing.credit')"
                               v-model="pickUpOrder.clearing.credit"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <e-form-checkbox :id="id + '-clearing-agreement'"
                               name="$t('pick-up-order.invoicing.agreement')"
                               :placeholder="$t('pick-up-order.invoicing.agreement')"
                               v-model="pickUpOrder.clearing.agreement"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <e-form-checkbox :id="id + '-clearing-centralInvoiceDepartment'"
                               name="$t('pick-up-order.invoicing.central')"
                               :placeholder="$t('pick-up-order.invoicing.central')"
                               v-model="pickUpOrder.clearing.centralInvoiceDepartment"/>
            </div>
          </div>

        </validation-provider>
      </template>
      <template #modal-footer="{ok, cancel, hide}">
        <b-button variant="secondary" @click="cancel">{{ $t('controls.abort') }}</b-button>
        <b-button variant="primary" @click="ok">PDF generieren</b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from '@emons/emons-vue'
import ShipmentService from "@/services/shipmentService";
import CountrySelect from "@/components/form-controls/CountrySelect.vue";
import CitySelect from "@/components/form-controls/CitySelect.vue";
import LocationService from "@/services/location.service"
import {dateToString} from "@/util/dateutils";

export default {
  name: "OrderPickUpModal",
  components: {CitySelect, CountrySelect, ValidationObserver, ValidationProvider},
  props: {
    id: {
      type: String,
      default: 'orderPickUpModal'
    },
    shipment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      location: {
        legalName: '',
        street: ''
      },
      locations: [], // fetched from server
      pickUpOrder: {
        type: 'SPEDITIONSAUFTRAG',
        partnerName: '',
        partnerCC: '',
        partnerAttentionOf: '',
        partnerFax: '',
        partnerEmail: '',
        partnerCCEmail: '',
        locationContactName: '',
        locationEmail: '',
        notes: '',
        customs: {
          documents: 'CLAIM'
        },
        clearing: {
          locationOkay: false,
          credit: false,
          agreement: false,
          centralInvoiceDepartment: false
        }
      }
    }
  },
  methods: {
    show() {
      LocationService
          .allPickUpOrderLocations('abbreviation', 'asc')
          .then(response => {
            this.locations = response.data.items
          })

      LocationService
          .getEntityDetails('/api/admin/locations/' + this.shipment.location.abbreviation)
          .then(response => {
            this.location.legalName = response.data.legalName
            this.location.additionalName = ''
            this.location.contactName = ''
            this.location.street = response.data.streetName + ' ' + response.data.streetNumber
            this.location.zipCode = response.data.zipCode
            this.location.city = response.data.city
            this.location.country = response.data.country
            this.location.phone = response.data.phone
          })

      this.pickUpOrder.locationContactName = this.$root.$options.keycloak?.tokenParsed.given_name + ' ' + this.$root.$options.keycloak?.tokenParsed.family_name
      this.pickUpOrder.locationEmail = this.$root.$options.keycloak?.tokenParsed.email

      this.pickUpOrder.shippingDate = this.shipment.shippingDate
      this.pickUpOrder.shippingTimeFrom = this.shipment.shippingTimeFrom
      this.pickUpOrder.shippingTimeUntil = this.shipment.shippingTimeUntil
      this.pickUpOrder.deliveryDate = this.shipment.deliveryDate
      this.pickUpOrder.deliveryTimeFrom = this.shipment.deliveryTimeFrom
      this.pickUpOrder.deliveryTimeUntil = this.shipment.deliveryTimeUntil
      this.pickUpOrder.sender = this.shipment.sender
      this.pickUpOrder.consignee = this.shipment.consignee
      this.pickUpOrder.customs.insuranceValue = this.shipment.insuranceValue

      this.$bvModal.show(this.id)
    },
    currentDateAsString() {
      return dateToString(new Date())
    },
    overrideConsignee() {
      if (this.location) {
        this.pickUpOrder.consignee.name = this.location.legalName
        this.pickUpOrder.consignee.additionalName = ''
        this.pickUpOrder.consignee.contactName = ''
        this.pickUpOrder.consignee.street = this.location.street
        this.pickUpOrder.consignee.zipCode = this.location.zipCode
        this.pickUpOrder.consignee.city = this.location.city
        this.pickUpOrder.consignee.country = this.location.country
        this.pickUpOrder.consignee.phone = this.location.phone
      }
    },
    sendPickUpOrder: async function () {
      this.$log("DEBUG", 'pick-up-order', this.pickUpOrder)
      try {
        await ShipmentService.sendPickUpOrder(this.shipment, this.pickUpOrder)
        //this.pickUpOrder = {}
      } catch (error) {
        this.$eventBus.$emit('backend-error', error)
      }
    }
  }
}
</script>

<style scoped>
.e-form-text-input-container {
  margin-bottom: 5px;
}
</style>