<template>
  <div class="card">
    <div class="card-header p-2">
      <label :for="id + 'productType'" class="mb-0">
        <a href="/Emons_Produktflyer.pdf" target="_blank">{{ $t('orders.product-characteristics') }}</a>
      </label>
    </div>
    <div class="card-body p-2">
      <div class="form-row">
        <div class="col-12">
          <e-form-select :id="id + '-product.identifier'" name="identifier"
                         :placeholder="$t('orders.choose-product')"
                         :disabled="!(editable && products?.length !== 0 && value.sender.zipCode && value.consignee.zipCode)"
                         v-model="selectedProduct">
            <option v-if="products" :value="product.identifier" v-for="(product, idx) in products" :key="idx">
              {{ product.name }}
            </option>
          </e-form-select>
        </div>
      </div>
      <div class="form-row">
        <div :class="value.product?.timeslot?'col-9':'col-12'"
             v-if="value.product && value.product.deliveryDateWindow">
          <e-form-datepicker v-if="!value.product.deliveryDateIsOptional" ref="deliveryDate" :id="id + '-deliveryDate'" name="deliveryDate"
                             :rules="{ required: true, withinDeliveryWindow: {deliveryWindow: deliveryWindow} }"
                             :placeholder="value.product.deliveryDateWindow.min == value.product.deliveryDateWindow.max?$t('orders.choose-shipping-date'):$t('orders.choose-delivery-date' + (!!value.product.deliveryDateIsOptional?'.optional':''))"
                             :min="deliveryWindow.min" :max="deliveryWindow.max"
                             :date-disabled-fn="isHoliday"
                             :disabled="!editable || value.product.deliveryDateWindow.min == value.product.deliveryDateWindow.max"
                             v-model="value.deliveryDate"/>
          <e-form-datepicker v-else ref="deliveryDate" :id="id + '-deliveryDate'" name="deliveryDate"
                             :rules="{ required: false, withinDeliveryWindow: {deliveryWindow: deliveryWindow} }"
                             :placeholder="value.product.deliveryDateWindow.min == value.product.deliveryDateWindow.max?$t('orders.choose-shipping-date'):$t('orders.choose-delivery-date.optional')"
                             :min="deliveryWindow.min" :max="deliveryWindow.max"
                             :date-disabled-fn="isHoliday"
                             :disabled="!editable || value.product.deliveryDateWindow.min == value.product.deliveryDateWindow.max"
                             v-model="value.deliveryDate"/>
        </div>

        <div class="col-3" v-if="value.product?.timeslot">
          <e-form-text-input :id="id + '-timeslot'" name="timeslot" disabled :value="$t('timeslot.' + value.product.timeslot)"/>
        </div>
      </div>
      <div class="form-row text-danger text-center" v-if="this.value.sender == null || this.value.consignee == null">
        <div class="col-12">{{ $t('product.senderAndConsigneeRequired') }}</div>
      </div>
      <div class="form-row alert alert-warning text-center" v-if="value.product?.region == 'EU' && value.product?.deliveryDateWindow && !value.product?.deliveryDateIsOptional">
        <div class="col-12">{{ $t('product.transitTimeShorteningPossible') }}</div>
      </div>
      <div class="form-row text-danger text-center" v-if="value.product?.requiresConsultation">
        <div class="col-12">{{ $t('product.requires-consultation') }}</div>
      </div>
      <div class="form-row text-danger text-center" v-if="notificationCodesFiltered">
        <div class="col-12" v-html="$t('product.consigneeAnnouncementFiltered')" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import {addWorkdays, calculateDeliveryWindow, dateToString, isHoliday} from "@/util/dateutils";
import {extend, validate} from '@emons/emons-vue'
import ProductService from "@/services/product.service"
import NotificationCodeService from "@/services/notificationCode.service";

extend('withinDeliveryWindow', {
  params: ['deliveryWindow'],
  validate(value, {deliveryWindow}) {
    if (value) {
      let date = new Date(value)
      return (date.getTime() >= deliveryWindow.min.getTime() && date.getTime() <= deliveryWindow.max.getTime())
    }
    return false
  }
})

export default {
  name: "OrderProductView",
  props: {
    value: {
      type: Object,
    },
    id: {
      type: String,
      default: 'orderProduct'
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedProductId: this.value.product ? this.value.product.identifier : null,
      notificationCodesFiltered: false
    }
  },
  asyncComputed: {
    async products() {
      if (!this.editable) {
        return this.value?.product?[this.value.product]:[]
      }

      const senderCountry = this.value?.sender?.country
      const senderZipCode = this.value?.sender?.zipCode
      const consigneeCountry = this.value?.consignee?.country
      const consigneeZipCode = this.value?.consignee?.zipCode

      // soll bei Änderungen diese Methode triggern
      const senderIsNeutral = this.value?.sender?.isNeutral

      if (senderCountry && senderZipCode && consigneeCountry && consigneeZipCode) {
        let senderValidation = await validate(senderZipCode, "required|zipCode:@senderCountry", {
          values: {
            senderCountry
          }
        })
        let consigneeValidation = await validate(consigneeZipCode, "required|zipCode:@consigneeCountry", {
          values: {
            consigneeCountry
          }
        })
        if (senderValidation.valid && consigneeValidation.valid) {

          let originIsCustomer = (this?.value?.sender?.emonsCustomerId?.length > 0)
          let originIsNeutral = this.value?.sender?.isNeutral ? true : false
          let destinationIsCustomer = (this?.value?.consignee?.emonsCustomerId?.length > 0)

          const response = await ProductService.findByOriginAndDestination(
              this,
              senderCountry,
              senderZipCode,
              originIsCustomer,
              originIsNeutral,
              consigneeCountry,
              consigneeZipCode,
              destinationIsCustomer)
              //new Date(shippingDate))
          this.$log(
              'DEBUG',
              //"findByOriginAndDestination(this, %s, %s, %s, %s, %s, %s, %s)",
              "findByOriginAndDestination(this, %s, %s, %s, %s, %s, %s)",
              senderCountry,
              senderZipCode,
              originIsCustomer,
              originIsNeutral,
              consigneeCountry,
              consigneeZipCode,
              destinationIsCustomer,
              //new Date(shippingDate),
              response?.data?.items)
          const products = response?.data?.items
          if (!this.selectedProduct) {
            this.value.product = products.find(p => p.identifier == 'P01')
            this.selectedProduct = this.value.product?.identifier
            this.$nextTick(() => this.selectedProduct = products.find(p => p.identifier == 'P01')?.identifier)
          }
          return products
        }
      }
    }
  },
  computed: {
    selectedProduct: {
      get: function () {
        /*if (!this.selectedProductId) {
          this.selectedProduct = 'P01'
        }
         */
        return this.selectedProductId
      },
      set: function (newValue) {
        this.selectedProductId = newValue
        if (this.products) {
          this.$set(this.value, 'product', this.products.find(p => p.identifier == newValue))
          //this.value.product = this.products.find(p => p.identifier == newValue)
        }
      }
    },
    // todo: check move to non-computed methods
    deliveryWindow: function () {
      const deliveryDateWindow = this.value?.product?.deliveryDateWindow
      const consigneeCountry = this?.value?.consignee?.country
      const shippingDate = this.value?.shippingDate

      return calculateDeliveryWindow(deliveryDateWindow, consigneeCountry, shippingDate)
    }
  },
  methods: {
    isHoliday: function (ymd, date) {
      return isHoliday(date, this?.value?.consignee?.country)
    },
    filterInvalidDeliveryNotes() {
      if (!this.value.product || this.value.product.identifier == "P01") {
        this.notificationCodesFiltered = false
      }
      const lengthBefore = this.value.deliveryNotes?.length
      const newNotes = this.value.deliveryNotes.filter(
          note => NotificationCodeService.isSelectable(note, this.value.product)
      )
      const lengthAfter = newNotes?.length
      if (lengthBefore != lengthAfter) {
        this.notificationCodesFiltered = true
      }
      this.$set(this.value, "deliveryNotes", newNotes)
    }
  },
  watch: {
    "value": function (newVal, oldVal) { // this is prop's watch
      this.selectedProduct = newVal.product ? newVal.product.identifier : null
    },
    "value.shippingDate": function (newVal, oldVal) {
      this.$root.$log("debug", 'shipping date changed from %s to %s', oldVal, newVal, this.value.product)
      if (!this.value.product || !this.value.product.deliveryDateWindow || newVal == null) {
        this.value.deliveryDate = null
      } else if (this.value.product.deliveryDateWindow.min == this.value.product.deliveryDateWindow.max) {
        this.value.deliveryDate = dateToString(this.deliveryWindow.min)
      }

      // use nextTick because validator changes after rerender
      let that = this
      Vue.nextTick().then(function () {
        if (that.value.deliveryDate != null) {
          that.$refs['deliveryDate'].$refs['validator'].validate()
        }
      })
    },
    "value.product": function (newVal, oldVal) {
      this.$root.$log("debug", 'product changed', oldVal, newVal)
      if (!newVal || !newVal.deliveryDateWindow || this.value.shippingDate == null) {
        this.value.deliveryDate = null
      } else if (newVal.deliveryDateWindow.min == newVal.deliveryDateWindow.max) {
        this.$log("DEBUG", "fixed delivery window ", this.deliveryWindow.min)
        this.value.deliveryDate = dateToString(this.deliveryWindow.min)
      } else if (oldVal && oldVal.deliveryDateWindow
          && (newVal.deliveryDateWindow.min != oldVal.deliveryDateWindow.min
              || newVal.deliveryDateWindow.max != oldVal.deliveryDateWindow.max)) {
        this.value.deliveryDate = null
      }
      this.filterInvalidDeliveryNotes()

      // use nextTick because rerender etc. affect focus
      let that = this
      Vue.nextTick().then(function () {
        // focus delivery date
        if (that.value.deliveryDate == null && that.$refs['deliveryDate'] && !that.$refs['deliveryDate'].disabled) {
          that.$refs['deliveryDate'].$refs['datePicker'].focus()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>