var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('myemons.about')) + " ")];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn() {
        return [_c('ul', [_c('li', [_vm._v("MyEmons Frontend Version: " + _vm._s(_vm.$root.$options.version))]), _c('li', [_vm._v("MyEmons Backend Version: " + _vm._s(_vm.backendVersion))])])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn(_ref) {
        var ok = _ref.ok;
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return ok();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.ok')))])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }