<template>
  <validation-observer ref="observer"
                       v-slot="{ invalid, changed, dirty, failed, handleSubmit }" slim>

    <form @submit.prevent="handleSubmit(onSubmit)">

      <b-tabs fill active-nav-item-class="font-weight-bold text-light bg-primary" nav-class="border">

        <b-tab no-body :title="$t('location.data')">

          <div class="row">

            <div class="col-md-6">

              <div v-if="entity.gln"
                   class="form-group row">
                <label :for="id + '-location-infos-label'" class="col-4 col-form-label">{{ $t('location.sscc') }}</label>

                <div id="input-label-infos"
                     class="col-8">

                  <b-input-group>

                    <b-input-group-prepend is-text>
                      <span>{{ $t('location.sscc.prefix') }}</span>
                    </b-input-group-prepend>

                    <b-form-input type="text"
                                  v-model="entity.ssccPrefix">
                    </b-form-input>

                  </b-input-group>

                  <b-input-group>

                    <b-input-group-prepend is-text>
                      <span>{{ $t('location.sscc.gln') }}</span>
                    </b-input-group-prepend>

                    <b-form-input type="text"
                                  v-model="entity.gln">
                    </b-form-input>

                  </b-input-group>

                  <b-input-group>

                    <b-input-group-prepend is-text>
                      <span>{{ $t('location.sscc.number-range') }}</span>
                    </b-input-group-prepend>

                    <b-form-input type="text"
                                  disabled
                                  :value="entity.ssccCounterMin">
                    </b-form-input>

                    <b-input-group-prepend is-text
                                           style="width: 75px;margin-left: -1px;">
                      <span>{{ $t('range.to') }}</span>
                    </b-input-group-prepend>

                    <b-form-input type="text"
                                  disabled
                                  :value="entity.ssccCounterMax">
                    </b-form-input>

                  </b-input-group>


                </div>
              </div>

            </div>

          </div>

        </b-tab>

        <b-tab no-body :title="$t('location.master-data')">

          <div class="row">

            <div class="col-6">

              <div class="form-group row">
                <label :for="id + '-location-abbreviation'" class="col-3 col-form-label">{{ $t('location.abbreviation') }}</label>
                <div class="col-9">
                  <e-form-text :id="id + '-location-abbreviation'" :value="entity.abbreviation"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-location-name'" class="col-3 col-form-label">{{ $t('location.name') }}</label>
                <div class="col-9">
                  <e-form-text :id="id + '-location-name'" :value="entity.name"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-location-streetName'" class="col-3 col-form-label">{{ $t('address.street') }}</label>
                <div class="col-9">
                  <e-form-text :id="id + '-location-streetName'"
                               :value="entity.streetName"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-location-streetNumber'" class="col-3 col-form-label">{{ $t('address.street-number') }}</label>
                <div class="col-9">
                  <e-form-text :id="id + '-location-streetNumber'"
                               :value="entity.streetNumber"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-location-zipCode'" class="col-3 col-form-label">{{ $t('address.zipCode') }}</label>
                <div class="col-9">
                  <e-form-text :id="id + '-location-zipCode'" :value="entity.zipCode"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-location-city'" class="col-3 col-form-label">{{ $t('address.city') }}</label>
                <div class="col-9">
                  <e-form-text :id="id + '-location-city'" :value="entity.city"/>
                </div>
              </div>

              <div class="form-group row">
                <label :for="id + '-location-country'" class="col-3 col-form-label">{{ $t('address.country') }}</label>
                <div class="col-9">
                  <e-form-text :id="id + '-location-country'" :value="entity.country"/>
                </div>
              </div>

            </div>

            <div class="col-6">

              <div v-if="!!entity?._links?.esv?.href"
                   class="form-group row">
                <label :for="id + '-location-esv-url'" class="col-3 col-form-label">{{ $t('location.esv.link') }}</label>
                <div class="col-9">
                  <strong>
                    <a :id="id + '-location-esv-url'" :href="entity._links.esv.href"
                       target="_blank"
                       style="position:relative; top: 5px;">
                      <b-icon icon="link" font-scale="1.5"/>
                    </a>
                  </strong>
                </div>
              </div>

              <div v-if="entity.syncDate"
                   class="form-group row">
                <label :for="id + '-location-sync-date'" class="col-3 col-form-label">{{ $t('location.last-sync') }}</label>
                <div class="col-9">
                  <e-form-text :id="id + '-location-sync-date'" :value="entity.syncDate"/>
                </div>
              </div>

            </div>

          </div>

        </b-tab>

        <b-tab no-body :title="$t('location.email-addresses')">
          <div class="row">

            <div class="col">
              <div class="form-group row mb-1">
                <label :for="id + '-location-announcementEmail'" class="col-3 col-form-label">{{ $t('location.email.shipping-notices') }}</label>
                <validation-provider :vid="id + '-location-announcementEmail'"
                                     name="announcementEmail"
                                     :disabled="!canModify"
                                     rules="email" v-slot="{ classes, errors }" slim>
                  <div class="col-8">
                    <div :class="['input-tags'].concat(classes)">
                      <b-form-tags :input-id="id + '-location-announcementEmail'" separator=","
                                   :placeholder="$t('email.add')"
                                   :add-button-text="$t('controls.add')"
                                   v-model="entity.announcementEmail" :disabled="!canModify()"/>
                    </div>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
              <div class="form-group row mb-1">
                <label :for="id + '-location-inquiryEmail'" class="col-3 col-form-label">{{ $t('location.email.requests') }}</label>
                <validation-provider :vid="id + '-location-inquiryEmail'"
                                     name="inquiryEmail"
                                     :disabled="!canModify"
                                     rules="email" v-slot="{ classes, errors }" slim>
                  <div class="col-8">
                    <div :class="['input-tags'].concat(classes)">
                      <b-form-tags :input-id="id + '-location-inquiryEmail'" separator=","
                                   :placeholder="$t('email.add')"
                                   :add-button-text="$t('controls.add')"
                                   v-model="entity.inquiryEmail" :disabled="!canModify()"/>
                    </div>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
              <div class="form-group row mb-1">
                <label :for="id + '-location-cancellationEmail'" class="col-3 col-form-label">{{ $t('location.email.cancellation') }}</label>
                <validation-provider :vid="id + '-location-cancellationEmail'"
                                     name="cancellationEmail"
                                     :disabled="!canModify"
                                     rules="email" v-slot="{ classes, errors }" slim>
                  <div class="col-8">
                    <div :class="['input-tags'].concat(classes)">
                      <b-form-tags :input-id="id + '-location-cancellationEmail'" separator=","
                                   :placeholder="$t('email.add')"
                                   :add-button-text="$t('controls.add')"
                                   v-model="entity.cancellationEmail" :disabled="!canModify()"/>
                    </div>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
              <div class="form-group row mb-1">
                <label :for="id + '-location-belogEmail'" class="col-3 col-form-label">{{ $t('location.email.belog') }}</label>
                <validation-provider :vid="id + '-location-belogEmail'"
                                     name="belogEmail"
                                     :disabled="!canModify"
                                     rules="email" v-slot="{ classes, errors }" slim>
                  <div class="col-8">
                    <div :class="['input-tags'].concat(classes)">
                      <b-form-tags :input-id="id + '-location-belogEmail'" separator=","
                                   :placeholder="$t('email.add')"
                                   :add-button-text="$t('controls.add')"
                                   v-model="entity.belogEmail" :disabled="!canModify()"/>
                    </div>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
              <div class="form-group row mb-1">
                <label :for="id + '-location-insuranceEmail'" class="col-3 col-form-label">{{ $t('location.email.insurance') }}</label>
                <validation-provider :vid="id + '-location-insuranceEmail'"
                                     name="insuranceEmail"
                                     :disabled="!canModify"
                                     rules="email" v-slot="{ classes, errors }" slim>
                  <div class="col-8">
                    <div :class="['input-tags'].concat(classes)">
                      <b-form-tags :input-id="id + '-location-insuranceEmail'" separator=","
                                   :placeholder="$t('email.add')"
                                   :add-button-text="$t('controls.add')"
                                   v-model="entity.insuranceEmail" :disabled="!canModify()"/>
                    </div>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
              <div class="form-group row mb-1">
                <label :for="id + '-location-registrationEmail'" class="col-3 col-form-label">{{ $t('location.email.registration') }}</label>
                <validation-provider :vid="id + '-location-registrationEmail'"
                                     name="registrationEmail"
                                     :disabled="!canModify"
                                     rules="email" v-slot="{ classes, errors }" slim>
                  <div class="col-8">
                    <div :class="['input-tags'].concat(classes)">
                      <b-form-tags :input-id="id + '-location-registrationEmail'" separator=","
                                   :placeholder="$t('email.add')"
                                   :add-button-text="$t('controls.add')"
                                   v-model="entity.registrationEmail" :disabled="!canModify()"/>
                    </div>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
            </div>
            <div class="col">
            </div>
          </div>
        </b-tab>

        <b-tab :title="'NV-Gebiet' + (entity.zones && entity.zones.page ? ' (' + entity.zones.page.totalElements + ')' : '')"
               class="tab-content"
               @click="findZones()"
               lazy>

          <div class="p-1 pl-0 pr-0 row">

            <div class="col-md-1">
              <b><span>{{ $t('address.country') }}</span></b>
            </div>

            <div class="col-md-2">
              <b><span>{{ $t('address.zipCode') }}</span></b>
            </div>

            <div class="col-md-3">
              <b><span>{{ $t('address.city') }}</span></b>
            </div>

            <div class="col-md-1">
              <b><span>Collection</span></b>
            </div>

            <div class="col-md-1">
              <b><span>Distribution</span></b>
            </div>

            <div class="col-md-2">
              <b><span>Uhrzeitprodukte</span></b>
            </div>

            <div class="col-md-2">

              <b-input-group class="search-embedded-list pull-right"
                             size="sm">

                <b-input-group-prepend>
                  <span class="input-group-text">
                    <b-icon icon="search" style="width: 25px;"/>
                  </span>
                </b-input-group-prepend>

                <b-form-input class="search-input"
                              size="sm"
                              type="text"
                              v-model="entity.searchTextZone"
                              v-debounce:1000ms="findZones">
                </b-form-input>

                <b-input-group-append v-if="entity.searchTextZone">
                   <span class="input-group-text">
                     <b-icon icon="x"
                             style="width: 25px;"
                             @click="entity.searchTextZone = null; findZones();"/>
                  </span>
                </b-input-group-append>

              </b-input-group>

            </div>

          </div>

          <div class="scroll-embedded-header-line"></div>

          <scrollbar v-if="entity.zones && entity.zones.items.length > 0"
                     class="scroll-embedded-content"
                     :style="{'height': '300px', 'width': '100%'}"
                     :settings="scrollSettings"
                     v-on:ps-y-reach-end="nextPageZones()">

            <div v-for="(zone, index) in entity.zones.items"
                 class="p-1 pl-0 pr-0 row">

              <div class="col-md-1 clip-grid-text">
                <span>{{ zone.zipCodeAreaInterval.start.alpha2Code }}</span>
              </div>

              <div class="col-md-2 clip-grid-text">
                <span>{{ zone.zipCodeAreaInterval.start.zipCode }}</span>
                <span v-if="zone.zipCodeAreaInterval.end && zone.zipCodeAreaInterval.start.zipCode !== zone.zipCodeAreaInterval.end.zipCode">&nbsp;-&nbsp;</span>
                <span v-if="zone.zipCodeAreaInterval.end && zone.zipCodeAreaInterval.start.zipCode !== zone.zipCodeAreaInterval.end.zipCode">{{ zone.zipCodeAreaInterval.end.zipCode }}</span>
              </div>

              <div class="col-md-3 clip-grid-text">
                <span>{{ zone.zipCodeAreaInterval.start.city }}</span>
                <span v-if="zone.zipCodeAreaInterval.end && zone.zipCodeAreaInterval.start.city !== zone.zipCodeAreaInterval.end.city">&nbsp;-&nbsp;</span>
                <span v-if="zone.zipCodeAreaInterval.end && zone.zipCodeAreaInterval.start.city !== zone.zipCodeAreaInterval.end.city">{{ zone.zipCodeAreaInterval.end.city }}</span>
              </div>

              <div class="col-md-1 clip-grid-text">
                <span v-if="zone.collection">{{ zone.collection.relation }}</span>
              </div>

              <div class="col-md-1 clip-grid-text">
                <span v-if="zone.distribution">{{ zone.distribution.relation }}</span>
              </div>

              <div class="col-md-4">
                <div style="display: table; width: 100%; table-layout: fixed;">
                  <div style="display: table-cell; width: 6.666666%;"
                       v-for="(value, key) in zone.productMap">
                    <strong><span :class="{'text-green': value === 'YES', 'text-red': value === 'NO'}">{{key}}</span></strong>
                  </div>
                </div>
              </div>

            </div>

          </scrollbar>

        </b-tab>

        <b-tab :title="'Routing' + (entity.routings && entity.routings.page ? ' (' + entity.routings.page.totalElements + ')' : '')"
               class="tab-content"
               @click="findRoutings()"
               lazy>

          <div class="p-1 pl-0 pr-0 row">

            <div class="col-md-1">
              <b><span>{{ $t('address.country') }}</span></b>
            </div>

            <div class="col-md-2">
              <b><span>{{ $t('address.zipCode') }}</span></b>
            </div>

            <div class="col-md-3">
              <b><span>{{ $t('address.city') }}</span></b>
            </div>

            <div class="col-md-1">
              <b><span>Collection</span></b>
            </div>

            <div class="col-md-1">
              <b><span>Distribution</span></b>
            </div>

            <div class="col-md-2">
              <b><span>Uhrzeitprodukte</span></b>
            </div>

            <div class="col-md-2">

              <b-input-group class="search-embedded-list pull-right"
                             size="sm">

                <b-input-group-prepend>
                  <span class="input-group-text">
                    <b-icon icon="search" style="width: 25px;"/>
                  </span>
                </b-input-group-prepend>

                <b-form-input class="search-input"
                              size="sm"
                              type="text"
                              v-model="entity.searchTextRouting"
                              v-debounce:1000ms="findRoutings">
                </b-form-input>

                <b-input-group-append v-if="entity.searchTextRouting">
                   <span class="input-group-text">
                     <b-icon icon="x"
                             style="width: 25px;"
                             @click="entity.searchTextRouting = null; findRoutings();"/>
                  </span>
                </b-input-group-append>

              </b-input-group>

            </div>

          </div>

          <div class="scroll-embedded-header-line"></div>

          <scrollbar v-if="entity.routings && entity.routings.items.length > 0"
                     class="scroll-embedded-content"
                     :style="{'height': '300px', 'width': '100%'}"
                     :settings="scrollSettings"
                     v-on:ps-y-reach-end="nextPageRoutings()">

            <div v-for="(routing, index) in entity.routings.items"
                 class="p-1 pl-0 pr-0 row">

              <div class="col-md-1 clip-grid-text">
                <span>{{ routing.zipCodeAreaInterval.start.alpha2Code }}</span>
              </div>

              <div class="col-md-2 clip-grid-text">
                <span>{{ routing.zipCodeAreaInterval.start.zipCode }}</span>
                <span v-if="routing.zipCodeAreaInterval.end && routing.zipCodeAreaInterval.start.zipCode !== routing.zipCodeAreaInterval.end.zipCode">&nbsp;-&nbsp;</span>
                <span v-if="routing.zipCodeAreaInterval.end && routing.zipCodeAreaInterval.start.zipCode !== routing.zipCodeAreaInterval.end.zipCode">{{ routing.zipCodeAreaInterval.end.zipCode }}</span>
              </div>

              <div class="col-md-3 clip-grid-text">
                <span>{{ routing.zipCodeAreaInterval.start.city }}</span>
                <span v-if="routing.zipCodeAreaInterval.end && routing.zipCodeAreaInterval.start.city !== routing.zipCodeAreaInterval.end.city">&nbsp;-&nbsp;</span>
                <span v-if="routing.zipCodeAreaInterval.end && routing.zipCodeAreaInterval.start.city !== routing.zipCodeAreaInterval.end.city">{{ routing.zipCodeAreaInterval.end.city }}</span>
              </div>

              <div class="col-md-1">
                <span v-if="routing.collection">{{ routing.collection.relation }}</span>
              </div>

              <div class="col-md-1">
                <span v-if="routing.distribution">{{ routing.distribution.relation }}</span>
              </div>

              <div class="col-md-4">
                <div style="display: table; width: 100%; table-layout: fixed;">
                  <div style="display: table-cell; width: 6.666666%;"
                       v-for="(value, key) in routing.productMap">
                    <strong><span :class="{'text-green': value === 'YES', 'text-red': value === 'NO'}">{{key}}</span></strong>
                  </div>
                </div>
              </div>

            </div>

          </scrollbar>

        </b-tab>

        <b-tab class="tab-content">

          <template #title>
            <span>Änderungen</span>
          </template>

          <span class="text-center">TODO</span>

        </b-tab>

      </b-tabs>

      <div class="row">

        <div class="col-md-12">
          <div class="form-group row justify-content-end">

            <div class="col-sm-2">
              <button id="abortButton"
                      class="btn btn-sm btn-block btn-outline-secondary"
                      :disabled="!(changed || dirty)"
                      @click.prevent="abortEdit()">
                {{ $t('controls.abort') }}
              </button>
            </div>

            <div class="col-sm-2">
              <button id="saveButton"
                      class="btn btn-sm btn-block btn-outline-primary"
                      :disabled="!(changed || dirty) || invalid"
                      @click.prevent="saveEntity()">
                {{ $t('controls.save') }}
              </button>
            </div>

          </div>
        </div>

      </div>

    </form>

  </validation-observer>
</template>

<script>

import service from "@/services/locationDetails.service";
import {ValidationObserver, ValidationProvider} from '@emons/emons-vue'
import Scrollbar from 'vue-custom-scrollbar'
import vueDebounce from 'vue-debounce'
import Vue from "vue";

Vue.use(vueDebounce)

export default {
  name: "LocationView",
  components: {ValidationObserver, ValidationProvider, 'scrollbar': Scrollbar},
  props: {
    value: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      scrollSettings: {
        suppressScrollX: true
      },
      entity: JSON.parse(JSON.stringify(this.value))
    }
  },
  methods: {
    canModify() {
      return !!this?.entity?._links?.modify
    },
    abortEdit() {
      this.entity = JSON.parse(JSON.stringify(this.value))
      this.$refs.observer.reset()
    },
    async saveEntity() {
      this.$emit('enableSpinner');
      const saved = await service.save(this.entity)
      this.$emit('disableSpinner')
      this.$bvModal.msgBoxOk(this.$t('location.saved', {location: saved?.data?.abbreviation}), {
        title: this.$t('location.saved.short'),
        titleClass: 'pl-2',
        centered: true,
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
      }).then(
          () => this.$emit('save', saved.data)
      )
    },
    findZones: async function () {
      this.$log('debug', 'findZones() called...');
      if (this.entity._links['zones']) {
        this.$emit('enableSpinner');
        await service
            .findZones(this.entity, this.entity.searchTextZone, 50, 'zipCode', 'asc')
            .then(response => {

              if (response['data'])
                Vue.set(this.entity, 'zones', response['data'])
              else
                Vue.set(this.entity, 'zones', {
                  items: []
                })

              this.$emit('disableSpinner');
            })
      }
    },
    nextPageZones: async function () {
      this.$log('debug', 'nextPageZones() called...');
      if (this.entity.zones.links && this.entity.zones.links.next) {
        this.$emit('enableSpinner');
        await service
            .nextPage(this.entity.zones.links.next.href, 'zones')
            .then(response => {
              Vue.set(this.entity.zones, 'links', response['data']['links'])
              Vue.set(this.entity.zones, 'page', response['data']['page'])
              Vue.set(this.entity.zones, 'items', this.entity.zones.items.concat(response['data']['items']))
              this.$emit('disableSpinner');
            })
      }
    },
    findRoutings: async function () {
      this.$log('debug', 'findRouting() called...');
      if (this.entity._links['routings']) {
        this.$emit('enableSpinner');
        await service
            .findRoutings(this.entity, this.entity.searchTextRouting, 50, 'zipCode', 'asc')
            .then(response => {
              console.log(response)

              if (response['data'])
                Vue.set(this.entity, 'routings', response['data'])
              else
                Vue.set(this.entity, 'routings', {
                  items: []
                })

              this.$emit('disableSpinner');
            })
      }
    },
    nextPageRoutings: async function () {
      this.$log('debug', 'nextPageRouting() called...');
      if (this.entity.routings.links && this.entity.routings.links.next) {
        this.$emit('enableSpinner');
        await service
            .nextPage(this.entity.routings.links.next.href, 'routings')
            .then(response => {
              Vue.set(this.entity.routings, 'links', response['data']['links'])
              Vue.set(this.entity.routings, 'page', response['data']['page'])
              Vue.set(this.entity.routings, 'items', this.entity.routings.items.concat(response['data']['items']))
              this.$emit('disableSpinner');
            })
      }
    }
  }
}
</script>

<style scoped>

.input-tags.is-invalid {
  background-color: rgba(192, 7, 25, .05);
  /*
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3E%3C/svg%3E");
  background-size: calc(.675em + .525rem) calc(.675em + .525rem);
  background-repeat: no-repeat;
  background-position: right calc(.3375em + .2625rem) center;
   */
  border-style: solid;
  border-color: #d9534f;
}

.input-tags > * {
  background: transparent;
}

.input-tags {
  border-width: 1px;
  border-radius: .2rem;
}

.form-group {
  margin-bottom: 0;
}

.tab-pane {
  padding-top: 20px;
  padding-left: 20px;
}

.tabs >>> div.tab-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tabs >>> .nav-link {
  color: #000000;
}

.tabs >>> .nav-link.active {
  font-weight: bold;
}

.tabs >>> .nav-item.show .nav-link,
.tabs >>> .nav-link.active {
  background-color: transparent;
}

.scroll-embedded-header-line {
  border-bottom: 1px solid #dee2e6;
  margin-right: 5px;
}

.scroll-embedded-content {
  position: relative;
  top: 10px;
  overflow: hidden;
}

#input-label-infos .input-group {
  padding-bottom: 5px;
}

#input-label-infos .input-group-prepend:first-child {
  width: 225px;
}

#input-label-infos .input-group-prepend .input-group-text {
  width: 100%;
  display: block;
  text-align: center;
  font-weight: bold;
}

#input-label-infos .form-control:disabled {
  text-align: center;
}

.search-embedded-list {
  margin-top: -15px;
}

.search-embedded-list [class^="fa-"], [class*=" fa-"] {
  display: inline-block;
  width: 100%;
}

.search-embedded-list .search-input:focus {
  box-shadow: none;
}

.text-green {
  color: #00bf00;
}

.text-red {
  color: #cf0000;
}

</style>