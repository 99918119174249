import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const rel = 'locations'
const path = '/api/admin/locations'
const schema = {
    abbreviation: null,
    name: null,
    address: {
        streetName: null,
        streetNumber: null,
        zipCode: null,
        city: null,
        country: null
    },
    barcodeInfos : {
        globalLocationPrefix: null,
        globalLocationNumber: null,
        numberInterval: {
            min: null,
            max: null
        }
    },
    announcementEmail: null,
    inquiryEmail: null,
    cancellationEmail: null,
    belogEmail: null,
    insuranceEmail: null,
    registrationEmail: null
}
const header = {
    abbreviation: {
        name: 'Niederlassung',
        col: 'col-2',
        property: 'abbreviation'
    },
    name: {
        name: 'Bezeichnung',
        col: 'col-3',
        property: 'name'
    }
}
const filter = null;
const sync = null;
const initialSort = 'abbreviation'
const projection = 'list'
const create = false

const Service = {
    getHeader: function () {
        return header
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function() {
        return initialSort
    },
    getProjection: function() {
        return projection
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size) {
        try {
            return ApiService.get(path, {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    nextPage: async function (link, tmpRel) {
        let theRel = tmpRel != null ? tmpRel : rel;
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][theRel]) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][theRel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    findZones: async function (location, query, size, sortProperty, sortDirection) {
        try {
            return ApiService.get(this.cleanLink(location._links['zones'].href), {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded']['zones']) {
                            return {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded']['zones']
                            };
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + 'zones' + ':', error)
        }
    },
    findRoutings: async function (location, query, size, sortProperty, sortDirection) {
        try {
            return ApiService.get(this.cleanLink(location._links['routings'].href), {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded']['routings']) {
                            return {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded']['routings']
                            };
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + 'routings' + ':', error)
        }
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    },
    save: async function (location) {
        if (location?._links?.self) {
            return ApiService.put(location._links.self.href, location).then(
                response => {
                    if (response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        } else {
            return ApiService.post(path, location).then(
                response => {
                    if (response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        }
    },
}

export default Service