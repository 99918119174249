import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const rel = 'mailPersistenceEvents'
const schema = {
    contextId: null,
    emonsCustomerId: null,
    publicationDate: null,
    multiUsage: null,
    recipients: null
}
const header = {
    contextId: {
        name: 'Sendungsnummer',
        col: 'col-2',
        property: 'contextId'
    },
    emonsCustomerId: {
        name: 'Kundennummer',
        col: 'col-2',
        property: 'emonsCustomerId'
    },
    publicationDate: {
        name: 'Datum',
        col: 'col-2',
        property: 'publicationDate'
    },
    recipients: {
        name: 'Empfänger',
        col: 'col-2',
        property: 'recipients'
    },
    subject: {
        name: 'Betreff',
        col: 'col-3',
        property: 'subject'
    },
    status: {
        name: 'Status',
        col: 'col-1',
        property: 'status'
    },
}
const filter = null;
const sync = null;
const initialSort = 'publicationDate'
const initialSortDirection = 'desc'
const projection = 'list'
const create = false


const Service = {
    getHeader: function () {
        return header
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function() {
        return initialSort
    },
    getInitialSortDirection: function() {
        return initialSortDirection
    },
    getProjection: function () {
        return projection
    },
    findAll: async function(active) {
        try {
            return ApiService.get('/api/admin/mailPersistenceEvents', {
                params: {
                  active: active
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })
                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying " + rel + ": ", error)
        }
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size) {
        try {
            return ApiService.get('/api/admin/mailPersistenceEvents', {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })
                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    nextPage: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    }
}

export default Service