var render = function () {
  var _vm$foundUsers, _vm$selectedUser2, _vm$selectedUser3, _vm$selectedUser3$cus;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id + '-addUserModal',
      "title": _vm.$t('customer.add-user')
    },
    on: {
      "ok": _vm.confirm
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var _vm$selectedUser, _vm$selectedUser$cust;

        var ok = _ref.ok,
            cancel = _ref.cancel,
            hide = _ref.hide;
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.abort')))]), _c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.selectedUser || ((_vm$selectedUser = _vm.selectedUser) === null || _vm$selectedUser === void 0 ? void 0 : (_vm$selectedUser$cust = _vm$selectedUser.customerIds) === null || _vm$selectedUser$cust === void 0 ? void 0 : _vm$selectedUser$cust.includes(_vm.customerId))
          },
          on: {
            "click": ok
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.ok')))])];
      }
    }])
  }, [_c('v-select', {
    attrs: {
      "options": (_vm$foundUsers = _vm.foundUsers) === null || _vm$foundUsers === void 0 ? void 0 : _vm$foundUsers.items,
      "transition": "",
      "label": "username",
      "searchable": true,
      "filterable": false
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$log('DEBUG', 'clicked');
      },
      "search": function search(query, loading) {
        return _vm.findUsers(query, loading);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        var _option$customerIds;

        return [_c('div', [_c('b', [_vm._v(_vm._s(option.username))]), ((_option$customerIds = option.customerIds) === null || _option$customerIds === void 0 ? void 0 : _option$customerIds.length) > 0 ? _c('span', [_vm._v(" - " + _vm._s(option.customerIds.join(', ')))]) : _vm._e()]), _c('div', [_c('em', [_vm._v(_vm._s(option.firstName) + " " + _vm._s(option.lastName) + " (" + _vm._s(option.email) + ")")])])];
      }
    }, {
      key: "no-options",
      fn: function fn(_ref2) {
        var search = _ref2.search,
            searching = _ref2.searching;
        return [searching ? [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('user.search.no-results', {
              query: search
            }))
          }
        })] : _c('em', {
          staticStyle: {
            "opacity": "0.5"
          }
        }, [_vm._v(_vm._s(_vm.$t('user.search.hint')))])];
      }
    }]),
    model: {
      value: _vm.selectedUser,
      callback: function callback($$v) {
        _vm.selectedUser = $$v;
      },
      expression: "selectedUser"
    }
  }), (_vm$selectedUser2 = _vm.selectedUser) !== null && _vm$selectedUser2 !== void 0 && _vm$selectedUser2.customerIds.includes(_vm.customerId) ? _c('div', {
    staticClass: "mt-2 text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('user.alreadyAssignedToCustomer')) + " ")]) : !!((_vm$selectedUser3 = _vm.selectedUser) !== null && _vm$selectedUser3 !== void 0 && (_vm$selectedUser3$cus = _vm$selectedUser3.customerIds) !== null && _vm$selectedUser3$cus !== void 0 && _vm$selectedUser3$cus.length) > 0 ? _c('div', {
    staticClass: "mt-2 text-danger"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('user.alreadyAssigned')) + " ")]), _c('div', {
    staticClass: "mt-2"
  }, [_c('e-form-checkbox', {
    attrs: {
      "id": _vm.id + '-addUserModal-reoveAssignments',
      "name": "removeAssignments"
    },
    model: {
      value: _vm.reassign,
      callback: function callback($$v) {
        _vm.reassign = $$v;
      },
      expression: "reassign"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('user.removeAssignment')) + " ")])], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }