var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "inquiry_observer",
    attrs: {
      "vid": "inquiryObserver",
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty;
        return [_c('b-modal', {
          attrs: {
            "id": _vm.id,
            "title": _vm.$t('shipment.inquiry.for-shipment', {
              shipment: _vm.shipment.shipmentNumber
            })
          },
          on: {
            "ok": _vm.sendInquiry
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('validation-provider', {
                attrs: {
                  "name": "inquiryText",
                  "vid": _vm.id + '-inquiryText',
                  "rules": "required",
                  "slim": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref2) {
                    var classes = _ref2.classes,
                        errors = _ref2.errors;
                    return [_c('b-form-textarea', {
                      attrs: {
                        "type": "text",
                        "name": "inquiryText",
                        "id": _vm.id + '-inquiryText',
                        "placeholder": _vm.$t('shipment.inquiry.your-inquiry'),
                        "rows": "3",
                        "max-rows": "6"
                      },
                      model: {
                        value: _vm.inquiryText,
                        callback: function callback($$v) {
                          _vm.inquiryText = $$v;
                        },
                        expression: "inquiryText"
                      }
                    })];
                  }
                }], null, true)
              })];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn(_ref3) {
              var ok = _ref3.ok,
                  cancel = _ref3.cancel,
                  hide = _ref3.hide;
              return [_c('b-button', {
                attrs: {
                  "variant": "secondary"
                },
                on: {
                  "click": cancel
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.abort')))]), _c('b-button', {
                attrs: {
                  "variant": "primary",
                  "disabled": invalid
                },
                on: {
                  "click": ok
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.send')))])];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }