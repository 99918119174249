<template>
  <notification-code-list />
</template>

<script>
import NotificationCodeList from "@/components/NotificationCodeList";

export default {
  name: "AdminNotificationCodes",
  components: {NotificationCodeList}
}
</script>

<style scoped>

</style>