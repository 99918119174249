<template>
  <e-scrollable-search-filter-page :service="service"
                                   v-on:enableSpinner="enableSpinner()" v-on:disableSpinner="disableSpinner()">
    <template v-slot:header-controls="{ created, deleted, saved, items, page, searchText, sortProperty, sortDirection, links }">
      <announce-shipment-modal id="announceShipment" ref="announceShipmentModal" @confirmed="announceShipments($event, items, saved)"/>
      <printer-type-modal @confirmed="printerConfirmed" ref="printerTypeModal"/>
      <b-button-toolbar key-nav class="float-right">
        <b-button-group class="mx-1" v-if="links?.create?.href">
          <b-dropdown id="dropdown-createOrder" size="sm" variant="primary" right
                      :disabled="!!items.find(s => !(s?._links?.self))">
            <template #button-content>
              <b-icon icon="plus"/>
              {{ $t('controls.create') }}
            </template>
            <b-dropdown-item @click="newShipmentCreated(service.create(), created)">
              {{ $t('shipment.create.form') }}
            </b-dropdown-item>
            <router-link to="/shipments/create" class="dropdown-item">{{ $t('shipment.create.wizard') }}</router-link>
          </b-dropdown>
        </b-button-group>

        <b-button-group class="mx-1" v-if="links?.modify?.href">
          <b-button type="button" size="sm" variant="secondary" class="pl-2 pr-2"
                    @click="$refs.announceShipmentModal.show(checkedOrders)"
                    :disabled="checkedOrders.length === 0 || !checkedOrders.find(s => !!s?._links?.announce?.href)">
            <b-icon icon="info-circle"/>
            {{ $t('shipment.shipping-notice') }}
          </b-button>
        </b-button-group>

        <b-button-group class="mx-1" v-if="links?.commission?.href">
          <b-button type="button" size="sm" variant="secondary" class="pl-2 pr-2"
                    @click="finalizeOrders(items, saved)"
                    :disabled="axiosSaveInProgress || checkedOrders.length === 0 || !checkedOrders.find(s => !!s._links?.commission?.href)">
            <b-icon icon="check-circle"/>
            {{ $t('shipment.commission') }}
          </b-button>
        </b-button-group>

        <b-button-group class="mx-1" v-if="links?.modify?.href">
          <b-dropdown id="dropdown-batch" size="sm" variant="secondary" right>
            <template #button-content>
              <b-icon-stack />
              {{ $t('shipment.batch-announce-and-commission') }}
            </template>
            <b-dropdown-item @click="batchAnnouncement">
              {{ $t('shipment.batch-announcement') }}
            </b-dropdown-item>
            <b-dropdown-item @click="batchCommision(items, saved)">
              {{ $t('shipment.batch-commissioning') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>

        <b-button-group class="mx-1">
          <b-button type="button" size="sm" variant="secondary" class="pl-2 pr-2"
                    @click="downloadLabels" :disabled="checkedOrders.length == 0">
            <b-icon icon="printer"/>
            {{ $t('shipment.print.labels') }}
          </b-button>
        </b-button-group>

        <b-button-group class="mx-1">
          <b-button type="button" size="sm" variant="secondary" class="pl-2 pr-2"
                    @click="printLoadingList(items, saved)"
                    :disabled="checkedOrders.length == 0 || !checkedOrders.find(order => ['FINALIZED'].includes(order.state))">
            <b-icon icon="file-text"/>
            {{ $t('shipment.print.cargo-list') }}
          </b-button>
        </b-button-group>

        <b-button-group class="mx-1" v-if="links?.delete?.href">
          <b-button type="button" size="sm" variant="secondary" class="pl-2 pr-2"
                    @click="confirmDeleteOrders(items, deleted)"
                    :disabled="checkedOrders.length == 0 || !checkedOrders.find(order => ['NEW', 'DRAFT', 'ANNOUNCED'].includes(order.state))">
            <b-icon icon="trash"/>
            {{ $t('controls.delete') }}
          </b-button>
        </b-button-group>

        <b-button-group class="mx-1" v-if="links?.exportXLS?.href">
          <b-button type="button" size="sm" variant="secondary" class="pl-2 pr-2"
                    @click="exportToExcel(page, searchText, sortProperty, sortDirection)"
                    :disabled="!page?.totalElements">
            <b-icon-file-earmark-excel />
            {{ $t('shipments.export-xls') }}
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </template>

    <template v-slot:spinner>
      <b-spinner v-if="loading" class="spinner" label="Loading..."/>
    </template>

    <template v-slot:table-header="{ items, sortBy, sortIcon }">
      <div class="col-2">
        <div class="row">

          <div class="col-2 pl-1 pr-1 clip-text">
            <b-checkbox @change="toggleSelectAll(items, $event)" :checked="checkedOrders.length == items?.length"/>
          </div>

          <div class="col-6 pl-1 pr-1 clip-text" @click="sortBy('shipmentNumber')">
            <component v-bind:is="sortIcon('shipmentNumber')"/>
            <strong>{{ $t('shipment.number.short') }}</strong>
          </div>

          <div class="col-4 pl-1 pr-1 clip-text" @click="sortBy('sender.customerId')">
            <component v-bind:is="sortIcon('sender.customerId')"/>
            <strong>{{ $t('customer.id.short') }}</strong>
          </div>
        </div>
      </div>

      <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('sender.name', sortBySender)">
        <component v-bind:is="sortIcon('sender.name', sortBySender)"/>
        <strong>{{ $t('shipment.sender') }}</strong>
      </div>

      <div class="col-4">
        <div class="row">
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('state')">
            <component v-bind:is="sortIcon('state')"/>
            <strong>{{ $t('shipment.state.short') }}</strong>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('lastModifiedBy')">
            <component v-bind:is="sortIcon('lastModifiedBy')"/>
            <strong>{{ $t('shipment.lastModifiedBy.short') }}</strong>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('orderNumber')">
            <component v-bind:is="sortIcon('orderNumber')"/>
            <strong>{{ $t('order.number.short') }}</strong>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('shippingDate')">
            <component v-bind:is="sortIcon('shippingDate')"/>
            <strong>{{ $t('shipment.shipping-date.short') }}</strong>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('deliveryDate')">
            <component v-bind:is="sortIcon('deliveryDate')"/>
            <strong>{{ $t('shipment.delivery-date.short') }}</strong>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('productType', sortByProduct)">
            <component v-bind:is="sortIcon('productType', sortByProduct)"/>
            <strong>{{ $t('product.type') }}</strong>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row">
          <div class="col-5 pl-1 pr-1 clip-text" @click="sortBy('consignee.name', sortByConsignee)">
            <component v-bind:is="sortIcon('consignee.name', sortByConsignee)"/>
            <strong>{{ $t('shipment.consignee') }}</strong>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('shippingNoticeCreated')">
            <component v-bind:is="sortIcon('shippingNoticeCreated')"/>
            <strong>{{ $t('shipment.shipping-notice.short') }}</strong>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('commissionedOn')">
            <component v-bind:is="sortIcon('commissionedOn')"/>
            <strong>{{ $t('shipment.commissioned.short') }}</strong>
          </div>
          <div class="col-1 pl-1 pr-1 clip-text" @click="sortBy('hazmat', sortByHazmat)">
            <component v-bind:is="sortIcon('hazmat', sortByHazmat)"/>
            <strong>{{ $t('shipment.dangerous-goods.short') }}</strong>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text" @click="sortBy('type', sortByHazmat)">
            <component v-bind:is="sortIcon('type', sortByHazmat)"/>
            <strong>{{ $t('shipment.type.short') }}</strong>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:entity-header="{id, items, entity, index, deleted, saved}">
      <div class="col-2">
        <div class="row">
          <div class="col-2 pl-1 pr-1">
            <span @click.stop>
              <b-checkbox :value="entity" v-model="checkedOrders" :disabled="['NEW'].includes(entity.state)"/>
            </span>
          </div>
          <div class="col-6 pl-1 pr-1 clip-text" >
              <span v-if="entity?._links?.trackAndTrace?.href" v-b-tooltip.hover :title="$t('shipment.track-and-trace.link-description')">
                <a :href="formatTrackAndTraceLink(entity?._links?.trackAndTrace?.href)" target="_blank" @click.stop>{{ entity.shipmentNumber }}</a>
              </span>
            <span v-else>{{ entity.shipmentNumber }}</span>
          </div>
          <div class="col-4 pl-1 pr-1 clip-text">{{ entity.sender ? entity.sender.customerId : '' }}</div>
        </div>
      </div>

      <div class="col-2 pl-1 pr-1 clip-text">
        <strong v-if="entity?.sender?.name">{{ entity.sender.name }}</strong>
        <span v-if="entity?.sender?.city">{{ entity?.sender?.name ? ', ' : '' }}{{ entity.sender.city }}</span>
      </div>

      <div class="col-4">
        <div class="row">
          <div class="col-2 pl-1 pr-1 clip-text">
            <b-link v-if="entity?._links?.loadinglist?.href" @click.stop="downloadLoadingList(entity)">
              {{ entity.state ? $t('shipment.state.' + entity.state) : '' }}
            </b-link>
            <span v-else>{{ entity.state ? $t('shipment.state.' + entity.state) : '' }}</span>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text">
            {{ entity.lastModifiedBy }}
          </div>
          <div class="col-2 pl-1 pr-1 clip-text">
            {{ entity.orderNumber }}
          </div>
          <div class="col-2 pl-1 pr-1 clip-text">
            {{ entity.shippingDate ? $d(new Date(entity.shippingDate), 'dateShort') : '' }}
          </div>
          <div class="col-2 pl-1 pr-1 clip-text">
            {{ entity.deliveryDate ? $d(new Date(entity.deliveryDate), 'dateShort') : '' }}
          </div>
          <div class="col-2 pl-1 pr-1 clip-text">{{ entity.product ? entity.product.name : '' }}</div>
        </div>
      </div>

      <div class="col-4">
        <div class="row">
          <div class="col-5 pl-1 pr-1 clip-text">
            <strong v-if="entity?.consignee?.name">{{ entity.consignee.name }}</strong>
            <span v-if="entity?.consignee?.city">{{ entity?.consignee?.name ? ', ' : '' }}{{ entity.consignee.city }}</span>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text">
            {{ entity.shippingNoticeCreated ? $d(new Date(entity.shippingNoticeCreated), 'dateShort') : '' }}
          </div>
          <div class="col-2 pl-1 pr-1 clip-text">{{ entity.commissionedOn ? $d(new Date(entity.commissionedOn), 'dateShort') : '' }}</div>
          <div class="col-1 pl-1 pr-1 clip-text">
            <b-icon v-if="hasHazmat(entity)" icon="check-circle"/>
          </div>
          <div class="col-2 pl-1 pr-1 clip-text">
            <span v-if="entity.type">{{ $t('shipment.mainType.' + entity.type) }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:entity-content="{ id, entity, index, deleted, saved }">
      <order-details :id="id" :order="entity" :default-country="defaultCountry"
                     @delete="deleted(index)" @save="saved(index, $event); orderSaved(index, $event)"
                     :branch-currency="branchCurrency"
                     v-on:enableSpinner="enableSpinner()" v-on:disableSpinner="disableSpinner()" />
    </template>
  </e-scrollable-search-filter-page>
</template>

<script>
import ShipmentService from "@/services/shipmentService";
import SettingsService from "@/services/settings.service";
import OrderDetails from "@/components/OrderDetails";
import PrinterTypeModal from "@/components/modals/PrinterTypeModal"
import SenderAddressService from "@/services/address.service";
import AnnounceShipmentModal from "@/components/modals/AnnounceShipmentModal";
import CargoListTemplateService from "@/services/cargoListTemplate.service";
import AxiosServiceMixin from "@/components/mixins/AxiosServiceMixin";
import CustomerService from "@/services/customer.service";
import LocationService from "@/services/location.service";
import CurrencyService from "@/services/currency.service";

export default {
  name: "OrderOverview",
  components: {AnnounceShipmentModal, OrderDetails, PrinterTypeModal},
  mixins: [AxiosServiceMixin],
  data() {
    return {
      checkedOrders: [],
      filterSenderAddresses: {
        sender: {
          type: 'SENDER',
          active: true
        },
        loadingSite: {
          type: 'LOADING_SITE',
          active: true
        }
      },
      filterConsigneeAddresses: {
        sender: {
          type: 'CONSIGNEE',
          active: true
        }
      },
      loading: false,
      service: ShipmentService,
      defaultSender: null,
      defaultConsignee: null,
      defaultCargoTemplate: null,
    }
  },
  mounted() {
    console.log('searchText: ' + this.$route.query.searchText)
  },
  computed: {

  },
  asyncComputed: {
    async customerData() {
      return await CustomerService.getSelf();
    },
    async customerBranch() {
      const customerData = await this.customerData

      if (customerData) {
        return (await LocationService.getEntityDetails(customerData._links.location.href))?.data
      }
    },
    async branchCurrency() {
      const location = this.customerBranch;

      if (location) {
        let currency = location.currency
        if (!currency) {
          currency = await CurrencyService.getDefault()
        }
        this.$log('DEBUG', 'branch currency: ', currency)
        return currency?.isoCode
      }
    },
    async defaultCountry() {
      const location = await this.customerBranch

      return location?.country || 'DE'
    },
  },
  methods: {
    enableSpinner: function () {
      this.$log('DEBUG', 'enableSpinner() called...')
      this.loading = true
    },
    disableSpinner: function () {
      this.$log('DEBUG', 'disableSpinner() called...')
      this.loading = false
    },
    formatTrackAndTraceLink: function (link) {
      let locale = this.$i18n.locale
      // fix locale according to track and trace
      if (locale == 'en') {
        locale = 'gb'
      }
      return link + '&userLanguage=' + locale
    },
    batchAnnouncement: async function() {
      const shipments = (await ShipmentService.find(
          null,
          {state: {selected: [{state: 'DRAFT'}]}},
          null,
          'shipmentNumber',
          'DESC',
          1000
      ))?.data?.items.filter(s => !!s?._links?.announce?.href)
      this.$refs.announceShipmentModal.show(shipments)
    },
    batchCommision: async function(allShipments, cbSaved) {
      const shipments = (await ShipmentService.find(
          null,
          {state: {selected: [{state: 'DRAFT'}, {state: 'ANNOUNCED'}]}},
          null,
          'shipmentNumber',
          'DESC',
          1000
      ))?.data?.items

      let result = await this.$bvModal.msgBoxConfirm(this.$t('shipments.batch-commission.confirm'), {title: this.$t('shipments.batch-commission.confirm.short')})
      if (!result) {
        return
      }

      this.enableSpinner()

      let savedCount = 0
      let lastSaved = null
      for (const shipment of shipments) {
        shipment.state = 'FINALIZED'
        try {
          const s = (await ShipmentService.setCommissioned(shipment))?.data
          if(!!s) {
            savedCount++

            let idx = allShipments.findIndex(s => s.shipmentNumber == shipment.shipmentNumber)
            if (idx >= 0) {
              cbSaved(idx, s)
            }
            lastSaved = s
          }
        } catch (error) {
          this.$eventBus.$emit('backend-error', error)
        }
      }
      this.disableSpinner()

      if (savedCount > 0) {
        if (savedCount > 1) {
          this.showMsgBox(this.$t('shipments.finalized.short'), this.$t('shipments.finalized'))
        } else {
          this.showMsgBox(this.$t('shipment.finalized.short'), this.$t('shipment.finalized', {shipment: lastSaved.shipmentNumber}))
        }
      }
    },
    announceShipments: async function (announcementData, allShipments, cbSaved) {
      this.enableSpinner()
      this.$log('DEBUG', 'shipment announcement confirmed', announcementData)
      let savedShipments = await ShipmentService.announceShipments(announcementData)
      for (const shipment of announcementData.passedShipments) {
        let idx = allShipments.findIndex(s => s.shipmentNumber == shipment.shipmentNumber)
        let saved = savedShipments.find(s => s.shipmentNumber == shipment.shipmentNumber)
        if (idx >= 0 && saved) {
          cbSaved(idx, saved)
        }
      }
      if (savedShipments?.length > 0) {
        if (savedShipments?.length > 1) {
          this.showMsgBox(this.$t('shipments.announced.short'), this.$t('shipments.announced'))
        } else {
          this.showMsgBox(this.$t('shipment.announced.short'), this.$t('shipment.announced', {shipment: savedShipments[0]?.shipmentNumber}))
        }
      }
      this.disableSpinner()
      this.$set(this, 'checkedOrders', [])
    },
    hasHazmat: function(order) {
      return order?.cargoList?.items?.find(item => item.hazmats?.length > 0)
    },
    sortByHazmat: function(order) {
      let r = order?.cargoList?.items?.find(item => item.hazmats?.length > 0)
      if (r) {
        this.$log('DEBUG', 'order has hazmat', order)
        return "1"
      }
      return "0"
    },
    sortByProduct: function (order) {
      return order?.product?.name
    },
    sortByConsignee: function (order) {
      return order?.consignee?.name
    },
    sortBySender: function(order) {
      return order?.sender?.name
    },
    toggleSelectAll: function (items, checked) {
      let selected = []
      if (checked) {
        items.forEach(item => selected.push(item))
      }
      this.checkedOrders = selected
    },
    showMsgBox: function (title, message) {
      this.$bvModal.msgBoxOk(message, {
        title: title,
        titleClass: 'pl-2',
        centered: true,
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
      })
    },
    orderSaved: function (idx, order) {
      if (order.state == 'ANNOUNCED') this.showMsgBox(this.$t('shipment.announced.short'), this.$t('shipment.announced', {shipment: order.shipmentNumber}))
      else if (order.state == 'CANCELLED') this.showMsgBox(this.$t('shipment.cancelled.short'), this.$t('shipment.cancelled', {shipment: order.shipmentNumber}))
      else if (order.state == 'FINALIZED') this.showMsgBox(this.$t('shipment.finalized.short'), this.$t('shipment.finalized', {shipment: order.shipmentNumber}))
      else this.showMsgBox(this.$t('shipment.saved.short'), this.$t('shipment.saved', {shipment: order.shipmentNumber}))
    },
    finalizeOrders: async function (allShipments, cbSaved) {
      await this.doAxiosCall(async () => {
        this.enableSpinner()
        let savedCount = 0
        let lastSaved = null
        try {
          for (const order of this.checkedOrders) {
            if (!!order._links?.commission?.href) {
              let idx = allShipments.indexOf(order)
              try {
                const s = (await ShipmentService.setCommissioned(order))?.data
                if (!!s) {
                  cbSaved(idx, s)
                  savedCount++
                  lastSaved = s
                }
              } catch (error) {
                this.$eventBus.$emit('backend-error', error)
              }
            }
          }
        } finally {
          this.disableSpinner()
        }
        if (savedCount > 0) {
          if (savedCount > 1) {
            this.showMsgBox(this.$t('shipments.finalized.short'), this.$t('shipments.finalized'))
          } else {
            this.showMsgBox(this.$t('shipment.finalized.short'), this.$t('shipment.finalized', {shipment: lastSaved.shipmentNumber}))
          }
        }
        this.$set(this, 'checkedOrders', [])
      })
    },
    confirmDeleteOrders: async function (items, cbDeleted) {
      let result = await this.$bvModal.msgBoxConfirm(this.$t('shipments.delete.confirm'), {title: this.$t('shipments.delete.confirm.short')})
      if (result) {
        await this.deleteOrders(items, cbDeleted)
      }
    },
    deleteOrders: async function (items, cbDeleted) {
      this.checkedOrders.filter(order => ['NEW', 'DRAFT', 'ANNOUNCED'].includes(order.state)).forEach(order => {
        ShipmentService.delete(order).then(response => {
          this.checkedOrders.splice(this.checkedOrders.indexOf(order), 1)
          cbDeleted(items.indexOf(order))
        }).catch(error => {
          this.$eventBus.$emit('backend-error', error)
        })
      })
    },
    downloadLoadingList: async function (order) {
      if (order) {
        await ShipmentService.downloadLoadingList(order)
      }
    },
    printLoadingList: async function (items, cbSaved) {
      const updatedShipments = await ShipmentService.createLoadingList(this.checkedOrders)
      this.$log('DEBUG', 'loading list created for shipments', updatedShipments)
      for (const updatedShipment of updatedShipments) {
        const idx = items.findIndex(i => i.shipmentNumber == updatedShipment.shipmentNumber)
        if (idx >= 0) {
          cbSaved(idx, updatedShipment)
        }
      }
      this.$set(this, 'checkedOrders', [])
    },
    downloadLabels: async function () {
      let printerSetting = await SettingsService.find('PRINTER_TYPE');
      this.$log('DEBUG', 'printerSetting', printerSetting)
      if (printerSetting && ['LASER', 'ZEBRA'].includes(printerSetting.value)) {
        this.$log("DEBUG", 'valid printer settings found: ' + printerSetting.value)
        this.printerConfirmed({type: printerSetting.value, save: false})
      } else {
        this.$log("DEBUG", "no valid printer settings found", printerSetting)
        this.$refs.printerTypeModal.show(printerSetting && ['LASER_POSITION'].includes(printerSetting.value))
      }
    },
    exportToExcel: function(page, searchText, sortProperty, sortDirection) {
      ShipmentService.exportXLS(searchText, null, sortProperty, sortDirection, page?.totalElements)
    },
    printerConfirmed: function (printerConfig) {
      if (printerConfig && printerConfig.save) {
        SettingsService.save({name: 'PRINTER_TYPE', value: printerConfig.type})
      }
      ShipmentService.downloadLabels(printerConfig.type, this.checkedOrders, printerConfig.position)
    },
    newShipmentCreated: async function(shipment, cb) {
      shipment = await this.setCustomerDefaults(shipment)
      this.$log('DEBUG', 'NEW SHIPMENT', shipment)
      shipment.isOpen = true
      cb(shipment)
    },
    setCustomerDefaults: async function (shipment, cb) {
      const location = await this.customerBranch
      const defaultCountry = location?.country || 'DE'
      if (!shipment.sender.country) {
        shipment.sender.country = defaultCountry;
      }
      if (!shipment.consignee.country) {
        shipment.consignee.country = defaultCountry;
      }

      if (null === this.defaultSender) {
        this.defaultSender = await SenderAddressService.getDefaultSenderAddress()
        if (!this.defaultSender) {
          this.defaultSender = false
        }
      }
      if (!!this.defaultSender) {
        shipment.sender = this.defaultSender
        if (!!this.defaultSender.invoiceRecipient) {
          shipment.invoiceRecipient = {...this.defaultSender.invoiceRecipient, _fromSender: true}
        }
      }
      if (null === this.defaultConsignee) {
        this.defaultConsignee = await SenderAddressService.getDefaultConsigneeAddress()
        if (!this.defaultConsignee) {
          this.defaultConsignee = false
        }
      }
      if (!!this.defaultConsignee) {
        shipment.consignee = this.defaultConsignee
      }

      if (null === this.defaultCargoTemplate) {
        this.defaultCargoTemplate = await CargoListTemplateService.getDefault()
        if (!this.defaultCargoTemplate) {
          this.defaultCargoTemplate = false
        }
      }
      if (this.defaultCargoTemplate) {
        shipment.cargoList.items = [{...this.defaultCargoTemplate}]
      }
      return shipment
    }
  }
}
</script>

<style scoped>

.content-header > div {
  cursor: pointer;
}

.card {
  border: 1px solid rgb(192, 7, 25);
  margin-right: 15px;
  margin-bottom: 3px;
  border-radius: .2rem;
  overflow: visible;
}

.card .card-body >>> .card .card-header {
  border-bottom: unset;
}

.card:focus {
  outline: none;
}

.card-header {
  margin-left: 0;
  margin-right: 0;
  height: 32px;
  background-color: transparent;
}

.clip-text {
  padding-right: 1px;
}

</style>