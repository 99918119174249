var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmit(_vm.onSubmit);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-3 col-form-label",
    attrs: {
      "for": _vm.id + '-zipCodeArea-country'
    }
  }, [_vm._v(_vm._s(_vm.$t('address.country')))]), _c('div', {
    staticClass: "col-9"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-zipCodeArea-country',
      "value": _vm.entity.country
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-3 col-form-label",
    attrs: {
      "for": _vm.id + '-zipCodeArea-zipCode'
    }
  }, [_vm._v(_vm._s(_vm.$t('address.zipCode')))]), _c('div', {
    staticClass: "col-9"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-zipCodeArea-zipCode',
      "value": _vm.entity.zipCode
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-3 col-form-label",
    attrs: {
      "for": _vm.id + '-zipCodeArea-city'
    }
  }, [_vm._v(_vm._s(_vm.$t('address.city')))]), _c('div', {
    staticClass: "col-9"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-zipCodeArea-city',
      "value": _vm.entity.city
    }
  })], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }