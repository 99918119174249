<template>
  <validation-observer ref="observer"
                       v-slot="{ invalid, changed, dirty, failed, handleSubmit }" slim>
    <div class="row">

      <div class="col-md-6">
        <div class="form-row">
          <div class="col-12"><label><strong>{{ $t('address.address-data') }}</strong></label></div>
        </div>

        <div class="form-row">
          <div class="col-7">
            <e-form-text-input :id="id + '-name'" name="name" rules="required"
                               :placeholder="$t('address.name')"
                               :disabled="!isAddressDataEditable"
                               v-model="value.name" />
          </div>
          <div class="col-5">
            <e-form-text-input :id="id + '-customerId'" name="customerId" rules="required"
                               :placeholder="$t('address.customerId')"
                               :disabled="!isAddressDataEditable"
                               v-model="value.customerId" />
          </div>
        </div>
        <div class="form-row">
          <div class="col-7">
            <e-form-text-input :id="id + '-additionalName'" name="additionalName"
                               :placeholder="$t('address.additionalName')"
                               :disabled="!isAddressDataEditable"
                               v-model="value.additionalName"/>
          </div>
          <div class="col-5">
            <validation-provider>
              <b-form-checkbox :id="id + '-isDefault'" name="isDefault" :disabled="!isEditable"
                               v-model="value.isDefault">
                {{ $t('invoiceRecipient.isDefault') }}
              </b-form-checkbox>
            </validation-provider>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12">
            <e-form-text-input :id="id + '-street'" name="street" rules="required"
                               :placeholder="$t('address.streetWithNumber')"
                               :disabled="!isAddressDataEditable"
                               v-model="value.street" />
          </div>
        </div>
        <div class="form-row">
          <div class="col-3">
            <country-select :id="id + '-country'" name="country" :required="true"
                            v-model="value.country"
                            :placeholder="$t('address.country')"
                            :disabled="!isAddressDataEditable" />
          </div>
          <div class="col-3">
            <e-form-text-input :id="id + '-zipCode'" name="zipCode"
                               :rules="{required: true, zipCode:{country: '@' + id + '-country'}}"
                               :placeholder="$t('address.zipCode')"
                               :disabled="!isAddressDataEditable"
                               @blur="formatZipCode($event)"
                               v-model="value.zipCode"/>
          </div>
          <div class="col-6">
            <city-select :id="id + '-city'" name="city" rules="required"
                         :disabled="!isAddressDataEditable"
                         :country="value.country" :zip-code="value.zipCode" v-model="value.city" />
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-row">
          <div class="col-12"><label><strong>{{ $t('address.contact-data') }}</strong></label></div>
        </div>

        <div class="form-row">
          <div class="col-sm-12 col-md-6">
            <e-form-text-input :id="id + '-contactName'" name="contactName"
                               :placeholder="$t('address.consignee.contactName')"
                               :disabled="!isEditable"
                               v-model="value.contactName" />
          </div>
          <div class="col-sm-12 col-md-6">
            <e-form-text-input :id="id + '-phone'" name="phone" rules="phoneNumber"
                               :placeholder="$t('address.phone')"
                               :disabled="!isEditable"
                               v-model="value.phone" />
          </div>
        </div>

      </div>
    </div>

    <div class="row" v-if="showActions && (isEditable || isAddressDataEditable || isDeletable)">

      <div class="col-md-6 offset-md-6">
        <div class="form-group row justify-content-end">
          <div class="col-sm-3" v-if="isEditable ||isAddressDataEditable">
            <button id="saveButton"
                    type="submit"
                    class="btn btn-sm btn-block btn-outline-primary"
                    :disabled="axiosSaveInProgress || !(changed || dirty) || invalid"
                    @click.prevent="saveEntity()">{{ $t('controls.save') }}</button>
          </div>
          <div class="col-sm-3" v-if="isDeletable">
            <button id="deleteButton"
                    class="btn btn-sm btn-block btn-outline-danger"
                    @click.prevent="confirmDelete()">{{ $t('controls.delete') }}</button>
          </div>
        </div>
      </div>

    </div>
  </validation-observer>
</template>

<script>

import service from "@/services/invoiceRecipientAddress.service";
import {log, ValidationObserver, ValidationProvider, formatZipCode} from "@emons/emons-vue"
import CitySelect from "@/components/form-controls/CitySelect";
import CountrySelect from "@/components/form-controls/CountrySelect";
import axiosServiceMixin from "@/components/mixins/AxiosServiceMixin";

export default {
  name: "InvoiceRecipientAddressView",
  components: {
    ValidationObserver,
    ValidationProvider,
    CitySelect,
    CountrySelect
  },
  mixins: [axiosServiceMixin],
  mounted() {
    log('debug', 'mounted()...')
    if (this.value._links)
      this.getEntityDetails()
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
    }
  },
  computed: {
    isEditable() {
      return !this?.value?._links || this.value._links?.modify?.href;
    },
    isAddressDataEditable() {
      return !this?.value?._links || this.value._links?.modifyAddressData?.href;
    },
    isDeletable() {
      return this?.value?._links?.delete?.href;
    },
    isNew() {
      !this?.value?._links
    }
  },
  methods: {
    validateInitial: function() {
      if (!this.isNew && this.isEditable) {
        this.$refs.observer.validate()
      }
    },
    getEntityDetails: function () {
      this.$emit('enableSpinner');
      service
          .getEntityDetails(this.value._links.self.href)
          .then(response => {
            Object.assign(this.value, response.data)
            this.$emit('disableSpinner')
            this.$nextTick(() => {
              this.validateInitial()
            })
          })
    },
    confirmDelete: function () {
      this.$bvModal.msgBoxConfirm(
          this.$t('invoiceRecipient.delete.confirm'),
          { title: this.$t('invoiceRecipient.delete.confirm.short')}
      ).then(result => {
        if (result) this.deleteEntity()
      })
    },
    deleteEntity() {
      this.$emit('enableSpinner');
      service.delete(this.value)
          .then(response => {
            this.$emit('delete', this.index)
            this.$emit('disableSpinner')
          })
    },
    async saveEntity(pathForCreate = null, dialogOnSuccess = false) {
      this.$log('DEBUG', 'saving...', pathForCreate)
      return await this.doSave(service, this.value,
          dialogOnSuccess?async () => {
            await this.$bvModal.msgBoxOk(this.$t('address.saved'), {
              title: this.$t('address.saved.short'),
              titleClass: 'pl-2',
              centered: true,
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
            }).then(
                this.value['isOpen'] = false
            )
          }:() => {},
          () => {},
          this.$refs.observer,
          this.id + '-',
          pathForCreate
      )
    },
    formatZipCode: function (zipCode) {
      this.value.zipCode = formatZipCode(this.value.country, zipCode)
    }
  }
}
</script>

<style scoped>

</style>