<template>
  <validation-provider v-if="!disabled" :vid="id" :name="name" :immediate="hasValue && !disabled"
                       :rules="required?'required':''" v-slot="{ classes, errors }" ref="validator" slim>
    <div :class="['form-label-group', 'input-group'].concat(size?'input-group-' + size:'')">
      <v-select
          :name="name"
          :id="id"
          :class="getCSSClasses(filterValidationClasses(classes))"
          @search:focus="onFocus"
          @search:blur="onBlur"
          @input="onInput"
          @open="onOpen"
          @close="onClose"
          :options="packagingCodes"
          :disabled="disabled"
          :filterable="true"
          :filterBy="(code, label, query) => {
                                      return code.code.toLowerCase().includes(query.toLowerCase())
                                             || code.name.toLowerCase().includes(query.toLowerCase())
                                  }"
          :reduce="code => code.code"
          :value="value"
          label="code">
        <template slot="option" slot-scope="option">
          {{ option.code }} - {{ option.name }}
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.code }}
        </template>
      </v-select>
      <label v-if="placeholder" class="required">{{ placeholder }}</label>
      <span class="invalid-feedback">{{ errors[0] }}</span>
    </div>
  </validation-provider>
  <e-form-text-input v-else :id="id" :name="name" :size="size" :placeholder="placeholder" disabled :value="value" />
</template>

<script>
import {ValidationProvider} from '@emons/emons-vue'
import HazmatCodeService from "@/services/hazmatCode.service";

export default {
  name: "HazmatPackagingCodeSelect",
  components: {ValidationProvider},
  props: {
    id: {
      type: String,
      default: 'packagingCode'
    },
    name: {
      type: String,
      default: 'packagingCode'
    },
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {
      packagingCodes: [],
    }
  },
  computed: {
    isRequired: function () {
      const rules = this.rules
      if (typeof rules === 'object' && rules.required) {
        return true
      } else if (typeof rules === 'string' && rules.includes('required')) {
        return true
      }
      return false
    },
    hasValue: function () {
      const value = this.value
      return value !== null
          && value !== undefined
          && ((typeof value === 'string' && value.length > 0) || (typeof value === 'number'))
    }
  },
  methods: {
    async onOpen() {
      // UGLY UGLY UGLY hack:
      // - add event listeners for mouseenter / mouseleave on dropdown menu using plain old js
      // - as vue-custom-scrollbar doesn't emit for these yet events yet
      await this.$nextTick() // dropdown-menu might not be in DOM yet
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.addEventListener('mouseenter', this.mouseListener, false)
      menu.addEventListener('mouseleave', this.mouseListener, false)
    },
    onClose() {
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.removeEventListener('mouseenter', this.mouseListener, false)
      menu.removeEventListener('mouseleave', this.mouseListener, false)
      this.lockParentScroll(false)
    },
    mouseListener(event) {
      this.lockParentScroll(event.type == 'mouseenter')
    },
    lockParentScroll(lock) {
      this.$eventBus.$emit('scroll:lock', lock)
    },
    onInput: function ($event) {
      this.$emit('input', $event)
    },
    onBlur: function () {
      this.$refs.validator.validate()
      this.$emit('blur')
    },
    onFocus: function () {
      this.findHazmatPackagingCodes()
      this.$emit('focus')
    },
    findHazmatPackagingCodes: async function () {
      if (!this.packagingCodes?.length) {
        this.$log('debug', 'findHazmatPackagingCodes() called...')
        const response = await HazmatCodeService.findAll()
        this.$log('debug', 'response', response)
        this.packagingCodes = response?.data?.items || []
      }
    },
    getCSSClasses(additionalClasses) {
      let classes = ['hazmat-packaging-code-select', 'form-control']
      if (this.size) {
        classes = classes.concat(['form-control-' + this.size])
      }
      if (!!this.value) {
        classes = classes.concat('has-value')
      }
      if (!this.placeholder) {
        classes = classes.concat('no-placeholder')
      }

      return classes.concat(additionalClasses)
    },
    filterValidationClasses: function (classes) {
      const newClasses = classes
      if (!this.isRequired && !this.hasValue) {
        delete newClasses['is-valid']
      }
      return newClasses
    }
  }
}
</script>

<style>
.hazmat-packaging-code-select .vs__dropdown-menu {
  overflow-x: hidden;
  min-width: 250px !important;
}

.hazmat-packaging-code-select .vs__search::placeholder,
.hazmat-packaging-code-select .vs__dropdown-toggle {
  height: inherit!important;
  border: none;
  background-color: transparent !important;
}

.hazmat-packaging-code-select.is-valid,
.was-validated .hazmat-packaging-code-select:valid {
  /* 32 px (actions size) + original background position */
  background-position: right calc(32px + 0.3375em + .2625rem) center;
}

.hazmat-packaging-code-select.is-invalid,
.was-validated .hazmat-packaging-code-select:invalid {
  /* 14 px (actions size) + original background position */
  background-position: right calc(14px + 0.3375em + .2625rem) center;
}

.hazmat-packaging-code-select.form-control {
  padding: unset !important;
}

.hazmat-packaging-code-select.has-value.form-control-lg ~ label,
.hazmat-packaging-code-select.vs--open.form-control-lg ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .825rem;
  color: #777;
}

.hazmat-packaging-code-select.has-value.form-control-sm ~ label,
.hazmat-packaging-code-select.vs--open.form-control-sm ~ label{
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .6rem;
  color: #777;
}

.hazmat-packaging-code-select.has-value ~ label,
.hazmat-packaging-code-select.vs--open ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .675rem;
  color: #778;
}

.hazmat-packaging-code-select .vs__clear {
  line-height: 10px;
}

.vs--single.vs--open .vs__selected {
  position: absolute;
  top: 8px;
}

.vs--disabled .vs__search {
  background-color: transparent !important;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator {
  background-color: #e9ecef !important;
}

.hazmat-packaging-code-select.form-control-lg .vs__selected,
.hazmat-packaging-code-select.form-control-lg .vs__search {
  font-size: 1.1rem;
  line-height: 1.5;
  padding: .5rem 1rem;
}

.hazmat-packaging-code-select.form-control-sm .vs__selected,
.hazmat-packaging-code-select.form-control-sm .vs__search {
  font-size: .8rem;
  line-height: 1.2;
  padding: .45rem .25rem;
}

.hazmat-packaging-code-select .vs__selected,
.hazmat-packaging-code-select .vs__search {
  font-size: .9rem;
  line-height: 1.35;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.hazmat-packaging-code-select.no-placeholder .vs__selected {
  margin: 0;
  position: absolute;
  top: 0
}

.hazmat-packaging-code-select.no-placeholder .vs__search {
  margin: 0;
  position: relative;
}
</style>