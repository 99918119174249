var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-provider', {
    ref: "noteValidator",
    attrs: {
      "vid": _vm.id,
      "immediate": true,
      "rules": _vm.rules,
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$note$attachment, _vm$note$attachment$n;

        var errors = _ref.errors;
        return [_c('input', {
          attrs: {
            "type": "hidden",
            "id": _vm.id + '.requiredFields',
            "name": _vm.validatorName
          },
          domProps: {
            "value": _vm.note.identifier
          }
        }), _c('div', {
          staticClass: "d-flex w-100 justify-content-between align-items-center"
        }, [_c('div', {
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s(_vm.note.identifier + " - " + _vm.note.name) + " ")]), _vm.editable ? _c('button', {
          staticClass: "btn btn-sm btn-link",
          on: {
            "click": _vm.deleted
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('controls.delete')) + " ")]) : _vm._e()]), _vm.note.identifier == '199' ? _c('div', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(_vm.$t('delivery-notes.consult-customer-service')) + " ")]) : _vm._e(), _vm._l(errors, function (error) {
          return _c('div', {
            staticClass: "text-danger",
            attrs: {
              "id": "errors"
            }
          }, [_vm._v(_vm._s(error))]);
        }), errors.length == 0 && (_vm$note$attachment = _vm.note.attachment) !== null && _vm$note$attachment !== void 0 && (_vm$note$attachment$n = _vm$note$attachment.name) !== null && _vm$note$attachment$n !== void 0 && _vm$note$attachment$n.startsWith('Ja') ? _c('div', [_c('validation-provider', {
          ref: "attachmentValidator",
          attrs: {
            "vid": _vm.id + 'attachmentValue',
            "name": "attachmentValue",
            "immediate": true,
            "rules": {
              required: _vm.enableRules
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var _vm$note, _vm$note$requiredFiel;

              var errors = _ref2.errors;
              return [_c('div', {
                staticClass: "input-group input-group-sm"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.note.attachmentValue,
                  expression: "note.attachmentValue"
                }],
                ref: "input",
                staticClass: "form-control form-control-sm",
                attrs: {
                  "type": "text",
                  "maxlength": "35",
                  "id": _vm.id + '.attachmentValue',
                  "disabled": !_vm.editable,
                  "readonly": ((_vm$note = _vm.note) === null || _vm$note === void 0 ? void 0 : (_vm$note$requiredFiel = _vm$note.requiredFields) === null || _vm$note$requiredFiel === void 0 ? void 0 : _vm$note$requiredFiel.length) > 0
                },
                domProps: {
                  "value": _vm.note.attachmentValue
                },
                on: {
                  "blur": function blur($event) {
                    return _vm.$emit('blur', $event.target.value);
                  },
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.note, "attachmentValue", $event.target.value);
                  }
                }
              })]), _vm._l(errors, function (error) {
                return _c('div', {
                  staticClass: "text-danger",
                  attrs: {
                    "id": "errors"
                  }
                }, [_vm._v(_vm._s(error))]);
              })];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }