<template>
  <validation-observer ref="observer" v-slot="{ invalid, changed }">
    <b-modal ref="modal" size="xl"
        :id="id" @hidden="reset">
      <template #modal-title>
        <span v-if="invoiceRecipient?._links?.self?.href">{{ $t('customer.edit-invoice-recipient', {customerId: customerId}) }}</span>
        <span v-else>{{ $t('customer.add-invoice-recipient', {customerId: customerId}) }}</span>
      </template>
      <template #default>
        <invoice-recipient-address-view :id="id" v-model="invoiceRecipient" ref="invoiceRecipientView"
                      :show-actions="false" @save="$emit('save', $event)" />
      </template>
      <template #modal-footer="{ok, cancel, hide}">
        <b-button variant="secondary" @click="cancel">{{ $t('controls.cancel') }}</b-button>
        <b-button variant="primary" @click="submit(ok)" :disabled="$refs['invoiceRecipientView']?.isSaveInProgress() || !(changed) || invalid">
          <b-spinner v-if="$refs['invoiceRecipientView']?.isSaveInProgress()" small /> {{ $t('controls.save') }}
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "@emons/emons-vue"
import InvoiceRecipientAddressView from "@/components/InvoiceRecipientAddressView"

export default {
  name: "InvoiceRecipientModal",
  components: {InvoiceRecipientAddressView, ValidationObserver},
  props: {
    id: {
      type: String,
      default: ''
    },
    customerId: {
      type: String,
      default: ''
    },
    creationLink: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      invoiceRecipient: {}
    }
  },
  methods: {
    show: function (invoiceRecipient = {}) {
      this.invoiceRecipient = invoiceRecipient
      this.$refs.modal.show()
    },
    reset: function() {
      this.invoiceRecipient = {}
    },
    submit: async function(ok) {
      const success = await this.$refs.invoiceRecipientView.saveEntity(this.creationLink, false)
      if (!!success) {
        ok()
      }
    }
  }
}
</script>

<style scoped>

</style>