import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const path = "/api/audit"
const schema = {
    revision: null,
    type: null,
    date: null,
    userFullname: null,
    userLogin: null,
    modifications: null
}

const Service = {
    _get: async function (path) {
        try {
            const params = {}
            const response = await ApiService.get(path, {
                params: params,

                transformResponse: [function (data) {
                    if (data) {
                        return JSON.parse(data)
                    }
                }]
            })
            return response?.data
        } catch (error) {
            if (error?.response?.status == 404) {
                log("debug", "audit log not not found", path)
            } else {
                log("error", "Error querying audit log:", error)
            }
        }
        return null
    },
}

export default Service