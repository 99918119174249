var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.disabled && _vm.cityLookupSupported && null == this.lastLookup.error ? _c('validation-provider', {
    ref: "validationProvider",
    attrs: {
      "vid": _vm.id,
      "name": "city",
      "rules": _vm.rules,
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var classes = _ref.classes,
            errors = _ref.errors;
        return [_c('div', {
          staticClass: "form-label-group"
        }, [_c('v-select', {
          class: ['city-select', 'form-control'].concat(_vm.value ? 'has-value' : '').concat(classes),
          attrs: {
            "name": "city",
            "label": "city",
            "aria-disabled": _vm.disabled,
            "rules": _vm.rules,
            "disabled": _vm.disabled,
            "options": _vm.selectableCities || [],
            "reduce": function reduce(area) {
              return area.city;
            },
            "value": _vm.value
          },
          on: {
            "open": _vm.onOpen,
            "close": _vm.onClose,
            "input": _vm.onInput,
            "option:selected": _vm.onSelect
          }
        }), _c('label', {
          staticClass: "required"
        }, [_vm._v(_vm._s(_vm.$t('address.city')))]), _c('span', {
          staticClass: "invalid-feedback"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }], null, false, 1991781224)
  }) : _c('e-form-text-input', {
    attrs: {
      "id": _vm.id,
      "name": "city",
      "placeholder": _vm.$t('address.city'),
      "rules": _vm.rules,
      "disabled": _vm.disabled,
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }