var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        var _vm$details, _vm$details2;

        return [_vm._v(" Fehler! "), _vm.status > 0 ? _c('span', [_vm._v(_vm._s(_vm.details.status))]) : _vm._e(), _vm.status > 0 && (_vm$details = _vm.details) !== null && _vm$details !== void 0 && _vm$details.error ? _c('span', [_vm._v(" - ")]) : _vm._e(), (_vm$details2 = _vm.details) !== null && _vm$details2 !== void 0 && _vm$details2.error ? _c('span', [_vm._v(_vm._s(_vm.details.error))]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn() {
        var _vm$details3, _vm$details4, _vm$details5, _vm$details6, _vm$details7, _vm$details8, _vm$details9, _vm$details10, _vm$details11;

        return [_vm.status == 500 ? _c('div', [_vm._v("Es ist ein interner Fehler aufgetreten:")]) : _vm.status == 503 ? _c('div', [_vm._v("MyEmons is currently down for maintenance. Please try again in a few minutes.")]) : _vm.status == 404 ? _c('div', [_vm._v("Die angeforderte Ressource wurde nicht gefunden:")]) : _vm.status == 403 ? _c('div', [_vm._v("Sie haben keine Berechtigung für die angeforderte Ressource:")]) : _vm.status == 401 ? _c('div', [_vm._v("Der Zugriff auf die angeforderte Ressource wurde verweigert:")]) : _vm.status == 400 ? _c('div', [_vm._v("Die Anfrage konnte nicht bearbeitet werden:")]) : _vm.status == -400 ? _c('div', [_vm._v("Das Formular wurde nicht korrekt ausgefüllt - bitte überprüfen Sie die folgenden Felder:")]) : _c('div', [_vm._v("Es ist ein Fehler aufgetreten:")]), (_vm$details3 = _vm.details) !== null && _vm$details3 !== void 0 && _vm$details3.timestamp || (_vm$details4 = _vm.details) !== null && _vm$details4 !== void 0 && _vm$details4.path || (_vm$details5 = _vm.details) !== null && _vm$details5 !== void 0 && _vm$details5.message || (_vm$details6 = _vm.details) !== null && _vm$details6 !== void 0 && _vm$details6.errors ? _c('div', [(_vm$details7 = _vm.details) !== null && _vm$details7 !== void 0 && _vm$details7.errors ? _c('div', {
          staticClass: "mt-2"
        }, [_c('ul', _vm._l((_vm$details8 = _vm.details) === null || _vm$details8 === void 0 ? void 0 : _vm$details8.errors, function (error, idx) {
          return _c('li', [_c('strong', [_vm._v(_vm._s(_vm.translateFieldName(error === null || error === void 0 ? void 0 : error.field)))]), _vm._v(": " + _vm._s(_vm.$t(error === null || error === void 0 ? void 0 : error.defaultMessage)))]);
        }), 0)]) : _c('div', {
          staticClass: "mt-2"
        }, [_c('ul', [(_vm$details9 = _vm.details) !== null && _vm$details9 !== void 0 && _vm$details9.timestamp ? _c('li', [_c('strong', [_vm._v("Zeitstempel:")]), _vm._v(" " + _vm._s(_vm.details.timestamp))]) : _vm._e(), (_vm$details10 = _vm.details) !== null && _vm$details10 !== void 0 && _vm$details10.path ? _c('li', [_c('strong', [_vm._v("Anfrage:")]), _vm._v(" " + _vm._s(_vm.method) + " " + _vm._s(_vm.details.path))]) : _vm._e(), (_vm$details11 = _vm.details) !== null && _vm$details11 !== void 0 && _vm$details11.message ? _c('li', [_c('strong', [_vm._v("Fehlermeldung:")]), _vm._v(" " + _vm._s(_vm.details.message))]) : _vm._e()])])]) : _vm.status != 503 ? _c('div', [typeof _vm.details == 'string' ? _c('div', {
          staticClass: "w-100"
        }, [_vm._v(_vm._s(_vm.details))]) : _vm._e(), _c('textarea', {
          staticClass: "w-100",
          staticStyle: {
            "min-height": "100px"
          }
        }, [_vm._v(_vm._s(JSON.stringify(_vm.details)))])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn(_ref) {
        var ok = _ref.ok;
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return ok();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.ok')))])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }