var render = function () {
  var _vm$$refs$vselect, _vm$$refs$vselect$$da;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    ref: "vselect",
    staticClass: "addressbook-dropdown",
    attrs: {
      "disabled": !_vm.editable,
      "options": _vm.results,
      "loading": _vm.isLoading,
      "filterable": false,
      "clearable": false,
      "select-on-tab": (_vm$$refs$vselect = _vm.$refs.vselect) === null || _vm$$refs$vselect === void 0 ? void 0 : (_vm$$refs$vselect$$da = _vm$$refs$vselect.$data) === null || _vm$$refs$vselect$$da === void 0 ? void 0 : _vm$$refs$vselect$$da.open,
      "placeholder": _vm.$t('orders.search-addressbook'),
      "label": "name",
      "value": _vm.selectedEntry
    },
    on: {
      "open": _vm.onOpen,
      "close": _vm.onClose,
      "search": _vm.onDebouncedSearch,
      "input": _vm.onInput
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('strong', [_vm._v(_vm._s(option.name))]), _vm._v(" "), _vm.showType && _vm.types.includes('SENDER') ? _c('span', {
          staticClass: "font-weight-lighter font-italic small"
        }, [_vm._v("(" + _vm._s(_vm.getSenderDisplayType(option)) + ")")]) : _vm._e(), _c('br'), option.customerId ? _c('span', [_vm._v(_vm._s(option.customerId))]) : _vm._e(), option.customerId ? _c('span', [_vm._v(" | ")]) : _vm._e(), _c('span', [_vm._v(_vm._s(option.street))]), _c('span', [_vm._v(" | ")]), _c('span', [_vm._v(_vm._s(option.country) + "-" + _vm._s(option.zipCode) + " " + _vm._s(option.city))])];
      }
    }, {
      key: "list-footer",
      fn: function fn() {
        return [_c('li', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.hasNextPage,
            expression: "hasNextPage"
          }],
          ref: "load",
          staticClass: "loader"
        }, [_vm._v(" Loading... ")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }