<template>
  <mail-event-list />
</template>

<script>
import MailEventList from "@/components/MailPersistenceEventList";

export default {
  name: "AdminMailEvents",
  components: {MailEventList}
}
</script>

<style scoped>

</style>