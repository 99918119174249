<template>
  <div>
    <div class="p-1 pl-0 pr-0 row">
      <div class="col-md-3">
        <b><span>{{ $t('user.username') }}</span></b>
      </div>

      <div class="col-md-3">
        <b><span>{{ $t('user.email') }}</span></b>
      </div>

      <div class="col-md-3">
        <b><span>{{ $t('user.name') }}</span></b>
      </div>

      <div class="col-md-3">
        <b><span>{{ $t('user.actions') }}</span></b>
      </div>
    </div>
    <div v-for="(member, index) in members?.items" class="p-1 pl-0 pr-0 row">
      <div class="col-md-3">{{ member.username }}</div>
      <div class="col-md-3">{{ member.email }}</div>
      <div class="col-md-3">{{ member.firstName }} {{ member.lastName }}</div>
      <div class="col-md-3">
        <b-link v-if="member?._links?.modify?.href && (member?.customerIds?.length > 1 || !member?._links?.delete?.href)" @click="confirmUnassign(member)">{{ $t('user.unassign') }}</b-link>
        <b-link v-else-if="member?._links?.delete?.href" @click="confirmDelete(member)">{{ $t('controls.delete') }}</b-link>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service"

export default {
  name: "CustomerMembersView",
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      refreshKey: 0
    }
  },
  methods: {
    confirmDelete(member) {
      this.$bvModal.msgBoxConfirm(
          this.$t('user.delete.confirm'), {title: this.$t('user.delete.confirm.short')}
      ).then(result => {
        if (result) {
          this.$emit('enableSpinner');
          UserService.delete(member).then(() => {
            this.setDirty()
            this.$emit('disableSpinner');
          })
        }
      })
    },
    confirmUnassign(member) {
      this.$bvModal.msgBoxConfirm(
          this.$t('user.unassign.confirm', {user: member.username, customer: this.customer.customerId}), {title: this.$t('user.unassign.confirm.short')}
      ).then(result => {
        if (result) {
          this.$emit('enableSpinner');
          member.customerIds = member.customerIds.filter(customerId => customerId != this.customer.customerId)
          UserService.save(member).then(() => {
            this.setDirty()
            this.$emit('disableSpinner');
          })
        }
      })
    },
    setDirty() {
      this.refreshKey++
    },
  },
  asyncComputed: {
    async members () {
      this.refreshKey

      this.$log('DEBUG', 'fetching members of group', this.customer)
      const response = await UserService.membersOf(this.customer)
      if (response) {
        return response.data
      }
    }
  },
}
</script>

<style scoped>

</style>