<template>
  <packaging-code-list />
</template>

<script>
import PackagingCodeList from "@/components/PackagingCodeList";

export default {
  name: "AdminPackagingCodes",
  components: {PackagingCodeList}
}
</script>

<style scoped>

</style>