<template>
  <e-scrollable-page>
    <template slot="content">
      <h5 class="mb-2">{{ $t('nav.admin') }}</h5>
      <h2>{{ $t('nav.admin.services') }}</h2>
      <div class="text-muted">Status und Verwalung einzelner myEmons Dienste</div>
      <div class="mt-4">
        <b-form-group label="SFTP Verarbeitung"
                      content-cols="9"
                      label-cols="3"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      v-slot="{ ariaDescribedby }">
          <div v-if="sftpServiceStatus?.enabled">
            <span class="text-white bg-success pt-2 pb-2 pl-2 pr-2 text-center m-2">Gestartet</span>
            <b-btn size="sm" variant="link" @click="disableSftp">Dienst stoppen</b-btn>
          </div>
          <div v-else>
            <span class="text-white bg-danger pt-2 pb-2 pl-2 pr-2 text-center m-2">Gestoppt</span>
            <b-btn size="sm" variant="link" @click="enableSftp">Dienst starten</b-btn>
          </div>

        </b-form-group>
      </div>
    </template>
  </e-scrollable-page>
</template>

<script>
import SftpService from "@/services/sftp.service";

export default {
  name: "ServiceAdministrationView",
  data() {
    return {
      sftpServiceStatus: null
    }
  },
  methods: {
    async enableSftp() {
      this.sftpServiceStatus = await SftpService.setStatus(true)
    },
    async disableSftp () {
      this.sftpServiceStatus = await SftpService.setStatus(false)
    }
  },
  mounted() {
    SftpService.getStatus().then(response => this.sftpServiceStatus = response)
  },
}
</script>

<style scoped>

</style>