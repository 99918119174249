<template>
  <validation-observer :ref="id + '-observer'" v-slot="{ invalid, dirty, failed, handleSubmit }" slim>
  <form @submit.prevent="handleSubmit(onSubmit)">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <label :for="id + '-alpha2Code'" class="col-sm-3 col-form-label text-right">{{ $t('country.code.alpha2') }}</label>
        <div class="col-sm-9">
          <e-form-text-input :id="id + '-alpha2Code'" rules="required" name="alpha2Code" size="sm" :value="country.alpha2Code" @input="$set(country, 'alpha2Code', $event)" :disabled="!isEditable" />
        </div>
      </div>
      <div class="form-group row">
        <label :for="id + '-alpha3Code'" class="col-sm-3 col-form-label text-right">{{ $t('country.code.alpha3') }}</label>
        <div class="col-sm-9">
          <e-form-text-input :id="id + '-alpha3Code'" rules="required" name="alpha3Code" size="sm" :value="country.alpha3Code" @input="$set(country, 'alpha3Code', $event)" :disabled="!isEditable" />
        </div>
      </div>
      <div class="form-group row">
        <label :for="id + '-isoCode'" class="col-sm-3 col-form-label text-right">{{ $t('country.code.iso') }}</label>
        <div class="col-sm-9">
          <e-form-text-input :id="id + '-isoCode'" rules="required" name="isoCode" size="sm" :value="country.isoCode" @input="$set(country, 'isoCode', $event)" :disabled="!isEditable" />
        </div>
      </div>
      <div class="form-group row">
        <label :for="id + '-iocCode'" class="col-sm-3 col-form-label text-right">{{ $t('country.code.ioc') }}</label>
        <div class="col-sm-9">
          <e-form-text-input :id="id + '-iocCode'" rules="required" name="iocCode" size="sm" :value="country.iocCode" @input="$set(country, 'iocCode', $event)" :disabled="!isEditable" />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 offset-md-6">
      <div class="form-group row">
        <div class="col-sm-3 offset-sm-6" v-if="isEditable">
          <button id="saveButton" type="submit" class="btn btn-block btn-outline-primary" :disabled="!dirty || invalid">{{ $t('controls.save') }}</button>
        </div>
        <div class="col-sm-3" v-if="country?._links?.delete?.href">
          <button id="deleteButton" class="btn btn-block btn-outline-danger" @click.prevent="deleteCountry">{{ $t('controls.delete') }}</button>
        </div>
      </div>
    </div>
  </div>

  </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from '@emons/emons-vue'
import CountryService from "@/services/country.service";

export default {
  name: "CountryView",
  components: {
    ValidationObserver
  },
  props: {
    id: {
      type: String,
      required: true
    },
    country: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  computed: {
    isNew() {
      return !(this.country._links && this.country._links.self)
    },
    isEditable() {
      return !!this.country?._links?.modify?.href
    }
  },
  methods: {
    deleteCountry() {
      CountryService.delete(this.country)
          .then(this.$emit('delete'))
    },
    onSubmit() {
      CountryService.save(this.country)
          .then(response => {
            this.$refs[this.id + '-observer'].reset()
            this.$emit('save', response.data)
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              this.$refs[this.id + '-observer'].setErrors(error.response.data.errors)
            }
          })

    },
  }
}
</script>

<style scoped>

</style>