var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "vid": "create_invoiceRecipient_observer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-modal', {
          ref: "modal",
          attrs: {
            "id": _vm.id
          },
          on: {
            "hidden": _vm.reset,
            "ok": _vm.submit
          },
          scopedSlots: _vm._u([{
            key: "modal-title",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoiceRecipient.migrate')) + " ")];
            },
            proxy: true
          }, {
            key: "default",
            fn: function fn() {
              var _vm$emrRecipient, _vm$emrRecipient$_lin, _vm$emrRecipient$_lin2;

              return [_c('div', {
                staticClass: "form-row pb-2"
              }, [_c('label', {
                staticClass: "col-4 col-form-label required text-right",
                attrs: {
                  "for": _vm.id + '-customerId'
                }
              }, [_vm._v(_vm._s(_vm.$t('customer.id')))]), _c('div', {
                staticClass: "col-8"
              }, [_c('e-form-text-input', {
                attrs: {
                  "id": _vm.id + '-customerId',
                  "name": "customerId",
                  "rules": "required|length:6"
                },
                model: {
                  value: _vm.customerId,
                  callback: function callback($$v) {
                    _vm.customerId = $$v;
                  },
                  expression: "customerId"
                }
              })], 1)]), _vm.customerId.length != 6 ? _c('div') : _vm.searching ? _c('div', {
                staticClass: "form-row pb-2"
              }, [_c('div', {
                staticClass: "col-8 offset-4"
              }, [_c('b-spinner')], 1)]) : !!((_vm$emrRecipient = _vm.emrRecipient) !== null && _vm$emrRecipient !== void 0 && (_vm$emrRecipient$_lin = _vm$emrRecipient._links) !== null && _vm$emrRecipient$_lin !== void 0 && (_vm$emrRecipient$_lin2 = _vm$emrRecipient$_lin.self) !== null && _vm$emrRecipient$_lin2 !== void 0 && _vm$emrRecipient$_lin2.href) ? _c('div', {
                staticClass: "form-row pb-2"
              }, [_c('div', {
                staticClass: "col-8 offset-4 text-danger"
              }, [_vm._v(_vm._s(_vm.$t('invoiceRecipient.already-exists')))])]) : !_vm.emrRecipient ? _c('div', {
                staticClass: "form-row pb-2"
              }, [_c('div', {
                staticClass: "col-8 offset-4 text-danger"
              }, [_vm._v(_vm._s(_vm.$t('invoiceRecipient.not-found')))])]) : _c('div', [_c('div', {
                staticClass: "form-row pb-2"
              }, [_c('label', {
                staticClass: "col-4 col-form-label required text-right",
                attrs: {
                  "for": _vm.id + '-name'
                }
              }, [_vm._v(_vm._s(_vm.$t('customer.name')))]), _c('div', {
                staticClass: "col-8"
              }, [_c('e-form-text-input', {
                attrs: {
                  "id": _vm.id + '-name',
                  "name": "name",
                  "disabled": true,
                  "rules": "required",
                  "value": _vm.emrRecipient.name
                }
              })], 1)]), _c('div', {
                staticClass: "form-row pb-2"
              }, [_c('label', {
                staticClass: "col-4 col-form-label text-right",
                attrs: {
                  "for": _vm.id + '-additionalName'
                }
              }, [_vm._v(_vm._s(_vm.$t('address.additionalName')))]), _c('div', {
                staticClass: "col-8"
              }, [_c('e-form-text-input', {
                attrs: {
                  "id": _vm.id + '-additionalName',
                  "name": "name",
                  "disabled": true,
                  "value": _vm.emrRecipient.additionalName
                }
              })], 1)]), _c('div', {
                staticClass: "form-row pb-2"
              }, [_c('label', {
                staticClass: "col-4 col-form-label text-right",
                attrs: {
                  "for": _vm.id + '-street'
                }
              }, [_vm._v(_vm._s(_vm.$t('address.street')))]), _c('div', {
                staticClass: "col-8"
              }, [_c('e-form-text-input', {
                attrs: {
                  "id": _vm.id + '-street',
                  "name": "street",
                  "disabled": true,
                  "rules": "required",
                  "value": _vm.emrRecipient.street
                }
              })], 1)]), _c('div', {
                staticClass: "form-row pb-2"
              }, [_c('label', {
                staticClass: "col-4 col-form-label text-right",
                attrs: {
                  "for": _vm.id + '-country'
                }
              }, [_vm._v(_vm._s(_vm.$t('address.country')) + " / " + _vm._s(_vm.$t('address.zipCode')) + " / " + _vm._s(_vm.$t('address.city')))]), _c('div', {
                staticClass: "col-2"
              }, [_c('e-form-text-input', {
                attrs: {
                  "id": _vm.id + '-country',
                  "name": "country",
                  "disabled": true,
                  "rules": "required",
                  "value": _vm.emrRecipient.country
                }
              })], 1), _c('div', {
                staticClass: "col-2"
              }, [_c('e-form-text-input', {
                attrs: {
                  "id": _vm.id + '-zipCode',
                  "name": "zipCode",
                  "disabled": true,
                  "rules": "required",
                  "value": _vm.emrRecipient.zipCode
                }
              })], 1), _c('div', {
                staticClass: "col-4"
              }, [_c('e-form-text-input', {
                attrs: {
                  "id": _vm.id + '-city',
                  "name": "city",
                  "disabled": true,
                  "rules": "required",
                  "value": _vm.emrRecipient.city
                }
              })], 1)])])];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn(_ref2) {
              var _vm$emrRecipient2, _vm$emrRecipient2$_li, _vm$emrRecipient2$_li2;

              var ok = _ref2.ok,
                  cancel = _ref2.cancel,
                  hide = _ref2.hide;
              return [_c('b-button', {
                attrs: {
                  "variant": "secondary"
                },
                on: {
                  "click": cancel
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.cancel')))]), _c('b-button', {
                attrs: {
                  "variant": "primary",
                  "disabled": invalid || _vm.searching || !_vm.emrRecipient || !!((_vm$emrRecipient2 = _vm.emrRecipient) !== null && _vm$emrRecipient2 !== void 0 && (_vm$emrRecipient2$_li = _vm$emrRecipient2._links) !== null && _vm$emrRecipient2$_li !== void 0 && (_vm$emrRecipient2$_li2 = _vm$emrRecipient2$_li.self) !== null && _vm$emrRecipient2$_li2 !== void 0 && _vm$emrRecipient2$_li2.href)
                },
                on: {
                  "click": ok
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.ok')))])];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }