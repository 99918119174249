<template>
  <country-list />
</template>

<script>
import CountryList from "@/components/CountryList";
export default {
  name: "AdminCountries",
  components: {CountryList}
}
</script>

<style scoped>

</style>