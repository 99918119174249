var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "printerTypeModal",
      "title": _vm.onlyPosition ? _vm.$t('settings.printer.position') : _vm.$t('settings.printer.printer-type')
    },
    on: {
      "ok": _vm.emitPrinterType
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_vm.onlyPosition ? _c('b-form-group', {
          attrs: {
            "label": _vm.$t('settings.printer.select-position'),
            "label-for": "labelPosition"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var ariaDescribedby = _ref.ariaDescribedby;
              return [_c('div', {
                staticClass: "row mt-2 mb-2"
              }, [_c('div', {
                staticClass: "col-3"
              }, [_c('b-img', {
                staticStyle: {
                  "width": "100%"
                },
                attrs: {
                  "src": "/labelnumbers_a4.png"
                }
              })], 1), _c('div', {
                staticClass: "col-9"
              }, [_c('b-form-radio-group', {
                attrs: {
                  "name": "labelPosition",
                  "disabled": _vm.printerType != 'LASER_POSITION',
                  "aria-describedby": ariaDescribedby
                },
                model: {
                  value: _vm.labelPosition,
                  callback: function callback($$v) {
                    _vm.labelPosition = $$v;
                  },
                  expression: "labelPosition"
                }
              }, [_c('b-form-radio', {
                attrs: {
                  "value": "1"
                }
              }, [_vm._v("1")]), _c('b-form-radio', {
                attrs: {
                  "value": "2"
                }
              }, [_vm._v("2")]), _c('b-form-radio', {
                attrs: {
                  "value": "3"
                }
              }, [_vm._v("3")]), _c('b-form-radio', {
                attrs: {
                  "value": "4"
                }
              }, [_vm._v("4")])], 1)], 1)])];
            }
          }], null, false, 2702016647)
        }) : _c('b-form-group', {
          attrs: {
            "label": _vm.$t('settings.printer.select-printer-type'),
            "label-for": "printerType"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var ariaDescribedby = _ref2.ariaDescribedby;
              return [_c('b-form-radio-group', {
                attrs: {
                  "aria-describedby": ariaDescribedby,
                  "name": "printerType"
                },
                model: {
                  value: _vm.printerType,
                  callback: function callback($$v) {
                    _vm.printerType = $$v;
                  },
                  expression: "printerType"
                }
              }, [_c('b-form-radio', {
                attrs: {
                  "value": "LASER"
                }
              }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings.printer.labels.laser')))]), _c('b-form-text', {
                domProps: {
                  "innerHTML": _vm._s(_vm.$t('settings.printer.labels.laser.description'))
                }
              })], 1), _c('b-form-radio', {
                attrs: {
                  "value": "LASER_POSITION"
                }
              }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings.printer.labels.laser_position')))]), _c('b-form-text', {
                domProps: {
                  "innerHTML": _vm._s(_vm.$t('settings.printer.labels.laser.description'))
                }
              }), _c('b-form-group', {
                attrs: {
                  "label": _vm.$t('settings.printer.select-position'),
                  "label-for": "labelPosition"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var ariaDescribedby = _ref3.ariaDescribedby;
                    return [_c('div', {
                      staticClass: "row"
                    }, [_c('div', {
                      staticClass: "col-3"
                    }, [_c('b-img', {
                      staticStyle: {
                        "width": "100%"
                      },
                      attrs: {
                        "src": "/labelnumbers_a4.png"
                      }
                    })], 1), _c('div', {
                      staticClass: "col-9"
                    }, [_c('b-form-radio-group', {
                      attrs: {
                        "name": "labelPosition",
                        "disabled": _vm.printerType != 'LASER_POSITION',
                        "aria-describedby": ariaDescribedby
                      },
                      model: {
                        value: _vm.labelPosition,
                        callback: function callback($$v) {
                          _vm.labelPosition = $$v;
                        },
                        expression: "labelPosition"
                      }
                    }, [_c('b-form-radio', {
                      attrs: {
                        "value": "1"
                      }
                    }, [_vm._v("1")]), _c('b-form-radio', {
                      attrs: {
                        "value": "2"
                      }
                    }, [_vm._v("2")]), _c('b-form-radio', {
                      attrs: {
                        "value": "3"
                      }
                    }, [_vm._v("3")]), _c('b-form-radio', {
                      attrs: {
                        "value": "4"
                      }
                    }, [_vm._v("4")])], 1)], 1)])];
                  }
                }], null, true)
              })], 1), _c('b-form-radio', {
                attrs: {
                  "value": "ZEBRA"
                }
              }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings.printer.labels.zebra')))]), _c('b-form-text', {
                domProps: {
                  "innerHTML": _vm._s(_vm.$t('settings.printer.labels.zebra.description'))
                }
              })], 1)], 1)];
            }
          }])
        })];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn(_ref4) {
        var ok = _ref4.ok,
            cancel = _ref4.cancel,
            hide = _ref4.hide;
        return [!_vm.onlyPosition ? _c('b-checkbox', {
          staticClass: "mr-auto",
          model: {
            value: _vm.save,
            callback: function callback($$v) {
              _vm.save = $$v;
            },
            expression: "save"
          }
        }, [_vm._v(_vm._s(_vm.$t('settings.save')))]) : _vm._e(), _c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.abort')))]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": ok
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.print')))])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }