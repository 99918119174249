var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
            changed = _ref.changed;
        return [_c('b-modal', {
          ref: "modal",
          attrs: {
            "size": "xl",
            "id": _vm.id
          },
          on: {
            "hidden": _vm.reset
          },
          scopedSlots: _vm._u([{
            key: "modal-title",
            fn: function fn() {
              var _vm$address, _vm$address$_links, _vm$address$_links$se;

              return [(_vm$address = _vm.address) !== null && _vm$address !== void 0 && (_vm$address$_links = _vm$address._links) !== null && _vm$address$_links !== void 0 && (_vm$address$_links$se = _vm$address$_links.self) !== null && _vm$address$_links$se !== void 0 && _vm$address$_links$se.href ? _c('span', [_vm._v(_vm._s(_vm.$t('customer.edit-address', {
                customerId: _vm.customerId
              })))]) : _c('span', [_vm._v(_vm._s(_vm.$t('customer.add-address', {
                customerId: _vm.customerId
              })))])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function fn() {
              return [_c('address-view', {
                ref: "addressView",
                attrs: {
                  "id": _vm.id,
                  "show-actions": false,
                  "is-admin": _vm.isAdmin
                },
                on: {
                  "save": function save($event) {
                    return _vm.$emit('save', $event);
                  }
                },
                model: {
                  value: _vm.address,
                  callback: function callback($$v) {
                    _vm.address = $$v;
                  },
                  expression: "address"
                }
              })];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn(_ref2) {
              var _vm$address2, _vm$address2$_links, _vm$address2$_links$d, _vm$$refs$addressView, _vm$$refs$addressView2;

              var ok = _ref2.ok,
                  cancel = _ref2.cancel,
                  hide = _ref2.hide;
              return [_c('div', {
                staticClass: "buttons"
              }, [(_vm$address2 = _vm.address) !== null && _vm$address2 !== void 0 && (_vm$address2$_links = _vm$address2._links) !== null && _vm$address2$_links !== void 0 && (_vm$address2$_links$d = _vm$address2$_links.delete) !== null && _vm$address2$_links$d !== void 0 && _vm$address2$_links$d.href ? _c('b-button', {
                staticClass: "float-left",
                attrs: {
                  "variant": "danger"
                },
                on: {
                  "click": function click($event) {
                    return _vm.confirmDelete(_vm.address, ok);
                  }
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.delete')))]) : _vm._e(), _c('b-button', {
                attrs: {
                  "variant": "secondary"
                },
                on: {
                  "click": cancel
                }
              }, [_vm._v(_vm._s(_vm.$t('controls.cancel')))]), _c('b-button', {
                attrs: {
                  "variant": "primary",
                  "disabled": ((_vm$$refs$addressView = _vm.$refs['addressView']) === null || _vm$$refs$addressView === void 0 ? void 0 : _vm$$refs$addressView.isSaveInProgress()) || !changed || invalid
                },
                on: {
                  "click": function click($event) {
                    return _vm.submit(ok);
                  }
                }
              }, [(_vm$$refs$addressView2 = _vm.$refs['addressView']) !== null && _vm$$refs$addressView2 !== void 0 && _vm$$refs$addressView2.isSaveInProgress() ? _c('b-spinner', {
                attrs: {
                  "small": ""
                }
              }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t('controls.save')) + " ")], 1)], 1)];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }