import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"
import CustomerService from "@/services/customer.service";

const rel = 'locations'
const path = '/api/locations'
const schema = {
    abbreviation: null,
    name: null,
}
const header = {
    abbreviation: {
        name: 'Niederlassung',
        col: 'col-2',
        property: 'abbreviation'
    },
    name: {
        name: 'Bezeichnung',
        col: 'col-3',
        property: 'name'
    }
}
const filter = null
const sync = null
const initialSort = 'abbreviation'
const projection = 'list'
const create = false

const Service = {
    getHeader: function () {
        return header
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function() {
        return initialSort
    },
    getProjection: function() {
        return projection
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size,
                          showAll = false) {
        try {
            return ApiService.get(path, {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100,
                    showAll: showAll
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    allPickUpOrderLocations: async function (sortProperty,
                          sortDirection,
                          size) {
        try {
            return ApiService.get('/api/allPickUpOrderLocations', {
                params: {
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    nextPage: async function (link, tmpRel) {
        let theRel = tmpRel != null ? tmpRel : rel;
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][theRel]) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][theRel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    },
    save: async function (location) {
        throw("save location not allowed")
    },
}

export default Service