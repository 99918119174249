<template>
  <div id="app">
    <about-modal ref="aboutModal" />
    <e-bug-report-modal ref="bugReportModal"
                      project-id="f41d0080-308e-4a25-a35c-2e943f6e49e6"
                      area-path="myEmons"
                      auth-header="Basic Om1jc2J6NnB2ZmtsbTZzenJlNWVta3h3eWx0aXo1b3F0NHBibm9pemJ6YXNqdDJ2cGc0bWE="
                      :version="$root.$options.version"
                      @submitted="bugReportSubmitted($event)"
    />
    <e-dev-ribbon />
    <e-menu-bar :user-data="userData" @customerChanged="forceUpdate++">
      <template #help-items>
        <b-dropdown-item href="/MyEmons_Handout_26042023.pdf" target="_blank">
          <b-icon-book />
          {{ $t('myemons.user-manual') }}
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item @click="$refs.aboutModal.show()">
          <b-icon-info-circle />
          {{ $t('myemons.about') }}
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item @click="$refs.bugReportModal.show()">
          <b-icon-bug/>
          {{ $t('report.bug') }}
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item href="https://emons-cms-public.s3-de-central.profitbricks.com/5f7efb140a2f05d7b0e08319/_1602600244798.pdf" target="_blank">
          {{ $t('myemons.adsp') }}</b-dropdown-item>
        <b-dropdown-item href="https://www.emons.de/datenschutz" target="_blank">{{ $t('myemons.privacy-statement') }}</b-dropdown-item>
        <b-dropdown-item href="https://www.emons.de/impressum" target="_blank">{{ $t('myemons.imprint') }}</b-dropdown-item>
      </template>

      <template #popover-items-before-logout="{loggedIn}" v-if="$router.checkPermission('/settings/index')">
        <b-dropdown-item v-if="loggedIn" to="/settings/index">
          <b-icon-wrench/>
          {{ $t('settings') }}
        </b-dropdown-item>
      </template>
      <template #popover-items-after-logout>
      </template>
      <template #nav-links>
        <li v-if="!$router.isLoggedIn()">
          <a href="#" @click="login">
            {{ $t('nav.login') }}
          </a>
        </li>

        <li v-if="!$router.isLoggedIn()"
            :class="{'router-link-active': subIsActive('/registration')}">
          <router-link to="/registration">{{ $t('nav.registration') }}</router-link>
        </li>

        <li v-if="$router.checkPermission('/shipments/list')"
            :class="{'router-link-active': subIsActive('/shipments')}">
          <router-link to="/shipments/list">{{ $t('nav.shipments') }}</router-link>
        </li>

        <li v-if="$router.checkPermission('/addresses/addresses')"
            :class="{'router-link-active': subIsActive('/addresses')}">
          <router-link to="/addresses/addresses">{{ $t('nav.addresses') }}</router-link>
        </li>

        <li v-if="$router.checkPermission('/templates/cargoList')"
            :class="{'router-link-active': subIsActive('/templates')}">
          <router-link to="/templates/cargoList">{{ $t('nav.templates') }}</router-link>
        </li>

        <li v-if="$router.checkPermission('/administration/customers')"
            :class="{'router-link-active': subIsActive('/administration')}">
          <router-link to="/administration/customers">{{ $t('nav.admin') }}</router-link>
        </li>
      </template>
    </e-menu-bar>
    <div id="app-content">
      <router-view :key="forceUpdate" />
    </div>
    <error-modal id="errorModal" ref="errorModal" />
  </div>
</template>

<script>
import ErrorModal from "@/components/modals/ErrorModal"
import AboutModal from "@/components/modals/AboutModal"
import UserService from "@/services/user.service"

export default {
  components: {AboutModal, ErrorModal},
  created() {
    this.$log('DEBUG', "App created")
    // event bus - use with caution, this is an antipattern
    this.$eventBus.$on("backend-error", error => this.handleBackendError(error))
    this.$eventBus.$on("frontend-error", error => this.handleFrontendError(error))
    //this.checkProfile(this)
  },
  data() {
    return {
      forceUpdate: 0
    }
  },
  asyncComputed: {
    userData: async function() {
      return UserService.getSelf()
    }
  },
  methods: {
    login() {
      return this.$root.$options.keycloak.login({
        redirectUri: window.location.origin + this.$router.currentRoute.fullPath
      })
    },
    handleBackendError: function (error) {
      this.$log("ERROR", "Caught backend error", error.toJSON?error.toJSON():error, error.response)

      this.$refs.errorModal.show(error)
    },
    handleFrontendError: function (error) {
      this.$refs.errorModal.show(error)
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },
    bugReportSubmitted(report) {
      this.$bvModal.msgBoxOk(this.$t('report.bug.created', {id: report.id}))
    }
  }
}
</script>

<style>
/* asap-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/asap-v26-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('/fonts/asap-v26-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Asap';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/asap-v26-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('/fonts/asap-v26-latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/asap-v26-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('/fonts/asap-v26-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Asap';
  font-style: italic;
  font-weight: 500;
  src: url('/fonts/asap-v26-latin-500italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('/fonts/asap-v26-latin-500italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/asap-v26-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('/fonts/asap-v26-latin-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Asap';
  font-style: italic;
  font-weight: 600;
  src: url('/fonts/asap-v26-latin-600italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('/fonts/asap-v26-latin-600italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/asap-v26-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('/fonts/asap-v26-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* asap-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Asap';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/asap-v26-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
  url('/fonts/asap-v26-latin-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
}

#app {
  font-family: 'Asap', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(64, 64, 64);
  height: 100%;
}

#app-content {
  padding-top: 55px;
  height: 100%;
}

.clip-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#e-navbar .navbar .navbar-brand {
  background-image: url("../src/assets/emons.logo.transparent@2x.png");
  background-size: 97px 30px;
  background-repeat: no-repeat;
  width: 97px;
  margin-top: 3px;
  margin-right: 13px;
}

#e-navbar.with-subnav ~ #app-content {
  padding-top: 8px;
}

#e-navbar .navbar-nav:first-child > li.router-link-active > a:after {
  border-bottom: 2px solid #eee;
  bottom: 8px;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  -webkit-animation: 0.75s zoomIn ease;
  animation: 0.75s zoomIn ease;
}

.bs-popover-bottom > div.arrow:after {
  content: "";
  border-bottom-color: rgba(0, 0, 0, 0.8) !important;
}

</style>
