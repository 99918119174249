<template>
  <validation-observer ref="observer"
                       v-slot="{ invalid, changed, dirty, failed, handleSubmit }" slim>

      <div class="row">

        <div class="col-md-6">

          <div class="form-row">
            <div class="col-12"><label><strong>{{ $t('address.address-data') }}</strong></label></div>
          </div>

          <div class="form-row">

            <div class="col-12">

              <div class="d-inline-flex">

                <validation-provider name="addressType"
                                     :vid="id + '-addressType'"
                                     :rules="'required'"
                                     v-slot="{ classes, errors }" slim>

                  <b-form-checkbox-group :disabled="!canEdit()"
                      name="addressTypes"
                      class="mb-4 address-type-checkbox-group"
                      :state="state"
                      v-model="details.types" @input="$emit('input', details)">

                    <b-form-checkbox value="SENDER" @input="checkTypes($event)">
                      {{ $t('addressType.SENDER') }}
                    </b-form-checkbox>

                    <b-form-checkbox value="DEFAULT_SENDER" :disabled="isDefaultSenderDisabled">
                      {{ $t('addressType.DEFAULT_SENDER') }}
                    </b-form-checkbox>

                    <b-form-checkbox value="CONSIGNEE" @input="checkTypes($event)">
                      {{ $t('addressType.CONSIGNEE') }}
                    </b-form-checkbox>

                    <b-form-checkbox value="DEFAULT_CONSIGNEE" :disabled="isDefaultConsigneeDisabled">
                      {{ $t('addressType.DEFAULT_CONSIGNEE') }}
                    </b-form-checkbox>

                    <b-form-checkbox value="LOADING_SITE" @input="checkTypes($event)"
                                     :disabled="!isLoadingSiteSelectable">
                      {{ $t('addressType.LOADING_SITE') }}
                    </b-form-checkbox>
                    <b-form-checkbox value="NEUTRAL"
                                     :disabled="isNeutralDisabled">{{ $t(getNeutralCheckboxTranslationKey()) }}
                    </b-form-checkbox>

                    <b-form-invalid-feedback :state="state">Es muss mindestens Versender, Empfänger oder Ladestelle ausgewählt werden!</b-form-invalid-feedback>

                  </b-form-checkbox-group>

                </validation-provider>

              </div>
            </div>

          </div>


          <div class="form-row">

            <div class="col-6">
              <e-form-text-input :id="id + '-name'" name="name" rules="required" maxlength="35"
                                 :placeholder="$t('address.name')"
                                 :disabled="!canEdit() || isAdministrativeDataReadOnly"
                                 v-model="details.name" @input="$emit('input', details)" />
            </div>
            <div class="col-3" v-if="isAdmin">
              <e-form-text-input :id="id + '-emnonsCustomerId'" name="emonsCustomerId" maxlength="6"
                                 :placeholder="$t('address.emonsCustomerId')"
                                 :disabled="!canEdit() || isAdministrativeDataReadOnly"
                                 v-model="details.emonsCustomerId"  @input="$emit('input', details)" />
            </div>
            <div class="col-3">
              <e-form-text-input :id="id + '-customerId'" name="customerId" maxlength="35"
                                 :placeholder="$t('address.customerId')"
                                 :disabled="!canEdit() || isAdministrativeDataReadOnly"
                                 v-model="details.customerId"  @input="$emit('input', details)" />
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-additionalName'" name="additionalName" maxlength="35"
                                 :placeholder="$t('address.additionalName')"
                                 :disabled="!canEdit() || isAdministrativeDataReadOnly"
                                 v-model="details.additionalName" @input="$emit('input', details)" />
            </div>
            <div class="col-5">
              <e-form-checkbox :id="id + '-isRetail'"
                               name="isRetail"
                               :disabled="!canEdit() || isAdministrativeDataReadOnly"
                               :placeholder="$t('address.isRetail')"
                               v-model="details.isRetail" @input="$emit('input', details)" />
            </div>
          </div>


          <div class="form-row">
            <div class="col-12">
              <e-form-text-input :id="id + '-street'" name="street" rules="required" maxlength="35"
                                 :placeholder="$t('address.streetWithNumber')"
                                 :disabled="!canEdit() || isAdministrativeDataReadOnly"
                                 v-model="details.street" @input="$emit('input', details)" />
            </div>
          </div>

          <div class="form-row">
            <div class="col-3">
              <country-select :id="id + '-country'" name="country" :required="true"
                              v-model="details.country"
                              :placeholder="$t('address.country')"
                              :disabled="!canEdit() || isAdministrativeDataReadOnly" @input="$emit('input', details)" />
            </div>
            <div class="col-3">
              <e-form-text-input :id="id + '-zipCode'" name="zipCode"
                                 :rules="{required: true, zipCode:{country: '@' + id + '-country'}}"
                                 :placeholder="$t('address.zipCode')"
                                 :disabled="!canEdit() || isAdministrativeDataReadOnly"
                                 @blur="formatZipCode($event)"
                                 v-model="details.zipCode" @input="$emit('input', details)" />
            </div>
            <div class="col-6">
              <city-select :id="id + '-city'"
                           rules="required"
                           :disabled="!canEdit() || isAdministrativeDataReadOnly"
                           :country="details.country"
                           :zip-code="details.zipCode"
                           v-model="details.city"
                           @input="$emit('input', details)"
                           @citySelect="onCitySelect"/>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12">
              <order-invoice-recipient-view :id="id + '-invoiceRecipient'" :editable="canEdit() && details.types?.some((t) => t == 'SENDER' || t == 'LOADING_SITE')" :data-editable="false"
                                            :api-path="details?._links?.invoiceRecipients?.href"
                                            v-model="details.invoiceRecipient" />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-row">
            <div class="col-12"><label><strong>{{ $t('address.contact-data') }}</strong></label></div>
          </div>

          <div class="form-row">
            <div class="col-sm-12 col-md-6">
              <e-form-text-input :id="id + '-contactName'" name="contactName" maxlength="255"
                                 :placeholder="$t('address.contactName')"
                                 :disabled="!canEdit()"
                                 v-model="details.contactName" @input="$emit('input', details)" />
            </div>
            <div class="col-sm-12 col-md-6">
              <e-form-text-input :id="id + '-phone'" name="phone" rules="phoneNumber"
                                 :placeholder="$t('address.phone')"
                                 :disabled="!canEdit()"
                                 v-model="details.phone" @input="$emit('input', details)" />
            </div>
            <div class="col-sm-12 col-md-6">
              <e-form-text-input :id="id + '-email'" name="email"
                                 :placeholder="$t('address.email')"
                                 :disabled="!canEdit()"
                                 :rules="{email: true, required: details.autosendShippingDocuments === true && isLoadingSiteSelected}"
                                 v-model="details.email" @input="$emit('input', details)" />
            </div>
            <div class="col-sm-12 col-md-6">
              <e-form-text-input :id="id + '-mobile'" name="mobile" rules="phoneNumber:MOBILE"
                                 :placeholder="$t('address.mobile')"
                                 :disabled="!canEdit()"
                                 v-model="details.mobile" @input="$emit('input', details)" />
            </div>
            <div class="col-sm-12 col-md-6">
              <e-form-text-input :id="id + '-web'" name="web"
                                 :placeholder="$t('address.url')"
                                 :disabled="!canEdit()"
                                 v-model="details.web" @input="$emit('input', details)" />
            </div>
            <div class="col-sm-12 col-md-6">
              <e-form-checkbox :id="id + '-autosendShippingDocuments'"
                               name="autosendShippingDocuments"
                               :disabled="!details?.types?.some(type => type === 'LOADING_SITE') || !canEdit()"
                               :placeholder="$t('address.autosendShippingDocuments')"
                               v-model="details.autosendShippingDocuments" @input="$emit('input', details)" />
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <validation-provider :vid="id + '-deliveryNotes'">
                <delivery-notes-view :id="id + '-deliveryNotes'" :editable="canEdit()" v-model="details.deliveryNotes"
                                     @input="$emit('input', details)"
                                     :consignee="details" :sender="details"
                                     :add-defaults="false" />
              </validation-provider>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-2" v-if="showActions && (canDelete() || canEdit())">
        <div class="col-md-6 offset-md-6">
          <div class="form-group row justify-content-end">
            <div class="col-sm-3" v-if="canEdit() && !isNew()">
              <button id="abortButton"
                      class="btn btn-sm btn-block btn-outline-secondary"
                      :disabled="!(changed || dirty)"
                      @click.prevent="abortEdit()">{{ $t('controls.abort') }}
              </button>
            </div>
            <div class="col-sm-3" v-if="canEdit()">
              <button id="saveButton"
                      type="submit"
                      class="btn btn-sm btn-block btn-outline-primary"
                      :disabled="axiosSaveInProgress || !(changed) || invalid"
                      @click.prevent="saveEntity()">{{ $t('controls.save') }}
              </button>
            </div>
            <div class="col-sm-3" v-if="canDelete()">
              <button id="deleteButton"
                      class="btn btn-sm btn-block btn-outline-danger"
                      @click.prevent="confirmDelete()">{{ $t('controls.delete') }}
              </button>
            </div>
          </div>
        </div>

      </div>

  </validation-observer>
</template>

<script>

import service from "@/services/address.service";
import {formatZipCode, ValidationObserver, ValidationProvider} from "@emons/emons-vue"
import CountrySelect from "@/components/form-controls/CountrySelect";
import AxiosServiceMixin from "@/components/mixins/AxiosServiceMixin";
import DeliveryNotesView from "@/components/DeliveryNotesView";
import CitySelect from "@/components/form-controls/CitySelect"
import OrderInvoiceRecipientView from "@/components/OrderInvoiceRecipientView.vue";

export default {
  name: "AddressView",
  components: {OrderInvoiceRecipientView, CitySelect, CountrySelect, DeliveryNotesView, ValidationObserver, ValidationProvider },
  mixins: [AxiosServiceMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      details: JSON.parse(JSON.stringify(this.value)),
      addressTypes: [{
        item: 'SENDER'
      }, {
        item: 'DEFAULT_SENDER'
      }, {
        item: 'CONSIGNEE'
      }, {
        item: 'DEFAULT_CONSIGNEE'
      }, {
        item: 'LOADING_SITE'
      }]
    }
  },
  mounted() {
    this.validateInitial()
  },
  computed: {
    isAdministrativeDataReadOnly: function () {
      return ((this.details.emonsCustomerId != null && this.details.emonsCustomerId.length > 0) && !this.isAdmin)
    },
    // TK26426.d
    isNeutralDisabled: function () {

      // Neutral macht nur Sinn, wenn Sender oder Empfänger angehakt wurde
      let case1 = !(this?.details?.types?.some(type => type === 'SENDER') || this?.details?.types?.some(type => type === 'CONSIGNEE'));

      // ...
      let case2 = (!this?.details?.types?.some(type => type === 'SENDER') && this?.details?.types?.some(type => type === 'CONSIGNEE'))
          || this?.details?.types?.some(type => type === 'LOADING_SITE');

      return !this.emonsCustomerIdEqualsCustomerId() && (case1 || case2)
    },
    isLoadingSiteSelected: function () {
      return this?.details?.types?.some(type => type === 'LOADING_SITE');
    },
    isLoadingSiteSelectable: function () {
      return !this.addressTypeEqualsSenderAndEmonsCustomerIdEqualsCustomerId()
    },
    isDefaultSenderDisabled: function() {
      return !this.canEdit() || !this?.details?.types?.some(type => type == 'SENDER')
    },
    isDefaultConsigneeDisabled: function() {
      return !this.canEdit() || !this?.details?.types?.some(type => type == 'CONSIGNEE')
    },
    state() {
      return this?.details?.types?.some(type => type === 'SENDER')
          || this?.details?.types?.some(type => type === 'CONSIGNEE')
          || this?.details?.types?.some(type => type === 'LOADING_SITE');
    }
  },
  methods: {
    validateInitial: function() {
      if (!this.isNew() && this.canEdit()) {
        this.$refs.observer.validate()
      }
    },
    checkTypes(types) {
      if (!types?.some(type => type == 'CONSIGNEE')) {
        this.$set(this.details, 'types', this.details?.types?.filter(type => type != 'DEFAULT_CONSIGNEE'))
      }
      if (!types?.some(type => type == 'SENDER')) {
        this.$set(this.details, 'types', this.details?.types?.filter(type => type != 'DEFAULT_SENDER'))

      }
      if (!types?.some(type => type == 'LOADING_SITE' || type == 'LOADING_SITE')) {
        this.$set(this.details, 'invoiceRecipient', null)
      }
      if (this.isNeutralDisabled) {
        let idx = this?.details?.types?.indexOf('NEUTRAL')
        if (idx > -1)
          this?.details?.types?.splice(idx, 1)
      }
    },
    emonsCustomerIdEqualsCustomerId: function () {
      return this.details.emonsCustomerId != null && this.details.emonsCustomerId.length > 0 && this.details.emonsCustomerId === this.details.customerId
    },
    // TK26426.b
    addressTypeEqualsSenderAndEmonsCustomerIdEqualsCustomerId: function () {
      return this?.details?.types?.some(type => type === 'SENDER')
          && this.emonsCustomerIdEqualsCustomerId()
    },
    // TK26426.c
    addressTypeEqualsSenderAndEmonsCustomerIdEmpty: function () {
      return this?.details?.types?.some(type => type === 'SENDER')
          && (this.details.emonsCustomerId == null || this.details.emonsCustomerId.length === 0)
    },
    getNeutralCheckboxTranslationKey() {
      if (this.addressTypeEqualsSenderAndEmonsCustomerIdEqualsCustomerId())
        return 'address.useNeutralLoadingSite';

      return 'address.useNeutralConsignor';
    },
    isNew() {
      return !this?.details?._links?.self
    },
    canDelete() {
      return this.isNew() || !!this?.details?._links?.delete
    },
    canEdit() {
      return this.isNew() || !!this?.details?._links?.modify
    },
    reset: async function () {
    },
    confirmDelete: function () {
      this.$bvModal.msgBoxConfirm(
          this.$t('address.delete.confirm'),
          { title: this.$t('address.delete.confirm.short')}
      ).then(result => {
        if (result) this.deleteEntity()
      })
    },
    deleteEntity() {
      this.$emit('enableSpinner');
      service.delete(this.details)
          .then(response => {
            this.$emit('delete', this.index)
            this.$emit('disableSpinner')
          })
    },
    async saveEntity(pathForCreate = null, dialogOnSuccess = true) {
      return await this.doSave(service, this.details,
          dialogOnSuccess?async () => {
              await this.$bvModal.msgBoxOk(this.$t('address.saved'), {
                title: this.$t('address.saved.short'),
                titleClass: 'pl-2',
                centered: true,
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
              }).then(
                  this.details['isOpen'] = false
              )
          }:() => {},
          () => {},
          this.$refs.observer,
          this.id + '-',
          pathForCreate
      )
    },
    abortEdit() {
      this.details = JSON.parse(JSON.stringify(this.value))
      this.$refs.observer.reset()
      this.validateInitial()
    },
    formatZipCode: function (zipCode) {
      this.details.zipCode = formatZipCode(this.details.country, zipCode)
    },
    onCitySelect: function(selection) {
      //B817: Wenn PLZ nur zum Teil eingegeben wird, dann aus der Liste der Orte ausgewählt wird mit vollständiger PLZ, diese auch im PLZ-Feld setzten!
      if(selection.zipCode.length > this.details.zipCode.length)
        this.details.zipCode = selection.zipCode;
    }
  },
  watch: {
    "value": {
      immediate: true,
      handler: async function (newVal, oldVal) {
        await this.reset()
        this.details = JSON.parse(JSON.stringify(newVal))
      }
    },
    "details.zipCode": { // wg. IN29303 hinzugefügt
      immediate: true,
      handler: async function (newVal, oldVal) {
        await this.reset()
      }
    },
    "details.types" : {
      immediate: false,
      handler: function (newVal) {
        if (!newVal.some(t => t === 'LOADING_SITE')) {
          if(!!this.details.autosendShippingDocuments) {
            this.details.autosendShippingDocuments = false
          }
        }
      }
    }
  }
}
</script>

<style scoped>

.address-type-checkbox-group >>> .custom-control-input.is-valid~.custom-control-label,
.address-type-checkbox-group >>> .was-validated .custom-control-input:valid~.custom-control-label {
  color: rgb(64, 64, 64) !important;
}

.address-type-checkbox-group >>> .custom-control-input.is-valid:checked~.custom-control-label::before,
.address-type-checkbox-group >>> .was-validated .custom-control-input:valid:checked~.custom-control-label::before {
  border-color: rgba(60,60,60,0.5) !important;
  background-color: #007bff !important;
}

.address-type-checkbox-group >>> .custom-control-input.is-valid~.custom-control-label::before,
.address-type-checkbox-group >>> .was-validated .custom-control-input:valid~.custom-control-label::before {
  border-color: rgba(60,60,60,0.5) !important;
}

</style>