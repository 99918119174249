<template>
  <validation-observer vid="create_customer_observer" ref="create_customer_observer"
                       v-slot="{ invalid }">
    <b-modal :id="id" ref="modal"
             @hidden="reset"
             @ok="submit">
      <template #modal-title>
        {{ $t('customer.create-customer') }}
      </template>

      <template #default>
        <div class="form-row pb-2">
          <label :for="id + '-customerId'" class="col-4 col-form-label required text-right">{{ $t('customer.id') }}</label>
          <div class="col-8">
            <e-form-text-input :id="id + '-customerId'" name="customerId"
                               rules="required|length:6" v-model="customerId"/>
          </div>
        </div>
        <div v-if="customerId.length != 6"></div>
        <div v-else-if="searching" class="form-row pb-2"><div class="col-8 offset-4"><b-spinner /></div></div>
        <div v-else-if="!!(emrCustomer?._links?.self?.href)" class="form-row pb-2">
          <div class="col-8 offset-4 text-danger">{{ $t('customer.already-exists') }}</div>
        </div>
        <div v-else-if="!(emrCustomer)" class="form-row pb-2">
          <div class="col-8 offset-4 text-danger">{{ $t('customer.not-found.euromistral') }}</div>
        </div>
        <div v-else>
          <div class="form-row pb-2">
            <label :for="id + '-name'" class="col-4 col-form-label required text-right">{{ $t('customer.name') }}</label>
            <div class="col-8">
              <e-form-text-input :id="id + '-name'" name="name" :disabled="true"
                                 rules="required" :value="emrCustomer.name"/>
            </div>
          </div>
          <div class="form-row pb-2">
            <label :for="id + '-additionalName'" class="col-4 col-form-label text-right">{{ $t('address.additionalName') }}</label>
            <div class="col-8">
              <e-form-text-input :id="id + '-additionalName'" name="name" :disabled="true"
                                 :value="emrCustomer.additionalName" />
            </div>
          </div>
          <div class="form-row pb-2">
            <label :for="id + '-street'" class="col-4 col-form-label text-right">{{ $t('address.street') }}</label>
            <div class="col-8">
              <e-form-text-input :id="id + '-street'" name="street" :disabled="true"
                                 rules="required" :value="emrCustomer.street" />
            </div>
          </div>
          <div class="form-row pb-2">
            <label :for="id + '-country'" class="col-4 col-form-label text-right">{{ $t('address.country') }} / {{ $t('address.zipCode') }} / {{ $t('address.city') }}</label>
            <div class="col-2">
              <e-form-text-input :id="id + '-country'" name="country" :disabled="true"
                                 rules="required" :value="emrCustomer.country" />
            </div>
            <div class="col-2">
              <e-form-text-input :id="id + '-zipCode'" name="zipCode" :disabled="true"
                                 rules="required" :value="emrCustomer.zipCode" />
            </div>
            <div class="col-4">
              <e-form-text-input :id="id + '-city'" name="city" :disabled="true"
                                 rules="required" :value="emrCustomer.city" />
            </div>
          </div>
        <div class="form-row">
          <label :for="id + '-location'" class="col-4 col-form-label required text-right">{{ $t('customer.branch') }}</label>
          <div class="col-8">
            <e-form-select v-if="locations?.length != 1" :id="id + '-location'" name="location" :placeholder="$t('customer.select-branch')"
                           rules="required" v-model="emrCustomer.location">
              <option v-for="(location, idx) in locations" :key="idx" :value="location.abbreviation">{{ location.name }}</option>
            </e-form-select>
            <e-form-text-input v-else :id="id + '-location'" name="location"
                               :value="locations[0].name" disabled />
          </div>
        </div>
        </div>
      </template>

      <template #modal-footer="{ok, cancel, hide}">
        <b-button variant="secondary" @click="cancel">{{ $t('controls.cancel') }}</b-button>
        <b-button variant="primary" @click="ok" :disabled="invalid || searching || !(emrCustomer) || !!(emrCustomer?._links?.self?.href)">{{ $t('controls.ok') }}</b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver} from '@emons/emons-vue'
import CustomerService from "@/services/customer.service"
import LocationService from "@/services/location.service"

export default {
  name: "CustomerCreateModal",
  components: {ValidationObserver},
  data() {
    return {
      searching: false,
      id: 'create-customer-modal',
      locations: [], // fetched from server
      customerId: '',
      location: '',
    }
  },
  created() {
    LocationService
        .find(
            null,
            null,
            'list',
            'abbreviation',
            'asc',
            100)
        .then(response => {
          this.locations = response.data.items
        })
  },
  asyncComputed: {
    emrCustomer: async function () {
      const customerId = this.customerId;
      this.searching = true
      if (customerId.length == 6) {
        const customer = await CustomerService.getByCustomerId(customerId, true)
        this.$log('DEBUG', 'customer', customer)
        this.searching = false
        return customer
      } else {
        this.searching = false
        return null
      }
    }
  },
  methods: {
    show: function () {
      this.$refs.modal.show()
    },
    reset: function () {
      this.searching = false
      this.customerId = ""
      this.location = ""
    },
    submit: async function (event) {
      event.preventDefault()

      try {
        await this.save()
        this.$refs.modal.hide()
        this.$refs.create_customer_observer.reset()
      } catch(error) {
        this.$eventBus.$emit('backend-error', error)
      }
    },
    save: async function () {
      if (this.locations?.length == 1) {
        this.emrCustomer.location = this.locations[0].abbreviation
      }
      if (!(this?.emrCustomer?.customerId) || !(this?.emrCustomer?.location)) {
        return
      }
      this.emrCustomer.activated = true
      try {
        let response = await CustomerService.save(this.emrCustomer)

        this.$log('DEBUG', 'saved', response)
        this.$refs.create_customer_observer.reset()
        this.$emit('save', response.data)
      } catch (error) {
        this.$log('WARN', 'Got error: ', error)
        if (!!error.response?.data?.errors) {
          this.$refs.create_customer_observer.setErrors(error.response.data.errors)
        } else {
          throw error
        }
      }
    },
  },
}
</script>

<style scoped>

</style>