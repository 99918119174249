import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const rel = 'customers'
const path = "/api/admin/customers"
const public_path = "/api/customers"
const self_path = '/api/customer-data'

const schema = {
    activated: true,
    name: null,
    location: null
}
const header = {
    activated: {
        name: 'Status',
        col: 'col-1',
        property: 'activated',
        formatter: function (val) {
            return val ? 'Aktiv' : 'Inaktiv'
        }
    },
    customerId: {
        name: 'Kundenummer',
        col: 'col-2',
        property: 'customerId'
    },
    name: {
        name: 'Kundenname',
        col: 'col-4',
        property: 'name'
    },
    location: {
        name: 'Niederlassung',
        col: 'col-3',
        property: 'location'
    }
}
const filterType = 'popover'
const filter = {
    location: {
        name: 'Niederlassung',
        orderBy: 'abbreviation,asc',
        selectable: [],
        selected: [],
        negate: false,
        links: {},
        page: {},
        api: '/api/admin/customers/search/findLocationsOnOccurrence',
        rel: 'locations',
        timeout: null
    }
}
const syncItems = undefined
const initialSort = 'customerId'
const projection = 'list'
const create = true

const Service = {
    getHeader: function () {
        return header
    },
    getFilterType: function () {
        return filterType
    },
    getFilter: function () {
        return filter
    },
    getSyncItems: function () {
        return syncItems
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function () {
        return initialSort
    },
    getProjection: function () {
        return projection
    },
    _get: async function (customerId, path, hash = null) {
        const customerPath = customerId ? '/' + customerId : ''
        try {
            const params = hash ? {hash: hash} : {}
            const response = await ApiService.get(path + customerPath, {
                params: params,

                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
            return response?.data
        } catch (error) {
            if (error?.response?.status == 404) {
                log("debug", "entity not found")
            } else {
                log("error", "Error querying entity:", error)
            }
        }
        return null
    },
    getSelf: async function () {
        return await this._get('', self_path)
    },
    getByCustomerIdAndHash: async function (customerId, hash) {
        return await this._get(customerId, public_path, hash)
    },
    getByCustomerId: async function (customerId, fallbackToEuromistral = false) {
        let customer = await this._get(customerId, path)
        if (customer == null && fallbackToEuromistral) {
            customer = await this._get(customerId, path + '/euromistral')
        }
        return customer
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size,
                          sync = false) {
        try {
            return ApiService.get(path, {
                params: {
                    query: query,
                    location: (filters && filters.location && filters.location.selected && filters.location.selected.length > 0)
                        ? filters.location.selected.map((item) => item['abbreviation']).join(',')
                        : null,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100,
                    sync: sync
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    findInFilter: async function (filterItem, searchText, queryDropDown) {
        try {
            return ApiService.get(filterItem['api'], {
                params: {
                    query: searchText,
                    queryDropDown: queryDropDown,
                    projection: 'dropDown',
                    sort: filterItem.orderBy,
                    size: 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let response = JSON.parse(data)

                        log("debug", response)

                        filterItem.links = response['_links']
                        filterItem.page = response['page']
                        filterItem.selectable = response['_embedded']
                            ? response['_embedded'][filterItem.rel]
                            : response['content']
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying filter elements:", error)
        }
    },
    importAddresses: async function (customer, file) {
        try {
            const formData = new FormData()
            formData.append('file', file)

            if (file.name.endsWith('.vcf'))
                return await ApiService.put(this.cleanLink(customer._links['exportAddresses'].href), formData, {
                    headers: {'Content-Type': 'text/vcf'}
                })

            if (file.name.endsWith('.xls'))
                return await ApiService.put(this.cleanLink(customer._links['exportAddresses'].href.replace('.vcf', '.xls')), formData, {
                    headers: {'Content-Type': 'application/vnd.ms-excel'}
                })

            if (file.name.endsWith('.xlsx'))
                return await ApiService.put(this.cleanLink(customer._links['exportAddresses'].href.replace('.vcf', '.xlsx')), formData, {
                    headers: {'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                })

            if (file.name.endsWith('.csv'))
                return await ApiService.put(this.cleanLink(customer._links['exportAddresses'].href.replace('.vcf', '.csv')), formData, {
                    headers: {'Content-Type': 'text/csv'}
                })
        } catch (error) {
            log('error', 'Error exporting addresses:', error)
        }
    },
    exportAddresses: async function (customer) {
        try {
            return ApiService.get(this.cleanLink(customer._links['exportAddresses'].href), {
                params: {
                    headers: {
                        Accept: 'text/vcf'
                    },
                    responseType: 'arraybuffer'
                }
            }).then(
                response => {
                    let blob = new Blob([response.data], {type: 'text/vcf'})
                    let fileURL = window.URL.createObjectURL(blob)

                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', customer.customerId + '.vcf');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                }
            )
        } catch (error) {
            log('error', 'Error exporting addresses:', error)
        }
    },
    deleteAllAddresses: async function(customer) {
      log('debug', 'deleteAll', customer, customer.addresses?.links?.deleteAll?.href)
      return ApiService.delete(customer.addresses?.links?.deleteAll?.href, {})
    },
    findAddresses: async function (customer, query, size, sortProperty, sortDirection) {
        let rel = 'addresses';
        try {
            return ApiService.get(this.cleanLink(customer._links['addresses'].href), {
                params: {
                    query: query,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {
                            return {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    nextPage: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    nextPageAddresses: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded']['addresses']) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded']['addresses']
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    },
    create: function () {
        log('debug', 'create() called...')
        return {
            isOpen: true,
            changed: true,
            country: 'DE',
            activated: true
        };
    },
    save: async function (customer) {
        if (customer._links && customer._links.self) {
            return ApiService.put(customer._links.self.href, customer).then(
                response => {
                    if (response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        } else {
            return ApiService.post(path, customer).then(
                response => {
                    if (response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        }
    },
    delete: async function (customer) {
        if (customer._links && customer._links.delete) {
            return ApiService.delete(customer._links.delete.href)
        }
    },

}

export default Service