<template>
  <inco-term-code-list />
</template>

<script>
import IncoTermCodeList from "@/components/IncoTermCodeList";

export default {
  name: "AdminIncoTermCodes",
  components: {IncoTermCodeList}
}
</script>

<style scoped>

</style>