import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const path = "/api/admin/services/sftp"
const schema = {
    enabled: null
}

const Service = {
    getStatus: async function () {
        try {
            const params = {}
            const response = await ApiService.get(path + "/status", {
                params: params,

                transformResponse: [function (data) {
                    if (data) {
                        return JSON.parse(data)
                    }
                }]
            })
            return response?.data
        } catch (error) {
            log("error", "Error querying sftp service:", error)
        }
        return null
    },

    setStatus: async function (status) {
        return ApiService.put(path + "/status", {enabled: status}).then(
            response => {
                if (response.data) {
                    ApiService.ensureSchema(schema, response.data)
                }
                return response?.data
            }
        )
    },
}

export default Service