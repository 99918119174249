<template>
  <div class="card">
    <div class="card-header p-2">
      <div class="form-row align-items-center">
        <label class="col-6 mb-0">{{ title?title:$t('orders.deliverynotes') }}</label>
        <div class="col-6 text-right">

          <b-dropdown class="notification-code-drop-down"
                      :disabled="value?.length >= maxNotes"
                      v-if="editable" variant="link" toggle-class="p-0" right
                      @hidden="searchTerm = ''" @shown="$refs.filterNotes.focus()">

            <template #button-content>
              {{ $t('controls.add') }}
            </template>

            <b-dropdown-form @submit.stop.prevent @focus="$refs.filterNotes.focus()">

              <b-form-input id="filter-notes" ref="filterNotes" size="sm" autocomplete="off"
                            placeholder="Filter"
                            v-model="searchTerm"></b-form-input>
            </b-dropdown-form>

            <b-dropdown-divider/>

            <b-dropdown-item v-for="(note, idx) in filteredDeliveryNotes" :key="'selectableNotes' + idx"
                             @click="addDeliveryNote(note)">{{ note.identifier + ' - ' + note.name }}
            </b-dropdown-item>

          </b-dropdown>
        </div>
      </div>
    </div>

    <!-- hidden references required for cross-field validation if they are nested in different ValidationObserver -->
    <validation-provider v-if="enableRules" vid="consignee.phone" :name="$t('form-fields.consignee.phone')" slim>
      <input type="hidden" :value="consignee?.phone"  ref="consignee.phone" />
    </validation-provider>
    <validation-provider v-if="enableRules" vid="consignee.mobile" :name="$t('form-fields.consignee.mobile')" slim>
      <input type="hidden" :value="consignee?.mobile"  ref="consignee.mobile" />
    </validation-provider>
    <validation-provider v-if="enableRules" vid="consignee.email" :name="$t('form-fields.consignee.email')" slim>
      <input type="hidden" :value="consignee?.email" ref="consignee.email" />
    </validation-provider>
    <validation-provider v-if="enableRules" vid="consignee.web" :name="$t('form-fields.consignee.web')" slim>
      <input type="hidden" :value="consignee?.web" ref="consignee.web" />
    </validation-provider>
    <validation-provider v-if="enableRules" vid="sender.phone" :name="$t('form-fields.sender.phone')" slim>
      <input type="hidden" :value="sender?.phone" ref="sender.phone" />
    </validation-provider>

    <ul class="list-group list-group-flush" v-if="value?.length > 0">
      <li class="list-group-item bg-warning text-dafk" v-if="editable && value?.length >= maxNotes">
        <b-icon-exclamation-triangle />
        {{ $t('shipment.deliverynotes.maximum-reached') }}
      </li>
      <li class="list-group-item" v-for="(note, idx) in value.filter(n => {if (this.filter) return this.filter(n); else return true})" :key="'deliveryNotes' + idx">
        <delivery-note :id="id + '-deliveryNotes['+idx+']'" :editable="editable" :note="note"
                       :consignee="consignee" :sender="sender"
                       :enable-rules="enableRules"
                       @delete="removeDeliveryNote(note)"
                       @attachment-value="note.attachmentValue = $event" />
      </li>
    </ul>
  </div>
</template>

<script>
import NotificationCodeService from "@/services/notificationCode.service"
import DeliveryNote from "@/components/DeliveryNote";
import {ValidationProvider} from "@emons/emons-vue"

export default {
  name: "DeliveryNotesView",
  components: {DeliveryNote, ValidationProvider},
  props: {
    value: {
      type: Array,
    },
    id: {
      type: String,
      default: 'orderNotifications'
    },
    editable: {
      type: Boolean,
      default: false
    },
    // add the default notification codes (if editable)
    addDefaults: {
      type: Boolean,
      default: true
    },
    // enable rule checking of selected delivery notes
    enableRules: {
      type: Boolean,
      default: true
    },
    // the current consignee - will be passed to selected delivery notes for rules checking
    consignee: {
      type: Object,
      required: false
    },
    // the current sender - will be passed to selected delivery notes for rules checking
    sender: {
      type: Object,
      required: false
    },
    // the currently selected product
    product: {
      type: Object,
      required: false
    },
    maxNotes: {
      type: Number,
      default: 10
    },
    filter: {
      type: Function,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      notificationCodes: null,
      defaultNotificationCodes: null,
      searchTerm: "",
      notificationCodesLoading: false,
    }
  },
  methods: {
    reset: async function () {
      if (this.editable && this.notificationCodes == null && !this.notificationCodesLoading) {
        this.notificationCodesLoading = true
        let notificationCodes = (await NotificationCodeService.findActive())?.data?.items

        if (this.addDefaults && this.defaultNotificationCodes == null) {
          this.defaultNotificationCodes = notificationCodes.filter(note => {
            return note.default == true
          })
        }

        this.notificationCodes = notificationCodes

        this.$log('DEBUG', 'notification codes loaded - filter selected delivery notes', notificationCodes)
        this.filterSelectedDeliveryNotes(this.product, this.defaultNotificationCodes, this.consignee, this.sender)
        this.notificationCodesLoading = false
      }
    },
    clear() {
      let newNotes = [...this.value.filter(n => {
        if (this.filter) {
          return !this.filter(n)
        }
        return false
      })]
      this.$emit('input', newNotes)
    },
    removeDeliveryNote(note) {
      let newNotes = [...this.value]

      newNotes.splice(newNotes.indexOf(note), 1)
      this.$emit('input', newNotes)
    },
    addDeliveryNote(note) {
      // clone first
      let clonedNote = JSON.parse(JSON.stringify(note))
      clonedNote.attachmentValue = note?.attachment?.defaultValue || ""

      let newNotes = !!this.value?[...this.value]:[]

      newNotes.splice(0, 0, clonedNote)
      this.$emit('input', newNotes)
    },
    filterSelectedDeliveryNotes(product, defaultNotificationCodes, consignee, sender) {
      if (!this.notificationCodes) {
        this.$log('DEBUG', 'filter selected delivery notes SKIPPED')
        return
      }
      let newNotes = [...this.value]
      let modified = false

      // filter delivery notes from previous consignee / sender
      let filtered = newNotes.filter(o => {
        if (o?._consignee && consignee && o?._consignee != consignee) {
          this.$log('DEBUG', 'filtering notification code (consignee changed)', o)
          return false
        }
        if (o?._sender && sender && o?._sender != sender) {
          this.$log('DEBUG', 'filtering notification code (sender changed)', o)
          return false
        }
        return true
      })
      modified = modified || filtered.length != newNotes.length
      newNotes = filtered

      // add delivery notes from current consignee
      for (let idx in consignee?.deliveryNotes) {
        const note = consignee?.deliveryNotes[idx]
        const attachmentValue = note?.attachmentValue || note.attachment?.defaultValue || ""
        const code = this.notificationCodes && this.notificationCodes.find(n => n.identifier == note.identifier)

        if (this.filter && !this.filter(code)) {
          this.$log('DEBUG', 'not adding consignee notification code ' + code?.identifier + " - filter not matched", code)
          continue
        }

        if (!newNotes?.find(n => n.identifier == note.identifier && (!n.multiUsage || n.attachmentValue == attachmentValue))) {
          this.$log('DEBUG', 'adding note ', code)

          let clonedNote = JSON.parse(JSON.stringify(code))
          clonedNote.attachmentValue = attachmentValue
          clonedNote._consignee = consignee

          newNotes.push(clonedNote)
          modified = true
          this.$log('DEBUG', 'adding consignee notification code ' + code?.identifier, code)
        } else {
          this.$log('DEBUG', 'not adding consignee notification code ' + code?.identifier, code, note)
        }
      }
      // add delivery notes from current sender
      for (let idx in sender?.deliveryNotes) {
        const note = sender?.deliveryNotes[idx]
        const attachmentValue = note?.attachmentValue || note.attachment?.defaultValue || ""
        const code = this.notificationCodes && this.notificationCodes.find(n => n.identifier == note.identifier)

        if (this.filter && !this.filter(code)) {
          this.$log('DEBUG', 'not adding sender notification code ' + code?.identifier + " - filter not matched", code)
          continue
        }

        if (!newNotes?.find(n => n.identifier == note.identifier && (!n.multiUsage || n.attachmentValue == attachmentValue))) {
          this.$log('DEBUG', 'adding note ', code)

          let clonedNote = JSON.parse(JSON.stringify(code))
          clonedNote.attachmentValue = attachmentValue
          clonedNote._sender = sender

          newNotes.push(clonedNote)
          modified = true
          this.$log('DEBUG', 'adding sender notification code ' + code?.identifier, code)
        } else {
          this.$log('DEBUG', 'not adding sender notification code ' + code?.identifier, code, note)
        }
      }

      // add defaults
      if (this.addDefaults && defaultNotificationCodes) {
        defaultNotificationCodes.map(note => {
          const code = this.notificationCodes && this.notificationCodes.find(n => n.identifier == note.identifier)
          const attachmentValue = code?.attachmentValue || code?.attachment?.defaultValue || ""

          if (!newNotes?.find(n => n.identifier == note.identifier && (!n.multiUsage || n.attachmentValue == attachmentValue))) {
            this.$log('DEBUG', 'adding default notification code', code)

            let clonedNote = JSON.parse(JSON.stringify(code))
            clonedNote.attachmentValue = attachmentValue

            newNotes.push(clonedNote)
            modified = true
            this.$log('DEBUG', 'adding default notification code ' + code?.identifier, code)
          } else {
            this.$log('DEBUG', 'not adding default notification code ' + code?.identifier, code, note)
          }
        })
      }

      // filter codes not valid vor this product
      // filtering is now done in product view because of wizard
      /*
      if (product) {
        const filtered = newNotes.filter(
            note => {
              let found = this.notificationCodes && this.notificationCodes.find(n => n.identifier == note.identifier)
              let valid = this.isNoteValidForProduct(found, product)
              if (!valid) this.$log('DEBUG', 'filtering notification code (product changed)', note)
              return valid
            }
        )
        modified = modified || (filtered.length != newNotes.length)
        newNotes = filtered
      }
       */

      if (newNotes.length >= this.maxNotes) {
        newNotes = newNotes.slice(0, this.maxNotes)
        modified = true
      }

      if (modified) {
        this.$emit('input', newNotes)
      }
    },

    isNoteValidForProduct(note, product) {
      if(!note) {
        // no note - not valid
        return false
      } else if (!product) {
        // no product - valid
        return true
      }
      // either no isSelectable fn or it returns true
      return (!note.isSelectable || note.isSelectable(product))
    }
  },
  computed: {
    filteredDeliveryNotes: function () {
      return this.notificationCodes ? this.notificationCodes.filter(
          note => {
            if (!this.isNoteValidForProduct(note, this.product)) {
              return false
            }

            let candidate = note.multiUsage == true ||
                (note.multiUsage != true && (!this.value || this.value.filter(item => item.identifier == note.identifier).length == 0))

            if (!candidate) {
              return false
            }
            if (this.filter && !this.filter(note)) {
              return false
            }

            const regex = new RegExp(this.searchTerm, 'ig');
            return note.name.match(regex) || note.identifier.match(regex)
          }
      ) : []
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        this.$log('DEBUG', 'VALUE CHANGED ', oldVal, newVal)
        await this.reset()
      }
    },
    editable: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        this.$log('DEBUG', 'EDITABLE CHANGED ', oldVal, newVal)
        await this.reset()
      }
    },
    consignee: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        this.$log('DEBUG', 'CONSIGNEE CHANGED - filter selected delivery notes', oldVal, newVal)
        this.filterSelectedDeliveryNotes(this.product, this.defaultNotificationCodes, newVal, this.sender)
      }
    },
    sender: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        this.$log('DEBUG', 'SENDER CHANGED - filter selected delivery notes', oldVal, newVal)
        this.filterSelectedDeliveryNotes(this.product, this.defaultNotificationCodes, this.consignee, newVal)
      }
    },
    product: function(newVal, oldVal) {
      if(newVal) {
        this.$log('DEBUG', 'PRODUCT CHANGED - filter selected delivery notes', oldVal, newVal)
        this.filterSelectedDeliveryNotes(newVal, this.defaultNotificationCodes, this.consignee, this.sender)
      }
    },
  }
}
</script>

<style scoped>
.notification-code-drop-down /deep/ .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}
</style>