
function stripNonPrintable(text, stripSurrogatesAndFormats) {
    // strip control chars. optionally, keep surrogates and formats
    if(stripSurrogatesAndFormats) {
        text = text.replace(/\p{C}/gu, '');
    } else {
        text = text.replace(/\p{Cc}/gu, '');
        text = text.replace(/\p{Co}/gu, '');
        text = text.replace(/\p{Cn}/gu, '');
    }
    text = text.replace(/\u202f/gu, '');
    return text;
}

function stripInvalidCharacters(obj) {
    for (const [key, value] of Object.entries(obj)) {
        if (value instanceof Object) {
            stripInvalidCharacters(value)
        } else if (typeof value == 'string') {
            const newValue = stripNonPrintable(value, false)
            if (newValue != value) {
                obj[key] = newValue
                console.log('stripped entry: ' + key + '=' + value + " / " + newValue)
            }
        }
    }
}

export { stripInvalidCharacters }