<template>
    <form @submit.prevent="handleSubmit(onSubmit)">

      <div class="row">

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-unNumber'" class="col-5 col-form-label">{{ $t('cargo-item.adr.un-number') }}</label>
            <div class="col-7">
              <e-form-text :id="id + '-hazmat-unNumber'" :value="entity.unNumber"/>
            </div>
          </div>

        </div>

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-sequenceNumber'" class="col-5 col-form-label">{{  $t('cargo-item.adr.sequence-number') }}</label>
            <div class="col-7">
              <e-form-text :id="id + '-hazmat-sequenceNumber'" :value="entity.sequenceNumber"/>
            </div>
          </div>

        </div>

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-clazz'" class="col-3 col-form-label">{{ $t('cargo-item.adr.hazard-class') }}</label>
            <div class="col-9">
              <e-form-text :id="id + '-hazmat-clazz'" :value="entity.clazz"/>
            </div>
          </div>

        </div>

      </div>

      <div class="row">

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-classificationCode'" class="col-5 col-form-label">{{ $t('cargo-item.adr.classification-code') }}</label>
            <div class="col-7">
              <e-form-text :id="id + '-hazmat-classificationCode'" :value="entity.classificationCode"/>
            </div>
          </div>

        </div>

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-packagingGroup'" class="col-5 col-form-label">{{ $t('cargo-item.adr.packaging-group') }}</label>
            <div class="col-7">
              <e-form-text :id="id + '-hazmat-packagingGroup'" :value="entity.packagingGroup"/>
            </div>
          </div>

        </div>

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-massUnit'" class="col-3 col-form-label">{{ $t('cargo-item.adr.mass-unit') }}</label>
            <div class="col-9">
              <e-form-text :id="id + '-hazmat-massUnit'" :value="entity.massUnit"/>
            </div>
          </div>

        </div>

      </div>

      <div class="row">

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-note'" class="col-5 col-form-label">{{ $t('cargo-item.adr.hazardous-substance-label') }}</label>
            <div class="col-7">
              <e-form-text :id="id + '-hazmat-note'" :value="entity.note"/>
            </div>
          </div>

        </div>

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-collectiveTerm'" class="col-5 col-form-label">{{ $t('cargo-item.adr.collective-term') }}</label>
            <div class="col-7">
              <e-form-text :id="id + '-hazmat-collectiveTerm'" :value="entity.collectiveTerm"/>
            </div>
          </div>

        </div>

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-transportCategory'" class="col-3 col-form-label">{{ $t('cargo-item.adr.transport-category') }}</label>
            <div class="col-9">
              <e-form-text :id="id + '-hazmat-transportCategory'" :value="entity.transportCategory"/>
            </div>
          </div>

        </div>

      </div>

      <div class="row">

        <div class="col-4">

          <div class="form-group row">
            <label :for="id + '-hazmat-tunnelRestrictionCode'" class="col-5 col-form-label">{{ $t('cargo-item.adr.tunnel-restriction-code') }}</label>
            <div class="col-7">
              <e-form-text :id="id + '-hazmat-tunnelRestrictionCode'" :value="entity.tunnelRestrictionCode"/>
            </div>
          </div>

        </div>

        <div class="col-8">

          <div class="form-group row">
            <label :for="id + '-hazmat-name'" class="col-2 col-form-label">{{ $t('cargo-item.adr.shipping-name') }}</label>
            <div class="col-7">
              <e-form-text :id="id + '-hazmat-name'" :value="entity.name"/>
            </div>
          </div>

        </div>

      </div>

    </form>
</template>

<script>

import service from "@/services/hazmat.service";
import {log} from "@emons/emons-vue"

export default {
  name: "HazmatView",
  components: {},
  mounted() {
    log('debug', 'Mounted HazmatView...')
    this.getEntityDetails()
  },
  props: {
    id: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      entity: {
        abbreviation: null,
        name: null
      }
    }
  },
  methods: {
    getEntityDetails: function () {
      this.$emit('enableSpinner');
      service
          .getEntityDetails(this.link)
          .then(response => {
            this.entity = response.data
            this.$emit('disableSpinner');
          })
    }
  }
}
</script>

<style scoped>

</style>