var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmit(_vm.onSubmit);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-5 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-unNumber'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.un-number')))]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-unNumber',
      "value": _vm.entity.unNumber
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-5 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-sequenceNumber'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.sequence-number')))]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-sequenceNumber',
      "value": _vm.entity.sequenceNumber
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-3 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-clazz'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.hazard-class')))]), _c('div', {
    staticClass: "col-9"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-clazz',
      "value": _vm.entity.clazz
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-5 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-classificationCode'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.classification-code')))]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-classificationCode',
      "value": _vm.entity.classificationCode
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-5 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-packagingGroup'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.packaging-group')))]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-packagingGroup',
      "value": _vm.entity.packagingGroup
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-3 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-massUnit'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.mass-unit')))]), _c('div', {
    staticClass: "col-9"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-massUnit',
      "value": _vm.entity.massUnit
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-5 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-note'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.hazardous-substance-label')))]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-note',
      "value": _vm.entity.note
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-5 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-collectiveTerm'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.collective-term')))]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-collectiveTerm',
      "value": _vm.entity.collectiveTerm
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-3 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-transportCategory'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.transport-category')))]), _c('div', {
    staticClass: "col-9"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-transportCategory',
      "value": _vm.entity.transportCategory
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-5 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-tunnelRestrictionCode'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.tunnel-restriction-code')))]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-tunnelRestrictionCode',
      "value": _vm.entity.tunnelRestrictionCode
    }
  })], 1)])]), _c('div', {
    staticClass: "col-8"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-2 col-form-label",
    attrs: {
      "for": _vm.id + '-hazmat-name'
    }
  }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.shipping-name')))]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-hazmat-name',
      "value": _vm.entity.name
    }
  })], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }