var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmit(_vm.onSubmit);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-notificationCode-identifier'
    }
  }, [_vm._v(_vm._s(_vm.$t('delivery-notes.identifier')))]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-notificationCode-identifier',
      "value": _vm.entity.identifier
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-notificationCode-name'
    }
  }, [_vm._v(_vm._s(_vm.$t('delivery-notes.label')))]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-notificationCode-name',
      "value": _vm.entity.name
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-notificationCode-attachment'
    }
  }, [_vm._v(_vm._s(_vm.$t('delivery-notes.attachment')))]), _c('div', {
    staticClass: "col-8"
  }, [_c('e-form-text', {
    attrs: {
      "id": _vm.id + '-notificationCode-attachment',
      "value": _vm.entity.attachment ? _vm.entity.attachment.name : ''
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  })]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-4 col-form-label",
    attrs: {
      "for": _vm.id + '-notificationCode-name'
    }
  }, [_vm._v(_vm._s(_vm.$t('delivery-notes.multiple-usage')))]), _c('div', {
    staticClass: "col-8"
  }, [_vm.entity.multiUsage ? _c('e-form-text', {
    attrs: {
      "id": _vm.id + '-notificationCode-name',
      "value": _vm.$t('delivery-notes.multiple-usage.allowed')
    }
  }) : _c('e-form-text', {
    attrs: {
      "id": _vm.id + '-notificationCode-name',
      "value": _vm.$t('delivery-notes.multiple-usage.disallowed')
    }
  })], 1)])]), _c('div', {
    staticClass: "col-4"
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }