<template>
 <div>
   <audit-log-entry v-for="entry in auditLog" v-bind:key="entry.revision" :value="entry" />
 </div>
</template>

<script>
import AuditLogEntry from "@/components/auditlog/AuditLogEntry.vue";
import AuditService from "@/services/audit.service";

export default {
  name: "AuditLog",
  components: {AuditLogEntry},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  asyncComputed: {
    auditLog: async function() {
      const link = this.value?._links?.auditLog?.href

      if (link) {
        this.$emit('enableSpinner')
        try {
          const log = await AuditService._get(link)
          if (log) {
            return log.reverse()
          }
        } finally {
          this.$emit('disableSpinner')
        }
      }
      return []
    }

  }
}
</script>

<style scoped>

</style>