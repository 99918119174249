var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-page', [_c('template', {
    slot: "content"
  }, [!_vm.registrationStatus ? _c('validation-observer', {
    ref: "observer",
    attrs: {
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty,
            failed = _ref.failed;
        return [_c('div', {
          staticClass: "jumbotron d-flex center justify-content-center align-items-center",
          staticStyle: {
            "max-width": "900px",
            "margin": "auto"
          }
        }, [_vm.customer ? _c('div', {
          staticClass: "clearfix",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "form-group form-row"
        }, [_c('div', {
          staticClass: "offset-sm-2 col-sm-6"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('registration.companyData')))])])]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "company"
          }
        }, [_vm._v(_vm._s(_vm.$t('address.company')))]), _c('div', {
          staticClass: "col-sm-7"
        }, [!_vm.customer ? _c('e-form-text-input', {
          attrs: {
            "id": "company",
            "rules": "required"
          },
          model: {
            value: _vm.registration.company,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "company", $$v);
            },
            expression: "registration.company"
          }
        }) : _c('e-form-text-input', {
          attrs: {
            "id": "company",
            "rules": "required",
            "disabled": "",
            "value": _vm.customer.name
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "additionalName"
          }
        }, [_vm._v(_vm._s(_vm.$t('address.additionalName')))]), _c('div', {
          staticClass: "col-sm-7"
        }, [!_vm.customer ? _c('e-form-text-input', {
          attrs: {
            "id": "additionalName"
          },
          model: {
            value: _vm.registration.additionalName,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "additionalName", $$v);
            },
            expression: "registration.additionalName"
          }
        }) : _c('e-form-text-input', {
          attrs: {
            "id": "additionalName",
            "disabled": "",
            "value": _vm.customer.additionalName
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "street"
          }
        }, [_vm._v(_vm._s(_vm.$t('address.street')))]), _c('div', {
          staticClass: "col-sm-7"
        }, [!_vm.customer ? _c('e-form-text-input', {
          attrs: {
            "id": "street",
            "rules": "required"
          },
          model: {
            value: _vm.registration.streetAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "streetAddress", $$v);
            },
            expression: "registration.streetAddress"
          }
        }) : _c('e-form-text-input', {
          attrs: {
            "id": "street",
            "rules": "required",
            "disabled": "",
            "value": _vm.customer.street
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "country"
          }
        }, [_vm._v(_vm._s(_vm.$t('address.country')) + " / " + _vm._s(_vm.$t('address.zipCode')) + " / " + _vm._s(_vm.$t('address.city')))]), _c('div', {
          staticClass: "col-sm-2"
        }, [!_vm.customer ? _c('country-select', {
          attrs: {
            "id": "country",
            "name": "country",
            "required": true,
            "placeholder": _vm.$t('address.country')
          },
          model: {
            value: _vm.registration.country,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "country", $$v);
            },
            expression: "registration.country"
          }
        }) : _c('e-form-text-input', {
          attrs: {
            "id": "country",
            "rules": "required",
            "disabled": "",
            "value": _vm.customer.country
          }
        })], 1), _c('div', {
          staticClass: "col-sm-2"
        }, [!_vm.customer ? _c('e-form-text-input', {
          attrs: {
            "id": "zipCode",
            "name": "zipCode",
            "rules": {
              required: true,
              zipCode: {
                country: '@country'
              }
            }
          },
          on: {
            "blur": function blur($event) {
              return _vm.formatZipCode($event);
            }
          },
          model: {
            value: _vm.registration.zipCode,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "zipCode", $$v);
            },
            expression: "registration.zipCode"
          }
        }) : _c('e-form-text-input', {
          attrs: {
            "id": "zipCode",
            "rules": "required",
            "disabled": "",
            "value": _vm.customer.zipCode
          }
        })], 1), _c('div', {
          staticClass: "col-sm-3"
        }, [!_vm.customer ? _c('city-select', {
          attrs: {
            "id": "city",
            "rules": "required",
            "disabled": false,
            "country": _vm.registration.country,
            "zip-code": _vm.registration.zipCode
          },
          model: {
            value: _vm.registration.city,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "city", $$v);
            },
            expression: "registration.city"
          }
        }) : _c('e-form-text-input', {
          attrs: {
            "id": "city",
            "rules": "required",
            "disabled": "",
            "value": _vm.customer.city
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mt-3"
        }, [_c('div', {
          staticClass: "offset-sm-2 col-sm-6"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('registration.contactData')))])])]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "firstName"
          }
        }, [_vm._v(_vm._s(_vm.$t('user.firstName')))]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": "firstName"
          },
          model: {
            value: _vm.registration.firstName,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "firstName", $$v);
            },
            expression: "registration.firstName"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "lastName"
          }
        }, [_vm._v(_vm._s(_vm.$t('user.lastName')))]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": "lastName",
            "rules": "required"
          },
          model: {
            value: _vm.registration.lastName,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "lastName", $$v);
            },
            expression: "registration.lastName"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "phone"
          }
        }, [_vm._v(_vm._s(_vm.$t('user.phone')))]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": "phone",
            "rules": "required|phoneNumber"
          },
          model: {
            value: _vm.registration.phoneNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "phoneNumber", $$v);
            },
            expression: "registration.phoneNumber"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mt-3"
        }, [_c('div', {
          staticClass: "offset-sm-2 col-sm-6"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('registration.loginData')))])])]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "email"
          }
        }, [_vm._v(_vm._s(_vm.$t('user.email')))]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": "email",
            "rules": "required|email"
          },
          model: {
            value: _vm.registration.email,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "email", $$v);
            },
            expression: "registration.email"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "emailConfirmation"
          }
        }, [_vm._v(_vm._s(_vm.$t('user.emailConfirmation')))]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": "emailConfirmation",
            "rules": "required|confirmed:email"
          },
          model: {
            value: _vm.emailConfirmation,
            callback: function callback($$v) {
              _vm.emailConfirmation = $$v;
            },
            expression: "emailConfirmation"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "password"
          }
        }, [_vm._v(_vm._s(_vm.$t('user.password')))]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": "password",
            "maxlength": "30",
            "rules": "required|passwordConstraints|min:8|max:30",
            "type": "password"
          },
          model: {
            value: _vm.registration.password,
            callback: function callback($$v) {
              _vm.$set(_vm.registration, "password", $$v);
            },
            expression: "registration.password"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mb-1"
        }, [_c('label', {
          staticClass: "col-sm-2 col-form-label text-right",
          attrs: {
            "for": "passwordConfirmation"
          }
        }, [_vm._v(_vm._s(_vm.$t('user.passwordConfirmation')))]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": "passwordConfirmation",
            "rules": "required|confirmed:password",
            "type": "password"
          },
          model: {
            value: _vm.passwordConfirmation,
            callback: function callback($$v) {
              _vm.passwordConfirmation = $$v;
            },
            expression: "passwordConfirmation"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mt-3"
        }, [_c('div', {
          staticClass: "offset-sm-2 col-sm-6"
        }, [_c('vue-recaptcha', {
          ref: "recaptcha",
          attrs: {
            "sitekey": "6LcDPd4cAAAAABY1aF1y5hiB1q7C0OpvxprIxE-z"
          },
          on: {
            "verify": _vm.onRecaptchaVerified
          }
        })], 1)]), _c('div', {
          staticClass: "form-group form-row mt-3"
        }, [_c('div', {
          staticClass: "offset-sm-2 col-sm-6"
        }, [_c('b-button', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "variant": "danger",
            "ype": "submit",
            "disabled": invalid || _vm.registration.captcha == null
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submitRegistration.apply(null, arguments);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('registration.register')) + " ")])], 1)])])])]) : _c('h4', [_vm._v(" Falls Sie auf unserer neuen Sendungserfassung "), _c('strong', [_vm._v("myEmons")]), _vm._v(" noch nicht registriert sind, wenden Sie sich bitte an Ihren zuständigen Vertriebsmitarbeiter. Von diesem erhalten Sie dann den für Sie gültigen Registrierungslink. ")])])];
      }
    }], null, false, 1891620578)
  }) : _vm.registrationStatus == 'registered' ? _c('div', {
    staticClass: "jumbotron d-flex center justify-content-center align-items-center",
    staticStyle: {
      "max-width": "900px",
      "margin": "auto"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-1"
  }, [_c('h1', [_c('b-icon', {
    attrs: {
      "icon": "check-circle",
      "variant": "success"
    }
  })], 1)]), _c('div', {
    staticClass: "col-11"
  }, [_c('h1', {
    staticClass: "text-success"
  }, [_vm._v(_vm._s(_vm.$t('registration.registered')))]), !!_vm.customer ? _c('h5', [_vm._v(_vm._s(_vm.$t('registration.continue-to-login')))]) : _c('h5', [_vm._v(_vm._s(_vm.$t('registration.wait-for-email-confirmation')))])])])])]) : _c('div', {
    staticClass: "jumbotron d-flex center justify-content-center align-items-center",
    staticStyle: {
      "max-width": "900px",
      "margin": "auto"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-1"
  }, [_c('h1', [_c('b-icon', {
    attrs: {
      "icon": "exclamation-triangle",
      "variant": "warning"
    }
  })], 1)]), _c('div', {
    staticClass: "col-11"
  }, [_c('h1', {
    staticClass: "text-warning"
  }, [_vm._v(_vm._s(_vm.$t('registration.already-registered')))]), _c('h5', [_vm._v(_vm._s(_vm.$t('registration.user-already-exists')))])])])])])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }