var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header p-2"
  }, [_c('div', {
    staticClass: "form-row align-items-center"
  }, [_c('div', {
    class: _vm.isEnabled ? 'col-6' : 'col-12'
  }, [_c('e-form-checkbox', {
    attrs: {
      "id": _vm.id + '-enableDivergentInvoiceRecipient',
      "name": "enableDivergentInvoiceRecipient",
      "placeholder": _vm.$t('orders.enable-divergent-invoice-recipient'),
      "disabled": !_vm.editable,
      "value": _vm.isEnabled
    },
    on: {
      "input": function input($event) {
        return _vm.toggleInvoiceRecipient($event);
      }
    }
  }), !_vm.isEnabled && !!_vm.defaultRecipient ? _c('span', {
    staticClass: "font-italic font-weight-light",
    staticStyle: {
      "color": "#6c757d"
    }
  }, [_vm._v(" (" + _vm._s(_vm.$t('invoiceRecipient.isDefault')) + ": " + _vm._s(_vm.formatRecipient(_vm.defaultRecipient)) + ") ")]) : _vm._e()], 1), _vm.isEnabled ? _c('div', {
    staticClass: "col-6"
  }, [_c('addressbook-select', {
    attrs: {
      "types": ['INVOICE_RECIPIENT'],
      "editable": _vm.editable,
      "api-path": _vm.apiPath
    },
    on: {
      "input": _vm.selectedInvoiceRecipientChanged
    }
  })], 1) : _vm._e()])]), _vm.isEnabled && !!_vm.value ? _c('div', {
    staticClass: "card-body p-2"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('label', {
    attrs: {
      "for": "invoice-recipient-name"
    }
  }, [_vm._v(_vm._s(_vm.$t('address.address-data')))])]), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.name',
      "name": "name",
      "placeholder": _vm.$t('address.name'),
      "value": _vm.value.name,
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "col-5"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.customerId',
      "name": "customerId",
      "placeholder": _vm.$t('address.customerId'),
      "value": _vm.value.customerId,
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "col-7"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.additionalName',
      "name": "additionalName",
      "placeholder": _vm.$t('address.additionalName'),
      "value": _vm.value.additionalName,
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "w-100"
  }), _c('div', {
    staticClass: "col-4"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.country',
      "name": "country",
      "placeholder": _vm.$t('address.country'),
      "value": _vm.$t('countries.' + _vm.value.country),
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.zipCode',
      "name": "zipCode",
      "placeholder": _vm.$t('address.zipCode'),
      "value": _vm.value.zipCode,
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "col-5"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.city',
      "name": "city",
      "placeholder": _vm.$t('address.city'),
      "value": _vm.value.city,
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.street',
      "name": "street",
      "placeholder": _vm.$t('address.streetWithNumber'),
      "value": _vm.value.street,
      "disabled": ""
    }
  })], 1)])]), _c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('label', {
    attrs: {
      "for": "invoice-recipient-contact-name"
    }
  }, [_vm._v(_vm._s(_vm.$t('address.contact-data')))])]), _c('div', {
    staticClass: "col-12"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.contactName',
      "name": "contactName",
      "placeholder": _vm.$t('address.contactName'),
      "disabled": !_vm.editable || !_vm.dataEditable
    },
    model: {
      value: _vm.value.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "contactName", $$v);
      },
      expression: "value.contactName"
    }
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-invoiceRecipient.phone',
      "name": "phone",
      "rules": "phoneNumber",
      "placeholder": _vm.$t('address.phone'),
      "disabled": !_vm.editable || !_vm.dataEditable
    },
    model: {
      value: _vm.value.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "phone", $$v);
      },
      expression: "value.phone"
    }
  })], 1)])])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }