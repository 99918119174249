<template>
  <customer-list />
</template>

<script>
import CustomerList from "@/components/CustomerList";

export default {
  name: "AdminCustomers",
  components: {CustomerList}
}
</script>

<style scoped>

</style>