var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "vid": 'orderObserver-' + _vm.orderId,
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$order, _vm$order$consignee, _vm$order$consignee$e, _vm$order2, _vm$order2$consignee, _vm$order3, _vm$order3$_links, _vm$order4, _vm$order4$_links, _vm$order5, _vm$order5$_links, _vm$order6, _vm$order6$_links, _vm$order6$_links$ann, _vm$order7, _vm$order7$_links, _vm$order7$_links$com, _vm$order8, _vm$order8$_links, _vm$order8$_links$mod, _vm$order9, _vm$order9$_links, _vm$order9$_links$del, _vm$order10, _vm$order10$_links, _vm$order10$_links$mo, _vm$order11, _vm$order11$_links, _vm$order12, _vm$order12$_links, _vm$order12$_links$mo, _vm$order13, _vm$order13$_links, _vm$order13$_links$de, _vm$order14, _vm$order14$_links, _vm$order14$_links$mo;

        var errors = _ref.errors,
            invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty;
        return [_c('div', [_c('b-tabs', {
          attrs: {
            "fill": "",
            "active-nav-item-class": "font-weight-bold text-light bg-primary",
            "nav-class": "border bg-primary-light"
          }
        }, [_c('b-tab', {
          attrs: {
            "title": _vm.$t('orders.overview'),
            "title-link-class": "text-light",
            "active": ""
          }
        }, [_c('div', {
          staticClass: "row mt-2"
        }, [_c('div', {
          staticClass: "col-md-8"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-6"
        }, [_c('order-sender-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": _vm.editMode,
            "default-country": _vm.defaultCountry,
            "is-belog": ((_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : (_vm$order$consignee = _vm$order.consignee) === null || _vm$order$consignee === void 0 ? void 0 : (_vm$order$consignee$e = _vm$order$consignee.emonsCustomerId) === null || _vm$order$consignee$e === void 0 ? void 0 : _vm$order$consignee$e.length) > 0
          },
          on: {
            "input": function input($event) {
              return _vm.senderSelected($event);
            },
            "cleared": function cleared($event) {
              return _vm.$refs.senderDeliveryNotes.clear();
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('validation-provider', {
                attrs: {
                  "vid": _vm.orderId + '-sender.deliveryNotes'
                }
              }, [_c('delivery-notes-view', {
                ref: "senderDeliveryNotes",
                attrs: {
                  "id": _vm.orderId,
                  "editable": _vm.editMode,
                  "title": _vm.$t('shipment.sender.deliveryNotes'),
                  "add-defaults": _vm.order.state == 'NEW',
                  "sender": _vm.order.sender,
                  "product": _vm.order.product,
                  "filter": function filter(n) {
                    return n.identifier.startsWith('6');
                  }
                },
                model: {
                  value: _vm.order.deliveryNotes,
                  callback: function callback($$v) {
                    _vm.$set(_vm.order, "deliveryNotes", $$v);
                  },
                  expression: "order.deliveryNotes"
                }
              })], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.order.sender,
            callback: function callback($$v) {
              _vm.$set(_vm.order, "sender", $$v);
            },
            expression: "order.sender"
          }
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('order-consignee-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": _vm.editMode,
            "default-country": _vm.defaultCountry
          },
          on: {
            "cleared": function cleared($event) {
              return _vm.$refs.consigneeDeliveryNotes.clear();
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('validation-provider', {
                attrs: {
                  "vid": _vm.orderId + '-consignee.deliveryNotes'
                }
              }, [_c('delivery-notes-view', {
                ref: "consigneeDeliveryNotes",
                attrs: {
                  "id": _vm.orderId,
                  "editable": _vm.editMode,
                  "title": _vm.$t('shipment.consignee.deliveryNotes'),
                  "add-defaults": _vm.order.state == 'NEW',
                  "consignee": _vm.order.consignee,
                  "product": _vm.order.product,
                  "filter": function filter(n) {
                    return !n.identifier.startsWith('6');
                  }
                },
                model: {
                  value: _vm.order.deliveryNotes,
                  callback: function callback($$v) {
                    _vm.$set(_vm.order, "deliveryNotes", $$v);
                  },
                  expression: "order.deliveryNotes"
                }
              })], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.order.consignee,
            callback: function callback($$v) {
              _vm.$set(_vm.order, "consignee", $$v);
            },
            expression: "order.consignee"
          }
        })], 1)]), _c('div', {
          staticClass: "row mt-2"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('order-invoice-recipient-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": _vm.editMode,
            "default-recipient": _vm.defaultInvoiceRecipient
          },
          model: {
            value: _vm.order.invoiceRecipient,
            callback: function callback($$v) {
              _vm.$set(_vm.order, "invoiceRecipient", $$v);
            },
            expression: "order.invoiceRecipient"
          }
        })], 1)])]), _c('div', {
          staticClass: "col-md-4"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('order-characteristics-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": _vm.editMode,
            "value": _vm.order,
            "shipping-date-offset": _vm.shippingDateOffset,
            "waiver-customer": _vm.waiverCustomer,
            "required-fields": _vm.requiredFields,
            "customer-currency": _vm.customerCurrency,
            "branch-currency": _vm.branchCurrency,
            "supported-currencies": _vm.supportedCurrencies,
            "default-term-national": _vm.defaultIncoTermNational,
            "default-term-international": _vm.defaultIncoTermInternational,
            "shipping-time-from": _vm.shippingTimeFrom,
            "shipping-time-from-fr": _vm.shippingTimeFromFr,
            "shipping-time-until": _vm.shippingTimeUntil,
            "shipping-time-until-fr": _vm.shippingTimeUntilFr
          }
        })], 1)]), _c('div', {
          staticClass: "row mt-2"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('order-product-view', {
          attrs: {
            "id": _vm.orderId,
            "editable": _vm.editMode,
            "value": _vm.order
          }
        })], 1)])])])]), _c('b-tab', {
          attrs: {
            "title": _vm.$t('orders.cargo-list'),
            "title-link-class": "text-light"
          }
        }, [_c('div', {
          staticClass: "row mt-2"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('order-cargo-list', {
          attrs: {
            "id": _vm.orderId,
            "editable": _vm.editMode,
            "required-fields": _vm.requiredFields,
            "optional-fields": _vm.optionalFields,
            "value": _vm.order.cargoList,
            "consignee-country": (_vm$order2 = _vm.order) === null || _vm$order2 === void 0 ? void 0 : (_vm$order2$consignee = _vm$order2.consignee) === null || _vm$order2$consignee === void 0 ? void 0 : _vm$order2$consignee.country,
            "additional-palette-swap-countries": _vm.additionalPaletteSwapCountries
          }
        })], 1)]), _c('div', {
          staticClass: "row mb-2"
        }, [_c('div', {
          staticClass: "col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pt-4"
        }, [_c('order-shipment-info', {
          attrs: {
            "id": _vm.orderId,
            "editable": _vm.editMode,
            "optional-fields": _vm.optionalFields,
            "mandatory-fields": _vm.mandatoryFields,
            "value": _vm.order.cargoList
          }
        })], 1)])]), (_vm$order3 = _vm.order) !== null && _vm$order3 !== void 0 && (_vm$order3$_links = _vm$order3._links) !== null && _vm$order3$_links !== void 0 && _vm$order3$_links.auditLog ? _c('b-tab', {
          attrs: {
            "title": _vm.$t('auditlog.title'),
            "title-link-class": "text-light",
            "lazy": ""
          }
        }, [_c('div', {
          staticClass: "mt-2"
        }, [_c('audit-log', {
          attrs: {
            "value": _vm.order
          },
          on: {
            "enableSpinner": function enableSpinner($event) {
              return _vm.$emit('enableSpinner');
            },
            "disableSpinner": function disableSpinner($event) {
              return _vm.$emit('disableSpinner');
            }
          }
        })], 1)]) : _vm._e()], 1), _c('div', {
          staticClass: "row mt-4"
        }, [_c('div', {
          staticClass: "col-9 offset-3"
        }, [!_vm.editMode ? _c('div', {
          staticClass: "form-group row justify-content-end"
        }, [(_vm$order4 = _vm.order) !== null && _vm$order4 !== void 0 && (_vm$order4$_links = _vm$order4._links) !== null && _vm$order4$_links !== void 0 && _vm$order4$_links.pickUpOrder ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('shipment-pick-up-order-modal', {
          ref: "shipmentPickUpOrderModal",
          attrs: {
            "id": _vm.orderId + '-pick-up-order',
            "shipment": _vm.order
          }
        }), _c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": 'pick-up-order-' + _vm.orderId
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.shipmentPickUpOrderModal.show();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('shipment.pick-up-order')))])], 1) : _vm._e(), (_vm$order5 = _vm.order) !== null && _vm$order5 !== void 0 && (_vm$order5$_links = _vm$order5._links) !== null && _vm$order5$_links !== void 0 && _vm$order5$_links.inquiry ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('shipment-inquiry-modal', {
          ref: "shipmentInquiryModal",
          attrs: {
            "id": _vm.orderId + '-inquiry',
            "shipment": _vm.order
          }
        }), _c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": 'inquiry-' + _vm.orderId
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.shipmentInquiryModal.show();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('shipment.inquiry')))])], 1) : _vm._e(), (_vm$order6 = _vm.order) !== null && _vm$order6 !== void 0 && (_vm$order6$_links = _vm$order6._links) !== null && _vm$order6$_links !== void 0 && (_vm$order6$_links$ann = _vm$order6$_links.announce) !== null && _vm$order6$_links$ann !== void 0 && _vm$order6$_links$ann.href ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('announce-shipment-modal', {
          ref: "announceShipmentModal",
          attrs: {
            "id": _vm.orderId + '-announceShipment'
          },
          on: {
            "confirmed": _vm.announceOrder
          }
        }), _c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": 'voravis-' + _vm.orderId,
            "disabled": _vm.axiosSaveInProgress
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.announceShipmentModal.show([_vm.order]);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('shipment.shipping-notice')))])], 1) : _vm._e(), (_vm$order7 = _vm.order) !== null && _vm$order7 !== void 0 && (_vm$order7$_links = _vm$order7._links) !== null && _vm$order7$_links !== void 0 && (_vm$order7$_links$com = _vm$order7$_links.commission) !== null && _vm$order7$_links$com !== void 0 && _vm$order7$_links$com.href ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": 'release-' + _vm.orderId,
            "disabled": _vm.axiosSaveInProgress
          },
          on: {
            "click": _vm.finalizeOrder
          }
        }, [_vm._v(_vm._s(_vm.$t('shipment.commission')))])]) : _vm._e(), (_vm$order8 = _vm.order) !== null && _vm$order8 !== void 0 && (_vm$order8$_links = _vm$order8._links) !== null && _vm$order8$_links !== void 0 && (_vm$order8$_links$mod = _vm$order8$_links.modify) !== null && _vm$order8$_links$mod !== void 0 && _vm$order8$_links$mod.href ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-primary",
          attrs: {
            "id": 'edit-' + _vm.orderId,
            "type": "submit"
          },
          on: {
            "click": _vm.enableEditMode
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.edit')))])]) : _vm._e(), (_vm$order9 = _vm.order) !== null && _vm$order9 !== void 0 && (_vm$order9$_links = _vm$order9._links) !== null && _vm$order9$_links !== void 0 && (_vm$order9$_links$del = _vm$order9$_links.delete) !== null && _vm$order9$_links$del !== void 0 && _vm$order9$_links$del.href ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-danger",
          attrs: {
            "id": 'delete-' + _vm.orderId
          },
          on: {
            "click": _vm.confirmDeleteOrder
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.delete')))])]) : (_vm$order10 = _vm.order) !== null && _vm$order10 !== void 0 && (_vm$order10$_links = _vm$order10._links) !== null && _vm$order10$_links !== void 0 && (_vm$order10$_links$mo = _vm$order10$_links.modify) !== null && _vm$order10$_links$mo !== void 0 && _vm$order10$_links$mo.href ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-danger",
          attrs: {
            "id": 'delete-' + _vm.orderId,
            "disabled": _vm.axiosSaveInProgress
          },
          on: {
            "click": _vm.confirmCancelOrder
          }
        }, [_vm._v(_vm._s(_vm.$t('shipment.cancel')))])]) : _vm._e()]) : _c('div', {
          staticClass: "form-group row justify-content-end"
        }, [(_vm$order11 = _vm.order) !== null && _vm$order11 !== void 0 && (_vm$order11$_links = _vm$order11._links) !== null && _vm$order11$_links !== void 0 && _vm$order11$_links.inquiry ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('shipment-inquiry-modal', {
          ref: "shipmentInquiryModal",
          attrs: {
            "id": _vm.orderId + '-inquiry',
            "shipment": _vm.order
          }
        }), _c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": 'inquiry-' + _vm.orderId
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.shipmentInquiryModal.show();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('shipment.inquiry')))])], 1) : _vm._e(), _c('div', {
          staticClass: "col-sm-2"
        }, [!_vm.isNew ? _c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": 'abort-' + _vm.orderId,
            "type": "submit"
          },
          on: {
            "click": _vm.abortEditMode
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.abort')))]) : _vm._e()]), _c('div', {
          staticClass: "col-sm-2"
        }, [invalid ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": _vm.$t('form.fillout-mandatory-fields')
          }
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": 'save-' + _vm.orderId,
            "type": "button",
            "disabled": _vm.axiosSaveInProgress || !changed
          },
          on: {
            "click": _vm.showErrors
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('controls.save')) + " ")])]) : _vm.isNew || (_vm$order12 = _vm.order) !== null && _vm$order12 !== void 0 && (_vm$order12$_links = _vm$order12._links) !== null && _vm$order12$_links !== void 0 && (_vm$order12$_links$mo = _vm$order12$_links.modify) !== null && _vm$order12$_links$mo !== void 0 && _vm$order12$_links$mo.href ? _c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-primary",
          attrs: {
            "id": 'save-' + _vm.orderId,
            "type": "submit",
            "disabled": _vm.axiosSaveInProgress || !changed
          },
          on: {
            "click": _vm.saveOrder
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('controls.save')) + " ")]) : _vm._e()]), _vm.isNew || (_vm$order13 = _vm.order) !== null && _vm$order13 !== void 0 && (_vm$order13$_links = _vm$order13._links) !== null && _vm$order13$_links !== void 0 && (_vm$order13$_links$de = _vm$order13$_links.delete) !== null && _vm$order13$_links$de !== void 0 && _vm$order13$_links$de.href ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-danger",
          attrs: {
            "id": 'delete-' + _vm.orderId
          },
          on: {
            "click": _vm.confirmDeleteOrder
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.delete')))])]) : (_vm$order14 = _vm.order) !== null && _vm$order14 !== void 0 && (_vm$order14$_links = _vm$order14._links) !== null && _vm$order14$_links !== void 0 && (_vm$order14$_links$mo = _vm$order14$_links.modify) !== null && _vm$order14$_links$mo !== void 0 && _vm$order14$_links$mo.href ? _c('div', {
          staticClass: "col-sm-2"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-danger",
          attrs: {
            "id": 'delete-' + _vm.orderId
          },
          on: {
            "click": _vm.confirmCancelOrder
          }
        }, [_vm._v(_vm._s(_vm.$t('shipment.cancel')))])]) : _vm._e()])])])], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }