var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: _vm.id + '-observer',
    attrs: {
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty,
            failed = _ref.failed,
            handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-4"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-name'
          }
        }, [_vm._v(_vm._s(_vm.$t('template.name')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-name',
            "name": "name",
            "size": "sm",
            "rules": "required",
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.name,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "name", $$v);
            },
            expression: "value.name"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-sm-9 offset-sm-3"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-default',
            "name": "default",
            "size": "sm",
            "disabled": !_vm.canModify(),
            "placeholder": _vm.$t('cargo-item.isDefault')
          },
          model: {
            value: _vm.value.isDefault,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "isDefault", $$v);
            },
            expression: "value.isDefault"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-dangerousGoods',
            "name": "dangerousGoods",
            "size": "sm",
            "disabled": !_vm.canModify(),
            "placeholder": _vm.$t('cargo-item.dangerousGoods')
          },
          model: {
            value: _vm.value.dangerousGoods,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "dangerousGoods", $$v);
            },
            expression: "value.dangerousGoods"
          }
        })], 1)])])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-colliQty'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.colli')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-colliQty',
            "size": "sm",
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.colliQty,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "colliQty", $$v);
            },
            expression: "value.colliQty"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-goods'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.goods')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-goods',
            "size": "sm",
            "maxlength": "30",
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.goods,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "goods", $$v);
            },
            expression: "value.goods"
          }
        })], 1)])]), _c('div', {
          staticClass: "col-md-4"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-packagingCode'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.packingCode')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('packaging-code-select', {
          attrs: {
            "id": _vm.id + '-packagingCode',
            "name": "packagingCode",
            "disabled": !_vm.canModify(),
            "size": "sm"
          },
          model: {
            value: _vm.value.packingCode,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "packingCode", $$v);
            },
            expression: "value.packingCode"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-marking'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.marking')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-marking',
            "name": "Name",
            "maxlength": "17",
            "size": "sm",
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.marking,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "marking", $$v);
            },
            expression: "value.marking"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-orderNumber'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.orderNumber')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-orderNumber',
            "maxlength": "35",
            "size": "sm",
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.orderNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "orderNumber", $$v);
            },
            expression: "value.orderNumber"
          }
        })], 1)])]), _c('div', {
          staticClass: "col-md-4"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-weight'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.weight')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-weight',
            "size": "sm",
            "append": _vm.$t('unit.kilogram'),
            "type": 'number',
            "min": '0',
            "step": '1',
            "max": '99999',
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.weight,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "weight", $$v);
            },
            expression: "value.weight"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-length'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.length')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-length',
            "size": "sm",
            "append": _vm.$t('unit.centimeter'),
            "type": 'number',
            "min": '0',
            "step": '1',
            "max": '999',
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.length,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "length", $$v);
            },
            expression: "value.length"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-width'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.width')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-width',
            "name": "Straße",
            "size": "sm",
            "append": _vm.$t('unit.centimeter'),
            "type": 'number',
            "min": '0',
            "step": '1',
            "max": '999',
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.width,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "width", $$v);
            },
            expression: "value.width"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-height'
          }
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.height')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-height',
            "name": "PLZ",
            "size": "sm",
            "append": _vm.$t('unit.centimeter'),
            "type": 'number',
            "min": '0',
            "step": '1',
            "max": '999',
            "disabled": !_vm.canModify()
          },
          model: {
            value: _vm.value.height,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "height", $$v);
            },
            expression: "value.height"
          }
        })], 1)])])]), _vm.canModify() || _vm.canDelete() ? _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6 offset-md-6"
        }, [_c('div', {
          staticClass: "form-group row justify-content-end"
        }, [_vm.canModify() ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-primary",
          attrs: {
            "id": "saveButton",
            "type": "submit",
            "disabled": !(changed || dirty) || invalid
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveEntity();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.save')))])]) : _vm._e(), _vm.canDelete() ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-danger",
          attrs: {
            "id": "deleteButton"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.deleteEntity();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.delete')))])]) : _vm._e()])])]) : _vm._e()])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }