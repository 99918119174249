var render = function () {
  var _vm$collectOnDelivery, _vm$value$collectOnDe, _vm$value$collectOnDe2, _vm$collectOnDelivery2, _vm$value$collectOnDe3, _vm$value$insuranceVa, _vm$value$insuranceVa2, _vm$insuranceCurrency, _vm$insuranceCurrency2, _vm$value$insuranceVa3, _vm$insuranceCurrency3, _vm$insuranceCurrency4, _vm$insuranceCurrency5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header p-2"
  }, [_c('label', {
    staticClass: "mb-0",
    attrs: {
      "for": _vm.id + '-orderNumber'
    }
  }, [_vm._v(_vm._s(_vm.$t('orders.order-characteristics')))])]), _c('div', {
    staticClass: "card-body p-2"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-orderNumber',
      "name": "shipment.orderNumber",
      "maxlength": "35",
      "rules": _vm.requiredFields.includes('shipment.orderNumber') ? 'required' : '',
      "placeholder": _vm.$t('order.number'),
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.value.orderNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "orderNumber", $$v);
      },
      expression: "value.orderNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-referenceNumber',
      "name": "referenceNumber",
      "rules": "integer",
      "maxlength": "10",
      "placeholder": _vm.$t('order.referenceNumber'),
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.value.referenceNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "referenceNumber", $$v);
      },
      expression: "value.referenceNumber"
    }
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('b-form-group', {
    staticClass: "mt-0 mb-0",
    attrs: {
      "label": _vm.$t('shipment.shipping-date'),
      "label-cols": "6"
    }
  }, [_c('e-form-datepicker', {
    ref: "shippingDate",
    attrs: {
      "id": _vm.id + '-shippingDate',
      "name": "shippingDate",
      "rules": {
        required: true,
        dateEqualOrAfter: _vm.currentDateAsString()
      },
      "placeholder": _vm.$t('orders.choose-shipping-date'),
      "date-disabled-fn": _vm.isHoliday,
      "min": new Date(),
      "max": new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate()),
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.value.shippingDate,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "shippingDate", $$v);
      },
      expression: "value.shippingDate"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('b-form-group', {
    staticClass: "mt-0 mb-0",
    attrs: {
      "label": _vm.$t('shipment.shipping-time'),
      "label-cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('time.from'),
      "label-for": "shippingTimeFrom",
      "label-cols": "3",
      "label-class": "text-right"
    }
  }, [_c('time-input', {
    attrs: {
      "id": _vm.id + '-shippingTimeFrom',
      "name": "shippingTimeFrom",
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.value.shippingTimeFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "shippingTimeFrom", $$v);
      },
      expression: "value.shippingTimeFrom"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('time.to'),
      "label-for": "shippingTimeUntil",
      "label-cols": "3",
      "label-class": "text-right"
    }
  }, [_c('time-input', {
    attrs: {
      "id": _vm.id + '-shippingTimeUntil',
      "name": "shippingTimeUntil",
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.value.shippingTimeUntil,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "shippingTimeUntil", $$v);
      },
      expression: "value.shippingTimeUntil"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('b-form-group', {
    staticClass: "mt-0 mb-0",
    attrs: {
      "label": _vm.$t('shipment.shipping-notice.notice'),
      "label-cols": "6"
    }
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-shippingNotice',
      "maxlength": "35",
      "name": "shippingNotice",
      "disabled": !_vm.editable,
      "placeholder": _vm.$t('shipment.shipping-notice.notice')
    },
    model: {
      value: _vm.value.shippingNotice,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "shippingNotice", $$v);
      },
      expression: "value.shippingNotice"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('e-form-select', {
    attrs: {
      "id": _vm.id + '-shippingTerms',
      "name": "shippingTerms",
      "rules": "required",
      "placeholder": _vm.$t('orders.choose-shipping-terms'),
      "disabled": !_vm.editable
    },
    on: {
      "input": function input($event) {
        var _vm$incoTerms$find;

        _vm.value.shippingTerms = (_vm$incoTerms$find = _vm.incoTerms.find(function (t) {
          return t.identifier == $event;
        })) === null || _vm$incoTerms$find === void 0 ? void 0 : _vm$incoTerms$find.name;
      }
    },
    model: {
      value: _vm.value.shippingTermsIdentifier,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "shippingTermsIdentifier", $$v);
      },
      expression: "value.shippingTermsIdentifier"
    }
  }, _vm._l(_vm.filterIncoTerms, function (term, idx) {
    return _c('option', {
      key: idx,
      domProps: {
        "value": term.identifier
      }
    }, [_vm._v(_vm._s(term.name) + " ")]);
  }), 0)], 1), _c('div', {
    staticClass: "col-5 col-md-6"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-collectOnDelivery.value',
      "name": "collectOnDelivery",
      "type": "number",
      "rules": {
        regex: /^[0-9]+([\.][0-9]{1,2})?$/,
        collectOnDelivery: {
          product: _vm.value.product
        },
        collectOnDeliveryMaximum: {
          maximum: (_vm$collectOnDelivery = _vm.collectOnDeliveryCurrency) === null || _vm$collectOnDelivery === void 0 ? void 0 : _vm$collectOnDelivery.collectOnDeliveryMaximum,
          currency: (_vm$value$collectOnDe = _vm.value.collectOnDelivery) === null || _vm$value$collectOnDe === void 0 ? void 0 : _vm$value$collectOnDe.currency
        }
      },
      "placeholder": _vm.$t('order.collectOnDelivery'),
      "disabled": !_vm.editable || _vm.checkCodDisabled,
      "append": _vm.$t('currency.' + (((_vm$value$collectOnDe2 = _vm.value.collectOnDelivery) === null || _vm$value$collectOnDe2 === void 0 ? void 0 : _vm$value$collectOnDe2.currency) || ((_vm$collectOnDelivery2 = _vm.collectOnDeliveryCurrency) === null || _vm$collectOnDelivery2 === void 0 ? void 0 : _vm$collectOnDelivery2.isoCode) || 'EUR')),
      "value": (_vm$value$collectOnDe3 = _vm.value.collectOnDelivery) === null || _vm$value$collectOnDe3 === void 0 ? void 0 : _vm$value$collectOnDe3.value
    },
    on: {
      "input": function input($event) {
        return _vm.setCollectOnDeliveryValue($event);
      }
    },
    scopedSlots: _vm._u([_vm.editable && !_vm.checkCodDisabled ? {
      key: "append",
      fn: function fn(_ref) {
        var _vm$value$collectOnDe4, _vm$collectOnDelivery3;

        var additionalClasses = _ref.additionalClasses;
        return [_c('b-dropdown', {
          staticClass: "p-0",
          class: additionalClasses,
          attrs: {
            "variant": "secondary",
            "toggle-class": "input-group-text dropdown-input",
            "text": _vm.$t('currency.' + (((_vm$value$collectOnDe4 = _vm.value.collectOnDelivery) === null || _vm$value$collectOnDe4 === void 0 ? void 0 : _vm$value$collectOnDe4.currency) || ((_vm$collectOnDelivery3 = _vm.collectOnDeliveryCurrency) === null || _vm$collectOnDelivery3 === void 0 ? void 0 : _vm$collectOnDelivery3.isoCode) || 'EUR'))
          }
        }, _vm._l(_vm.supportedCurrencies, function (c, idx) {
          return _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.setCollectOnDeliveryCurrency(c.isoCode);
              }
            }
          }, [_vm._v(" " + _vm._s(c.isoCode) + " ")]);
        }), 1)];
      }
    } : null], null, true)
  })], 1), (_vm$value$insuranceVa = _vm.value.insuranceValue) !== null && _vm$value$insuranceVa !== void 0 && _vm$value$insuranceVa.value || !_vm.waiverCustomer ? _c('div', {
    staticClass: "col-7 col-md-6"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-insuranceValue.value',
      "name": "insuranceValue",
      "type": "number",
      "rules": {
        regex: /^[0-9]+([\.][0-9]{1,2})?$/
      },
      "placeholder": _vm.$t('order.insuranceValue'),
      "disabled": !_vm.editable,
      "append": _vm.$t('currency.' + _vm.value.insuranceValue.currency)
    },
    model: {
      value: _vm.value.insuranceValue.value,
      callback: function callback($$v) {
        _vm.$set(_vm.value.insuranceValue, "value", $$v);
      },
      expression: "value.insuranceValue.value"
    }
  })], 1) : _vm._e(), !_vm.waiverCustomer && !((_vm$value$insuranceVa2 = _vm.value.insuranceValue) !== null && _vm$value$insuranceVa2 !== void 0 && _vm$value$insuranceVa2.value) ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-row text-danger text-center"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._v(_vm._s(_vm.$t('order.insuranceValue.defaultInsurance', {
    currency: (_vm$insuranceCurrency = _vm.insuranceCurrency) === null || _vm$insuranceCurrency === void 0 ? void 0 : _vm$insuranceCurrency.isoCode,
    defaultPerKg: _vm.$n((_vm$insuranceCurrency2 = _vm.insuranceCurrency) === null || _vm$insuranceCurrency2 === void 0 ? void 0 : _vm$insuranceCurrency2.insuranceDefaultPerKg)
  })))])])]) : _vm._e(), ((_vm$value$insuranceVa3 = _vm.value.insuranceValue) === null || _vm$value$insuranceVa3 === void 0 ? void 0 : _vm$value$insuranceVa3.value) > ((_vm$insuranceCurrency3 = _vm.insuranceCurrency) === null || _vm$insuranceCurrency3 === void 0 ? void 0 : _vm$insuranceCurrency3.insuranceThreshold) ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "form-row text-danger text-center"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._v(_vm._s(_vm.$t('order.insuranceValue.adviceText', {
    currency: (_vm$insuranceCurrency4 = _vm.insuranceCurrency) === null || _vm$insuranceCurrency4 === void 0 ? void 0 : _vm$insuranceCurrency4.isoCode,
    threshold: _vm.$n((_vm$insuranceCurrency5 = _vm.insuranceCurrency) === null || _vm$insuranceCurrency5 === void 0 ? void 0 : _vm$insuranceCurrency5.insuranceThreshold)
  })))])])]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }