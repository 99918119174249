var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('e-scrollable-search-filter-page', {
    ref: "page",
    attrs: {
      "service": _vm.service
    },
    on: {
      "enableSpinner": function enableSpinner($event) {
        return _vm.loading(true);
      },
      "disableSpinner": function disableSpinner($event) {
        return _vm.loading(false);
      }
    },
    scopedSlots: _vm._u([{
      key: "header-controls",
      fn: function fn(_ref) {
        var created = _ref.created;
        return [_c('b-button-toolbar', {
          staticClass: "float-right",
          attrs: {
            "key-nav": ""
          }
        }, [_c('b-button-group', {
          staticClass: "mx-1"
        }, [_c('b-button', {
          staticClass: "pl-2 pr-2",
          attrs: {
            "type": "button",
            "size": "sm",
            "variant": "secondary"
          },
          on: {
            "click": _vm.showCreateCustomerModal
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "plus"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('controls.create')) + " ")], 1)], 1)], 1), _c('customer-create-modal', {
          ref: "create-customer-modal",
          on: {
            "save": created
          }
        })];
      }
    }, {
      key: "spinner",
      fn: function fn() {
        return [_vm.isLoading ? _c('b-spinner', {
          staticClass: "spinner",
          attrs: {
            "label": "Loading..."
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "entity-content",
      fn: function fn(_ref2) {
        var id = _ref2.id,
            entity = _ref2.entity,
            index = _ref2.index,
            deleted = _ref2.deleted,
            saved = _ref2.saved;
        return [_c('detail-view', {
          attrs: {
            "id": id,
            "value": entity
          },
          on: {
            "delete": function _delete($event) {
              return deleted(index);
            },
            "save": function save($event) {
              return saved(index, $event);
            },
            "enableSpinner": function enableSpinner($event) {
              return _vm.loading(true);
            },
            "disableSpinner": function disableSpinner($event) {
              return _vm.loading(false);
            }
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }