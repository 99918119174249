<template>
  <form @submit.prevent="handleSubmit(onSubmit)">

    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-contextId'" class="col-4 col-form-label">Sendungsnummer</label>
          <div class="col-8">

            <p class="form-control-plaintext">
              <router-link :to="{ path: '/shipments/list', query: { searchText: entity.contextId } }">
                {{ entity.contextId }}
              </router-link>
            </p>

          </div>
        </div>

      </div>

      <div class="col-8">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-recipients'" class="col-2 col-form-label">Empfänger</label>
          <div class="col-10">
            <e-form-text :id="id + '-mailPersistenceEvent-recipients'" :value="entity.recipients"/>
          </div>
        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-emonsCustomerId'" class="col-4 col-form-label">Kundennummer</label>
          <div class="col-8">
            <e-form-text :id="id + '-mailPersistenceEvent-emonsCustomerId'" :value="entity.emonsCustomerId"/>
          </div>
        </div>


      </div>

      <div class="col-8">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-subject'" class="col-2 col-form-label">Betreff</label>
          <div class="col-10">
            <e-form-text :id="id + '-mailPersistenceEvent-subject'" :value="entity.subject"/>
          </div>
        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-publicationDate'" class="col-4 col-form-label">Datum</label>
          <div class="col-8">
            <e-form-text :id="id + '-mailPersistenceEvent-publicationDate'" :value="entity.publicationDate"/>
          </div>
        </div>


      </div>

      <div class="col-8">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-content'" class="col-2 col-form-label">Inhalt</label>
          <div class="col-10">
            <e-form-text :id="id + '-mailPersistenceEvent-content'" :value="entity.content"/>
            <b-icon v-if="entity.hasAttachment" icon="paperclip"/>
          </div>
        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-status'" class="col-4 col-form-label">Status</label>
          <div class="col-8">
            <e-form-text :id="id + '-mailPersistenceEvent-status'" :value="entity.status"/>
          </div>
        </div>

      </div>

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-retry'" class="col-4 col-form-label">Anzahl Versuche</label>
          <div class="col-8">
            <e-form-text :id="id + '-mailPersistenceEvent-retry'" :value="entity.retry + ' von ' + entity.maxRetries"/>
          </div>
        </div>

      </div>

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-nextRetryAfter'" class="col-4 col-form-label">Nächster Versuch</label>
          <div class="col-8">
            <e-form-text :id="id + '-mailPersistenceEvent-nextRetryAfter'" :value="entity.nextRetryAfter"/>
          </div>
        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-8">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-statusMessage'" class="col-2 col-form-label">Status-Message</label>
          <div class="col-10">
            <e-form-text :id="id + '-mailPersistenceEvent-statusMessage'" :value="entity.statusMessage"/>
          </div>
        </div>

      </div>

      <div class="col-4">


      </div>


    </div>

    <div class="row"
         v-if="entity.completionDate">

      <div class="col-4">

        <div class="form-group row">
          <label :for="id + '-mailPersistenceEvent-completionDate'" class="col-4 col-form-label">Versendet am</label>
          <div class="col-8">
            <e-form-text :id="id + '-mailPersistenceEvent-completionDate'" :value="entity.completionDate"/>
          </div>
        </div>

      </div>

      <div class="col-4">

      </div>

      <div class="col-4">

      </div>

    </div>

  </form>
</template>

<script>

import service from "@/services/mailPersistenceEvent.service";
import {log} from "@emons/emons-vue"

export default {
  name: "MailPersistenceEventView",
  components: {},
  mounted() {
    log('debug', 'Mounted MailPersistenceEventView...')
    this.getEntityDetails()
  },
  props: {
    id: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      entity: {
        abbreviation: null,
        name: null
      }
    }
  },
  methods: {
    getEntityDetails: function () {
      this.$emit('enableSpinner');
      service
          .getEntityDetails(this.link)
          .then(response => {
            this.entity = response.data
            this.$emit('disableSpinner');
          })
    }
  }
}
</script>

<style scoped>

</style>