<template>
  <e-scrollable-search-filter-page :service="service"
                                   v-on:enableSpinner="enableSpinner()"
                                   v-on:disableSpinner="disableSpinner()">

    <template v-slot:header-controls="{ created, items, links }">

      <b-button-toolbar key-nav class="float-right">

        <b-button-group class="mx-1" v-if="links?.create?.href">
          <b-button type="button"
                    size="sm"
                    variant="secondary"
                    class="pl-2 pr-2"
                    :disabled="items.filter(item => !item._links).length > 0"
                    @click="created(service.create())">
            <b-icon icon="plus"/>
            {{ $t('controls.create') }}
          </b-button>
        </b-button-group>

      </b-button-toolbar>
    </template>

    <template v-slot:spinner>

      <b-spinner v-if="loading"
                 class="spinner"
                 label="Loading..."/>

    </template>

    <template v-slot:table-header="{ items, sortBy, sortIcon }">
      <div class="col-1 clip-text" @click="sortBy('isDefaultSenderAddress')">
        <component v-bind:is="sortIcon('isDefaultSenderAddress')"/>
        <strong>{{ $t('address.defaultAddress') }}</strong>
      </div>
      <div class="col-1 clip-text" @click="sortBy('customerId')">
        <component v-bind:is="sortIcon('customerId')"/>
        <strong>{{ $t('address.customerId') }}</strong>
      </div>
      <div class="col-3 clip-text" @click="sortBy('name')">
        <component v-bind:is="sortIcon('name')"/>
        <strong>{{ $t('address.name') }}</strong>
      </div>
      <div class="col-5">
        <div class="row">
          <div class="col-5 clip-text" @click="sortBy('street')">
            <component v-bind:is="sortIcon('street')"/>
            <strong>{{ $t('address.street') }}</strong>
          </div>
          <div class="col-1 clip-text" @click="sortBy('country')">
            <component v-bind:is="sortIcon('country')"/>
            <strong>{{ $t('address.country') }}</strong>
          </div>
          <div class="col-2 clip-text" @click="sortBy('zipCode')">
            <component v-bind:is="sortIcon('zipCode')"/>
            <strong>{{ $t('address.zipCode') }}</strong>
          </div>
          <div class="col-4 clip-text" @click="sortBy('city')">
            <component v-bind:is="sortIcon('city')"/>
            <strong>{{ $t('address.city') }}</strong>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:entity-header="{id, items, entity, index, deleted, saved}">
      <div class="col-1 clip-text text-center">
        <span v-if="!!entity.isDefaultSenderAddress">
          <e-tooltip :title="$t('addressType.SENDER')"><b-icon-check-circle /></e-tooltip>
        </span>
        <span v-if="!!entity.isDefaultSenderAddress && !!entity.isDefaultConsigneeAddress"> / </span>
        <span v-if="!!entity.isDefaultConsigneeAddress">
          <e-tooltip :title="$t('addressType.CONSIGNEE')">
            <b-icon-check-circle />
          </e-tooltip>
        </span>
        <span v-else></span>
      </div>
      <div class="col-1 clip-text">
        <span>{{ entity.customerId }}</span>
      </div>
      <div class="col-3 clip-text">
        <span>{{ entity.name }}</span>
      </div>
      <div class="col-5">
        <div class="row">
          <div class="col-5 clip-text">
            <span>{{ entity.street }}</span>
          </div>
          <div class="col-1 clip-text">
            <span>{{ entity.country }}</span>
          </div>
          <div class="col-2 clip-text">
            <span>{{ entity.zipCode }}</span>
          </div>
          <div class="col-4 clip-text">
            <span>{{ entity.city }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:entity-content="{ id, entity, index, deleted, saved }">
      <detail-view :id="id"
                   :value="entity"
                   @delete="deleted(index)"
                   @save="saved(index, $event)"
                   v-on:enableSpinner="enableSpinner()"
                   v-on:disableSpinner="disableSpinner()"/>
    </template>

  </e-scrollable-search-filter-page>
</template>

<script>
import service from "@/services/address.service";
import DetailView from "@/components/AddressView";
import {log} from "@emons/emons-vue"

export default {
  name: 'AddressList',
  components: {DetailView},
  data() {
    return {
      loading: false,
      service: service
    }
  },
  methods: {
    enableSpinner: function () {
      log('debug', 'enableSpinner() called...')
      this.loading = true
    },
    disableSpinner: function () {
      log('debug', 'disableSpinner() called...')
      this.loading = false
    }
  }
}
</script>

<style scoped>

.spinner {
  position: absolute;
  z-index: 100000;
  top: 25%;
  left: 50%;
}

</style>