<template>
  <validation-observer ref="observer" v-slot="{ invalid, changed }">
    <b-modal ref="modal" size="xl"
        :id="id" @hidden="reset">
      <template #modal-title>
        <span v-if="address?._links?.self?.href">{{ $t('customer.edit-address', {customerId: customerId}) }}</span>
        <span v-else>{{ $t('customer.add-address', {customerId: customerId}) }}</span>
      </template>
      <template #default>
        <address-view :id="id" v-model="address" ref="addressView"
                      :show-actions="false" @save="$emit('save', $event)" :is-admin="isAdmin" />
      </template>
      <template #modal-footer="{ok, cancel, hide}">
        <div class="buttons">
          <b-button variant="danger" v-if="address?._links?.delete?.href"
                    class="float-left" @click="confirmDelete(address, ok)">{{ $t('controls.delete') }}</b-button>
          <b-button variant="secondary" @click="cancel">{{ $t('controls.cancel') }}</b-button>
          <b-button variant="primary" @click="submit(ok)" :disabled="$refs['addressView']?.isSaveInProgress() || !(changed) || invalid">
            <b-spinner v-if="$refs['addressView']?.isSaveInProgress()" small /> {{ $t('controls.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import AddressView from "@/components/AddressView"
import { ValidationObserver } from "@emons/emons-vue"
import UserService from "@/services/user.service";
import AddressService from "@/services/address.service";

export default {
  name: "AddressModal",
  components: {AddressView, ValidationObserver},
  props: {
    id: {
      type: String,
      default: ''
    },
    customerId: {
      type: String,
      default: ''
    },
    creationLink: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      address: {}
    }
  },
  methods: {
    confirmDelete(address, ok) {
      this.$bvModal.msgBoxConfirm(
          this.$t('address.delete.confirm'), {title: this.$t('address.delete.confirm.short')}
      ).then(result => {
        if (result) {
          this.$emit('enableSpinner');
          AddressService.delete(address).then(() => {
            this.$emit('disableSpinner');
            this.$emit('delete')
            ok();
          })
        }
      })
    },
    show: function (address = {}) {
      this.address = address
      this.$refs.modal.show()
    },
    reset: function() {
      this.address = {}
    },
    submit: async function(ok) {
      const success = await this.$refs.addressView.saveEntity(this.creationLink, false)
      if (!!success) {
        this.$emit('save')
        ok()
      }
    }
  }
}
</script>

<style scoped>
.modal-footer > .buttons {
  width: 100%!important;
  text-align: right;
}
.modal-footer > .buttons > * {
  margin: 0.25rem;
}
</style>