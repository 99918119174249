<template>
  <b-modal :id="id + '-addUserModal'" :title="$t('customer.add-user')" @ok="confirm">
    <v-select :options="foundUsers?.items" @click.stop="$log('DEBUG', 'clicked')"
              transition=""
              label="username"
              :searchable=true
              @search="(query, loading) => findUsers(query, loading)"
              :filterable=false
              v-model="selectedUser">
      <template v-slot:option="option">
        <div><b>{{ option.username }}</b><span v-if="option.customerIds?.length > 0"> - {{ option.customerIds.join(', ') }}</span></div>
        <div><em>{{ option.firstName}} {{option.lastName}} ({{ option.email }})</em></div>
      </template>
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching"><span v-html="$t('user.search.no-results', { query: search})" /></template>
        <em v-else style="opacity: 0.5">{{ $t('user.search.hint') }}</em>
      </template>
    </v-select>

    <div v-if="selectedUser?.customerIds.includes(customerId)" class="mt-2 text-danger">
      {{ $t('user.alreadyAssignedToCustomer') }}
    </div>
    <div v-else-if="!!selectedUser?.customerIds?.length > 0" class="mt-2 text-danger">
      <div>
      {{ $t('user.alreadyAssigned') }}
      </div>
      <div class="mt-2">
        <e-form-checkbox :id="id + '-addUserModal-reoveAssignments'" v-model="reassign" name="removeAssignments">
          {{$t('user.removeAssignment')}}
        </e-form-checkbox>
      </div>
    </div>


    <template #modal-footer="{ok, cancel, hide}">
      <b-button variant="secondary" @click="cancel">{{ $t('controls.abort') }}</b-button>
      <b-button variant="primary" @click="ok" :disabled="!selectedUser || selectedUser?.customerIds?.includes(customerId)">{{ $t('controls.ok') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import UserService from "@/services/user.service";

export default {
  name: "CustomerAddUserModal",
  props: {
    id: {
      type: String,
      required: true
    },
    customerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchString: "",
      foundUsers: [],
      selectedUser: null,
      reassign: true,
    }
  },
  methods: {
    reset: function() {
      this.searchString = ""
      this.selectedUser = null
      this.foundUsers = []
      this.reassign = true
    },
    show: function () {
      this.reset()
      this.$bvModal.show(this.id + '-addUserModal')
    },
    findUsers: async function(query, loadingIndicator) {
      this.foundUsers = []
      if (query.length > 2) {
        this.$log('DEBUG', 'searching members with searchString', query)
        loadingIndicator(true)
        this.foundUsers = (await UserService.find(query))?.data
        loadingIndicator(false)
      }
    },
    confirm() {
      if (!!this.selectedUser) {
        if (this.reassign) {
          this.selectedUser.customerIds = [this.customerId]
        } else {
          this.selectedUser.customerIds.push(this.customerId)
        }
        this.$emit('confirmed', this.selectedUser)
      }
    },
  },
  asyncComputed: {
    async searchUsers() {
      if (this.searchString?.length > 2) {
        this.$log('DEBUG', 'fetching members with searchString', this.searchString)
        return (await UserService.find(this.searchString))?.data
      }
    }
  }
}
</script>

<style scoped>

</style>