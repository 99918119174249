var render = function () {
  var _vm$products, _vm$value$product, _vm$value$product2, _vm$value$product3, _vm$value$product4, _vm$value$product5, _vm$value$product6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header p-2"
  }, [_c('label', {
    staticClass: "mb-0",
    attrs: {
      "for": _vm.id + 'productType'
    }
  }, [_c('a', {
    attrs: {
      "href": "/Emons_Produktflyer.pdf",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t('orders.product-characteristics')))])])]), _c('div', {
    staticClass: "card-body p-2"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('e-form-select', {
    attrs: {
      "id": _vm.id + '-product.identifier',
      "name": "identifier",
      "placeholder": _vm.$t('orders.choose-product'),
      "disabled": !(_vm.editable && ((_vm$products = _vm.products) === null || _vm$products === void 0 ? void 0 : _vm$products.length) !== 0 && _vm.value.sender.zipCode && _vm.value.consignee.zipCode)
    },
    model: {
      value: _vm.selectedProduct,
      callback: function callback($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  }, _vm._l(_vm.products, function (product, idx) {
    return _vm.products ? _c('option', {
      key: idx,
      domProps: {
        "value": product.identifier
      }
    }, [_vm._v(" " + _vm._s(product.name) + " ")]) : _vm._e();
  }), 0)], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_vm.value.product && _vm.value.product.deliveryDateWindow ? _c('div', {
    class: (_vm$value$product = _vm.value.product) !== null && _vm$value$product !== void 0 && _vm$value$product.timeslot ? 'col-9' : 'col-12'
  }, [!_vm.value.product.deliveryDateIsOptional ? _c('e-form-datepicker', {
    ref: "deliveryDate",
    attrs: {
      "id": _vm.id + '-deliveryDate',
      "name": "deliveryDate",
      "rules": {
        required: true,
        withinDeliveryWindow: {
          deliveryWindow: _vm.deliveryWindow
        }
      },
      "placeholder": _vm.value.product.deliveryDateWindow.min == _vm.value.product.deliveryDateWindow.max ? _vm.$t('orders.choose-shipping-date') : _vm.$t('orders.choose-delivery-date' + (!!_vm.value.product.deliveryDateIsOptional ? '.optional' : '')),
      "min": _vm.deliveryWindow.min,
      "max": _vm.deliveryWindow.max,
      "date-disabled-fn": _vm.isHoliday,
      "disabled": !_vm.editable || _vm.value.product.deliveryDateWindow.min == _vm.value.product.deliveryDateWindow.max
    },
    model: {
      value: _vm.value.deliveryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "deliveryDate", $$v);
      },
      expression: "value.deliveryDate"
    }
  }) : _c('e-form-datepicker', {
    ref: "deliveryDate",
    attrs: {
      "id": _vm.id + '-deliveryDate',
      "name": "deliveryDate",
      "rules": {
        required: false,
        withinDeliveryWindow: {
          deliveryWindow: _vm.deliveryWindow
        }
      },
      "placeholder": _vm.value.product.deliveryDateWindow.min == _vm.value.product.deliveryDateWindow.max ? _vm.$t('orders.choose-shipping-date') : _vm.$t('orders.choose-delivery-date.optional'),
      "min": _vm.deliveryWindow.min,
      "max": _vm.deliveryWindow.max,
      "date-disabled-fn": _vm.isHoliday,
      "disabled": !_vm.editable || _vm.value.product.deliveryDateWindow.min == _vm.value.product.deliveryDateWindow.max
    },
    model: {
      value: _vm.value.deliveryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "deliveryDate", $$v);
      },
      expression: "value.deliveryDate"
    }
  })], 1) : _vm._e(), (_vm$value$product2 = _vm.value.product) !== null && _vm$value$product2 !== void 0 && _vm$value$product2.timeslot ? _c('div', {
    staticClass: "col-3"
  }, [_c('e-form-text-input', {
    attrs: {
      "id": _vm.id + '-timeslot',
      "name": "timeslot",
      "disabled": "",
      "value": _vm.$t('timeslot.' + _vm.value.product.timeslot)
    }
  })], 1) : _vm._e()]), this.value.sender == null || this.value.consignee == null ? _c('div', {
    staticClass: "form-row text-danger text-center"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._v(_vm._s(_vm.$t('product.senderAndConsigneeRequired')))])]) : _vm._e(), ((_vm$value$product3 = _vm.value.product) === null || _vm$value$product3 === void 0 ? void 0 : _vm$value$product3.region) == 'EU' && (_vm$value$product4 = _vm.value.product) !== null && _vm$value$product4 !== void 0 && _vm$value$product4.deliveryDateWindow && !((_vm$value$product5 = _vm.value.product) !== null && _vm$value$product5 !== void 0 && _vm$value$product5.deliveryDateIsOptional) ? _c('div', {
    staticClass: "form-row alert alert-warning text-center"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._v(_vm._s(_vm.$t('product.transitTimeShorteningPossible')))])]) : _vm._e(), (_vm$value$product6 = _vm.value.product) !== null && _vm$value$product6 !== void 0 && _vm$value$product6.requiresConsultation ? _c('div', {
    staticClass: "form-row text-danger text-center"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._v(_vm._s(_vm.$t('product.requires-consultation')))])]) : _vm._e(), _vm.notificationCodesFiltered ? _c('div', {
    staticClass: "form-row text-danger text-center"
  }, [_c('div', {
    staticClass: "col-12",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('product.consigneeAnnouncementFiltered'))
    }
  })]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }