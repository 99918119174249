<template>
  <div class="card">
    <div class="card-header p-2">

      <div class="form-row align-items-center">
        <label class="col-4 mb-0">{{ $t('shipment.sender_or_loadingsite') }}</label>
        <div class="col-8 text-right">
          <addressbook-select :types="['SENDER', 'LOADING_SITE', 'NEUTRAL']"
                              show-type
                              :editable="editable" @input="selectedSenderChanged" ref="addressbookSelect" />
        </div>
      </div>
    </div>
    <div class="card-body p-2">
      <div v-if="value == null">
        Keine Adresse ausgewählt.
      </div>
      <div v-else>
        <validation-observer vid="sender_observer"
                             ref="observer"
                             v-slot="{ invalid, changed, dirty }" slim>

          <div class="form-row">
            <div class="col-12"><label for="sender-name">{{ $t('address.address-data') }}</label></div>
          </div>

          <div class="form-row">
            <div class="col-7">
              <e-form-text-input :id="id + '-sender.name'" name="name" rules="required" maxlength="35"
                                 :placeholder="$t('address.name')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.name"/>
            </div>
            <div class="col-5">
              <e-form-text-input :id="id + '-sender.customerId'" name="customerId" maxlength="35"
                                 :placeholder="$t('address.customerId')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.customerId"/>
            </div>
          </div>
          <div class="form-row">
            <div class="col-7">
              <e-form-text-input :id="id + '-sender.additionalName'" name="additionalName" maxlength="35"
                                 :placeholder="$t('address.additionalName')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.additionalName"/>
            </div>
            <div class="col-5">
              <e-form-checkbox :id="id + '-sender.isNeutral'" name="isNeutral"
                               :disabled="!editable"
                               :value="value.types?.some(type => type === 'NEUTRAL')" @input="toggleNeutral($event)">
                <e-tooltip :disabled="!editable" :title="neutralCheckboxTooltip">
                  {{ neutralCheckboxLabel }}
                </e-tooltip>
              </e-form-checkbox>
              <e-form-checkbox :id="id + '-sender.isLoadingSite'" name="isLoadingSite"
                               :placeholder="$t('addressType.LOADING_SITE')"
                               :disabled="!editable"
                               :value="isLoadingSite"
                               @input="setLoadingSite($event)" />
              <e-form-checkbox :id="id + '-sender.isRetail'" name="sender.isRetail"
                               :placeholder="$t('shipment.sender.isRetail')"
                               :disabled="!(editable && isAddressDataEditable)"
                               v-model="value.isRetail"/>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12">
              <e-form-text-input :id="id + '-sender.street'" name="street" rules="required" maxlength="35"
                                 :placeholder="$t('address.streetWithNumber')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.street"/>
            </div>
          </div>
          <div class="form-row">
            <div class="col-3">
              <country-select :id="id + '-sender.country'" name="country" :required="true"
                              v-model="value.country"
                              :placeholder="$t('address.country')"
                              :disabled="!(editable && isAddressDataEditable)" />
            </div>
            <div class="col-3">
              <e-form-text-input :id="id + '-sender.zipCode'" name="zipCode"
                                 :rules="{required: true, zipCode:{country: '@' + id + '-sender.country'}}"
                                 :placeholder="$t('address.zipCode')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 @blur="formatZipCode($event)"
                                 v-model="value.zipCode"/>
            </div>
            <div class="col-6">
              <city-select :id="id + '-sender.city'" name="city"
                           rules="required"
                           :disabled="!(editable && isAddressDataEditable)"
                           :country="value.country"
                           :zip-code="value.zipCode"
                           v-model="value.city"
                           @citySelect="onCitySelect"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12  pb-2" v-if="editable && !isAddressDataEditable">
              <em>
              {{ $t('address.only-contact-data-editable') }}
              {{ $t('address.contact-support-for-changing-administrative-data') }}
              </em>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <label for="sender-contact-name">{{ $t('address.contact-data') }}</label>
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-12 col-md-6">
              <e-form-text-input :id="id + '-sender.contactName'" name="contactName" maxlength="255"
                                 ref="contactName"
                                 :placeholder="$t('address.contactName')"
                                 :disabled="!editable"
                                 v-model="value.contactName"/>
            </div>
            <div class="col-sm-12 col-md-6">
              <e-form-text-input :id="id + '-sender.phone'" name="phone" rules="phoneNumber"
                                 ref="phoneNumber"
                                 :placeholder="$t('address.phone')"
                                 :disabled="!editable"
                                 v-model="value.phone"/>
            </div>
            <div class="col-sm-12 col-md-6" v-if="isLoadingSite">
              <e-form-text-input :id="id + '-sender.email'" name="email"
                                 :placeholder="$t('address.email')"
                                 :disabled="!editable"
                                 :rules="{email: true, required: value.autosendShippingDocuments === true}"
                                 v-model="value.email" />
            </div>
            <div class="col-sm-12 col-md-6" v-if="isLoadingSite">
              <e-form-checkbox :id="id + '-sender.autosendShippingDocuments'" name="autosendShippingDocuments"
                               :disabled="!editable"
                               :placeholder="$t('address.autosendShippingDocuments')"
                               v-model="value.autosendShippingDocuments" />
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <slot name="default" />
            </div>
          </div>

          <div class="form-row">

            <div class="col-12 text-right">

              <button class="btn btn-link"
                      :disabled="!editable || !(changed || dirty || value._links)"
                      @click="createNewSender">
                <span>{{ $t('address.reset-addressbook-entry') }}</span>
              </button>

              <button v-if="editable"
                      class="btn btn-link"
                      :disabled="!(changed || dirty) || invalid"
                      @click="saveSender">
                {{ value._links ? $t('address.update-addressbook-entry') : $t('address.create-addressbook-entry') }}
              </button>

            </div>

          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SenderAddressService from "@/services/address.service"
import {formatZipCode, ValidationObserver, ValidationProvider} from '@emons/emons-vue'
import CountrySelect from "@/components/form-controls/CountrySelect";
import vueDebounce from 'vue-debounce'
import CitySelect from "@/components/form-controls/CitySelect"
import AddressbookSelect from "@/components/form-controls/AddressbookSelect";
import DeliveryNotesView from "@/components/DeliveryNotesView.vue";

Vue.use(vueDebounce)

export default {
  name: "OrderSenderView",
  components: {DeliveryNotesView, AddressbookSelect, CitySelect, CountrySelect, ValidationObserver, ValidationProvider},
  props: {
    value: {
      type: Object,
    },
    id: {
      type: String,
      default: 'sender'
    },
    editable: {
      type: Boolean,
      default: false
    },
    deliveryNotes: {
    },
    defaultCountry: {
      type: String,
      default: 'DE'
    },
    isBelog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    isLoadingSite: function() {
      const types = this.value?.types
      this.$log('DEBUG', 'recalculating types...', types)

      return types?.some(type => type === 'LOADING_SITE')
    },
    isAddressDataEditable: function () {
      this.$log('DEBUG', 'recalculating isAddressDataEditable...')
      return this.isEmonsCustomerIdEmpty
    },
    isEmonsCustomerIdEqualsCustomerId: function () {
      const emonsCustomerId = this.value.emonsCustomerId
      const customerId = this.value.customerId

      this.$log('DEBUG', 'recalculating isEmonsCustomerIdEqualsCustomerId...', emonsCustomerId)

      return emonsCustomerId != null
          && emonsCustomerId.length > 0
          && emonsCustomerId === customerId
    },
    isEmonsCustomerIdEmpty: function () {
      const emonsCustomerId = this.value.emonsCustomerId

      this.$log('DEBUG', 'recalculating isEmonsCustomerIdEmpty...', emonsCustomerId)
      return  emonsCustomerId == null || emonsCustomerId.length === 0
    },
    neutralCheckboxLabel: function() {
      this.$log('DEBUG', 'recalculating neutralCheckboxLabel...')
      if (this.isEmonsCustomerIdEqualsCustomerId) {
        return this.$t('address.useNeutralLoadingSite');
      }

      return this.$t('address.useNeutralConsignor');
    },
    neutralCheckboxTooltip: function() {
      this.$log('DEBUG', 'recalculating neutralCheckboxTooltip...')
      if (this.isEmonsCustomerIdEqualsCustomerId) {
        return this.$t('address.useNeutralLoadingSite.tooltip')
      }
      return this.$t('address.useNeutralConsignor.tooltip');
    }
  },
  methods: {
    setLoadingSite: function (isLoadingSite) {
      if (!this.value.types) {
        this.$set(this.value, 'types', [])
      }
      if (isLoadingSite) {
        if (this.value.types?.indexOf('LOADING_SITE') < 0) {
          this.value.types?.push('LOADING_SITE')
        }
      } else {
        this.value.types = this.value?.types?.filter(type => type != 'LOADING_SITE')
      }
    },
    selectedSenderChanged: function (sender) {
      if (sender != null) {
        sender.isNeutral = sender.types?.includes('NEUTRAL')
        this.$log('DEBUG', 'isNeutral', sender.isNeutral, sender.types?.includes('NEUTRAL'))

        this.$log("debug", 'sender address "%s" selected', sender?._links?.self?.href , sender)
        this.$emit('input', sender)
      }
    },
    createNewSender: function () {
      this.$log('debug', 'create new sender')
      this.$refs.addressbookSelect.clear()
      this.$emit('input',{
        name: '',
        additionalName: '',
        emonsCustomerId: '',
        customerId: '',
        isRetail: false,
        types: ['SENDER'],
        street: '',
        country: this.defaultCountry,
        zipCode: '',
        city: '',
        contactName: '',
        phone: '',
        email: '',
        autosendShippingDocuments: false
      })
      this.$emit('cleared')
    },
    toggleNeutral: function(neutral) {
      if (neutral) {
        if (this.value.types?.indexOf('NEUTRAL') < 0) {
          this.value.types?.push('NEUTRAL')
        }
      } else {
        this.value.types = this.value.types?.filter(type => type != 'NEUTRAL')
      }
      const prevValue = this?.value?.isNeutral
      this.value.isNeutral = this.value.types?.includes('NEUTRAL')
      this.$log('DEBUG', 'isNeutral', this.value.isNeutral, this.value.types?.includes('NEUTRAL'))
      if (prevValue != this.value.isNeutral) {
        this.fillContactDataWithCustomer(this.value, this.isBelog)
      }

    },
    saveSender: async function () {
      if (this.value != null) {
        if (this.value.types == null || this.value.types.length === 0) {
          this.value.types = ['SENDER'];
        }

        const isUpdate = !!this.value?._links

        this.$root.$log("debug", "saving sender", this.value)
        SenderAddressService
            .save(this.value)
            .then((response) => {
              this.$refs.observer.reset()
              this.selectedSenderChanged(response.data)
              this.$bvModal.msgBoxOk(this.$t(isUpdate?'address.updated':'address.saved'), {
                title: this.$t(isUpdate?'address.updated.short':'address.saved.short'),
                titleClass: 'pl-2',
                centered: false,
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
              })
            })
      }
    },
    formatZipCode: function (zipCode) {
      this.value.zipCode = formatZipCode(this.value.country, zipCode)
    },
    onCitySelect: function(selection) {
      //B388: Wenn PLZ nur zum Teil eingegeben wird, dann aus der Liste der Orte ausgewählt wird mit vollständiger PLZ, diese auch im PLZ-Feld setzten!
      if(selection.zipCode.length > this.value.zipCode.length)
        this.value.zipCode = selection.zipCode;
    },
    fillContactDataWithCustomer(sender, isBelog) {
      // [Azure:1146] get contactName and phone number from token not set
      const wasModified = this.$refs.observer?.flags?.changed || this.$refs.observer?.flags?.dirty || sender._links || false
      let localModified = false
      if (!sender?.types?.includes('NEUTRAL') && !isBelog && !sender.contactName && !sender?.phone) {
        const kc = this.$root.$options.keycloak?.tokenParsed
        if (kc?.name) {
          sender.contactName = kc.name
          sender._contactDataAutofilled = true
          localModified = true
        }
        if (kc?.phone_number) {
          sender.phone = kc.phone_number
          sender._contactDataAutofilled = true
          localModified = true
        }
      } else {
        if (sender._contactDataAutofilled) {
          sender.contactName = ""
          sender.phone = ""
          delete sender._contactDataAutofilled
          localModified = true
        }
      }
      if (!wasModified && localModified) {
        this.$nextTick(() => this.$refs.observer?.reset())
      }
    }
  },
  watch: {
    isBelog: function (newVal, oldVal) {
      if (!this.editable) {
        return
      }
      if (newVal != oldVal) {
        this.fillContactDataWithCustomer(this.value, this.isBelog)
      }
    },
    value: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (!this.editable) {
          return
        }
        if (newVal !== oldVal) { // for some unknown reason value is changed although the real object doesn't change
          if (newVal != null) {
            this.fillContactDataWithCustomer(newVal, this.isBelog)

            this.$nextTick(() => {
              this.$refs.observer?.reset()
              if (newVal._links) {
                this.$refs.observer.validate()
              } else {
                // [Azure:1146] contactName and phone number need to be validated if set
                if (newVal.contactName) {
                  this.$refs.contactName.$refs.validationProvider.validate()
                }
                if (newVal.phone) {
                  this.$refs.phoneNumber.$refs.validationProvider.validate()
                }
              }
            })
          }
        }
      }
    }
  }
}
</script>
