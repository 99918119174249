var AxiosServiceMixin = {
    data() {
        return {
            axiosSaveInProgress: false,
        }
    },
    methods: {
        isSaveInProgress: function() {
            return this.axiosSaveInProgress
        },
        setFormErrors: function(axiosResponse, observer, idPrefix) {
            if (observer && axiosResponse?.response?.data?.errors) {
                let errors = {}
                for (const err of axiosResponse?.response?.data?.errors) {
                    errors[idPrefix + err.field] = []
                    errors[idPrefix + err.field][0] = err.defaultMessage
                }
                observer.setErrors(errors)
            }
        },
        handleAxiosError: function(axiosResponse, observer, idPrefix) {
            this.setFormErrors(axiosResponse, observer, idPrefix)
            this.$eventBus.$emit('backend-error', axiosResponse)
        },
        doAxiosCall: async function(axiosTransaction) {
            if (this.axiosSaveInProgress) {
                return;
            }
            this.axiosSaveInProgress = true
            let start = Date.now()
            this.$emit('enableSpinner')
            try {
                return await axiosTransaction()
            } finally {
                let duration = Date.now() - start
                if (duration < 1000) {
                    setTimeout(() => this.axiosSaveInProgress = false, 1000 - duration)
                } else {
                    this.axiosSaveInProgress = false
                }
                this.$emit('disableSpinner')
            }
        },
        doSave: async function(service, objectToSave, cbSuccess, cbError, observer, idPrefix, pathForCreate = null) {
            this.$log('debug', 'call doAxiosCall')
            return await this.doAxiosCall(async () => {
                let success = false
                try {
                    let response = await service.save(objectToSave, pathForCreate)
                    this.$emit('disableSpinner')
                    Object.assign(objectToSave, response.data)
                    observer?.reset()
                    await cbSuccess()
                    this.$emit('save', response.data)
                    success = true
                } catch(error) {
                    success = false
                    this.$emit('disableSpinner')
                    const allErrorsHandled = cbError(error)
                    if (!allErrorsHandled) {
                        this.handleAxiosError(error, observer, idPrefix)
                    }
                }
                return success
            })
        }
    }
}

export default AxiosServiceMixin