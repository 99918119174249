<template>
  <div class="card">
    <div class="card-header p-2">
      <div class="form-row align-items-center">
        <label class="col-4 mb-0">{{ $t('shipment.consignee') }}</label>
        <div class="col-8 text-right">
          <addressbook-select :types="['CONSIGNEE']" :editable="editable" @input="selectedConsigneeChanged" ref="addressbookSelect" />
        </div>
      </div>
    </div>
    <div class="card-body p-2">
      <div v-if="value == null">
        Keine Adresse ausgewählt.
      </div>
      <div v-else>
        <validation-observer vid="consignee_observer" ref="observer"
                             v-slot="{ invalid, changed, dirty }" slim>
          <div class="form-row">
            <div class="col-12"><label for="consignee-name">{{ $t('address.address-data') }}</label></div>
          </div>

          <div class="form-row">
            <div class="col-7">
              <e-form-text-input :id="id + '-consignee.name'" name="name" rules="required" maxlength="35"
                                 :placeholder="$t('address.name')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.name"/>
            </div>
            <div class="col-5">
              <e-form-text-input :id="id + '-consignee.customerId'" name="customerId" maxlength="35"
                                 :placeholder="$t('address.customerId')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.customerId"/>
            </div>
          </div>
          <div class="form-row">
            <div class="col-7">
              <e-form-text-input :id="id + '-consignee.additionalName'" name="additionalName" maxlength="35"
                                 :placeholder="$t('address.additionalName')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.additionalName"/>
            </div>
            <div class="col-5">
              <e-form-checkbox :id="id + '-consigneee.isRetail'" name="isRetail"
                               :placeholder="$t('shipment.consignee.isRetail')"
                               :disabled="!(editable && isAddressDataEditable)"
                               v-model="value.isRetail"/>
              <e-form-checkbox :id="id + '-consigneee.isBusiness'" name="isBusiness"
                               :placeholder="$t('shipment.consignee.isBusiness')"
                               :disabled="!(editable && isAddressDataEditable)"
                               :value="!value.isRetail" @input="value.isRetail = !$event"/>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12">
              <e-form-text-input :id="id + '-consignee.street'" name="street" rules="required" maxlength="35"
                                 :placeholder="$t('address.streetWithNumber')"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.street"/>
            </div>
          </div>
          <div class="form-row">
            <div class="col-3">
              <country-select :id="id + '-consignee.country'" name="country" :required="true"
                              v-model="value.country"
                              :placeholder="$t('address.country')"
                              :disabled="!(editable && isAddressDataEditable)" />
            </div>
            <div class="col-3">
              <e-form-text-input :id="id + '-consignee.zipCode'" name="zipCode"
                                 :rules="{required: true, zipCode:{country: '@' + id + '-consignee.country'}}"
                                 :placeholder="$t('address.zipCode')"
                                 @blur="formatZipCode($event)"
                                 :disabled="!(editable && isAddressDataEditable)"
                                 v-model="value.zipCode"/>
            </div>
            <div class="col-6">
              <city-select :id="id + '-consignee.city'"
                           rules="required"
                           :disabled="!(editable && isAddressDataEditable)"
                           :country="value.country"
                           :zip-code="value.zipCode"
                           v-model="value.city"
                           @citySelect="onCitySelect"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12  pb-2" v-if="editable && !isAddressDataEditable">
              <em>
                {{ $t('address.only-contact-data-editable') }}
                {{ $t('address.contact-support-for-changing-administrative-data') }}
              </em>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12"><label for="consignee-contact-name">{{ $t('address.contact-data') }}</label>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <e-form-text-input :id="id + '-consignee.contactName'" name="contactName"
                                 :placeholder="$t('address.consignee.contactName')" maxlength="255"
                                 :disabled="!editable"
                                 v-model="value.contactName"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.mobile'" name="mobile" rules="phoneNumber:MOBILE"
                                 :placeholder="$t('address.mobile')"
                                 :disabled="!editable"
                                 v-model="value.mobile"/>
            </div>

            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.email'" name="email" rules="email"
                                 :placeholder="$t('address.email')"
                                 :disabled="!editable"
                                 v-model="value.email"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.phone'" name="phone" rules="phoneNumber"
                                 :placeholder="$t('address.phone')"
                                 :disabled="!editable"
                                 v-model="value.phone"/>
            </div>

            <div class="col-6">
              <e-form-text-input :id="id + '-consignee.web'" name="web"
                                 :placeholder="$t('address.url')"
                                 :disabled="!editable"
                                 v-model="value.web"/>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">
              <slot name="default" />
            </div>
          </div>

          <div class="form-row">
            <div class="col-12 text-right">

              <button class="btn btn-link"
                      :disabled="!editable || !(changed || dirty || value._links)"
                      @click="createNewConsignee">
                <span>{{ $t('address.reset-addressbook-entry') }}</span>
              </button>

              <button v-if="editable"
                      class="btn btn-link"
                      :disabled="!(changed || dirty) || invalid"
                      @click="saveConsignee">
                {{ value._links ? $t('address.update-addressbook-entry') : $t('address.create-addressbook-entry') }}
              </button>

            </div>
          </div>
        </validation-observer>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import AddressbookSelect from "@/components/form-controls/AddressbookSelect";
import {formatZipCode, ValidationObserver, ValidationProvider} from '@emons/emons-vue'
import ConsigneeAddressService from "@/services/address.service"
import vueDebounce from 'vue-debounce'
import CitySelect from "@/components/form-controls/CitySelect"
import CountrySelect from "@/components/form-controls/CountrySelect";

Vue.use(vueDebounce)

export default {
  name: "OrderConsigneeView",
  components: {CountrySelect, AddressbookSelect, CitySelect, ValidationObserver, ValidationProvider},
  props: {
    value: {
      type: Object,
    },
    id: {
      type: String,
      default: 'consignee'
    },
    editable: {
      type: Boolean,
      default: false
    },
    defaultCountry: {
      type: String,
      default: 'DE'
    }
  },
  data() {
    return {
    }
  },
  computed: {
    isAddressDataEditable: function () {
      return this.isEmonsCustomerIdEmpty()
    }
  },
  methods: {
    selectedConsigneeChanged: function (consignee) {
      if (consignee != null) {
        this.$log("debug", 'consignee address "%s" selected', consignee?._links?.self?.href, consignee)
        this.$emit('input', consignee)
      }
    },
    createNewConsignee: function () {
      this.$log('debug', 'create new consignee')
      this.$refs.addressbookSelect.clear()
      this.$emit('input',{
        name: '',
        additionalName: '',
        emonsCustomerId: '',
        customerId: '',
        isRetail: false,
        types: ['CONSIGNEE'],
        street: '',
        country: this.defaultCountry,
        zipCode: '',
        city: '',
        contactName: '',
        mobile: '',
        email: '',
        phone: '',
        web: ''
      })
      this.$emit('cleared')
    },
    saveConsignee: async function () {
      if (this.value != null) {
        if (this.value.types == null || this.value.types.length === 0)
          this.value.types = ['CONSIGNEE'];
        const isUpdate = !!this.value?._links

        this.$root.$log("debug", "saving consignee", this.value)
        ConsigneeAddressService
            .save(this.value)
            .then((response) => {
              this.$refs.observer.reset()
              this.selectedConsigneeChanged(response.data)
              this.$bvModal.msgBoxOk(this.$t(isUpdate?'address.updated':'address.saved'), {
                title: this.$t(isUpdate?'address.updated.short':'address.saved.short'),
                titleClass: 'pl-2',
                centered: false,
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
              })
            })
      }
    },
    isEmonsCustomerIdEmpty: function () {
      return this.value.emonsCustomerId == null || this.value.emonsCustomerId.length === 0
    },
    formatZipCode: function (zipCode) {
      this.value.zipCode = formatZipCode(this.value.country, zipCode)
    },
    onCitySelect: function(selection) {
      //B388: Wenn PLZ nur zum Teil eingegeben wird, dann aus der Liste der Orte ausgewählt wird mit vollständiger PLZ, diese auch im PLZ-Feld setzten!
      if(selection.zipCode.length > this.value.zipCode.length)
        this.value.zipCode = selection.zipCode;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal != null) {
            this.$nextTick(() => {
              this.$refs.observer?.reset()
              if (newVal._links) {
                this.$refs.observer.validate()
              }
            })
          }
        }
      }
    }
  }
}
</script>
