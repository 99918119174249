<template>
  <product-list />
</template>

<script>
import ProductList from "@/components/ProductList";

export default {
  name: "AdminProducts",
  components: {ProductList}
}
</script>

<style scoped>

</style>