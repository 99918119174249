import {getEnv} from '@emons/emons-vue'
import App from '@/App'
import SubView from "@/components/SubView";
import OrderOverview from "@/components/OrderOverview";
import OrderWizard from "@/components/OrderWizard";
import Addresses from "@/components/Addresses";
import InvoiceRecipientAddresses from "@/components/InvoiceRecipientAddresses";
import CargoListTemplates from "@/components/CargoListTemplates";
import AdminCustomers from "@/components/AdminCustomers";
import AdminCountries from "@/components/AdminCountries";
import AdminMailPersistenceEvents from "@/components/AdminMailPersistenceEvents";
import AdminLocations from "@/components/AdminLocations";
import AdminPackagingCodes from "@/components/AdminPackagingCodes";
import AdminNotificationCodes from "@/components/AdminNotificationCodes";
import AdminProducts from "@/components/AdminProducts";
import AdminIncoTermCodes from "@/components/AdminIncoTermCodes";
import AdminHazmatCodes from "@/components/AdminHazmatCodes";
import AdminHazmats from "@/components/AdminHazmats";
import AdminZipCodeAreas from "@/components/AdminZipCodeAreas";
import Registration from "@/components/Registration";
import Settings from "@/components/Settings";
import version from "@/version";
import ServiceAdministrationView from "@/views/admin/ServiceAdministrationView.vue";

const env = getEnv( process.env )

export default {
    supportedLocales: ['de', 'en', 'cs', 'sk'],
    version: version, // now set by genversion
    environment: env.NODE_ENV,
    kcInitOptions: {
        // onLoad: 'login-required',
        enableLogging: env.NODE_ENV == 'development'
    },
    kcConfig: {
        url: env.VUE_APP_KEYCLOAK_URL,
        realm: 'myEmons',
        clientId: 'myEmons-web'
    },
    app: App,
    routes: [
        {
            path: '/',
            redirect: '/shipments/list'
        },
        {
            path: '/registration',
            name: 'registration',
            component: Registration,
            meta: {
                onlyWhenLoggedOut: true
            }
        },
        {
            path: '/settings/index',
            name: 'settings',
            component: Settings,
            meta: {
                requiredAuthority: "ROLE_EIP_MyEmons_User"
            },
            children: [{
                path: '/settings',
                name: 'settings',
                component: Settings
            }]
        },
        {
            path: '/shipments/list',
            name: "shipments",
            component: SubView,
            meta: {
                requiredAuthority: "SCOPE_profile"
            },
            children: [
                {
                    path: '/shipments/list',
                    name: 'shipments.list',
                    component: OrderOverview
                },

            ]
        },
        {
            path: '/shipments/create',
            name: "shipments.create",
            component: SubView,
            meta: {
                requiredAuthority: "SCOPE_profile"
            },
            children: [
                {
                    path: '/shipments/create',
                    name: 'shipments.create',
                    component: OrderWizard
                },

            ]
        },
        {
            path: '/addresses/addresses',
            name: 'addresses',
            component: SubView,
            meta: {
                requiredAuthority: "SCOPE_profile"
            },
            children: [
                {
                    path: '/addresses/addresses',
                    name: 'addresses',
                    component: Addresses
                },
                {
                    path: '/addresses/invoice-recipient',
                    name: 'addresses.invoiceRecipient',
                    component: InvoiceRecipientAddresses
                }
            ]
        },
        {
            path: '/templates/cargoList',
            name: 'templates',
            component: CargoListTemplates,
            meta: {
                requiredAuthority: "SCOPE_profile"
            },
            children: [
                {
                    path: '/templates/cargoList',
                    name: 'templates.cargoList',
                    component: CargoListTemplates
                }
            ]
        },
        {
            path: '/administration/customers',
            name: 'admin',
            component: SubView,
            meta: {
                requiredAuthority: ["ROLE_EIP_MyEmons_NL_Admin", "ROLE_EIP_MyEmons_All_NL_Admin", "ROLE_EIP_MyEmons_Admin"]
            },
            children: [
                {
                    path: '/administration/customers',
                    name: 'admin.customers',
                    component: AdminCustomers,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_NL_Admin", "ROLE_EIP_MyEmons_All_NL_Admin", "ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/services',
                    name: 'admin.services',
                    component: ServiceAdministrationView,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/countries',
                    name: 'admin.countries',
                    component: AdminCountries,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/locations',
                    name: 'admin.locations',
                    component: AdminLocations,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_NL_Admin", "ROLE_EIP_MyEmons_All_NL_Admin", "ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/notificationCodes',
                    name: 'admin.notificationCodes',
                    component: AdminNotificationCodes,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/products',
                    name: 'admin.products',
                    component: AdminProducts,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/packagingCodes',
                    name: 'admin.packagingCodes',
                    component: AdminPackagingCodes,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/hazmatCodes',
                    name: 'admin.hazmatCodes',
                    component: AdminHazmatCodes,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/incoTermCodes',
                    name: 'admin.incoTermCodes',
                    component: AdminIncoTermCodes,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/hazmats',
                    name: 'admin.hazmats',
                    component: AdminHazmats,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/zipCodeAreas',
                    name: 'admin.zipCodeAreas',
                    component: AdminZipCodeAreas,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                },
                {
                    path: '/administration/mailPersistenceEvents',
                    name: 'admin.mailPersistenceEvents',
                    component: AdminMailPersistenceEvents,
                    meta: {
                        requiredAuthority: ["ROLE_EIP_MyEmons_Admin"]
                    },
                }
            ]
        }
    ]
}
