var render = function () {
  var _vm$$refs$validator, _vm$$refs$validator2, _vm$$refs$validator3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-input-group', {
    staticClass: "mb-3 time-input"
  }, [_c('b-input-group-prepend', [_c('b-form-timepicker', {
    class: ['form-control'].concat(_vm.filterValidationClasses((_vm$$refs$validator = _vm.$refs.validator) === null || _vm$$refs$validator === void 0 ? void 0 : _vm$$refs$validator.classes)),
    staticStyle: {
      "padding-right": "unset !important"
    },
    attrs: {
      "value": _vm.inputVal,
      "disabled": _vm.disabled,
      "id": _vm.id + '-timepicker',
      "minutes-step": "15",
      "hour12": false,
      "placeholder": _vm.$t('time.not-selected'),
      "reset-button": "",
      "label-no-time-selected": _vm.$t('time.not-selected'),
      "label-close-button": _vm.$t('controls.close'),
      "label-reset-button": _vm.$t('controls.reset'),
      "button-only": "",
      "dropright": ""
    },
    on: {
      "input": function input($event) {
        return _vm.setFromTimepicker($event);
      }
    }
  })], 1), _c('validation-provider', {
    ref: "validator",
    attrs: {
      "disabled": _vm.disabled,
      "vid": _vm.id,
      "name": _vm.name,
      "immediate": _vm.hasValue && !_vm.disabled,
      "rules": Object.assign({
        regex: _vm.hoursMinutesRE
      }, _vm.rules),
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var classes = _ref.classes,
            errors = _ref.errors;
        return [_c('b-form-input', {
          ref: "input",
          staticClass: "form-control",
          class: _vm.filterValidationClasses(classes),
          attrs: {
            "type": "text",
            "id": _vm.id,
            "name": _vm.name,
            "minlength": "5",
            "maxlength": "5",
            "value": _vm.stripSeconds(_vm.inputVal),
            "placeholder": _vm.placeholder || _vm.$t('time.not-selected'),
            "disabled": _vm.disabled
          },
          on: {
            "input": function input($event) {
              return _vm.setFromInput($event);
            },
            "keydown": function keydown($event) {
              return _vm.onInputKeyDown($event);
            }
          }
        })];
      }
    }])
  }), _c('b-input-group-append', [_c('div', {
    class: ['input-group-text', 'form-control', 'form-control-' + this.size, 'input-border-right'].concat(_vm.filterValidationClasses((_vm$$refs$validator2 = _vm.$refs.validator) === null || _vm$$refs$validator2 === void 0 ? void 0 : _vm$$refs$validator2.classes))
  }, [!_vm.disabled ? _c('b-icon-backspace-fill', {
    on: {
      "click": function click($event) {
        return _vm.clearTime();
      }
    }
  }) : _c('b-icon-backspace-fill')], 1)]), _c('span', {
    staticClass: "invalid-feedback"
  }, [_vm._v(_vm._s((_vm$$refs$validator3 = _vm.$refs.validator) === null || _vm$$refs$validator3 === void 0 ? void 0 : _vm$$refs$validator3.errors[0]))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }