<template>
  <validation-observer vid="announcementObserver" ref="announcement_observer"
                       v-slot="{ invalid, changed, dirty }" slim>
    <b-modal :id="id + '-announceShipmentModal'" :title="$t('shipment.shipping-notice') + ' - ' + $t('shipment.shipping-notice.long')" @ok="announceShipment">
      <template #default>
        <div class="alert alert-danger" v-if="failedShipments && failedShipments.length > 0">
          <h6 class="alert-heading">
            {{ $t('shipment.shipping-notice.not-possible') }}
          </h6>
          <ul>
            <li v-for="(order, idx) in failedShipments">{{ order.shipmentNumber }} - {{ $t('shipment.shipping-notice.not-possible.' + order.failedReason) }}</li>
          </ul>
        </div>
        <div class="mt-4" v-if="passedShipments && passedShipments.length > 0">
          <b-form-group class="mt-0 mb-0" :label="$t('shipment.shipping-date')" label-cols="4">
            <b-form-group :label="showDateUntil?$t('date.from'):''" label-for="dateFrom" label-cols="2" label-class="required" class="mt-0 mb-0">
              <e-form-datepicker :id="id + '-dateFrom'" name="dateFrom" :rules="{required: true, dateNotInPast: true}"
                                 :placeholder="$t('orders.choose-shipping-date')"
                                 :date-disabled-fn="isHoliday"
                                 :min="new Date()"
                                 :max="new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())"
                                 @input="updateShippingTime($event)"
                                 v-model="dateFrom" />
            </b-form-group>
            <b-form-group :label="$t('date.to')" label-cols="2" class="mb-0 mt-0" v-if="showDateUntil">
              <e-form-datepicker :id="id + '-dateUntil'" name="dateUntil"
                                 :placeholder="$t('date.not-selected')"
                                 :date-disabled-fn="isHoliday"
                                 :min="new Date(dateFrom)"
                                 :max="new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())"
                                 v-model="dateUntil"/>
            </b-form-group>
          </b-form-group>
          <div class="alert alert-info" v-if="dateFromMultiple">
            {{ $t('shipment.shipping-notice.multiple-shipping-dates') }}
            {{ $t('shipment.shipping-notice.schedule-common-shipping-date') }}
          </div>
          <div class="alert alert-info" v-if="dateFromModified">
            {{ $t('shipment.shipping-notice.shipping-date-in-past') }}
          </div>

          <b-form-group class="mt-0 mb-0" :label="$t('shipment.delivery-date')" label-cols="4" v-if="!hasMultipleDeliveryDateWindows && deliveryDateWindow != null">
            <b-form-group label="" class="mb-0 mt-0" label-cols="2">
              <e-form-datepicker ref="deliveryDate" :id="id + '-deliveryDate'" name="deliveryDate"
                                 :rules="{ required: true, withinDeliveryWindow: {deliveryWindow: deliveryWindow} }"
                                 :placeholder="$t('date.not-selected')"
                                 :disabled="deliveryDateWindow.min == deliveryDateWindow.max"
                                 :date-disabled-fn="isHoliday"
                                 :min="deliveryWindow.min" :max="deliveryWindow.max"
                                 v-model="deliveryDate"/>
            </b-form-group>
          </b-form-group>
          <div class="alert alert-info" v-if="!hasMultipleDeliveryDateWindows && deliveryDateWindow != null && passedShipments.filter(order => order.product.deliveryDateWindow == null || order.product.deliveryDateIsOptional).length > 0">
            {{ $t('shipment.shipping-notice.delivery-date-excludes-standard-shipments') }}
          </div>

          <b-form-group class="mt-0 mb-0" :label="$t('shipment.shipping-time')" label-cols="4">
            <b-form-group :label="$t('time.from')" class="mb-3 mt-0" label-for="timeFrom" label-cols="2">
              <time-input :id="id + '-timeFrom'" name="timeFrom" v-model="timeFrom" />
            </b-form-group>

            <b-form-group :label="$t('time.to')" class="mb-3 mt-0" label-for="timeUntil" label-cols="2" label-class="required">
              <time-input :id="id + '-timeUntil'" name="timeUntil" v-model="timeUntil" />
            </b-form-group>
          </b-form-group>
          <b-form-group class="mt-0 mb-0" :label="$t('shipment.shipping-notice.notice')" label-cols="4">
            <e-form-text-input :id="id + '-notice'" maxlength="35" name="notice" v-model="notice"/>
          </b-form-group>
          <div class="alert alert-warning mt-4" v-if="hasMultipleDeliveryDateWindows">
            <div><strong>{{ $t('shipment.shipping-notice.multiple-delivery-windows') }}</strong></div>
            <div>{{ $t('shipment.shipping-notice.delivery-date-rescheduled') }}</div>
          </div>
        </div>
        <div class="alert alert-danger" v-else>
          {{ $t('shipment.shipping-notice.no-shipments-available') }}
        </div>
      </template>
      <template #modal-footer="{ok, cancel, hide}">
        <b-button variant="secondary" @click="cancel">{{ $t('controls.abort') }}</b-button>
        <b-button variant="primary" @click="ok" :disabled="invalid || !(passedShipments && passedShipments.length > 0)">{{ $t('shipment.shipping-notice.announce') }}</b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import Vue from "vue"
import Holidays from "date-holidays"
import {addWorkdays, calculateDeliveryWindow, dateToString} from "@/util/dateutils"
import {extend, ValidationObserver} from '@emons/emons-vue'
import SettingsService from "@/services/settings.service";
import TimeInput from "@/components/form-controls/TimeInput.vue";

extend('dateNotInPast', {
  validate(value) {
    let d = new Date(value)
    let date = new Date()
    let min = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    return d.getTime() >= min.getTime()
  }
})

extend('withinDeliveryWindow', {
  params: ['deliveryWindow'],
  validate(value, {deliveryWindow}) {
    if (value) {
      let date = new Date(value)
      return (date.getTime() >= deliveryWindow.min.getTime() && date.getTime() <= deliveryWindow.max.getTime())
    }
    return false
  }
})

export default {
  name: "AnnounceShipmentModal",
  components: {TimeInput, ValidationObserver},
  props: {
    id: {
      type: String,
      default: 'announceShipmentModal'
    },
  },
  data() {
    return {
      passedShipments: null,
      failedShipments: null,
      dateFrom: null,
      dateFromModified: false,
      dateFromMultiple: false,
      dateUntil: null,
      showDateUntil: false,
      timeFrom: null,
      timeUntil: null,
      notice: null,
      deliveryDate: null,
      deliveryDateWindow: null,
      hasMultipleDeliveryDateWindows: false,
      shippingTimeFrom : null,
      shippingTimeUntil: null,
      shippingTimeFromFr: null,
      shippingTimeUntilFr: null
    }
  },
  computed: {
    // copied from OrderProductView...
    deliveryWindow: function () {
      const deliveryDateWindow = this.deliveryDateWindow
      const consigneeCountry =  this.passedShipments[0]?.consignee?.country
      const shippingDate = this.dateFrom

      return calculateDeliveryWindow(deliveryDateWindow,consigneeCountry, shippingDate)
    },
  },
  methods: {
    reset: async function (value) {

      let today = new Date()
      today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()))

      this.passedShipments = []
      this.failedShipments = []

      let checkArray = []
      if (Array.isArray(value)) {
        // filter non-announceable orders from array
        checkArray = value
      } else {
        // array, but length <= 1
        checkArray = [ value ]
      }

      checkArray.forEach(order => {
        if (!!order?._links?.announce?.href) {
          this.passedShipments.push(order)
        } else {
          if (!['DRAFT', 'ANNOUNCED'].includes(order.state)) {
            order.failedReason = 'ALREADY_FINALIZED'
          } else {
            order.failedReason = 'WRONG_SHIPMENT_TYPE'
          }
          this.failedShipments.push(order)
        }
      })

      this.$log('DEBUG', 'passed', this.passedShipments)
      this.$log('DEBUG', 'failed', this.failedShipments)

      let array = null;

      // only set dateFrom if it is the same access all elements
      array = this.passedShipments.filter(order => order.shippingDate != null).map(order => order.shippingDate)
      this.dateFrom = array.length == 0?null:array.reduce((left, right) => left == right?left:null)
      if (this.dateFrom == null) {
        // shipping dates are not equal - use today as shipping date
        today = addWorkdays(today, 0, this.passedShipments[0].consignee.country)
        this.dateFrom = dateToString(today)
        this.dateFromMultiple = true
      } else {
        this.dateFromMultiple = false
      }

      // only set timeFrom/timeUntil/notice if identical across all elements with existing timeFrom/timeUntil/notice
      array = this.passedShipments.filter(order => order.shippingTimeFrom != null).map(order => order.shippingTimeFrom)
      this.timeFrom = array.length == 0?'':array.reduce((left,right) => left == right?left:'')

      array = this.passedShipments.filter(order => order.shippingTimeUntil != null).map(order => order.shippingTimeUntil)
      this.timeUntil = array.length == 0?'':array.reduce((left, right) => left == right?left:'')

      array = this.passedShipments.filter(order => order.shippingNotice != null).map(order => order.shippingNotice)
      this.notice = array.length == 0?'':array.reduce((left, right) => left == right?left:'')

      if (this.dateFrom != null) {
        today = addWorkdays(today, 0, this.passedShipments[0].consignee.country)

        // check if dateFrom is before today
        let d = this.dateFrom.split("-")
        d = new Date(Date.UTC(d[0], d[1] - 1, d[2]))
        if (d.getTime() < today.getTime()) {
          this.dateFrom = dateToString(today)
          d = today
          this.dateFromModified = true
        } else {
          this.dateFromModified = false
        }
      }

      // check if there are order with delivery date window
      let ordersWithDeliveryDateWindow = this.passedShipments.filter(order => order.product.deliveryDateWindow != null && !order.product.deliveryDateIsOptional)
      if (ordersWithDeliveryDateWindow.length > 0) {
        // at least one order with delivery date window -> do not show dateUntil input
        this.showDateUntil = false

        // only set delivery date window if identical access all elements with existing delivery date window
        array = ordersWithDeliveryDateWindow.map(order => order.product.deliveryDateWindow)
        this.deliveryDateWindow = array.length == 0?null:array.reduce((left, right) => (left != null && left.min == right.min && left.max == right.max)?left:null)
        if (this.deliveryDateWindow == null) {
          // must have been products with different delivery date windows - show production selection?
          this.hasMultipleDeliveryDateWindows = true
        } else {
          this.hasMultipleDeliveryDateWindows = false
        }

        // now check if deliveryDate is identical
        array = ordersWithDeliveryDateWindow.map(order => order.deliveryDate)
        this.deliveryDate = array.length == 0?null:array.reduce((left, right) => left == right?left:null)

        // dateUntil not relevant when there are orders with delivery date window
        this.dateUntil = null
      } else {
        // all orders without deliveryDateWindow -> show dateUntil input
        this.showDateUntil = true

        this.deliveryDateWindow = null
        this.hasMultipleDeliveryDateWindows = false
        this.deliveryDate = null

        // only set date until if identical accross all orders with shippingDateUntil set
        array = this.passedShipments.filter(order => order.shippingDateUntil != null).map(order => order.shippingDateUntil)
        this.dateUntil = array.length == 0?null:array.reduce((left, right) => left == right?left:null)
      }

      this.shippingTimeFrom = await SettingsService.find('SHIPPING_TIME_FROM')
      this.shippingTimeFromFr = await SettingsService.find('SHIPPING_TIME_FROM_FR')
      this.shippingTimeUntil = await SettingsService.find('SHIPPING_TIME_UNTIL')
      this.shippingTimeUntilFr = await SettingsService.find('SHIPPING_TIME_UNTIL_FR')

      if (this.deliveryDate != null) {
        // check if delivery date is before today
        let d = this.deliveryDate.split("-")
        d = new Date(Date.UTC(d[0], d[1] - 1, d[2]))
        if (d.getTime() < today.getTime()) {
          this.deliveryDate = null
        }
      }
    },
    updateShippingTime(dateFrom) {
      let d = dateFrom.split("-")
      d = new Date(Date.UTC(d[0], d[1] - 1, d[2]))
      if (d.getDay() == 5) {
        this.timeFrom = this.shippingTimeFromFr?.value || this.shippingTimeFrom?.value || ''
        this.timeUntil = this.shippingTimeUntilFr?.value || this.shippingTimeUntil?.value || ''
      } else {
        this.timeFrom = this.shippingTimeFrom?.value || ''
        this.timeUntil = this.shippingTimeUntil?.value || ''
      }
    },
    async show(order) {
      await this.reset(order)
      this.$bvModal.show(this.id + '-announceShipmentModal')
    },
    announceShipment() {
      this.$emit('confirmed', this.$data)
    },
    isHoliday: function (ymd, date) {
      if (date.getDay() == 0 || date.getDay() == 6) {
        return true
      }
      // todo: check all orders...
      if (this.passedShipments[0].sender == null) {
        return false
      }
      let hd = new Holidays(this.passedShipments[0].sender.country, '', '', {types: ['public', 'bank']})
      return hd.isHoliday(date)
    },
  },
  watch: {
    "dateFrom": function (newVal, oldVal) {
      this.$log("debug", 'dateFrom changed from %s to %s', oldVal, newVal)
      if (this.deliveryDateWindow && this.deliveryDateWindow.min == this.deliveryDateWindow.max) {
        this.deliveryDate = dateToString(this.deliveryWindow.min)
      }

      // use nextTick because validator changes after rerender
      let that = this
      Vue.nextTick().then(function () {
        if (that.deliveryDate != null) {
          that.$refs['deliveryDate'].$refs['validator'].validate()
        }
      })
    },
  }
}
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

</style>