var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$entity, _vm$entity$_links, _vm$entity$_links$esv;

        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty,
            failed = _ref.failed,
            handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('b-tabs', {
          attrs: {
            "fill": "",
            "active-nav-item-class": "font-weight-bold text-light bg-primary",
            "nav-class": "border"
          }
        }, [_c('b-tab', {
          attrs: {
            "no-body": "",
            "title": _vm.$t('location.data')
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_vm.entity.gln ? _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-4 col-form-label",
          attrs: {
            "for": _vm.id + '-location-infos-label'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.sscc')))]), _c('div', {
          staticClass: "col-8",
          attrs: {
            "id": "input-label-infos"
          }
        }, [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('location.sscc.prefix')))])]), _c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.entity.ssccPrefix,
            callback: function callback($$v) {
              _vm.$set(_vm.entity, "ssccPrefix", $$v);
            },
            expression: "entity.ssccPrefix"
          }
        })], 1), _c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('location.sscc.gln')))])]), _c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.entity.gln,
            callback: function callback($$v) {
              _vm.$set(_vm.entity, "gln", $$v);
            },
            expression: "entity.gln"
          }
        })], 1), _c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('location.sscc.number-range')))])]), _c('b-form-input', {
          attrs: {
            "type": "text",
            "disabled": "",
            "value": _vm.entity.ssccCounterMin
          }
        }), _c('b-input-group-prepend', {
          staticStyle: {
            "width": "75px",
            "margin-left": "-1px"
          },
          attrs: {
            "is-text": ""
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('range.to')))])]), _c('b-form-input', {
          attrs: {
            "type": "text",
            "disabled": "",
            "value": _vm.entity.ssccCounterMax
          }
        })], 1)], 1)]) : _vm._e()])])]), _c('b-tab', {
          attrs: {
            "no-body": "",
            "title": _vm.$t('location.master-data')
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-6"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-abbreviation'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.abbreviation')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-location-abbreviation',
            "value": _vm.entity.abbreviation
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-name'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.name')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-location-name',
            "value": _vm.entity.name
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-streetName'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.street')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-location-streetName',
            "value": _vm.entity.streetName
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-streetNumber'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.street-number')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-location-streetNumber',
            "value": _vm.entity.streetNumber
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-zipCode'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.zipCode')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-location-zipCode',
            "value": _vm.entity.zipCode
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-city'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.city')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-location-city',
            "value": _vm.entity.city
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-country'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.country')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-location-country',
            "value": _vm.entity.country
          }
        })], 1)])]), _c('div', {
          staticClass: "col-6"
        }, [!!((_vm$entity = _vm.entity) !== null && _vm$entity !== void 0 && (_vm$entity$_links = _vm$entity._links) !== null && _vm$entity$_links !== void 0 && (_vm$entity$_links$esv = _vm$entity$_links.esv) !== null && _vm$entity$_links$esv !== void 0 && _vm$entity$_links$esv.href) ? _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-esv-url'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.esv.link')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('strong', [_c('a', {
          staticStyle: {
            "position": "relative",
            "top": "5px"
          },
          attrs: {
            "id": _vm.id + '-location-esv-url',
            "href": _vm.entity._links.esv.href,
            "target": "_blank"
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "link",
            "font-scale": "1.5"
          }
        })], 1)])])]) : _vm._e(), _vm.entity.syncDate ? _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-sync-date'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.last-sync')))]), _c('div', {
          staticClass: "col-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-location-sync-date',
            "value": _vm.entity.syncDate
          }
        })], 1)]) : _vm._e()])])]), _c('b-tab', {
          attrs: {
            "no-body": "",
            "title": _vm.$t('location.email-addresses')
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col"
        }, [_c('div', {
          staticClass: "form-group row mb-1"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-announcementEmail'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.email.shipping-notices')))]), _c('validation-provider', {
          attrs: {
            "vid": _vm.id + '-location-announcementEmail',
            "name": "announcementEmail",
            "disabled": !_vm.canModify,
            "rules": "email",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var classes = _ref2.classes,
                  errors = _ref2.errors;
              return [_c('div', {
                staticClass: "col-8"
              }, [_c('div', {
                class: ['input-tags'].concat(classes)
              }, [_c('b-form-tags', {
                attrs: {
                  "input-id": _vm.id + '-location-announcementEmail',
                  "separator": ",",
                  "placeholder": _vm.$t('email.add'),
                  "add-button-text": _vm.$t('controls.add'),
                  "disabled": !_vm.canModify()
                },
                model: {
                  value: _vm.entity.announcementEmail,
                  callback: function callback($$v) {
                    _vm.$set(_vm.entity, "announcementEmail", $$v);
                  },
                  expression: "entity.announcementEmail"
                }
              })], 1), _c('span', {
                staticClass: "invalid-feedback"
              }, [_vm._v(_vm._s(errors[0]))])])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "form-group row mb-1"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-inquiryEmail'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.email.requests')))]), _c('validation-provider', {
          attrs: {
            "vid": _vm.id + '-location-inquiryEmail',
            "name": "inquiryEmail",
            "disabled": !_vm.canModify,
            "rules": "email",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var classes = _ref3.classes,
                  errors = _ref3.errors;
              return [_c('div', {
                staticClass: "col-8"
              }, [_c('div', {
                class: ['input-tags'].concat(classes)
              }, [_c('b-form-tags', {
                attrs: {
                  "input-id": _vm.id + '-location-inquiryEmail',
                  "separator": ",",
                  "placeholder": _vm.$t('email.add'),
                  "add-button-text": _vm.$t('controls.add'),
                  "disabled": !_vm.canModify()
                },
                model: {
                  value: _vm.entity.inquiryEmail,
                  callback: function callback($$v) {
                    _vm.$set(_vm.entity, "inquiryEmail", $$v);
                  },
                  expression: "entity.inquiryEmail"
                }
              })], 1), _c('span', {
                staticClass: "invalid-feedback"
              }, [_vm._v(_vm._s(errors[0]))])])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "form-group row mb-1"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-cancellationEmail'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.email.cancellation')))]), _c('validation-provider', {
          attrs: {
            "vid": _vm.id + '-location-cancellationEmail',
            "name": "cancellationEmail",
            "disabled": !_vm.canModify,
            "rules": "email",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var classes = _ref4.classes,
                  errors = _ref4.errors;
              return [_c('div', {
                staticClass: "col-8"
              }, [_c('div', {
                class: ['input-tags'].concat(classes)
              }, [_c('b-form-tags', {
                attrs: {
                  "input-id": _vm.id + '-location-cancellationEmail',
                  "separator": ",",
                  "placeholder": _vm.$t('email.add'),
                  "add-button-text": _vm.$t('controls.add'),
                  "disabled": !_vm.canModify()
                },
                model: {
                  value: _vm.entity.cancellationEmail,
                  callback: function callback($$v) {
                    _vm.$set(_vm.entity, "cancellationEmail", $$v);
                  },
                  expression: "entity.cancellationEmail"
                }
              })], 1), _c('span', {
                staticClass: "invalid-feedback"
              }, [_vm._v(_vm._s(errors[0]))])])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "form-group row mb-1"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-belogEmail'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.email.belog')))]), _c('validation-provider', {
          attrs: {
            "vid": _vm.id + '-location-belogEmail',
            "name": "belogEmail",
            "disabled": !_vm.canModify,
            "rules": "email",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var classes = _ref5.classes,
                  errors = _ref5.errors;
              return [_c('div', {
                staticClass: "col-8"
              }, [_c('div', {
                class: ['input-tags'].concat(classes)
              }, [_c('b-form-tags', {
                attrs: {
                  "input-id": _vm.id + '-location-belogEmail',
                  "separator": ",",
                  "placeholder": _vm.$t('email.add'),
                  "add-button-text": _vm.$t('controls.add'),
                  "disabled": !_vm.canModify()
                },
                model: {
                  value: _vm.entity.belogEmail,
                  callback: function callback($$v) {
                    _vm.$set(_vm.entity, "belogEmail", $$v);
                  },
                  expression: "entity.belogEmail"
                }
              })], 1), _c('span', {
                staticClass: "invalid-feedback"
              }, [_vm._v(_vm._s(errors[0]))])])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "form-group row mb-1"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-insuranceEmail'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.email.insurance')))]), _c('validation-provider', {
          attrs: {
            "vid": _vm.id + '-location-insuranceEmail',
            "name": "insuranceEmail",
            "disabled": !_vm.canModify,
            "rules": "email",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var classes = _ref6.classes,
                  errors = _ref6.errors;
              return [_c('div', {
                staticClass: "col-8"
              }, [_c('div', {
                class: ['input-tags'].concat(classes)
              }, [_c('b-form-tags', {
                attrs: {
                  "input-id": _vm.id + '-location-insuranceEmail',
                  "separator": ",",
                  "placeholder": _vm.$t('email.add'),
                  "add-button-text": _vm.$t('controls.add'),
                  "disabled": !_vm.canModify()
                },
                model: {
                  value: _vm.entity.insuranceEmail,
                  callback: function callback($$v) {
                    _vm.$set(_vm.entity, "insuranceEmail", $$v);
                  },
                  expression: "entity.insuranceEmail"
                }
              })], 1), _c('span', {
                staticClass: "invalid-feedback"
              }, [_vm._v(_vm._s(errors[0]))])])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "form-group row mb-1"
        }, [_c('label', {
          staticClass: "col-3 col-form-label",
          attrs: {
            "for": _vm.id + '-location-registrationEmail'
          }
        }, [_vm._v(_vm._s(_vm.$t('location.email.registration')))]), _c('validation-provider', {
          attrs: {
            "vid": _vm.id + '-location-registrationEmail',
            "name": "registrationEmail",
            "disabled": !_vm.canModify,
            "rules": "email",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var classes = _ref7.classes,
                  errors = _ref7.errors;
              return [_c('div', {
                staticClass: "col-8"
              }, [_c('div', {
                class: ['input-tags'].concat(classes)
              }, [_c('b-form-tags', {
                attrs: {
                  "input-id": _vm.id + '-location-registrationEmail',
                  "separator": ",",
                  "placeholder": _vm.$t('email.add'),
                  "add-button-text": _vm.$t('controls.add'),
                  "disabled": !_vm.canModify()
                },
                model: {
                  value: _vm.entity.registrationEmail,
                  callback: function callback($$v) {
                    _vm.$set(_vm.entity, "registrationEmail", $$v);
                  },
                  expression: "entity.registrationEmail"
                }
              })], 1), _c('span', {
                staticClass: "invalid-feedback"
              }, [_vm._v(_vm._s(errors[0]))])])];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "col"
        })])]), _c('b-tab', {
          staticClass: "tab-content",
          attrs: {
            "title": 'NV-Gebiet' + (_vm.entity.zones && _vm.entity.zones.page ? ' (' + _vm.entity.zones.page.totalElements + ')' : ''),
            "lazy": ""
          },
          on: {
            "click": function click($event) {
              return _vm.findZones();
            }
          }
        }, [_c('div', {
          staticClass: "p-1 pl-0 pr-0 row"
        }, [_c('div', {
          staticClass: "col-md-1"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.country')))])])]), _c('div', {
          staticClass: "col-md-2"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.zipCode')))])])]), _c('div', {
          staticClass: "col-md-3"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.city')))])])]), _c('div', {
          staticClass: "col-md-1"
        }, [_c('b', [_c('span', [_vm._v("Collection")])])]), _c('div', {
          staticClass: "col-md-1"
        }, [_c('b', [_c('span', [_vm._v("Distribution")])])]), _c('div', {
          staticClass: "col-md-2"
        }, [_c('b', [_c('span', [_vm._v("Uhrzeitprodukte")])])]), _c('div', {
          staticClass: "col-md-2"
        }, [_c('b-input-group', {
          staticClass: "search-embedded-list pull-right",
          attrs: {
            "size": "sm"
          }
        }, [_c('b-input-group-prepend', [_c('span', {
          staticClass: "input-group-text"
        }, [_c('b-icon', {
          staticStyle: {
            "width": "25px"
          },
          attrs: {
            "icon": "search"
          }
        })], 1)]), _c('b-form-input', {
          directives: [{
            name: "debounce",
            rawName: "v-debounce:1000ms",
            value: _vm.findZones,
            expression: "findZones",
            arg: "1000ms"
          }],
          staticClass: "search-input",
          attrs: {
            "size": "sm",
            "type": "text"
          },
          model: {
            value: _vm.entity.searchTextZone,
            callback: function callback($$v) {
              _vm.$set(_vm.entity, "searchTextZone", $$v);
            },
            expression: "entity.searchTextZone"
          }
        }), _vm.entity.searchTextZone ? _c('b-input-group-append', [_c('span', {
          staticClass: "input-group-text"
        }, [_c('b-icon', {
          staticStyle: {
            "width": "25px"
          },
          attrs: {
            "icon": "x"
          },
          on: {
            "click": function click($event) {
              _vm.entity.searchTextZone = null;

              _vm.findZones();
            }
          }
        })], 1)]) : _vm._e()], 1)], 1)]), _c('div', {
          staticClass: "scroll-embedded-header-line"
        }), _vm.entity.zones && _vm.entity.zones.items.length > 0 ? _c('scrollbar', {
          staticClass: "scroll-embedded-content",
          style: {
            'height': '300px',
            'width': '100%'
          },
          attrs: {
            "settings": _vm.scrollSettings
          },
          on: {
            "ps-y-reach-end": function psYReachEnd($event) {
              return _vm.nextPageZones();
            }
          }
        }, _vm._l(_vm.entity.zones.items, function (zone, index) {
          return _c('div', {
            staticClass: "p-1 pl-0 pr-0 row"
          }, [_c('div', {
            staticClass: "col-md-1 clip-grid-text"
          }, [_c('span', [_vm._v(_vm._s(zone.zipCodeAreaInterval.start.alpha2Code))])]), _c('div', {
            staticClass: "col-md-2 clip-grid-text"
          }, [_c('span', [_vm._v(_vm._s(zone.zipCodeAreaInterval.start.zipCode))]), zone.zipCodeAreaInterval.end && zone.zipCodeAreaInterval.start.zipCode !== zone.zipCodeAreaInterval.end.zipCode ? _c('span', [_vm._v(" - ")]) : _vm._e(), zone.zipCodeAreaInterval.end && zone.zipCodeAreaInterval.start.zipCode !== zone.zipCodeAreaInterval.end.zipCode ? _c('span', [_vm._v(_vm._s(zone.zipCodeAreaInterval.end.zipCode))]) : _vm._e()]), _c('div', {
            staticClass: "col-md-3 clip-grid-text"
          }, [_c('span', [_vm._v(_vm._s(zone.zipCodeAreaInterval.start.city))]), zone.zipCodeAreaInterval.end && zone.zipCodeAreaInterval.start.city !== zone.zipCodeAreaInterval.end.city ? _c('span', [_vm._v(" - ")]) : _vm._e(), zone.zipCodeAreaInterval.end && zone.zipCodeAreaInterval.start.city !== zone.zipCodeAreaInterval.end.city ? _c('span', [_vm._v(_vm._s(zone.zipCodeAreaInterval.end.city))]) : _vm._e()]), _c('div', {
            staticClass: "col-md-1 clip-grid-text"
          }, [zone.collection ? _c('span', [_vm._v(_vm._s(zone.collection.relation))]) : _vm._e()]), _c('div', {
            staticClass: "col-md-1 clip-grid-text"
          }, [zone.distribution ? _c('span', [_vm._v(_vm._s(zone.distribution.relation))]) : _vm._e()]), _c('div', {
            staticClass: "col-md-4"
          }, [_c('div', {
            staticStyle: {
              "display": "table",
              "width": "100%",
              "table-layout": "fixed"
            }
          }, _vm._l(zone.productMap, function (value, key) {
            return _c('div', {
              staticStyle: {
                "display": "table-cell",
                "width": "6.666666%"
              }
            }, [_c('strong', [_c('span', {
              class: {
                'text-green': value === 'YES',
                'text-red': value === 'NO'
              }
            }, [_vm._v(_vm._s(key))])])]);
          }), 0)])]);
        }), 0) : _vm._e()], 1), _c('b-tab', {
          staticClass: "tab-content",
          attrs: {
            "title": 'Routing' + (_vm.entity.routings && _vm.entity.routings.page ? ' (' + _vm.entity.routings.page.totalElements + ')' : ''),
            "lazy": ""
          },
          on: {
            "click": function click($event) {
              return _vm.findRoutings();
            }
          }
        }, [_c('div', {
          staticClass: "p-1 pl-0 pr-0 row"
        }, [_c('div', {
          staticClass: "col-md-1"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.country')))])])]), _c('div', {
          staticClass: "col-md-2"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.zipCode')))])])]), _c('div', {
          staticClass: "col-md-3"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.city')))])])]), _c('div', {
          staticClass: "col-md-1"
        }, [_c('b', [_c('span', [_vm._v("Collection")])])]), _c('div', {
          staticClass: "col-md-1"
        }, [_c('b', [_c('span', [_vm._v("Distribution")])])]), _c('div', {
          staticClass: "col-md-2"
        }, [_c('b', [_c('span', [_vm._v("Uhrzeitprodukte")])])]), _c('div', {
          staticClass: "col-md-2"
        }, [_c('b-input-group', {
          staticClass: "search-embedded-list pull-right",
          attrs: {
            "size": "sm"
          }
        }, [_c('b-input-group-prepend', [_c('span', {
          staticClass: "input-group-text"
        }, [_c('b-icon', {
          staticStyle: {
            "width": "25px"
          },
          attrs: {
            "icon": "search"
          }
        })], 1)]), _c('b-form-input', {
          directives: [{
            name: "debounce",
            rawName: "v-debounce:1000ms",
            value: _vm.findRoutings,
            expression: "findRoutings",
            arg: "1000ms"
          }],
          staticClass: "search-input",
          attrs: {
            "size": "sm",
            "type": "text"
          },
          model: {
            value: _vm.entity.searchTextRouting,
            callback: function callback($$v) {
              _vm.$set(_vm.entity, "searchTextRouting", $$v);
            },
            expression: "entity.searchTextRouting"
          }
        }), _vm.entity.searchTextRouting ? _c('b-input-group-append', [_c('span', {
          staticClass: "input-group-text"
        }, [_c('b-icon', {
          staticStyle: {
            "width": "25px"
          },
          attrs: {
            "icon": "x"
          },
          on: {
            "click": function click($event) {
              _vm.entity.searchTextRouting = null;

              _vm.findRoutings();
            }
          }
        })], 1)]) : _vm._e()], 1)], 1)]), _c('div', {
          staticClass: "scroll-embedded-header-line"
        }), _vm.entity.routings && _vm.entity.routings.items.length > 0 ? _c('scrollbar', {
          staticClass: "scroll-embedded-content",
          style: {
            'height': '300px',
            'width': '100%'
          },
          attrs: {
            "settings": _vm.scrollSettings
          },
          on: {
            "ps-y-reach-end": function psYReachEnd($event) {
              return _vm.nextPageRoutings();
            }
          }
        }, _vm._l(_vm.entity.routings.items, function (routing, index) {
          return _c('div', {
            staticClass: "p-1 pl-0 pr-0 row"
          }, [_c('div', {
            staticClass: "col-md-1 clip-grid-text"
          }, [_c('span', [_vm._v(_vm._s(routing.zipCodeAreaInterval.start.alpha2Code))])]), _c('div', {
            staticClass: "col-md-2 clip-grid-text"
          }, [_c('span', [_vm._v(_vm._s(routing.zipCodeAreaInterval.start.zipCode))]), routing.zipCodeAreaInterval.end && routing.zipCodeAreaInterval.start.zipCode !== routing.zipCodeAreaInterval.end.zipCode ? _c('span', [_vm._v(" - ")]) : _vm._e(), routing.zipCodeAreaInterval.end && routing.zipCodeAreaInterval.start.zipCode !== routing.zipCodeAreaInterval.end.zipCode ? _c('span', [_vm._v(_vm._s(routing.zipCodeAreaInterval.end.zipCode))]) : _vm._e()]), _c('div', {
            staticClass: "col-md-3 clip-grid-text"
          }, [_c('span', [_vm._v(_vm._s(routing.zipCodeAreaInterval.start.city))]), routing.zipCodeAreaInterval.end && routing.zipCodeAreaInterval.start.city !== routing.zipCodeAreaInterval.end.city ? _c('span', [_vm._v(" - ")]) : _vm._e(), routing.zipCodeAreaInterval.end && routing.zipCodeAreaInterval.start.city !== routing.zipCodeAreaInterval.end.city ? _c('span', [_vm._v(_vm._s(routing.zipCodeAreaInterval.end.city))]) : _vm._e()]), _c('div', {
            staticClass: "col-md-1"
          }, [routing.collection ? _c('span', [_vm._v(_vm._s(routing.collection.relation))]) : _vm._e()]), _c('div', {
            staticClass: "col-md-1"
          }, [routing.distribution ? _c('span', [_vm._v(_vm._s(routing.distribution.relation))]) : _vm._e()]), _c('div', {
            staticClass: "col-md-4"
          }, [_c('div', {
            staticStyle: {
              "display": "table",
              "width": "100%",
              "table-layout": "fixed"
            }
          }, _vm._l(routing.productMap, function (value, key) {
            return _c('div', {
              staticStyle: {
                "display": "table-cell",
                "width": "6.666666%"
              }
            }, [_c('strong', [_c('span', {
              class: {
                'text-green': value === 'YES',
                'text-red': value === 'NO'
              }
            }, [_vm._v(_vm._s(key))])])]);
          }), 0)])]);
        }), 0) : _vm._e()], 1), _c('b-tab', {
          staticClass: "tab-content",
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('span', [_vm._v("Änderungen")])];
            },
            proxy: true
          }], null, true)
        }, [_c('span', {
          staticClass: "text-center"
        }, [_vm._v("TODO")])])], 1), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_c('div', {
          staticClass: "form-group row justify-content-end"
        }, [_c('div', {
          staticClass: "col-sm-2"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-secondary",
          attrs: {
            "id": "abortButton",
            "disabled": !(changed || dirty)
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.abortEdit();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('controls.abort')) + " ")])]), _c('div', {
          staticClass: "col-sm-2"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-primary",
          attrs: {
            "id": "saveButton",
            "disabled": !(changed || dirty) || invalid
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveEntity();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('controls.save')) + " ")])])])])])], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }