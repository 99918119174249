import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const countrySchema = {
    alpha2Code: null,
    alpha3Code: null,
    isoCode: null,
    iocCode: null
}

const CountryService = {
    findAll: async function() {
        try {
            return ApiService.get('/api/countries', {
                transformResponse: [function (data) {
                    let response = data? JSON.parse(data)._embedded.countries : data
                    response.forEach(obj => {
                        ApiService.ensureSchema(countrySchema, obj)
                    })

                    return response
                }]
            })
        } catch (error) {
            log("error", "Error querying countries:", error)
        }
    },
    findByQuery: async function (context, filterItem, searchText) {
        context.$emit('enableSpinner');
        try {
            return ApiService.get('/api/countries', {
                params: {
                    query: searchText,
                    sort: filterItem.orderBy,
                    size: 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let response = JSON.parse(data)

                        filterItem.links = response['_links'];
                        filterItem.page = response['page'];
                        filterItem.selectable = response['_embedded']['countries'];

                        context.$emit('disableSpinner');
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying filter elements:", error)
        }
    },
    save: async function(country) {
        if (country._links && country._links.self) {
            return ApiService.put(country._links.self.href, country).then(
                response => {
                    if(response.data) {
                        ApiService.ensureSchema(countrySchema, response.data)
                    }
                    return response
                }
            )
        } else {
            return ApiService.post('/api/countries', country).then(
                response => {
                    if(response.data) {
                        ApiService.ensureSchema(countrySchema, response.data)
                    }
                    return response
                }
            )
        }
    },
    delete: async function(country) {
        if (country._links && country._links.self) {
            return ApiService.delete(country._links.self.href)
        }
    }
}

export default CountryService