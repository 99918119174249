var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.editable ? _c('validation-provider', {
    ref: "vp",
    attrs: {
      "name": "unNumber",
      "immediate": _vm.hasValue && _vm.editable,
      "vid": _vm.id,
      "rules": _vm.rules,
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var classes = _ref.classes,
            errors = _ref.errors;
        return [_c('div', {
          staticClass: "form-label-group"
        }, [_c('v-select', {
          ref: "vselect",
          staticClass: "unNumber-dropdown",
          class: ['un-number-select', 'form-control'].concat(_vm.sizeClass).concat(_vm.value ? 'has-value' : '').concat(_vm.filterValidationClasses(classes)),
          attrs: {
            "disabled": !_vm.editable,
            "options": _vm.results,
            "loading": _vm.isLoading,
            "filterable": false,
            "clearable": false,
            "select-on-tab": false,
            "value": _vm.value,
            "placeholder": _vm.$t('cargo-item.adr.un-number.short'),
            "label": "unNumber"
          },
          on: {
            "open": _vm.onOpen,
            "close": _vm.onClose,
            "search": _vm.onSearch,
            "input": _vm.onInput
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(option) {
              return [_c('strong', [_vm._v(_vm._s(option.unNumber))]), _c('br'), option.packagingGroup ? _c('span', [_vm._v("VG " + _vm._s(option.packagingGroup) + ": ")]) : _vm._e(), _c('span', [_vm._v(_vm._s(option.name))]), option.transportCategory ? _c('span', [_vm._v(" / " + _vm._s(option.transportCategory))]) : _vm._e(), option.tunnelRestrictionCode ? _c('span', [_vm._v(" / " + _vm._s(option.tunnelRestrictionCode))]) : _vm._e()];
            }
          }, {
            key: "list-footer",
            fn: function fn() {
              return [_c('li', {
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasNextPage,
                  expression: "hasNextPage"
                }],
                ref: "load",
                staticClass: "loader"
              }, [_vm._v(" Loading... ")])];
            },
            proxy: true
          }], null, true)
        }), _c('label', {
          staticClass: "required"
        }, [_vm._v(_vm._s(_vm.$t('cargo-item.adr.un-number.short')))]), _c('span', {
          staticClass: "invalid-feedback"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }], null, false, 4108742597)
  }) : _c('e-form-text-input', {
    attrs: {
      "id": _vm.id,
      "name": "unNumber",
      "size": _vm.size,
      "placeholder": _vm.$t('cargo-item.adr.un-number.short'),
      "rules": _vm.rules,
      "disabled": "",
      "value": _vm.value
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }