<template>
  <validation-observer :ref="id + '-observer'"
                       v-slot="{ invalid, changed, dirty, failed, handleSubmit }" slim>

    <form @submit.prevent="handleSubmit(onSubmit)">

      <div class="row">

        <div class="col-md-4">

          <div class="form-group row">

            <label :for="id + '-name'"
                   class="col-sm-3 col-form-label text-right">{{ $t('template.name') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-name'"
                                 name="name" size="sm" rules="required"
                                 :disabled="!canModify()"
                                 v-model="value.name" />
            </div>

          </div>

          <div class="form-group row">
            <div class="col-sm-9 offset-sm-3 ">
              <div class="row">
                <div class="col-sm-6">
                  <e-form-checkbox :id="id + '-default'"
                                   name="default" size="sm"
                                   :disabled="!canModify()"
                                   :placeholder="$t('cargo-item.isDefault')"
                                   v-model="value.isDefault"/>
                </div>

                <div class="col-sm-6">
                  <e-form-checkbox :id="id + '-dangerousGoods'"
                                   name="dangerousGoods" size="sm"
                                   :disabled="!canModify()"
                                   :placeholder="$t('cargo-item.dangerousGoods')"
                                   v-model="value.dangerousGoods"/>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">

            <label :for="id + '-colliQty'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.colli') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-colliQty'" size="sm"
                                 :disabled="!canModify()"
                                 v-model="value.colliQty"/>
            </div>

          </div>

          <div class="form-group row">

            <label :for="id + '-goods'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.goods') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-goods'"
                                 size="sm" maxlength="30"
                                 :disabled="!canModify()"
                                 v-model="value.goods"/>
            </div>

          </div>

        </div>

        <div class="col-md-4">

          <div class="form-group row">

            <label :for="id + '-packagingCode'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.packingCode') }}</label>
            <div class="col-sm-9">
              <packaging-code-select :id="id + '-packagingCode'" name="packagingCode"
                                     :disabled="!canModify()"
                                     v-model="value.packingCode" size="sm" />

            </div>

          </div>

          <div class="form-group row">

            <label :for="id + '-marking'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.marking') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-marking'"
                                 name="Name" maxlength="17" size="sm"
                                 :disabled="!canModify()"
                                 v-model="value.marking"/>
            </div>

          </div>

          <div class="form-group row">

            <label :for="id + '-orderNumber'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.orderNumber') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-orderNumber'"
                                 maxlength="35" size="sm"
                                 :disabled="!canModify()"
                                 v-model="value.orderNumber"/>
            </div>

          </div>

        </div>

        <div class="col-md-4">

          <div class="form-group row">

            <label :for="id + '-weight'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.weight') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-weight'" size="sm"
                                 :append="$t('unit.kilogram')"
                                 :type="'number'"
                                 :min="'0'"
                                 :step="'1'"
                                 :max="'99999'"
                                 :disabled="!canModify()"
                                 v-model="value.weight"/>
            </div>

          </div>

          <div class="form-group row">

            <label :for="id + '-length'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.length') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-length'" size="sm"
                                 :append="$t('unit.centimeter')"
                                 :type="'number'"
                                 :min="'0'"
                                 :step="'1'"
                                 :max="'999'"
                                 :disabled="!canModify()"
                                 v-model="value.length"/>
            </div>

          </div>

          <div class="form-group row">

            <label :for="id + '-width'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.width') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-width'" name="Straße" size="sm"
                                 :append="$t('unit.centimeter')"
                                 :type="'number'"
                                 :min="'0'"
                                 :step="'1'"
                                 :max="'999'"
                                 :disabled="!canModify()"
                                 v-model="value.width"/>
            </div>

          </div>

          <div class="form-group row">

            <label :for="id + '-height'"
                   class="col-sm-3 col-form-label text-right">{{ $t('cargo-item.height') }}</label>

            <div class="col-sm-9">
              <e-form-text-input :id="id + '-height'" name="PLZ" size="sm"
                                 :append="$t('unit.centimeter')"
                                 :type="'number'"
                                 :min="'0'"
                                 :step="'1'"
                                 :max="'999'"
                                 :disabled="!canModify()"
                                 v-model="value.height"/>
            </div>

          </div>

        </div>

      </div>

      <div class="row" v-if="canModify() || canDelete()">

        <div class="col-md-6 offset-md-6">
          <div class="form-group row justify-content-end">
            <div class="col-sm-3" v-if="canModify()">
              <button id="saveButton"
                      type="submit"
                      class="btn btn-sm btn-block btn-outline-primary"
                      :disabled="!(changed || dirty) || invalid"
                      @click.prevent="saveEntity()">{{ $t('controls.save') }}</button>
            </div>
            <div class="col-sm-3" v-if="canDelete()">
              <button id="deleteButton"
                      class="btn btn-sm btn-block btn-outline-danger"
                      @click.prevent="deleteEntity()">{{ $t('controls.delete') }}</button>
            </div>
          </div>
        </div>

      </div>

    </form>

  </validation-observer>
</template>

<script>

import service from "@/services/cargoListTemplate.service";
import {log, ValidationObserver, ValidationProvider} from "@emons/emons-vue"
import PackagingCodeSelect from "@/components/form-controls/PackagingCodeSelect";

export default {
  name: "CargoTemplateView",
  components: {
    PackagingCodeSelect,
    ValidationObserver,
    ValidationProvider
  },
  mounted() {
    log('debug', 'mounted()...')
    if (this.value._links)
      this.getEntityDetails()
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  computed: {

  },
  methods: {
    isNew() {
      return !this?.value?._links?.self
    },
    canDelete() {
      return this.isNew() || !!this?.value?._links?.delete
    },
    canModify() {
      return this.isNew() || !!this?.value?._links?.modify
    },
    getEntityDetails: function () {
      this.$emit('enableSpinner');
      service
          .getEntityDetails(this.value._links.self.href)
          .then(response => {
            Object.assign(this.value, response.data)
            this.$emit('disableSpinner')
          })
    },
    deleteEntity() {
      this.$emit('enableSpinner');
      service.delete(this.value)
          .then(response => {
            this.$emit('delete', this.index)
            this.$emit('disableSpinner')
          })
    },
    saveEntity() {
      this.$emit('enableSpinner');
      service.save(this.value)
          .then(response => {
            Object.assign(this.value, response.data)
            this.value['isOpen'] = false
            this.$emit('disableSpinner')
          })
    },
    onSubmit() {
      this.$emit('enableSpinner');
      this.service.save(this.value)
          .then(response => {
            this.$refs[this.id + '-observer'].reset()
            this.$emit('save', response.data)
            this.$emit('disableSpinner')
          })
          .catch(error => {
            this.$emit('disableSpinner')
            if (error.response && error.response.data && error.response.data.errors) {
              this.$refs[this.id + '-observer'].setErrors(error.response.data.errors)
            }
          })
    },
  }
}
</script>

<style scoped>

</style>