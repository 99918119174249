var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty,
            failed = _ref.failed,
            handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('label', [_c('strong', [_vm._v(_vm._s(_vm.$t('address.address-data')))])])])]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-7"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-name',
            "name": "name",
            "rules": "required",
            "placeholder": _vm.$t('address.name'),
            "disabled": !_vm.isAddressDataEditable
          },
          model: {
            value: _vm.value.name,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "name", $$v);
            },
            expression: "value.name"
          }
        })], 1), _c('div', {
          staticClass: "col-5"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-customerId',
            "name": "customerId",
            "rules": "required",
            "placeholder": _vm.$t('address.customerId'),
            "disabled": !_vm.isAddressDataEditable
          },
          model: {
            value: _vm.value.customerId,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "customerId", $$v);
            },
            expression: "value.customerId"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-7"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-additionalName',
            "name": "additionalName",
            "placeholder": _vm.$t('address.additionalName'),
            "disabled": !_vm.isAddressDataEditable
          },
          model: {
            value: _vm.value.additionalName,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "additionalName", $$v);
            },
            expression: "value.additionalName"
          }
        })], 1), _c('div', {
          staticClass: "col-5"
        }, [_c('validation-provider', [_c('b-form-checkbox', {
          attrs: {
            "id": _vm.id + '-isDefault',
            "name": "isDefault",
            "disabled": !_vm.isEditable
          },
          model: {
            value: _vm.value.isDefault,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "isDefault", $$v);
            },
            expression: "value.isDefault"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('invoiceRecipient.isDefault')) + " ")])], 1)], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-street',
            "name": "street",
            "rules": "required",
            "placeholder": _vm.$t('address.streetWithNumber'),
            "disabled": !_vm.isAddressDataEditable
          },
          model: {
            value: _vm.value.street,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "street", $$v);
            },
            expression: "value.street"
          }
        })], 1)]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-3"
        }, [_c('country-select', {
          attrs: {
            "id": _vm.id + '-country',
            "name": "country",
            "required": true,
            "placeholder": _vm.$t('address.country'),
            "disabled": !_vm.isAddressDataEditable
          },
          model: {
            value: _vm.value.country,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "country", $$v);
            },
            expression: "value.country"
          }
        })], 1), _c('div', {
          staticClass: "col-3"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-zipCode',
            "name": "zipCode",
            "rules": {
              required: true,
              zipCode: {
                country: '@' + _vm.id + '-country'
              }
            },
            "placeholder": _vm.$t('address.zipCode'),
            "disabled": !_vm.isAddressDataEditable
          },
          on: {
            "blur": function blur($event) {
              return _vm.formatZipCode($event);
            }
          },
          model: {
            value: _vm.value.zipCode,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "zipCode", $$v);
            },
            expression: "value.zipCode"
          }
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('city-select', {
          attrs: {
            "id": _vm.id + '-city',
            "name": "city",
            "rules": "required",
            "disabled": !_vm.isAddressDataEditable,
            "country": _vm.value.country,
            "zip-code": _vm.value.zipCode
          },
          model: {
            value: _vm.value.city,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "city", $$v);
            },
            expression: "value.city"
          }
        })], 1)])]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('label', [_c('strong', [_vm._v(_vm._s(_vm.$t('address.contact-data')))])])])]), _c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-contactName',
            "name": "contactName",
            "placeholder": _vm.$t('address.consignee.contactName'),
            "disabled": !_vm.isEditable
          },
          model: {
            value: _vm.value.contactName,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "contactName", $$v);
            },
            expression: "value.contactName"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-12 col-md-6"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-phone',
            "name": "phone",
            "rules": "phoneNumber",
            "placeholder": _vm.$t('address.phone'),
            "disabled": !_vm.isEditable
          },
          model: {
            value: _vm.value.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "phone", $$v);
            },
            expression: "value.phone"
          }
        })], 1)])])]), _vm.showActions && (_vm.isEditable || _vm.isAddressDataEditable || _vm.isDeletable) ? _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6 offset-md-6"
        }, [_c('div', {
          staticClass: "form-group row justify-content-end"
        }, [_vm.isEditable || _vm.isAddressDataEditable ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-primary",
          attrs: {
            "id": "saveButton",
            "type": "submit",
            "disabled": _vm.axiosSaveInProgress || !(changed || dirty) || invalid
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveEntity();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.save')))])]) : _vm._e(), _vm.isDeletable ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-danger",
          attrs: {
            "id": "deleteButton"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.confirmDelete();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t('controls.delete')))])]) : _vm._e()])])]) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }