import Vue from 'vue'
import EmonsVue from '@emons/emons-vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import ApiService from "@/services/api.service";
import config from '@/config'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AsyncComputed from "vue-async-computed";

Vue.config.productionTip = false
Vue.config.devtools = true

ApiService.init(process.env.VUE_APP_ROOT_API)

// bootstrap, icons, emons-vue
Vue.use(AsyncComputed)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(EmonsVue, config)
Vue.component('v-select', vSelect)

// wait for keycloak to initialize - must be called before creating Vue instance because of router initialization...
EmonsVue.init(config).then(() => {
  const vm = new Vue(config)

  // remove when development finished...
  vm.$options.i18n.mergeLocaleMessage('de', require('./locales/de.json'))
  vm.$options.i18n.mergeLocaleMessage('en', require('./locales/en.json'))
  vm.$options.i18n.mergeLocaleMessage('cs', require('./locales/cs.json'))
  vm.$options.i18n.mergeLocaleMessage('sk', require('./locales/sk.json'))

  // mount app after messages have been loaded from backend
  /*
  vm.loadMessagesFromBackend().then(() => {
    vm.$mount('#app')
  })
   */
  EmonsVue.vm = vm
  vm.$mount('#app')
})

